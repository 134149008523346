import { BrowserModule } from '@angular/platform-browser';
import { NgModule, CUSTOM_ELEMENTS_SCHEMA} from '@angular/core';
import {Router, RouterModule, Routes, Scroll} from '@angular/router';
import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import {HTTP_INTERCEPTORS, HttpClient, HttpClientModule} from '@angular/common/http';
import {FormsModule} from '@angular/forms';

// Third party plugins
import {NgbDropdown, NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import {TranslateModule, TranslateLoader} from '@ngx-translate/core';
import {TranslateHttpLoader} from '@ngx-translate/http-loader';
import {TrackingModule} from './tracking/tracking.module';
import {NgxCaptchaModule} from 'ngx-captcha';
import 'hammerjs';
import 'mousetrap';
import { GalleryModule} from '@ks89/angular-modal-gallery';


import { AppComponent } from './containers/app.component';
import { AccountModule} from './account/account.module';
import { AboutModule} from './about/about.module';
import { ProductsModule} from './products/products.module';
import { AuthModule} from './auth/auth.module';
import { ShoppingModule} from './shopping/shopping.module';
import { AppHeaderComponent} from './components/app-header/app-header.component';
import { AppFooterComponent} from './components/app-footer/app-footer.component';
import { HomeModule} from './home/home.module';
import { NewsModule} from './news/news.module';
import {Store} from '../store';
import {UserService} from './services/user/user.service';
import {ShoppingCartService} from './services/shopping-cart.service';
import {ContactModule} from './contact/contact.module';
import {ProductService} from './services/product.service';
import {Globals} from './global';
import {NewsService} from './services/news.service';
import {CarouselService} from './services/carousel.service';
import {StoresModule} from './hume-stores/stores.module';
import {TestimonialService} from './services/testimonial.service';
import {CategoriesModule} from './categories/categories.module';
import {InventoryCategoriesService} from './services/inventory-categories.service';
import {HumeStoresService} from './services/hume-stores.service';
import {ContactService} from './services/contact.service';
import {TrackingService} from './services/tracking.service';
import {OrderFormComponent} from './components/order-form/order-form.component';
import {SharedModule} from './shared/shared.module';
import {ProjectsModule} from './project/projects.module';
import {Projectervice} from './services/project.service';
import {SearchService} from './services/search.service';
import {PipesModule} from './pipe/pipes.module';
import {SEOService} from './services/seo/seo.service';
import {TrackingComponent} from './tracking/tracking.component';
import {CategoriesComponent} from './categories/categories.component';
import {WebCategoriesSubCategoriesComponent} from './categories/sub-categories/sub-categories.component';
import {HomeComponent} from './home/containers/home.component';
import {JwtInterceptor} from './services/auth/jwt.interceptor';
import {HandleErrorService} from './services/handle-error.service';
import {AlertService} from './services/alert.service';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {DataService} from './services/data.service';
import {MatMenuModule} from '@angular/material/menu';
import {MatButtonModule} from '@angular/material/button';
import {PaymentCompletedComponent} from './shopping/payment-completed/payment-completed.component';
import { BrochureComponent } from './brochure/brochure.component';
import {GetLocalStorageService} from './services/get-local-storage.service';
import {ValidationComponent} from './shared/ecommerce/validation/validation.component';
import {OrderTrackingComponent} from './tracking/order-tracking/order-tracking.component';
import {ViewportScroller} from '@angular/common';
import { filter } from 'rxjs/operators';
import {MatProgressSpinnerModule} from '@angular/material/progress-spinner';
import {NgxImageZoomModule} from 'ngx-image-zoom';
import {CareersModule} from './careers/careers.module';
import {MessageService} from 'primeng/api';
import {AuthGuard} from './auth/guards/auth.guard';
import {MakePaymentComponent} from './shared/make-payment/make-payment.component';

// required for AOT compilation
export function HttpLoaderFactory(http: HttpClient) {
  return new TranslateHttpLoader(http);
}

// routes
export const ROUTES: Routes = [
  {path: 'home', redirectTo: '' },
  {path: 'main', redirectTo: '' },
  {path: '', component: HomeComponent},
  {path: 'auth', loadChildren: () => import('./auth/auth.module').then(m => m.AuthModule)},
  {path: 'brochures', component: BrochureComponent},
  {path: 'architectural', component: HomeComponent},
  {path: 'commercial', component: HomeComponent},
  {path: 'order-form', component: OrderFormComponent },
  {path: 'tracking', component: TrackingComponent},
  {path: 'order-tracking', component: OrderTrackingComponent},
  {path: 'account-validation', component: ValidationComponent},
  {path: 'create/payment', canActivate: [AuthGuard], component: MakePaymentComponent},
  {path: 'create/account', component: ValidationComponent},
  {path: 'activate/account', component: ValidationComponent},
  {path: 'payment-complete', component: PaymentCompletedComponent},
  {path: 'AAC', component: CategoriesComponent},
  {path: 'ceiling_tiles', component: CategoriesComponent},
  {path: 'cladding', component: CategoriesComponent},
  {path: 'cfc', component: CategoriesComponent},
  {path: 'doors_and_skylights', component: CategoriesComponent},
  {path: 'fibre_cement', component: CategoriesComponent},
  {path: 'flooring', component: CategoriesComponent},
  {path: 'insulation', component: CategoriesComponent},
  {path: 'kitchens_bathrooms', component: CategoriesComponent},
  {path: 'metals', component: CategoriesComponent},
  {path: 'plasterboard', component: CategoriesComponent},
  {path: 'render', component: CategoriesComponent},
  {path: 'tiles', component: CategoriesComponent},
  {path: 'timber_products', component: CategoriesComponent},
  {path: 'tools_and_accesories', component: CategoriesComponent},
  {path: 'timber', component: CategoriesComponent},
  {path: 'bathrooms', component: CategoriesComponent},
  {path: 'kitchens', component: CategoriesComponent},
  {path: ':link',
    children: [
      {path: '', component: CategoriesComponent},
      {path: ':link', component: WebCategoriesSubCategoriesComponent}
    ]
  },
  {path: '**', pathMatch: 'full',  redirectTo: '' }
];

@NgModule({
  declarations: [
    AppComponent,
    AppHeaderComponent,
    AppFooterComponent,
    OrderFormComponent,
    BrochureComponent,
  ],
  imports: [
    BrowserModule,
    AuthModule,
    HomeModule,
    SharedModule,
    ProductsModule,
    ShoppingModule,
    AccountModule,
    AboutModule,
    NgbModule,
    NewsModule,
    ContactModule,
    CareersModule,
    HttpClientModule,
    ProjectsModule,
    FormsModule,
    NgxCaptchaModule,
    NgxSkeletonLoaderModule,
    RouterModule.forRoot(ROUTES, /*{scrollPositionRestoration: 'enabled',
          anchorScrolling: 'enabled', scrollOffset: [0, 190]}*/),
    BrowserAnimationsModule,
    StoresModule,
    CategoriesModule,
    TrackingModule,
    PipesModule,
    GalleryModule.forRoot(),
    TranslateModule.forRoot({
      loader: {
        provide: TranslateLoader,
        useFactory: HttpLoaderFactory,
        deps: [HttpClient]
      }
    }),
    MatMenuModule,
    MatButtonModule,
    MDBBootstrapModule.forRoot(),
    NgxImageZoomModule,
    MatProgressSpinnerModule,
  ],
  providers: [
    // { provide: APP_INITIALIZER, useFactory: appInitializer, multi: true, deps: [AuthService] },
    { provide: HTTP_INTERCEPTORS, useClass: JwtInterceptor, multi: true },
    // { provide: HTTP_INTERCEPTORS, useClass: ErrorInterceptor, multi: true },
    // { provide: LOCALE_ID, useValue: 'en-US'},
    Store,
    Globals,
    AlertService,
    UserService,
    ShoppingCartService,
    SearchService,
    ProductService,
    NewsService,
    CarouselService,
    TestimonialService,
    InventoryCategoriesService,
    HumeStoresService,
    ContactService,
    TrackingService,
    Projectervice,
    SEOService,
    HandleErrorService,
    DataService,
    GetLocalStorageService,
    NgbDropdown,
    MessageService,
    /*{
      provide: DEFAULT_CONFIG,
      useValue: {
        name: 'MyApp',
        driver: [ // defaults to indexedDB -> webSQL -> localStorage
          Driver.INDEXED_DB,
          Driver.LOCAL_STORAGE
        ]
      } as NgForageOptions
    },
    NGFORAGE_CONFIG_PROVIDER*/
  ],
  exports: [RouterModule],
  bootstrap: [AppComponent],
  schemas: [CUSTOM_ELEMENTS_SCHEMA]
})
export class AppModule {

  // ** Anchor link for pages
  constructor(router: Router, viewportScroller: ViewportScroller) {
    viewportScroller.setOffset([0, 150]);
    router.events.pipe(filter(e => e instanceof Scroll)).subscribe((e: Scroll) => {
      if (e.anchor) {
        // anchor navigation
        setTimeout(() => {
          viewportScroller.scrollToAnchor(e.anchor);
        }, 300);
      } else if (e.position) {
        // backward navigation
        viewportScroller.scrollToPosition(e.position);
      } else {
        // forward navigation
        viewportScroller.scrollToPosition([0, 0]);
      }
    });
  }
  // ** Anchor link for pages
}
