
<div class="text-center mt-3">
  <div class="row">
    <div class="col-12 col-sm-6 mb-4" size="6" *ngFor="let item of current; let i = index">
      <div class="progress">
        <div class="progress-bar g-height-20 progress-bar-striped progress-bar-animated" role="progressbar" aria-valuenow="{{item}}"
             aria-valuemin="0" aria-valuemax="100"  [ngStyle]="{'width' : item + '%'}">{{item + '%'}}</div>
      </div>
      <div class="mb-1 row" *ngIf="item === 100">
        <span class="mx-auto mt-3 text-capitalize">{{fileName[i]}}</span>
      </div>
      <button class="btn u-btn-outline-primary g-font-size-14 text-uppercase g-py-10 g-px-10 mt-2 shadow-none font-weight-bold"
              *ngIf="fileName[i].length > 0" (click)="deleteFile(i)">Delete File</button>
      <div class="row" *ngIf="fileName[i].length <= 0">
        <amplify-photo-picker-ionic class="mx-auto"
                                    path="pics"
                                    (picked)="onImagePicked($event, i)"
                                    (loaded)="onImageLoaded($event, i)">
        </amplify-photo-picker-ionic>
      </div>
    </div>
  </div>
</div>
