import {Component, OnDestroy, OnInit} from '@angular/core';
import {NewsService} from '../../services/news.service';
import {Observable, Subscription} from 'rxjs';
import {NewsCat} from '../models/news.interface';
import {PaginationInstance} from 'ngx-pagination';
import {Router} from '@angular/router';
import {Globals} from '../../global';

declare var $: any;

@Component({
  selector: 'app-news',
  templateUrl: './news.component.html',
  // styleUrls: ['./news.component.scss']
})
export class NewsComponent implements OnInit, OnDestroy {
  allNews;
  filterNews: any;
  newsCat$: Observable<NewsCat[]>;
  newsSub: Subscription;
  newsCatSub: Subscription;
  preLinkLabel = 'all';

  /* Pagination configuration */
  public maxSize = 7;
  public autoHide = false;
  public responsive = false;
  public config: PaginationInstance = {
    id: 'advanced',
    itemsPerPage: 5,
    currentPage: 1
  };
  public labels: any = {
    previousLabel: 'Previous',
    nextLabel: 'Next',
    screenReaderPaginationLabel: 'Pagination',
    screenReaderPageLabel: 'page',
    screenReaderCurrentLabel: `You're on page`
  };
  /* End of Pagination configuration */

  constructor(
    public globals: Globals,
    private router: Router,
    private newsService: NewsService,
  ) {}

  ngOnInit () {
      this.newsSub = this.newsService.getNewsList().subscribe(res => {
        this.allNews = res.response;
        this.filterNews = res.response;
      });

      this.newsCatSub = this.newsService.getNewsCategories().subscribe(res => {
        this.newsCat$ = res.response;
      });
    }

  ngOnDestroy() {
    if (this.newsSub) { this.newsSub.unsubscribe(); }
    if ( this.newsCatSub) { this.newsCatSub.unsubscribe(); }
    }

  categoryNewsCount(name) {
      let count = 0;
      if (this.allNews) {
        if (name === 'all') {
          count = this.allNews.length;
        } else {
          for (let i = 0; i < this.allNews.length; i++) {
            if (this.allNews[i].category === name) {
              count++;
            }
          }
        }
      }
        return count;
    }

  getNewsByCat(name) {
    if (this.preLinkLabel !== null) {
      const elementIdTemp = 'links-label-' + this.preLinkLabel;
      document.getElementById(elementIdTemp).classList.add('g-color-gray-dark-v4');
      document.getElementById(elementIdTemp).classList.remove('g-color-primary', 'font-weight-bold');
    }
    this.preLinkLabel = name;
    const elementId = 'links-label-' + name;
    document.getElementById(elementId).classList.add('g-color-primary', 'font-weight-bold');
    document.getElementById(elementId).classList.remove('g-color-gray-dark-v4');
    if (name === 'all') {
      this.filterNews = this.allNews;
    } else {
      this.filterNews = this.allNews.filter(news => news.category === name);
    }
  }

   // for Mobile version
  getNewsByCatMobile(name) {
    if (this.preLinkLabel !== null) {
      const elementIdTemp = 'links-mobile-label-' + this.preLinkLabel;
      document.getElementById(elementIdTemp).classList.add('g-color-gray-dark-v4');
      document.getElementById(elementIdTemp).classList.remove('g-color-primary', 'font-weight-bold');
    }
    this.preLinkLabel = name;
    const elementId = 'links-mobile-label-' + name;
    document.getElementById(elementId).classList.add('g-color-primary', 'font-weight-bold');
    document.getElementById(elementId).classList.remove('g-color-gray-dark-v4');
    if (name === 'all') {
      this.filterNews = this.allNews;
    } else {
      this.filterNews = this.allNews.filter(news => news.category === name);
    }
  }

  onPageChange(page: number) {
    this.config.currentPage = page;
    window.scrollTo(0, 0);
  }
}
