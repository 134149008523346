<div class="container">

  <h1 class="result-heading" >
    <i class="fa fa-truck hume-truck mr-1"></i>{{ 'TRACKING.Tracking Details' | translate }}</h1>
  <div class="text-center">
    <button type="button" class="btn btn-lg btn-outline-dark waves-light waves-effect mb-4 btn g-color-primary--hover" (click)="homePage()" >
      <i class="fa fa-search"></i>{{ 'TRACKING.Track another order' | translate }}</button>
  </div>
  <mdb-card>
    <mdb-card-header class="white-text btn-hume-color">
      <div class="row text-center">
        <div class="col-md-6">
          <h4><span >{{ 'TRACKING.Order No' | translate }}</span><span id="ps">{{ps}}</span></h4>
        </div>
        <div class="col-md-6">
          <h4><span >{{ 'TRACKING.Suburb' | translate }}</span><span id="suburb">{{suburb}}</span></h4>
        </div>
      </div>

    </mdb-card-header>
    <mdb-card-body>
      <mdb-card-title>
        <div class="row text-center">
          <div class="col-md-4">
            <span class="font-weight-bold" >{{ 'TRACKING.Status' | translate }}</span><span class="font-weight-normal">{{status}}</span>
          </div>
          <div class="col-md-4">
            <span class="font-weight-bold" >{{ 'TRACKING.Expected Date' | translate }}</span><span class="font-weight-normal">{{expectedDate}}</span>
          </div>
          <div class="col-md-4">
            <span class="font-weight-bold" >{{ 'TRACKING.Schedule' | translate }}</span><span class="font-weight-normal">{{schedule}}</span>
          </div>
        </div>
      </mdb-card-title>
      <p></p>
      <!-- List style tracking -->
      <ul class="list-group" style="margin-left:auto; margin-right: auto;" *ngFor="let s of this.processSteps">
        <li class="list-group-item d-flex justify-content-between align-items-center">
              <span class="font-weight-bold">
                <i class="fa fa-check right text-success" *ngIf="s.status"></i>
                <i class="fa fa-close right text-danger" *ngIf="!s.status"></i>
                <span>{{ 'TRACKING.'+ s.title | translate }}</span></span>
          <span class="badge badge-success badge-pill badge-font-size" *ngIf="s.status">{{s.timeStamp}}</span>
          <span class="badge badge-danger badge-pill badge-font-size" *ngIf="!s.status">Pending</span>
        </li>
      </ul>
    </mdb-card-body>
    <button type="button" (click)="externalPage()" class="btn btn-lg btn-hume-color btn-rounded waves-light waves-effect btn-pod-page"  *ngIf="showPod"><span >{{ 'TRACKING.Proof Of Delivery' | translate }}</span></button>
  </mdb-card>
  <p></p>
  <mdb-card [hidden]="mapStatus">
    <mdb-card-body>
      <mdb-card-title>
        <h4 ><i class="fa fa-location-arrow"></i>{{ 'TRACKING.Real-Time tracking' | translate }}</h4>
      </mdb-card-title>
      <div #map [ngStyle]="globals.isMobile ? {'height': '400px'} : {'height': '600px'}"></div>
      <strong id="trackInfo">Distance: {{distance}}, Duration: {{duration}}</strong>
    </mdb-card-body>
  </mdb-card>
  <p></p>

  <!-- <div class="fixed-action-btn" style="bottom: 45px; left: 15px;">

    <a class="btn-floating btn-large btn-hume-color waves-light" mdbWavesEffect (click)="homePage()">
      <i class="fa fa-chevron-left home-page-button"></i>
    </a>
  </div> -->
</div>
