import { Injectable } from '@angular/core';
import swal from 'sweetalert2';

@Injectable()
export class AlertService {
  loading(title) {
    swal.fire({
      title,
      showConfirmButton: false,
      allowEscapeKey: false,
      allowOutsideClick: false,
      // timer: 2000,
      onOpen: () => {
        swal.showLoading();
      }
    });
  }

  showBriefError(error, showButton) {
    swal.fire({
      icon: 'error',
      title: error,
      showConfirmButton: showButton,
      confirmButtonColor: '#ae1e37',
    });
  }

  showError(error, showButton, timer?, tag?) {
    swal.fire({
      icon: 'error',
      /*title: 'Sorry, ' + (error.errorCode ? error.errorCode : error.error ?  error.error : error.length > 0 ?
        (error.toLowerCase().includes('cannot find entity') && tag ? 'Invalid ' + tag : error) :
        'something went wrong, Please contact us via the below contact details.'),*/
      // text: error.message ? error.message : '',
      title: 'Sorry, something went wrong, Please contact us via the below contact details.',
      showConfirmButton: showButton,
      confirmButtonColor: '#ae1e37',
      timer: timer ? timer : 10000,
      footer: '<p class="px-3">Any issue please contact us via <a href="tel:134863">13 4863</a> or send us an email at ' +
        '<a href="mailto:info@humebuildingproducts.com.au">info@humebuildingproducts.com.au</a>. </p>'
    });
  }

  showErrorWithMsg(error, showButton, timer?, tag?) {
    swal.fire({
      icon: 'error',
      title: 'Sorry, ' + (error.errorCode ? error.errorCode : error.error ?  error.error : error.length > 0 ?
        (error.toLowerCase().includes('cannot find entity') && tag ? 'Invalid ' + tag : error) :
        'something went wrong, Please contact us via the below contact details.'),
      text: error.message ? error.message : '',
      showConfirmButton: showButton,
      confirmButtonColor: '#ae1e37',
      timer: timer ? timer : 10000,
      footer: '<p class="px-3">Any issue please contact us via <a href="tel:134863">13 4863</a> or send us an email at ' +
        '<a href="mailto:info@humebuildingproducts.com.au">info@humebuildingproducts.com.au</a>. </p>'
    });
  }

  showDeliveryFreightError(error, showButton, timer?) {
    swal.fire({
      icon: 'error',
      text: error.errorCode ? error.errorCode : error,
      // text: 'Please try it again.',
      showConfirmButton: showButton,
      confirmButtonColor: '#ae1e37',
      timer: timer ? timer : 10000,
      footer: '<p class="px-3">Any issue please contact us via <a href="tel:134863">13 4863</a> or send us an email at ' +
        '<a href="mailto:info@humebuildingproducts.com.au">info@humebuildingproducts.com.au</a>. </p>'
    });
  }

  showSuccess(title, showButton, timer?) {
    swal.fire({
      icon: 'success',
      title: title,
      showConfirmButton: showButton,
      confirmButtonColor: '#ae1e37',
      timer: timer ? timer : 5000,
    });
  }

  showHtmlAlert(icon, html, showButton, timer?) {
    return swal.fire({
      icon: icon,
      html: html,
      showConfirmButton: showButton,
      confirmButtonColor: '#ae1e37',
      timer: timer ? timer : 5000,
    }).then((result) => {
      if (result.value) {
        return true;
      } else {
        return false;
      }
    });
  }

  confirm(title?) {
    return swal.fire({
      title: (title === 'all') ? 'Are you sure to empty the cart?' : 'Are you sure to remove this product from cart?',
      // text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ae1e37',
      cancelButtonColor: '#333',
      confirmButtonText: (title === 'all') ? 'Yes, empty the cart' : 'Yes, remove it'
    }).then((result) => {
      if (result.value) {
        /*Swal.fire(
          'Deleted!',
          'success'
        );*/
        return true;
      } else {
        return false;
      }
    });
  }

  showAlertDialog(title, confirmButtonText) {
    return swal.fire({
      title: title,
      // text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#ae1e37',
      cancelButtonColor: '#333',
      confirmButtonText: confirmButtonText,
    }).then((result) => {
      if (result.value) {
        return true;
      } else {
        return false;
      }
    });
  }

  applying() {
    swal.fire({
      title: 'Now applying the default list',
      // text: this.currentTeamName + '...',
      allowEscapeKey: false,
      allowOutsideClick: false,
      // timer: 2000,
      onOpen: () => {
        swal.showLoading();
      }
    });
  }

  saveSuccess() {
    swal.fire({
      icon: 'success',
      title: 'Saved successfully!',
      showConfirmButton: false,
      timer: 3000,
    });
  }

  saveError(error) {
    swal.fire({
      icon: 'error',
      title: error,
    });
  }

  alertClose() {
    swal.close();
  }
}
