import {Component, OnDestroy, OnInit} from '@angular/core';
import {InventoryCategoriesService} from '../../services/inventory-categories.service';
import {Subscription} from 'rxjs';
import {HumeStoresService} from '../../services/hume-stores.service';
import {OrderPipe} from 'ngx-order-pipe';
import {Globals} from '../../global';
import {ActivatedRoute} from '@angular/router';
import {ProductService} from '../../services/product.service';
import {AuthService} from "../../services/auth/auth.service";

@Component({
  selector: 'app-footer',
  templateUrl: './app-footer.component.html',
})
export class AppFooterComponent implements OnInit, OnDestroy {
  submitted: boolean;
  today: number = Date.now();
  tokenSubscription: Subscription;
  inventSub: Subscription;
  storesSub: Subscription;
  items = [];
  branches = [];
  currentUrl;

  constructor (private inventoryCatService: InventoryCategoriesService,
               public globals: Globals, private productService: ProductService,
               private orderPipe: OrderPipe, private authService: AuthService,
               private  humeStoresService: HumeStoresService) {}

  async ngOnInit() {
    this.inventSub = this.productService.webCat.subscribe(res => {
      this.items = this.inventoryCatService.arrangeArray(res);
    });
    if (!this.globals.token) {
      this.tokenSubscription = await this.authService.token.subscribe(res1 => {
        if (res1) {
          this.globals.token = res1;
        }
      });
    }
    this.currentUrl = window.location.href;

    this.storesSub = this.humeStoresService.getStoresList().subscribe(res => {
      res = res.response.filter(store => store.name.toLowerCase() !== 'sydney' && store.name.toLowerCase() !== 'melbourne');
      const data = this.orderPipe.transform(res, 'name');
      // @ts-ignore
      this.branches = this.inventoryCatService.arrangeArray(data);
    });
  }

  ngOnDestroy(): void {
    if (this.tokenSubscription) { this.tokenSubscription.unsubscribe(); }
    if (this.storesSub) { this.storesSub.unsubscribe(); }
    if (this.inventSub) { this.inventSub.unsubscribe(); }
  }
}
