<div *ngIf="!globals.isMobile else mobile">
  <div class="g-width-300">
    <!--<h2 class="h2 mb-4">Categories</h2>-->
    <a mat-menu-item class="font-weight-bold g-bg-primary--hover g-color-white" (click)="openCategory('specials', 'Hume Specials')">Hume Specials</a>
    <a class="font-weight-bold g-bg-primary--hover g-color-white" *ngFor="let category of eCategories, let i = index"
            mat-menu-item [matMenuTriggerFor]="subMenu" (mouseover)="openCategory(category?.id, category?.name, category?.useGrouping)">{{category?.name}}</a>

          <!-- Submenu (level 2) -->
          <mat-menu #subMenu="matMenu">
            <a mat-menu-item class="font-weight-bold g-bg-primary--hover g-color-white" *ngFor="let item of subCategories"
                    (click)="openSubCategory(item)" style="max-width: 450px !important;">{{item?.name ? item?.name : item?.description}}</a>
          </mat-menu>
          <!-- End Submenu (level 2) -->
  </div>
</div>
<ng-template #mobile>
  <p-accordion id ="category-menu-accordion" style="overflow: auto; max-height: 65vh;">
    <p-accordionTab [header]="category?.name" *ngFor="let category of eCategories" (click)="openCategoryMenu(category)">
      <a>
        <p class="g-color-white" *ngFor="let item of subCategories" (click)="openSubCategory(item)">{{item?.name ? item?.name : item?.description}}</p>
      </a>
    </p-accordionTab>
  </p-accordion>
    <!--<accordion id="category-menu-accordion" [closeOthers]="true" [isAnimated]="true" style="overflow: auto; max-height: 65vh;">
      <accordion-group panelClass="category-menu-item font-weight-bold g-bg-primary--hover g-color-white" [heading]="category?.name"
                       *ngFor="let category of eCategories" (isOpenChange)="openCategoryMenu(category)">
        <a>
          <p class="g-color-white" *ngFor="let item of subCategories" (click)="openSubCategory(item)">{{item?.name ? item?.name : item?.description}}</p>
        </a>
      </accordion-group>
    </accordion>-->
</ng-template>

<!-- Basic dropdown -->
<!--<div class="text-center mt-2" *ngIf="globals.isMobile">
  <ngb-accordion #acc="ngbAccordion" [activeIds]="activeIds">
    <ngb-panel id="mobile-cat">
      <ng-template ngbPanelTitle>
        <span id="cat-icon-bottom" class="g-color-white"><b>Categories</b><i class="ml-3 hs-icon hs-icon-arrow-bottom"></i></span>
        <span id="cat-icon-up" class="g-color-white"><b>Categories</b><i class="ml-3 hs-icon hs-icon-arrow-top"></i></span>
      </ng-template>
      <ng-template ngbPanelContent>
        <div class="accordion md-accordion my-3" id="accordionEx" role="tablist" aria-multiselectable="true">
          <ul class="list-unstyled">
            <h5 class="dropdown-item g-color-primary font-weight-bold">Online offers</h5>
            <li class="dropdown-item">
              <a class="pl-3 nav-link g-color-gray-dark-v4 font-weight-bold" routerLink="/products">Hume Specials</a>
            </li>
            <li class="dropdown-item">
              <a class="pl-3 nav-link g-color-gray-dark-v4 font-weight-bold" routerLink="/categories/Tools_&_accessories">Tools</a>
            </li>
            <li class="dropdown-item">
              <a class="pl-3 nav-link g-color-gray-dark-v4 font-weight-bold" routerLink="/categories/Tools_&_accessories">Accessories</a>
            </li>
            <h5 class="dropdown-item g-color-primary font-weight-bold">Building products</h5>
            <li class="dropdown-item" *ngFor="let category of globals.webCategories">
              <a class="pl-3 nav-link g-color-gray-dark-v4 font-weight-bold"
                 [routerLink]="category?.name === 'Compressed fibre cement' || category?.name === 'BiPV Solar' ? ['/cladding/' + category?.link] : ['/categories/' + category?.link]">{{category?.name}}</a>
            </li>
          </ul>
        </div>
      </ng-template>
    </ngb-panel>
  </ngb-accordion>
</div>-->

<!-- Basic dropdown -->


