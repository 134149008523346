import {AfterViewChecked, AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {Globals} from '../../global';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {AlertService} from '../../services/alert.service';
import {AuthService} from '../../services/auth/auth.service';
import {UserService} from '../../services/user/user.service';
import {ShoppingCartService} from '../../services/shopping-cart.service';

declare var securePayUI: any;

@Component({
  selector: 'app-make-payment',
  templateUrl: './make-payment.component.html',
  styleUrls: ['./make-payment.component.scss']
})
export class MakePaymentComponent implements OnInit, AfterViewInit, OnDestroy {
  routeSub: Subscription;
  userSub: Subscription;
  currentUser;
  mySecurePayUI;
  quotation;
  order;
  isCardFormValid: (boolean) = false;
  showButton = true;
  submitted = false;
  placeButtonError;
  orderButton = 'card';
  accountBalance;

  constructor(public globals: Globals, private activatedRoute: ActivatedRoute, private alertService: AlertService, private router: Router,
              private authService: AuthService, private userService: UserService, private shoppingCartService: ShoppingCartService) { }

  ngOnInit(): void {
   this.routeSub = this.activatedRoute.queryParams.subscribe(res => {
      this.quotation = res.order;
    });

    this.userSub = this.authService.currentUser.subscribe(x => {
      this.currentUser = x;
      if (this.currentUser?.type === 2 && this.currentUser?.access[0] === 'FULL') {
        this.userService.getCustomerCreditBalance().toPromise().then(res => {
          this.accountBalance = res;
        });
      }
    });

    this.userService.getQuotationDetailsByNumber(this.quotation).toPromise().then(res => {
      this.order = res;
    });
  }

  ngAfterViewInit() {
    setTimeout(() => {
      this.securePayInit();
      this.eventListeners();
    }, 1000);
  }

  eventListeners() {
    const element = document.getElementById('accordion-07-heading-01');
    if (element) {
      element.addEventListener('click', () => { this.orderButton = 'card'; });
    }
  }

  securePayInit() {
    const self = this;
    // const uuid = this.globals.cart?.cartUuid;
    this.mySecurePayUI = new securePayUI.init(
      { /** for live **/
        containerId: 'pay-container', scriptId: 'securepay-ui-js', clientId: '0oa9a5c3fdZNAl2VB3l6', merchantCode: '57F0017',
        /** for live **/

        card: {
          allowedCardTypes: ['visa', 'mastercard'],
          showCardIcons: true,
          onCardTypeChange: function(cardType) {
            // card type has changed
          },
          onBINChange: function(cardBIN) {
            // card BIN has changed
          },
          onFormValidityChange: function(valid) {
            self.isCardFormValid = valid;
            // form validity has changed
          },
          onTokeniseSuccess: function(tokenisedCard) {
            self.placeButtonError = null;
            // @ts-ignore
            self.paymentProcessAfterTokenise(tokenisedCard.token);
          },
          onTokeniseError: function(errors) {
          }
        },
        style: {
          backgroundColor: 'rgb(238,238,238)',
          label: {
            font: {
              family: 'Arial, Helvetica, sans-serif',
              size: '0.75rem',
            }
          },
          input: {
            font: {
              family: 'Arial, Helvetica, sans-serif',
              size: '1.1rem',
              color: 'darkblue'
            }
          }
        },
        onLoadComplete: function () {
          // the UI Component has successfully loaded and is ready to be interacted with
        }
      });
  }

  paymentProcessAfterTokenise(token?) {
    const object = {
      cardToken: this.orderButton === 'card' ? token : 'credit',
      total: '',
      freight: '',
      grantTotal: this.order?.header?.grossAmount,
      quoteNumber: this.quotation,
      // ** 0: order, 1: quote, 2: account
      type: 1
    };

    // ** For live payment ** //
    this.shoppingCartService.cardPaymentV2(object).subscribe(res => {
      // @ts-ignore
      switch (res.status) {
        case 'failed':
          // @ts-ignore
          const error = res.gatewayResponseMessage.split(':')[0];
          this.alertService.showError(error, true);
          break;
        case 'paid':
        case 'Pass-through':
          // this.alertService.showSuccess('Creating your order...', false);
          // this.createOrder(res);
          this.alertService.showSuccess('Your quotation has been paid successfully', true, 5000);
          this.shoppingCartService.paymentSuccess();
          break;
      }
    }, error => {
      this.alertService.showError(
        'Your payment was unsuccessful, Please contact us via the below contact details.',
        true, 5000);
    });
    // ** For live payment ** //
  }

  paymentSubmit() {
    this.submitted = true;
    if (this.orderButton === 'card') {
      if (this.isCardFormValid) { this.cardFormValidProcess(); } else { this.cardFormInvalid(); }
    } else if (this.orderButton === 'credit') {
      this.paymentProcessAfterTokenise();
    } else {
      this.paymentMethodError();
    }
  }

  paymentChange(event, tag) {
    if (event.target.checked) {
      this.orderButton = tag;
    } else  {
      this.orderButton = '';
    }
  }

  paymentMethodError() {
    this.placeButtonError = 'Please select at least one payment method!';
    setTimeout(() => {
      this.placeButtonError = null;
    }, 8000);
  }

  cardFormValidProcess() {
    this.alertService.loading('Payment is processing... Please do not refresh the page!');
    this.mySecurePayUI.tokenise();
    // this.testFunc();
  }

  cardFormInvalid() {
    if (!this.isCardFormValid) {
      this.placeButtonError = 'Please fill correct payment information';
      setTimeout(() => {
        this.placeButtonError = null;
      }, 8000);
    }
  }


  formatAddress(address) {
    return (address?.addressLine1 ? address?.addressLine1 : '') + (address?.addressLine2 ? (' ' + address?.addressLine2) : '')
      + (address?.suburb ? (' ' + address?.suburb) : '')
      + (address?.state ? (', ' + address?.state) : '') + (address?.postcode ? (' ' + address?.postcode) : '');
  }

  expandList() {
    if (this.showButton) {
      document.getElementById('order-content').style.maxHeight = '100%';
      this.showButton = false;
    } else {
      document.getElementById('order-content').style.maxHeight = '300px';
      this.showButton = true;
    }
  }

  ngOnDestroy() {
    if (this.routeSub) { this.routeSub.unsubscribe(); }
  }

}
