<section class="g-brd-bottom g-brd-gray-light-v5 g-py-10" *ngIf="catWebName">
  <div class="container">
    <div class="row">
      <ul class="u-list-inline g-font-size-13 g-font-size-16--md">
        <li class="list-inline-item g-mr-5">
          <a class="u-link-v5 g-color-primary" [routerLink]="'/products/'">All Products</a>
          <i class="g-color-black g-ml-5 fa fa-angle-right"></i>
        </li>
        <li class="list-inline-item g-mr-5">
          <a class="u-link-v5 g-color-primary g-color-black--hover mr-1 text-capitalize" [matMenuTriggerFor]="catMat" (click)="triggerOpenCategory('main')"
             *ngIf="catWebName?.toLowerCase() !=='hume eofy' && catWebName?.toLowerCase() !== 'hume specials' else others">{{catWebName}}</a>
          <ng-template #others>
            <span class="u-link-v5 g-color-primary g-color-black--hover mr-1 text-capitalize">{{catWebName?.includes('eofy') ? 'Hume EOFY Sales' : catWebName}}</span>
          </ng-template>
          <mat-menu #catMat="matMenu">
            <a mat-menu-item class="font-weight-bold g-bg-primary--hover g-color-white" *ngFor="let item of subCategories" (click)="triggerOpenCategory('sub')"
               [routerLink]="'/products/' + catWebName?.split(' ').join('_') + '/' + (item?.name ? (item?.name?.split(' ').join('_')) : (item?.description?.split(' ').join('_')))"
               style="max-width: 450px !important;">{{item?.name ? item?.name : item?.description}}</a>
          </mat-menu>
          <i class="g-color-black g-ml-5 fa fa-angle-right"></i>
        </li>
        <li class="list-inline-item g-mr-5">
          <span class="u-link-v5 g-color-black text-capitalize" *ngIf="page === 'list' else detailsPage">{{subCatName}}</span>
          <ng-template #detailsPage>
            <a class="u-link-v5 g-color-primary text-capitalize" [routerLink]="'/products/' + catWebName?.split(' ').join('_') + '/' + (subCatName?.split(' ').join('_'))">{{subCatName}}</a>
          </ng-template>
        </li>
      </ul>
    </div>
    <h1 class="g-color-primary font-weight-bold pt-3 g-font-size-23 text-capitalize g-font-size-35--md" *ngIf="page === 'list'">{{subCatName ? subCatName : (catWebName?.includes('eofy') ? 'Hume EOFY Sales' : catWebName)}}</h1>
  </div>
</section>
