<!--Mobile Social Icons -->
<!--<div class="text-center">
  <h3 class="h6 g-color-black g-font-weight-600 text-uppercase mb-3">Share:</h3>
  <ul class="list-inline g-mb-60">
    <li class="list-inline-item g-mx-2">
      <a class="u-icon-v1 u-icon-slide-up--hover g-color-gray-dark-v4 g-color-facebook--hover" href="#!">
        <i class="g-font-size-default g-line-height-1 u-icon__elem-regular fa fa-facebook"></i>
        <i class="g-font-size-default g-line-height-0_8 u-icon__elem-hover fa fa-facebook"></i>
      </a>
    </li>
    <li class="list-inline-item g-mx-2">
      <a class="u-icon-v1 u-icon-slide-up--hover g-color-gray-dark-v4 g-color-twitter--hover" href="#!">
        <i class="g-font-size-default g-line-height-1 u-icon__elem-regular fa fa-twitter"></i>
        <i class="g-font-size-default g-line-height-0_8 u-icon__elem-hover fa fa-twitter"></i>
      </a>
    </li>
    <li class="list-inline-item g-mx-2">
      <a class="u-icon-v1 u-icon-slide-up--hover g-color-gray-dark-v4 g-color-google-plus--hover" href="#!">
        <i class="g-font-size-default g-line-height-1 u-icon__elem-regular fa fa-google-plus"></i>
        <i class="g-font-size-default g-line-height-0_8 u-icon__elem-hover fa fa-google-plus"></i>
      </a>
    </li>
    <li class="list-inline-item g-mx-2">
      <a class="u-icon-v1 u-icon-slide-up--hover g-color-gray-dark-v4 g-color-linkedin--hover" href="#!">
        <i class="g-font-size-default g-line-height-1 u-icon__elem-regular fa fa-linkedin"></i>
        <i class="g-font-size-default g-line-height-0_8 u-icon__elem-hover fa fa-linkedin"></i>
      </a>
    </li>
  </ul>
</div>-->
<!-- End Mobile Social Icons -->

<!-- Share -->
<div class="my-3">
  <!--<h3 class="h5 g-color-black mb-3">Share:</h3>-->
  <ul class="list-inline mb-0">
    <li class="list-inline-item mr-1 mb-1">
      <a class="btn u-btn-outline-facebook g-rounded-25" href="#!">
        <i class="mr-1 fa fa-facebook"></i>
        <span>Facebook</span>
      </a>
    </li>
    <li class="list-inline-item mx-1 mb-1">
      <a class="btn u-btn-outline-twitter g-rounded-25" href="#!">
        <i class="mr-1 fa fa-twitter"></i>
        <span>Twitter</span>
      </a>
    </li>
    <li class="list-inline-item mx-1 mb-1">
      <a class="btn u-btn-outline-linkedin g-rounded-25" href="#!">
        <i class="mr-1 fa fa-linkedin"></i>
        <span>Linkedin</span>
      </a>
    </li>
  </ul>
</div>
<!-- End Share -->
