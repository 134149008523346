import {Component, HostListener, OnDestroy, OnInit} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth/auth.service';
import {Globals} from '../../global';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-account',
  templateUrl: './account.component.html',
  // styleUrls: ['./login.component.scss']
})

export class AccountComponent implements OnInit, OnDestroy{
  width;
  currentUser;
  loading = true;
  userSub: Subscription;
  @HostListener('window:resize', [])
  private onResize() {
    this.width = window.innerWidth;
    this.resizeSwitch();
  }

  constructor(private router: Router, private authService: AuthService, public globals: Globals) {
    this.width = window.innerWidth;
  }

  ngOnInit() {
    this.resizeSwitch();
    this.authService.currentUser.subscribe(x => {
      this.currentUser = x;
    });
  }

  resizeSwitch() {
    if (window.location.href.includes('account-menu') && this.width > 768) {
      this.router.navigate(['/account/details']);
    }
  }

  signOut() {
    this.authService.logoutUser();
  }

  ngOnDestroy() {
    if (this.userSub) { this.userSub.unsubscribe(); }
  }

}
