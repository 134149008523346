<!-- Google place auto complete component -->

<h5 class="g-font-size-17 font-weight-bold g-color-black">{{titleText}}</h5>

<div class="multi-collapse collapse show" id="mannual_address1">
  <input type="text" class="form-control u-form-control g-placeholder-gray-light-v1 rounded-0 g-py-15 dropdown-toggle" id="id_address"
         placeholder="Search address" required data-msg="This field is mandatory" data-error-class="u-has-error-v1" data-success-class="u-has-success-v1">
</div>

<div class="text-center mt-2">
  <div class="d-inline-block w-25 text-center g-height-2 g-bg-primary my-2" style="width: 100%;"></div>
</div>

<form [formGroup]="addressForm">
  <div class="row mt-3">
    <div class="col-6">
      <div class="form-group" [hidden]="pageUrl === 'cod'">
        <label class="d-block g-color-gray-dark-v2 g-font-size-13">
          <span class="g-color-primary">*</span>First name</label>
        <input class="form-control u-form-control g-placeholder-gray-light-v1 rounded-0 g-py-15" name="firstName" type="text" placeholder="First Name" formControlName="firstName">
        <div class="error" *ngIf="f.get('firstName').errors && submitted && ( f.get('firstName').dirty || f.get('firstName').touched || f.get('firstName').invalid)">
          <div *ngIf="f.get('firstName').hasError('required')">
            First name is required.
          </div>
        </div>
      </div>
    </div>
    <div class="col-6" [hidden]="pageUrl === 'cod'">
      <div class="form-group">
        <label class="d-block g-color-gray-dark-v2 g-font-size-13">
          <span class="g-color-primary">*</span>Last name</label>
        <input class="form-control u-form-control g-placeholder-gray-light-v1 rounded-0 g-py-15" name="lastName" type="text" placeholder="Last name" formControlName="lastName">
        <div class="error" *ngIf="f.get('lastName').errors && submitted && ( f.get('lastName').dirty || f.get('lastName').touched || f.get('lastName').invalid)">
          <div *ngIf="f.get('lastName').hasError('required')">
            Last name is required.
          </div>
        </div>
      </div>
    </div>
    <div class="col-12">
      <div class="form-group">
        <label class="d-block g-color-gray-dark-v2 g-font-size-13">
          <span class="g-color-primary">*</span>Street address</label>
        <input class="form-control u-form-control g-placeholder-gray-light-v1 rounded-0 g-py-15" name="address" type="text" placeholder="Street address" formControlName="address">
        <div class="error" *ngIf="f.get('address').errors && submitted && ( f.get('address').dirty || f.get('address').touched || f.get('address').invalid)">
          <div *ngIf="f.get('address').hasError('required')">
            Address is required.
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6">
      <div class="form-group">
        <label class="d-block g-color-gray-dark-v2 g-font-size-13">
          <span class="g-color-primary">*</span>Suburb</label>
        <input class="form-control u-form-control g-placeholder-gray-light-v1 rounded-0 g-py-15" name="suburb" type="text" placeholder="Suburb" formControlName="suburb">
        <div class="error" *ngIf="f.get('suburb').errors && submitted && ( f.get('suburb').dirty || f.get('suburb').touched || f.get('suburb').invalid)">
          <div *ngIf="f.get('suburb').hasError('required')">
            Suburb is required.
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6">
      <div class="form-group">
        <label class="d-block g-color-gray-dark-v2 g-font-size-13">
          <span class="g-color-primary">*</span>State</label>
        <input class="form-control u-form-control g-placeholder-gray-light-v1 rounded-0 g-py-15" name="state" type="text" placeholder="State" formControlName="state">
        <div class="error" *ngIf="f.get('state').errors && submitted && ( f.get('state').dirty || f.get('state').touched || f.get('state').invalid)">
          <div *ngIf="f.get('state').hasError('required')">
            State is required.
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6">
      <div class="form-group">
        <label class="d-block g-color-gray-dark-v2 g-font-size-13">
          <span class="g-color-primary">*</span>Post code</label>
        <input class="form-control u-form-control g-placeholder-gray-light-v1 rounded-0 g-py-15" name="postcode" type="text" placeholder="Post code" formControlName="postCode">
        <div class="error" *ngIf="f.get('postCode').errors && submitted && ( f.get('postCode').dirty || f.get('postCode').touched || f.get('postCode').invalid)">
          <div *ngIf="f.get('postCode').hasError('required')">
            Post code is required.
          </div>
        </div>
      </div>
    </div>
    <div class="col-12 col-sm-6" [hidden]="pageUrl === 'cod'">
      <div class="form-group">
        <label class="d-block g-color-gray-dark-v2 g-font-size-13">
          <span class="g-color-primary">*</span>Mobile</label>
        <input class="form-control u-form-control g-placeholder-gray-light-v1 rounded-0 g-py-15" name="mobile" type="text" placeholder="Mobile" formControlName="mobile">
        <div class="error" *ngIf="f.get('mobile').errors && submitted && ( f.get('mobile').dirty || f.get('mobile').touched || f.get('mobile').invalid)">
          <div *ngIf="f.get('mobile').hasError('required')">
            Mobile is required.
          </div>
        </div>
      </div>
    </div>

    <!--<div class="col-12 mt-2" *ngIf="length >= 1">
      <label class="form-check-inline u-check d-block u-link-v5 g-color-text g-pl-30 mb-0">
        <input class="g-hidden-xs-up g-pos-abs g-top-0 g-left-0" type="checkbox" formControlName="default">
        <span class="d-block u-check-icon-checkbox-v4 g-absolute-centered--y g-left-0">
                          <i class="fa" data-check-icon="&#xf00c;"></i>
                        </span>
        Set as Default
      </label>
    </div>-->
  </div>

</form>

<div *ngIf="pageUrl?.toLowerCase()?.includes('contact')">
  <button class="btn u-btn-primary g-font-size-14 font-weight-bold text-uppercase g-py-12 g-px-25 shadow-none" type="submit" (click)="submitAddress(pageUrl)">
    {{pageUrl?.toLowerCase()?.includes('add') ? 'add' : 'update'}}</button>
  <button class="btn u-btn-primary g-font-size-14 font-weight-bold text-uppercase g-py-12 g-px-25 shadow-none" (click)="modalRef.hide()">Cancel</button>
</div>

<div class="multi-collapse collapse" id="mannual_address2">

</div>
