<div class="single-component-container">
  <div class="container g-py-10">
      <app-location></app-location>
    <hr>
    <div class="g-max-width-645 text-center mx-auto g-mb-70 g-mt-50">
      <h2 class="h1 mb-3">Contact Us</h2>
      <p class="g-font-size-17 mb-0">Fill in the form and hit the "Send Message". One of our representatives will get back to you as soon as possible.</p>
    </div>

    <!-- Contact Form -->
    <section id="form">
      <div class="row justify-content-center g-mb-30">
        <div class="col-lg-8">
          <form [formGroup]="contactFormGroup" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-md-6 form-group g-mb-20">
                <input id="name" class="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-brd-primary--hover rounded g-py-13 g-px-15" type="text" formControlName="name" placeholder="Name*">
                <div class="error" *ngIf="f.get('name').errors && submitted && ( f.get('name').dirty || f.get('name').touched || f.get('name').invalid)">
                  <div *ngIf="f.get('name').hasError('required')">
                    Name is required.
                  </div>
                </div>
              </div>

              <div class="col-md-6 form-group g-mb-20">
                <input class="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-brd-primary--hover rounded g-py-13 g-px-15" type="text" formControlName="suburb" placeholder="Suburb">
              </div>

              <div class="col-md-6 form-group g-mb-20">
                <input id="state" class="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-brd-primary--hover rounded g-py-13 g-px-15" type="text" formControlName="state"  placeholder="State*">
                <div class="error" *ngIf="f.get('state').errors && submitted && (f.get('state').dirty || f.get('state').touched)">
                  <div *ngIf="f.get('state').hasError('required')">
                    State is required.
                  </div>
                </div>
              </div>


              <div class="col-md-6 form-group g-mb-20">
                <input id="phone" class="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-brd-primary--hover rounded g-py-13 g-px-15" type="text" formControlName="number" placeholder="Phone*">
                <div class="error" *ngIf="f.get('number').errors && submitted && ( f.get('number').dirty || f.get('number').touched)">
                  <div *ngIf="f.get('number').hasError('required')">
                    Phone is required.
                  </div>
                  <div *ngIf="f.get('number').hasError('minlength')">
                    Phone must be at least 8 characters long.
                  </div>
                </div>

              </div>


              <div class="col-md-6 form-group g-mb-20">
                <input id="email" class="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-brd-primary--hover rounded g-py-13 g-px-15" type="email"  formControlName="email"  placeholder="Email*" >
                <div class="error" *ngIf="f.get('email').errors && submitted && (f.get('email').dirty || f.get('email').touched)">
                  <div *ngIf="f.get('email').hasError('required')">
                    Email is required.
                  </div>
                  <div *ngIf="f.get('email').hasError('pattern')">
                    Email is invalid.
                  </div>
                </div>
              </div>

              <div class="col-md-6 form-group g-mb-20">
                <label class="g-mr-10">Type of Enquiry:</label>
                <label>
                  <select class="form-control g-mt-10" formControlName="type">
                    <option value="0">Product</option>
                    <option value="1">Employment</option>
                    <option value="2" selected="selected">General</option>
                  </select>
                </label>
              </div>

              <div class="col-md-6 form-group g-mb-20">
                <label class="g-mr-10"><span class="g-color-primary">*</span>Are you enquiring as a:</label>
                <label>
                  <select class="form-control g-mt-10" formControlName="customerType">
                    <option value="1">Home Owner</option>
                    <option value="2">Building Professional</option>
                    <option value="3">Architect or Designer</option>
                    <option value="4">Distributor</option>
                    <option value="5">Installer</option>
                    <option value="6">Other</option>
                  </select>
                </label>
                <div class="error" *ngIf="f.get('customerType').errors && submitted && ( f.get('customerType').dirty || f.get('customerType').touched || f.get('customerType').invalid)">
                  <div *ngIf="f.get('customerType').hasError('required')">
                    This field is required.
                  </div>
                </div>
              </div>

              <div class="col-12 form-group g-mb-20">
                <label class="g-mr-10"><span class="g-color-primary">*</span>How did you find out about this webpage:</label>
                <label>
                  <select class="form-control g-mt-10" formControlName="source">
                    <option value="1">Internet search engine</option>
                    <option value="2">Google ads</option>
                    <option value="3">Facebook</option>
                    <option value="4">Instagram</option>
                    <option value="5">WeChat</option>
                    <option value="6">Other social media</option>
                    <option value="7">Email</option>
                    <option value="8">My Hume sales rep</option>
                    <option value="9">Word of mouth</option>
                    <option value="10">Other</option>
                  </select>
                </label>
                <div class="error" *ngIf="f.get('source').errors && submitted && ( f.get('source').dirty || f.get('source').touched || f.get('source').invalid)">
                  <div *ngIf="f.get('source').hasError('required')">
                    This field is required.
                  </div>
                </div>
              </div>

              <div class="col-md-12 form-group g-mb-40">
                <textarea id="message" class="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-brd-primary--hover g-resize-none rounded g-py-13 g-px-15"
                          [(ngModel)]="message" [ngModelOptions]="{standalone: true}" rows="7" placeholder="Message" maxlength="500"></textarea>
                <span class="float-right">{{message?.length}}/500</span>
              </div>
            </div>
            <div class="d-flex justify-content-center">
              <div class="text-center">
                <ngx-recaptcha2 #captchaElem
                                [siteKey] = siteKey
                                (reset)="handleReset()"
                                formControlName="recaptcha">
                </ngx-recaptcha2>
                <div class="error" *ngIf="f.get('recaptcha').errors && submitted">
                  <div *ngIf="f.get('recaptcha').hasError('required')">
                    recaptcha is required.
                  </div>
                </div>
                <button class="btn u-btn-primary g-font-size-12 text-uppercase g-py-12 g-px-25 mt-3" type="submit">Send Message</button>
              </div>
            </div>
          </form>
        </div>
      </div>
    </section>
    <!-- End Contact Form -->
  </div>

</div>
