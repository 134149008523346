import { NgModule } from '@angular/core';
import { CommonModule} from '@angular/common';
import { RouterModule, Routes} from '@angular/router';
import {ContactComponent} from './contact.component';
import {LocationComponent} from './location/location.component';
import {NgxCaptchaModule} from 'ngx-captcha';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PipesModule} from '../pipe/pipes.module';

export const ROUTES: Routes = [
  { path: 'contact', component: ContactComponent,
    data: {
      title: 'Hume Building Products store location',
      description: 'With warehouse stores across Sydney and Melbourne employing over 230 people, ' +
        'Hume has 100,000 square metres of space and a national delivery network servicing contractors large and small.',
      ogUrl: '/contact'
    }
  },
  { path: 'contact-us', component: ContactComponent,
    data: {
      title: 'Hume Building Products store location',
      description: 'With warehouse stores across Sydney and Melbourne employing over 230 people, ' +
        'Hume has 100,000 square metres of space and a national delivery network servicing contractors large and small.',
      ogUrl: '/contact-us'
    }},
  { path: 'zh-hans/store-location', component: ContactComponent,
    data: {
      title: 'Hume Building Products store location',
      description: 'With warehouse stores across Sydney and Melbourne employing over 230 people, ' +
        'Hume has 100,000 square metres of space and a national delivery network servicing contractors large and small.',
      ogUrl: '/zh-hans/store-location'
    }},
  { path: 'store-location', component: ContactComponent,
    data: {
      title: 'Hume Building Products store location',
      description: 'With warehouse stores across Sydney and Melbourne employing over 230 people, ' +
        'Hume has 100,000 square metres of space and a national delivery network servicing contractors large and small.',
      ogUrl: '/store-location'
    }}
];

@NgModule({
  imports: [
    CommonModule,
    NgxCaptchaModule,
    ReactiveFormsModule,
    FormsModule,
    PipesModule,
    RouterModule.forChild(ROUTES)
  ],
  declarations: [
    ContactComponent,
    LocationComponent
  ],
    exports: [
        LocationComponent
    ],
  providers: []
})
export class ContactModule { }
