<!-- Header -->
<header *ngIf="globals.showHeader" id="js-header" class="u-header" data-header-fix-moment="500" data-header-fix-effect="slide">
  <!-- First Top Bar -->
  <div  class="u-header__section bg-color-black g-brd-bottom g-brd-gray-light-v4 g-transition-0_3"
       [attr.data-header-fix-moment-exclude]="'u-header__section--dark bg-color-black ' + topBarExcludeClass"
       [attr.data-header-fix-moment-classes]="'g-bg-black u-shadow-v18 ' + topBarClass">
    <div class="container app-header-container">
      <div class="row align-items-center g-mx-0--lg g-font-size-16 g-pl-10">
        <div class="g-font-size-16 g-py-10 g-hidden-lg-down">
          <!-- Social Icons -->
          <ul class="list-inline mb-0">
            <li class="list-inline-item">
              <a class="g-color-white-opacity-0_8 g-color-facebook--hover g-pa-3" href="https://www.facebook.com/HumeBuildingProducts">
                <i class="fa fa-facebook"></i>
              </a>
            </li>
            <li class="list-inline-item">
              <a class="g-color-white-opacity-0_8 g-color-youtube--hover g-pa-3" href="https://www.youtube.com/user/HumePlasterboard?feature=watch">
                <i class="fa fa-youtube"></i>
              </a>
            </li>
            <li class="list-inline-item">
              <a class="g-color-white-opacity-0_8 g-color-linkedin--hover g-pa-3" href="https://www.linkedin.com/company/humebuildingproducts">
                <i class="fa fa-linkedin"></i>
              </a>
            </li>
            <li class="list-inline-item">
              <a class="g-color-white-opacity-0_8 g-color-twitter--hover g-pa-3" href="https://twitter.com/humebuilding">
                <i class="fa fa-twitter"></i>
              </a>
            </li>
            <li class="list-inline-item">
              <a class="g-color-white-opacity-0_8 g-color-instagram--hover g-pa-3" href="https://www.instagram.com/humebuildingproducts/">
                <i class="fa fa-instagram"></i>
              </a>
            </li>
            <li class="list-inline-item">
              <a class="g-color-white-opacity-0_8 g-color-pinterest--hover g-pa-3" href="https://www.pinterest.com.au/HumeBuildingProducts/">
                <i class="fa fa-pinterest"></i>
              </a>
            </li>
            <li class="list-inline-item">
              <a class="g-color-white-opacity-0_8 g-color-primary--hover g-pa-3"
                 [href]="globals.divisionTag?.includes('architectural') ? 'mailto:enquiry@humebuildingproducts.com.au' : (globals.divisionTag?.includes('commercial') ? 'mailto:inquiries@humebuildingproducts.com.au' : 'mailto:info@humebuildingproducts.com.au')">
                <i class="fa fa-envelope"></i>
              </a>
            </li>
          </ul>
          <!-- End Social Icons -->
        </div>

        <!-- Language -->
        <div class="g-pos-rel g-pl-15--xs">
          <ul class="list-inline g-overflow-hidden g-pt-1 mb-0">
            <li class="list-inline-item">
              <a class="g-color-white-opacity-0_9 g-color-primary-unbold--hover g-text-underline--none--hover" routerLink="/about-cn">
                中文
              </a>
            </li>
          </ul>
        </div>
        <!-- End Language -->

        <!-- Search -->
        <div class="g-pos-rel g-pl-30--md mt-3 g-width-35x--md g-width-30x mx-auto g-hidden-xs-down">

          <div id="search-input" class="mb-3 row mx-auto input-group">
            <i class="fas fa-search g-font-size-25 pt-2 g-color-white-opacity-0_8 g-hidden-sm-down" aria-hidden="true" (click)="openSearchWindow()"></i>
            <input (focus)="openSearchWindow()" class="form-control form-control-sm ml-3 hume-global-search" type="text" [(ngModel)]="keywords" (input)="search(keywords)"
                   placeholder="Search our products" aria-label="Search" style="">
            <!--<div class="input-group-append border-0 bg-white" *ngIf="keywords" style="border-radius: 0 50px 50px 0">
              <i class="fa fa-close g-color-primary mr-2 my-auto g-font-size-25" (click)="clearSearch()"></i>
            </div>-->
          </div>

          <div id="searchWindow" class="display-none">
            <div class="search-result u-shadow-v1-5 g-bg-white" [ngStyle]="filterSearchResult.length > 0 ? {'height': 'calc(70vh)'} : {'height': 'calc(30vh)'}" *ngIf="filterSearchResult && keywords?.length > 0 else desktopLoading">
              <ul class="list-unstyled" *ngIf="filterSearchResult.length > 0 else noSearchResult">
                <li class="d-flex justify-content-start g-brd-around rounded g-brd-bottom-hume g-pa-10" style="min-height: 85px" *ngFor="let item of filterSearchResult; let i = index">
                  <div class="row g-min-width-100x">
                    <div class="col-3 text-center">
                      <img class="rounded g-width-auto" (mouseover)="hover[i] = true" (mouseleave)="hover[i] = false"
                           [src]="hover[i] ? (item?.photoUrl?.length > 1 ? item?.photoUrl[1] : item?.photoUrl[0]) : item?.photoUrl[0]" (error)="img.src = globals.defaultImgUrl" alt="{{item?.description}}" #img style="max-height: 80px; max-width: 100%">
                    </div>
                    <div class="col-6 pt-1 align-items-center d-flex">
                      <h3 class="g-font-size-13 g-font-size-18--md g-font-weight-400 g-color-black g-mb-3">
                           <span class="g-mr-5">
                              <a class="g-color-black" [routerLink]="['/product/' + item?.id]" (click)="closeSearchWindow()">{{item?.description}}</a>
                           </span>
                      </h3>
                    </div>
                    <div class="col-3 pt-3">
                      <span class="g-font-size-15 g-color-gray-dark-v5" style="text-decoration: line-through" *ngIf="item?.msrp > item?.grossPrice">
                            Was {{item?.msrp | currency: 'AUD' : '$' :  '1.2-2'}}</span>
                      <h4 class="g-color-primary font-weight-bold g-font-size-11 g-font-size-20--sm" *ngIf="!item?.hidePrice">{{item?.grossPrice | currency}}</h4>
                    </div>
                  </div>
                </li>
              </ul>
              <ng-template #noSearchResult>
                <h3 class="g-color-primary text-center g-pos-rel top-40x">No result found, please try other keywords.</h3>
              </ng-template>
            </div>
            <ng-template #desktopLoading>
              <div class="search-result text-center pt-3 u-shadow-v1-5 g-bg-white" *ngIf="keywords?.length > 1">
                <ngx-skeleton-loader *ngFor="let item of generateFake(4)" [theme]="{ width: '45vw', height: '85px'}"></ngx-skeleton-loader>
              </div>

            </ng-template>
          </div>

        </div>
        <!-- End search -->

        <div id="top-bar-ul" class="g-pos-rel ml-auto">
          <!-- List -->
          <ul class="list-inline g-overflow-hidden g-pt-1 g-mx-minus-4 mb-0 g-mr-60">
            <li class="list-inline-item g-mx-4">
              <a href="tel:134863" class="g-color-white-opacity-0_6 g-color-white--hover g-text-underline--none--hover">
                <i class="fa fa-phone g-font-size-17 g-color-white-opacity-0_8 g-mr-10"></i>
                <span class="g-font-size-20--md g-font-size-17 g-color-primary g-color-white--hover font-weight-bold">13 4863</span></a>
            </li>

            <li class="list-inline-item g-color-white-opacity-0_3 g-mx-4">|</li>
            <li class="list-inline-item g-mx-4">
              <a class="g-color-white-opacity-0_9 g-color-primary-unbold--hover g-text-underline--none--hover" routerLink="/contact">
                <i class="g-font-size-17 g-color-white-opacity-0_8 g-mr-10 fa fa-location-arrow"></i>
                <span class="hume-hidden-1350-down">Locations</span></a>

                <i class="fa fa-map-marker ml-2 pl-2 g-font-size-17 g-color-white-opacity-0_8 hume-hidden-md-down"></i>

                <span class="ml-1 g-color-white-opacity-0_9 g-font-size-17 hume-hidden-md-down" [(ngModel)]="locationTitle" ngDefaultControl>
                  <span class="g-color-white-opacity-0_9 g-font-size-14 hume-hidden-md-down">{{locationTitle + ' '}}</span>
                  <a class="g-color-primary g-font-size-17 hume-hidden-md-down font-weight-bold" [routerLink]="['/hume-stores/' + nearestBranch | urlTransfer ]">{{nearestBranch}}</a>
                </span>
            </li>

            <li class="list-inline-item g-color-white-opacity-0_3 g-mx-4">|</li>

            <li class="list-inline-item g-mx-4">
              <a class="g-color-white-opacity-0_9 g-color-primary-unbold--hover g-text-underline--none--hover" routerLink="/order-tracking">
                <i class="g-font-size-17 g-color-white-opacity-0_8 g-mr-10 fa fa-file-text-o"></i>
                <span class="hume-hidden-1550-down">Track an order</span>
              </a>
            </li>

            <!--<li ngbDropdown #myDrop="ngbDropdown" container="body">
              <a class="g-color-white-opacity-0_9 g-color-primary-unbold--hover g-text-underline--none--hover" ngbDropdownToggle>
                <i class="g-font-size-17 g-color-white-opacity-0_8 g-mr-10 fa fa-file-text-o"></i>
                <span class="hume-hidden-md-down">Track your order</span>
              </a>
              <div class="g-bg-black" ngbDropdownMenu (mouseleave)="$event.stopPropagation(); myDrop.close();">
                <a ngbDropdownItem class="g-color-white g-py-15 g-bg-primary--hover" routerLink="/order-tracking">
                  <i class="g-font-size-17 g-color-white-opacity-0_8 g-mr-10 fa fa-file-text-o"></i>Tracking Order</a>
                <a ngbDropdownItem class="g-color-white g-py-15 g-bg-primary--hover" routerLink="/tracking">
                  <i class="g-font-size-17 g-color-white-opacity-0_8 g-mr-10 fa fa-truck"></i>Tracking Delivery</a>
              </div>
            </li>-->

            <li class="list-inline-item g-color-white-opacity-0_3 g-mx-4 g-hidden-md-down">|</li>
            <li class="list-inline-item g-mx-4 g-hidden-md-down">
              <a class="g-color-white-opacity-0_9 g-color-primary-unbold--hover g-text-underline--none--hover" [routerLink]="currentUser?.customer ? '/account/details' : '/auth/login'">
                <i class=" g-font-size-17 g-mr-10 fa fa-user" [ngClass]="currentUser?.customer ? 'g-color-primary' : 'g-color-white-opacity-0_9'"></i>
                <span [ngClass]="currentUser?.customer ? 'g-color-primary' : 'g-color-white-opacity-0_9'">{{currentUser?.customer ? 'Account' : 'Login'}}</span>
              </a>
            </li>
            <li class="list-inline-item g-color-white-opacity-0_3 g-mx-4 g-hidden-md-down" *ngIf="currentUser?.customer">|</li>
            <li class="list-inline-item g-mx-4 g-hidden-md-down" *ngIf="currentUser?.customer">
              <a class="g-color-white-opacity-0_9 g-color-primary-unbold--hover g-text-underline--none--hover" (click)="signOut()">
                <i class=" g-font-size-17 g-mr-10 fa fa-sign-out" [ngClass]="currentUser?.customer ? 'g-color-primary' : 'g-color-white-opacity-0_9'"></i>
                <span [ngClass]="currentUser?.customer ? 'g-color-primary' : 'g-color-white-opacity-0_9'">{{currentUser?.customer ? 'Sign out' : ''}}</span>
              </a>
            </li>
          </ul>
          <!-- End List -->
        </div>

        <div id="shopping-cart" class="g-pr-15">

          <!-- Search button for mobile version -->
          <div id="search" class="d-inline-block g-valign-middle g-hidden-sm-up g-pl-15">

            <div class="g-py-10">
              <a (click)="frame.show()" class="g-color-white-opacity-0_8 g-color-primary-unbold--hover g-font-size-17 g-text-underline--none--hover">
                <i class="g-pos-rel pl-3 fa fa-search"></i>
              </a>
            </div>
          </div>
          <!-- End Search button for mobile version -->

        </div>
      </div>
    </div>
  </div>
  <!-- End First Top Bar -->

  <!-- Second Top Bar -->
  <div class="u-header__section u-header__section--light g-bg-white g-transition-0_3 u-shadow-v1-2" data-header-fix-moment-exclude="" data-header-fix-moment-classes="u-shadow-v18">
    <nav class="js-mega-menu navbar navbar-expand-xl">
      <div class="container g-max-width-90x--xl g-max-width-100x">
        <a class="g-pos-abs g-text-underline--none--hover g-hidden-xl-up" [ngClass]="currentUser ? 'g-color-primary' : 'g-color-gray-dark-v4'"
           [routerLink]="currentUser ? '/account/account-menu' : '/auth/login'" style="top: 1.2rem; right: 5rem">
          <i class=" g-font-size-30 g-mr-10 fa fa-user"></i>
        </a>
        <!-- Responsive Toggle Button -->
        <button class="navbar-toggler navbar-toggler-right btn g-line-height-1 g-brd-none g-pa-0 g-pos-abs g-top-3 g-right-0 shadow-none" type="button"
                aria-label="Toggle navigation"
                aria-expanded="false"
                aria-controls="navBar"
                data-toggle="collapse"
                data-target="#navBar">
                <span class="hamburger hamburger--slider">
                  <span class="hamburger-box">
                    <span class="hamburger-inner"></span>
                  </span>
                </span>
        </button>
        <!-- End Responsive Toggle Button -->

        <!-- Logo -->
        <a class="navbar-brand" routerLink="/">
          <img class="g-max-width-150" src="/assets/img/logo/hbp-logo.jpg" alt="Hume Logo">
        </a>
        <!-- End Logo -->

        <!-- Navigation -->
        <div id="navBar" class="js-navigation collapse navbar-collapse align-items-center flex-sm-row u-main-nav--overlay-hidden g-bg-white g-py-10 g-py-5--lg font-weight-bold" data-navigation-breakpoint="xl" data-navigation-position="left" data-navigation-init-classes="g-transition-0_5"
             data-navigation-init-body-classes="g-transition-0_5" data-navigation-overlay-classes="g-bg-black-opacity-0_8 g-transition-0_5">
          <div class="u-main-nav__list-wrapper ml-auto">
            <ul class="navbar-nav ml-auto">

              <!-- Order now button -->
              <li class="g-mx-10--lg g-mx-15--xl text-center">
                <a id="mega-menu-label-3" class="text-uppercase hover g-color-primary--hover g-px-5" routerLink="products/" data-toggle="collapse" data-target=".navbar-collapse.show">
                  <button type="button" mdbBtn rounded="true" data-toggle="modal" mdbWavesEffect class="btn u-btn-primary g-rounded-25 rounded g-py-8 g-px-18 g-font-size-14 font-weight-bold btn-rounded shadow-none" style="min-width: 115px">
                    <i class="g-pos-rel g-font-size-17 pr-2 fa fa-shopping-cart hume-hidden-1610-down"></i>
                    <span>Shop Now</span>
                  </button>
                </a>
              </li>
              <!-- End order now button -->

              <!-- About -->
              <li class="nav-item dropdown hs-has-sub-menu g-mx-10--lg g-mx-15--xl text-center pt-2">
                <a class="nav-link dropdown-toggle text-uppercase hover g-color-primary--hover g-px-5" data-toggle="dropdown" routerLink="about">About</a>
                <ul class="dropdown-menu u-shadow-v1-5">
                  <li class="px-2 hume-brd-top-2 g-bg-color-grey--hover" [ngStyle]=" screenHeight <= 1024 ? {'max-height' : screenHeight*0.7 + 'px', 'overflow' : 'auto'} : {}">
                    <a class="dropdown-item nav-link g-color-gray-dark-v2 g-color-primary--hover font-weight-bold py-2"
                       routerLink="about" data-toggle="collapse" data-target=".navbar-collapse.show">About us</a>
                  </li>
                  <li class="px-2 g-bg-color-grey--hover" [ngStyle]=" screenHeight <= 1024 ? {'max-height' : screenHeight*0.7 + 'px', 'overflow' : 'auto'} : {}">
                    <a class="dropdown-item nav-link g-color-gray-dark-v2 g-color-primary--hover font-weight-bold py-2"
                       routerLink="/commercial" data-toggle="collapse" data-target=".navbar-collapse.show">Hume Commercial</a>
                  </li>
                  <li class="px-2 g-bg-color-grey--hover" [ngStyle]=" screenHeight <= 1024 ? {'max-height' : screenHeight*0.7 + 'px', 'overflow' : 'auto'} : {}">
                    <a class="dropdown-item nav-link g-color-gray-dark-v2 g-color-primary--hover font-weight-bold py-2"
                       routerLink="/architectural" data-toggle="collapse" data-target=".navbar-collapse.show">Hume Architectural</a>
                  </li>
                </ul>
              </li>
              <!-- End About -->

              <!-- Architectural -->
              <li class="nav-item dropdown hs-has-sub-menu g-mx-10--lg g-mx-15--xl text-center pt-2">
                <a class="nav-link dropdown-toggle text-uppercase hover g-color-primary--hover g-px-5" data-toggle="dropdown" routerLink="/architectural">Architectural</a>
                <ul class="dropdown-menu hume-brd-top-2 u-shadow-v1-5">
                  <li class="px-2 g-bg-color-grey--hover" *ngFor="let category of archiCategories" [ngStyle]=" screenHeight <= 1024 ? {'max-height' : screenHeight*0.7 + 'px', 'overflow' : 'auto'} : {}">
                    <a class="dropdown-item nav-link g-color-gray-dark-v2 g-color-primary--hover font-weight-bold py-2"
                       [routerLink]="['/' + category?.link | urlTransfer]" data-toggle="collapse" data-target=".navbar-collapse.show">{{category?.name}}</a></li>
                </ul>
              </li>
              <!-- End Architectural -->

              <!-- Commercial -->
              <li class="nav-item dropdown hs-has-sub-menu g-mx-10--lg g-mx-15--xl text-center pt-2" [ngClass]="screenWidth <= 1450 && screenWidth > 1200 ? 'hume-hidden-1450-down' : ''">
                <a class="nav-link dropdown-toggle text-uppercase hover g-color-primary--hover g-px-5" routerLink="/commercial" data-toggle="dropdown">Commercial</a>
                <ul id="sub-navbar-commercial" class="dropdown-menu u-shadow-v1-5 hume-brd-top-2">
                  <li class="px-2 g-bg-color-grey--hover" *ngFor="let category of commCategories" [ngStyle]=" screenHeight <= 1024 ? {'max-height' : screenHeight*0.7 + 'px', 'overflow' : 'auto'} : {}">
                    <a class="dropdown-item nav-link g-color-gray-dark-v2 g-color-primary--hover font-weight-bold py-2"
                       [routerLink]="['/' + category?.link | urlTransfer]" data-toggle="collapse" data-target=".navbar-collapse.show">{{category?.name}}</a></li>
                </ul>
              </li>
              <!-- End Commercial -->

              <!-- Products -->
              <li class="nav-item g-mx-10--lg g-mx-15--xl text-center pt-2" *ngIf="screenWidth < 1200 else desktopV">
                <a class="nav-link text-uppercase hover g-color-primary--hover g-px-5" [routerLink]="'products'" (click)="closeMenu()" data-toggle="collapse" data-target=".navbar-collapse.show">Products</a>
              </li>
              <ng-template #desktopV>
                <li class="hs-has-sub-menu dropdown nav-item g-mx-10--lg g-mx-15--xl pt-2" data-animation-in="fadeIn" data-animation-out="fadeOut">
                  <a id="nav-link-products" class="nav-link dropdown-toggle text-uppercase hover g-color-primary--hover g-px-5 text-center" [routerLink]="'products'" aria-controls="nav-submenu-pages" data-toggle="dropdown"
                  >Products</a>
                  <ul class="hs-sub-menu u-shadow-v1-5 list-unstyled g-brd-top g-brd-primary g-brd-top-2 g-min-width-300" id="nav-submenu-pages" aria-labelledby="nav-link-products">
                    <li class="dropdown-item px-2 nav-link g-color-gray-dark-v2 g-color-primary--hover g-bg-color-grey--hover font-weight-bold">
                      <a class="nav-link" routerLink="/products/hume_specials" data-toggle="collapse" data-target=".navbar-collapse.show" style="font-size: 1rem;">Hume Specials</a>
                    </li>
                    <li class="dropdown-item px-2 nav-link g-color-gray-dark-v2 g-color-primary--hover g-bg-color-grey--hover font-weight-bold">
                      <a class="nav-link" routerLink="/products/hume_eofy" data-toggle="collapse" data-target=".navbar-collapse.show" style="font-size: 1rem;" *ngIf="isEOFYAvailable">EOFY Sales</a>
                    </li>
                    <li class="dropdown-item hs-has-sub-menu px-2 g-color-gray-dark-v2 g-color-primary--hover g-bg-color-grey--hover font-weight-bold" *ngFor="let category of globals?.eWebCat, let i = index">
                      <a [id]="'nav-link--pages--' + category?.id" class="nav-link" [routerLink]="['/' + category?.link | urlTransfer]" aria-haspopup="true" aria-expanded="false"
                         [attr.aria-controls]="'nav-submenu--pages--' + category?.id" style="font-size: 1rem;">{{category?.name}}</a>


                      <ul class="hs-sub-menu g-pos-abs g-left-0 u-shadow-v1-5 list-unstyled g-mt-minus-2 g-brd-top g-brd-primary g-brd-top-2" [ngClass]="category?.subCategories?.length >= 6 ? 'u-dropdown-col-2' : ''" [id]="'nav-submenu--pages--' + category?.id"
                          [attr.aria-labelledby]="'nav-link--pages--' + category?.id">
                        <li class="dropdown-item g-bg-color-grey--hover" *ngFor="let item of category?.subCategories">
                          <a class="nav-link g-color-gray-dark-v2 g-color-primary--hover font-weight-bold py-2" style="max-width: 450px !important; font-size: 1rem;"
                             [routerLink]="'/products/' + category.name.split(' ').join('_') + '/' + (item?.name ? (item?.name?.split(' ').join('_')) : (item?.description?.split(' ').join('_')))"
                             data-toggle="collapse" data-target=".navbar-collapse.show">{{item?.name ? item?.name : item?.description}}</a>
                        </li>
                        <li class="dropdown-item g-bg-color-grey--hover">
                          <a [routerLink]="['/' + category?.link | urlTransfer]" class="nav-link g-color-primary font-weight-bold py-2" style="max-width: 450px !important; font-size: 1rem;">More >></a>
                        </li>
                      </ul>

                    </li>
                  </ul>
                </li>
              </ng-template>
              <!-- End Products -->

              <!-- Projects -->
              <li class="nav-item g-mx-10--lg g-mx-15--xl text-center">
                <a class="nav-link text-uppercase hume-header-menu g-color-primary--hover g-px-5" routerLink="projects" data-toggle="collapse" data-target=".navbar-collapse.show">
                  Projects
                </a>
              </li>
              <!-- End Projects -->

              <!-- Brochures - -->
              <li class="nav-item g-mx-10--lg g-mx-15--xl text-center">
                <a id="nav-link--brochures" class="nav-link text-uppercase hume-header-menu g-color-primary--hover g-px-5" routerLink="brochures" data-toggle="collapse" data-target=".navbar-collapse.show"
                   aria-haspopup="true"
                   aria-expanded="false"
                   aria-controls="nav-submenu--pages">
                  Brochures
                </a>
              </li>
              <!-- End Brochures -->

              <!-- News - Submenu -->
              <li class="nav-item g-mx-10--lg g-mx-15--xl text-center">
                <a id="nav-link--news" class="nav-link text-uppercase hume-header-menu g-color-primary--hover g-px-5" routerLink="news" data-toggle="collapse" data-target=".navbar-collapse.show"
                   aria-haspopup="true"
                   aria-expanded="false"
                   aria-controls="nav-submenu--pages">
                  News
                </a>
              </li>
              <!-- End News - Submenu -->

              <!-- Career - Mega Menu -->
              <li class="nav-item g-mx-10--lg g-mx-15--xl text-center" [ngClass]="screenWidth <= 1450 && screenWidth > 1200 ? 'hume-hidden-1450-down' : ''">
                <a class="nav-link text-uppercase hume-header-menu g-color-primary--hover g-px-5" routerLink="/careers">
                  Careers
                </a>
              </li>
              <!-- End Career Mega Menu -->

              <!-- Contact Us -->
              <li class="nav-item dropdown hs-has-sub-menu g-mx-10--lg g-mx-15--xl text-center pt-2">
                <a class="nav-link dropdown-toggle text-uppercase hover g-color-primary--hover g-px-5" data-toggle="dropdown" routerLink="contact">Contact</a>
                <ul class="dropdown-menu u-shadow-v1-5">
                  <li class="hume-brd-top-2 px-2 g-bg-color-grey--hover" [ngStyle]=" screenHeight <= 1024 ? {'max-height' : screenHeight*0.7 + 'px', 'overflow' : 'auto'} : {}">
                    <a class="dropdown-item nav-link g-color-gray-dark-v2 g-color-primary--hover font-weight-bold py-2"
                       routerLink="hume-in-sydney" data-toggle="collapse" data-target=".navbar-collapse.show">Hume in Sydney</a>
                  </li>
                  <li class="px-2 g-bg-color-grey--hover" [ngStyle]=" screenHeight <= 1024 ? {'max-height' : screenHeight*0.7 + 'px', 'overflow' : 'auto'} : {}">
                    <a class="dropdown-item nav-link g-color-gray-dark-v2 g-color-primary--hover font-weight-bold py-2"
                       routerLink="hume-in-melbourne" data-toggle="collapse" data-target=".navbar-collapse.show">Hume in Melbourne</a>
                  </li>
                </ul>
              </li>
              <!-- End Contact Us -->

            </ul>
          </div>
        </div>
        <!-- End Navigation -->
      </div>
    </nav>
  </div>
    <div class="location g-show-xs g-font-size-14 g-bg-black hoverable" data-toggle="modal" data-target="#locationEnabledModal" *ngIf="globals.isMobile">
      <i class="fa fa-map-marker"></i><span class="ml-1 g-color-white" [(ngModel)]="locationTitle" ngDefaultControl>{{locationTitle + ' '}}
        <a class="g-color-white g-text-underline font-weight-bold" [routerLink]="['/hume-stores/' + nearestBranch | urlTransfer ]">{{nearestBranch}}</a></span>
    </div>
  <!--End Second Top Bar -->
</header>
<!-- End Header -->

<!-- Alert modal for online store -->
<div mdbModal #alertModal="mdbModal" class="modal fade top" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel"
     aria-hidden="true">
  <div class="modal-dialog modal-frame modal-top" role="document">
    <div class="modal-content">
      <div class="modal-body">
        <div class="row d-flex justify-content-center align-items-center">
          <p class="pt-3 pr-2 g-font-size-20">Our online store is coming soon. Please stay tuned!
          </p>
          <button type="button" class="btn u-btn-primary waves-effect waves-light pl-10" data-dismiss="modal" (click)="alertModal.hide()">Close</button>
        </div>
      </div>
    </div>
  </div>
</div>
<!-- Alert modal for online store -->

<!-- Search result modal mobile-->
<div mdbModal #frame="mdbModal" class="modal fade top" id="frameModalTop" tabindex="-1" role="dialog"
     aria-labelledby="myModalLabel" aria-hidden="true" [config]="{backdrop: false}">
  <div class="modal-dialog cascading-modal modal-avatar modal-fluid g-width-95x g-width-75x--md" role="document">
    <div class="modal-content g-bg-black-opacity-0_6 g-px-15">
      <div class="input-group g-py-20">
          <input class="form-control g-font-size-20 search-wrapper border-0" [(ngModel)]="keywords" #searchBox2 (input)="search(keywords)" style="height: 60px">
          <div class="input-group-append border-0 bg-white" *ngIf="keywords" style="border-radius: 0 4px 4px 0;">
            <i class="fa fa-close g-color-primary mr-3 mr-md-4 my-auto g-font-size-25" (click)="clearSearch()"></i>
          </div>
      </div>

      <section *ngIf="filterSearchResult?.length > 0 else loading">

        <div class="tab-content card g-pt-30 mb-4" style="overflow: scroll; height: 70vh;">
          <div  *ngFor="let item of filterSearchResult; let i = index">
            <div class="tab-pane fade in show active" [id]="item.doctype" role="tabpanel">
              <ul class="list-unstyled" style="margin-bottom: 0.28571rem">
                <li class="d-flex justify-content-start g-brd-around rounded g-bg-white g-brd-bottom-hume g-pa-10 g-mb-4" style="min-height: 85px">
                  <div class="row g-min-width-100x">
                    <div class="col-3 text-center">
                      <img class="rounded g-width-auto" (mouseover)="hover[i] = true" (mouseleave)="hover[i] = false"
                           [src]="hover[i] ? (item?.photoUrl?.length > 1 ? item?.photoUrl[1] : item?.photoUrl[0]) : item?.photoUrl[0]" (error)="img.src = globals.defaultImgUrl" alt="{{item?.description}}" #img style="max-height: 80px; max-width: 100%">
                    </div>
                    <div class="col-6 pt-1">
                      <h3 class="g-font-size-13 g-font-size-18--md g-font-weight-400 g-color-black g-mb-3">
                           <span class="g-mr-5">
                              <a class="g-color-black" [routerLink]="['/product/' + item?.id]" (click)="frame.hide()">{{item?.description}}</a>
                           </span>
                      </h3>
                    </div>
                    <div class="col-3 pt-3">
                      <h4 class="g-color-primary font-weight-bold g-font-size-11 g-font-size-20--sm" *ngIf="!item?.hidePrice">{{item?.grossPrice | currency}}</h4>
                    </div>
                  </div>
                </li>

              </ul>
            </div>
          </div>
        </div>
      </section>

      <ng-template #loading>
        <ul class="mt-2 list-unstyled g-pos-rel g-top-45x" *ngIf="noResult && filterSearchResult?.length === 0">
          <li class="d-flex justify-content-start g-brd-around rounded g-bg-white g-brd-gray-light-v4 g-pa-20 g-mb-7">
            <div class="align-self-center g-px-10">
              <h3 class="h5 g-font-weight-600 g-color-black g-mb-3">No Result found, Please try other keywords!</h3>
            </div>
          </li>
        </ul>

        <div *ngIf="noResult === false">
          <div class="tab-content card g-pt-30 mb-4" style="overflow: scroll; height: 70vh;">
            <div class="g-pa-10"  *ngFor="let item of generateFake(9)">
              <ngx-skeleton-loader [theme]="{ width: '100%', height: '45px'}"></ngx-skeleton-loader>
            </div>
          </div>
        </div>

      </ng-template>
    </div>

  </div>

</div>
<!-- End Search result modal-->

