import {Injectable} from '@angular/core';

import {User} from '../../auth/models/user.interface';
import {map} from 'rxjs/operators';
import {ajax} from 'rxjs/ajax';
import {Globals} from '../../global';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {AlertService} from '../alert.service';
import {ShoppingCartService} from '../shopping-cart.service';
import jwt_decode from 'jwt-decode';

@Injectable()

export class AuthService {
  url = window.location.href;
  headers = new HttpHeaders({
      // 'Origin': '1234',
      'Content-Type':  'application/json'
    });

  private currentUserSubject = new BehaviorSubject<User>(JSON.parse(localStorage.getItem('currentUser')));
  public currentUser = this.currentUserSubject.asObservable();
  private tokenSubject = new BehaviorSubject('');
  public token = this.tokenSubject.asObservable();

  constructor(
    public globals: Globals,
    private http: HttpClient,
    private router: Router, private scService: ShoppingCartService,
    private alertService: AlertService
  ) {
    if (!this.globals.token && localStorage.getItem('HumeR')) {
     this.globals.token = localStorage.getItem('HumeR');
    }
  }

  public get currentUserValue(): User {
    return this.currentUserSubject.value;
  }

  setUserInfo() {
    localStorage.setItem('currentUser', JSON.stringify({username: 'info@humeplaster.com.au', }));
    this.currentUserSubject.next(JSON.parse(localStorage.getItem('currentUser')));
  }

  public get currentTokenValue() {
    return this.tokenSubject.value;
  }

  getToken(): string {
    return localStorage.getItem('HumeR');
  }

  setToken(token: string): void {
    localStorage.setItem('HumeR', token);
  }

  isTokenExpired(token?: string): boolean {
    if (!token) { token = this.getToken(); }
    if (!token) { return true; }

    const date = this.getTokenExpirationDate(token?.replace('Bearer ', ''));
    if (date === undefined) { return false; }
    return !(date.valueOf() > new Date().valueOf());
  }

  getTokenExpirationDate(token: string): Date {
    const decoded = jwt_decode(token);

    // @ts-ignore
    if (decoded?.exp === undefined) { return null; }

    const date = new Date(0);
    // @ts-ignore
    date.setUTCSeconds(decoded?.exp);
    return date;
  }

  // ** Refresh token;
  async refreshToken() {
    return new Promise(async resolve => {
      if (!this.isTokenExpired()) {
        this.tokenSubject.next(localStorage.getItem('HumeR'));
        resolve(localStorage.getItem('HumeR'));
      } else {
        const currentUser = JSON.parse(localStorage.getItem('currentUser'));
        if (currentUser?.username !== 'info@humeplaster.com.au') {
          this.logoutUser('refresh');
        }
        await this.getCashSaleToken().toPromise().then(async res => {
          resolve(res);
          this.currentUserSubject.next(JSON.parse(localStorage.getItem('currentUser')));
        });
      }
    });
  }

  getCashSaleToken() {
    const myJSON = JSON.stringify({username: 'info@humeplaster.com.au', password: '8860ea1c'});
    return this.http.post<any>( this.globals.humeEcomApiRef + 'auth/standard', myJSON,
      {observe: 'response', headers: this.headers})
      .pipe(
        map(async response => {
          this.setToken(response.headers.get('authorization'));
          this.tokenSubject.next(response.headers.get('authorization'));
          localStorage.setItem('currentUser', JSON.stringify({username: 'info@humeplaster.com.au', }));
          return response.headers.get('authorization');
        }));
  }

  loginUser(username: string, password: string, returnUrl?: string, singleProductId?) {
    const myJSON = JSON.stringify({username, password});
    return this.http.post<any>( this.globals.humeEcomApiRef + 'auth/standard', myJSON,
      {observe: 'response', headers: this.headers})
      .pipe(
        map(async response => {
          const token = response.headers.get('authorization');
          this.setToken(token);
          this.tokenSubject.next(token);
          this.globals.token = token;
          // ** If user login, then save user details.
          if (username !== 'info@humeplaster.com.au') {
            await this.getLoginDetails().subscribe(res => {
              // @ts-ignore
              const status = res.customer?.creditStatus?.toLowerCase();
              if ( status === 'active' || status === 'cash' || status === 'cod') {
                this.currentUserSubject.next(<User>res);
                localStorage.setItem('currentUser', JSON.stringify(res));
                this.getCartFromServer();
                this.loginResponse(returnUrl, token, singleProductId);
              } else {
                if (localStorage.getItem('HumeR')) { localStorage.removeItem('HumeR'); }
                this.globals.token = null;
                this.alertService.showError('There is an issue with your account. ' +
                  'Please contact your sales rep.', true);
              }

            }, error => {
              switch (error?.status) {
                case 401:
                  this.alertService.showError('Incorrect login credential.', true, 5000);
                  break;
                case 500:
                  this.alertService.showError(
                    // tslint:disable-next-line:max-line-length
                    'We are unable to access to server currently.', true);
                  break;
                default:
                  // tslint:disable-next-line:max-line-length
                  this.alertService.showError('Something went wrong.', true );
                  break;
              }
              return error;
            });
          } else {
            this.loginResponse(returnUrl, token);
          }
          // this.startRefreshTokenTimer();
        return response;
      }));
  }

  // Response after getting cashsale or cod account information
  loginResponse(returnUrl, token, singleProductId?) {
    this.alertService.alertClose();
    if (returnUrl) {
      if (returnUrl.includes('single-product')) {
        this.router.navigate(['single-product'], { queryParams: { id: singleProductId } });
      } else if (returnUrl.includes('/product?')) {
        this.router.navigate(['product'], { queryParams: { id: singleProductId } });
      } else {
        this.router.navigate([returnUrl]);
      }
    } else {
      this.router.navigate(['account/details']);
    }
  }

  getCartFromServer() {
      this.scService.getECart().subscribe(res => {
        this.globals.cart = res;
        localStorage.setItem('cart', JSON.stringify(res));
      }, error => {
        // console.log(error);
        if (error.error.error === 'Unauthorized') {
          if (!this.currentUser) {
            this.loginUser('info@humeplaster.com.au', 'fdacxafdafsd').subscribe(res => {
              this.router.navigate(['checkout']);
            }, error1 => {
              // console.log(error1);
            });
          } else {
            localStorage.removeItem('currentUser');
            this.logoutUser();
          }
        }
      });
  }

  getLoginDetails() {
    return this.http.get(this.globals.humeEcomApiRef + 'customer/login');
  }

  getCustomerDetails() {
    return this.http.get(this.globals.humeEcomApiRef + 'customer/details');
  }

  getCustomerContactList(customerCode) {
    return this.http.get(this.globals.humeEcomApiRef + 'customer/contact/' + customerCode + '/add');
  }

  logoutUser(tag?) {
    if (localStorage.getItem('currentUser')) { localStorage.removeItem('currentUser'); }
    if (localStorage.getItem('HumeR')) { localStorage.removeItem('HumeR'); }
    if (localStorage.getItem('returnPageUrl')) { localStorage.removeItem('returnPageUrl'); }
    if (localStorage.getItem('cart')) { localStorage.removeItem('cart'); }
    if (localStorage.getItem('wishList')) { localStorage.removeItem('wishList'); }
    this.globals.cart = null;
    this.globals.token = null;
    this.currentUserSubject.next(null);
    this.getCashSaleToken().toPromise().then(res => {});
    if (tag !== 'refresh') {
      this.router.navigate(['/auth/login']);
    }
  }

  getNews() {
    return ajax.get(this.globals.humeEcomApiRef + 'info/news');
  }

  getBaseUrl() {
    return this.globals.humeEcomApiRef;
  }

  registerNewUser(obj) {
    /**
    private String email;
    private String mobile;
    private String displayName;
    private boolean marketingConsent;
    private String password; **/
    const myJSON = JSON.stringify(obj);
    return this.http.post(this.globals.humeEcomApiRef + 'customer/user/create', myJSON, {headers: this.headers});
  }

  // Activate the mobile number for a user
  activateUserMobileNumber(mobileToken) {
    return this.http.post(this.globals.humeEcomApiRef + 'customer/user/create?token=' + mobileToken, {});
  }

  // Request to verify a mobile number
  requestMobileLink(mobileNumber) {
    return this.http.post(this.globals.humeEcomApiRef + 'customer/user/mobile/link?mobile=' + mobileNumber, {});
  }

  // Request to reset password by SMS a message with a 6 digit verification code to the linked mobile number
  requestResetByMobile(formData) {
    return this.http.post(this.globals.humeEcomApiRef + 'customer/user/reset/mobile', formData );
  }

  // Request to reset password by email
  requestResetByEmail(formData) {
    return this.http.post(this.globals.humeEcomApiRef + 'customer/user/reset/email', formData);
  }

  // Request to reset password by SMS a message with a 6 digit verification code to the linked mobile number
  doReset(formData) {
    return this.http.post(this.globals.humeEcomApiRef + 'customer/user/reset/do', formData);
  }

  activateAccount(formData) {
    return this.http.post(this.globals.humeEcomApiRef + 'customer/user/activate', formData);
  }

  setUpAccount(formData) {
    return this.http.post(this.globals.humeEcomApiRef + 'customer/user/setup', formData);
  }

  redirectToLoginPage(id?) {
    localStorage.setItem('returnPageUrl', this.router.url);
    this.logoutUser();
  }

}
