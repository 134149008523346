import {Component, OnDestroy, OnInit} from '@angular/core';
import {TestimonialService} from '../../services/testimonial.service';
import {Globals} from '../../global';
import {Subscription} from 'rxjs';
import { NgbCarouselConfig } from '@ng-bootstrap/ng-bootstrap';
declare var $: any;

@Component({
  selector: 'app-home-testimonials',
  templateUrl: './home-testimonials.component.html',
  providers: [NgbCarouselConfig]
})
export class HomeTestimonialsComponent implements OnInit, OnDestroy {
  testimonials;
  imgUrl = this.globals.picStorageRef;
  testimonialSub: Subscription;
  slides: any = [[]];

  slideConfig = {'slidesToShow': 3, 'slidesToScroll': 1, 'autoplay': true, 'dots': false, 'autoplaySpeed': 2000, 'arrows': false,
    'responsive': [{
      breakpoint: 1200,
      settings: { slidesToShow: 3 }
    }, {
      breakpoint: 992,
      settings: { slidesToShow: 2 }
    }, {
      breakpoint: 576,
      settings: { slidesToShow: 1 }
    }]
  };

  constructor (private testimonialService: TestimonialService,
               public globals: Globals,
               config: NgbCarouselConfig) {
    config.showNavigationIndicators = false;
    config.showNavigationArrows = false;
  }

  ngOnInit() {
    this.testimonialSub = this.testimonialService.getTestimonial().subscribe(res => {
      this.testimonials = res.response;
    });
  }

  ngOnDestroy() {
    if (this.testimonialSub) {
      this.testimonialSub.unsubscribe();
    }
  }
}
