import { Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {Globals} from '../global';
import {DomSanitizer, Title} from '@angular/platform-browser';
import {FormBuilder} from '@angular/forms';
import {ContactService} from '../services/contact.service';
import {ProductService} from '../services/product.service';
import {Projectervice} from '../services/project.service';
import {AlertService} from '../services/alert.service';
import {HandleErrorService} from '../services/handle-error.service';
import {QuoteFormComponent} from '../shared/quote-form/quote-form.component';
import {SEOService} from '../services/seo/seo.service';
import {AuthService} from '../services/auth/auth.service';
declare var $: any;

@Component({
  selector: 'app-categories',
  templateUrl: './categories.component.html',
  styleUrls: ['./categories.component.scss'],
})
export class CategoriesComponent implements OnInit, OnDestroy {
  singleCatSub: Subscription;
  singleCat;
  imgUrl = this.globals.picStorageRef;
  docUrl = this.globals.docStorageRef;
  routeParamsSubscription: Subscription;
  tokenSubscription: Subscription;
  catWebSubscription: Subscription;
  projectsSubscription: Subscription;
  brandsSub: Subscription;
  subCats = [];
  eSubCats;
  pageContent;
  brands;
  skirtingProfile = [
    {title: 'Bullnose AS9', src: 'https://static.wixstatic.com/media/6adede_debcda730a6b4ceea8c152255a75f4cc~mv2.jpg'},
    {title: 'Colonial NSW', src: 'https://static.wixstatic.com/media/6adede_9ae037bb99844fc6a50ef26a2bf81d27~mv2.jpg'},
    {title: 'DAR', src: 'https://static.wixstatic.com/media/6adede_7e6c680f19d848c598ec9327208e24ac~mv2.jpg'},
    {title: 'Bullnose AS1', src: 'https://static.wixstatic.com/media/6adede_396f01ed4244474e8de02ec4b642f60e~mv2.jpg'},
    {title: 'Colonial NSW AS2', src: 'https://static.wixstatic.com/media/6adede_42a68319a1b24a8f8802e7556227d609~mv2.jpg'},
    {title: 'Flat Jamb', src: 'https://static.wixstatic.com/media/6adede_e73567c775944b1bbf4f0cefee140488~mv2.jpg'},
    {title: 'Half Splay AS4', src: 'https://static.wixstatic.com/media/6adede_06b5bf2a641542baa9ac149f62b3f45a~mv2.jpg'},
    {title: 'Half Splay AS5', src: 'https://static.wixstatic.com/media/6adede_596bf734d6f649419b84a7581733613b~mv2.jpg'},
    {title: 'Pencil Round AS7', src: 'https://static.wixstatic.com/media/6adede_dc67bf9b7ec843459ea48c6b1c7cf7c5~mv2.jpg'},
    {title: 'Lining Boards 302 and 321', src: 'https://static.wixstatic.com/media/6adede_f420b11fa05842a788f64756bb8b5d65~mv2.jpg'},
    {title: 'Pencil Round AS15', src: 'https://static.wixstatic.com/media/6adede_2a7737e34720426582bb5d906a04fd54~mv2.jpg'},
    {title: 'Rebated Door Jamb', src: 'https://static.wixstatic.com/media/6adede_1f17de42575f4f248c4ce53ccecfe94c~mv2.jpg'},
    {title: 'Splayed AS8', src: 'https://static.wixstatic.com/media/6adede_4b6104a59de940218ba10fb7b2e9c5b3~mv2.jpg'},
    {title: 'Step AS12', src: 'https://static.wixstatic.com/media/6adede_46c59e2a5bd34357971b7f5a6b89b240~mv2.jpg'},
    {title: 'Window Reveal', src: 'https://static.wixstatic.com/media/6adede_a6018cb1b872454ead6ceec410625368~mv2.jpg'},
    {title: 'Stop', src: 'https://static.wixstatic.com/media/6adede_490d96f4f5754ed0a46fb0251b33f1a5~mv2.jpg'},
    {title: 'Bevel AS3', src: 'https://static.wixstatic.com/media/6adede_5619c524ecfb46bf911b3e003c27cc84~mv2.jpg'}
  ];
  smartStone = [
    {name: 'Allegro', url: this.imgUrl + 'categories-web/smart-stone/Allegro.jpg'},
    {name: 'Calacatta Forte', url: this.imgUrl + 'categories-web/smart-stone/Calacatta_Forte.jpg'},
    {name: 'Calacatta Soprano', url: this.imgUrl + 'categories-web/smart-stone/Calacatta_Soprano.jpg'},
    {name: 'Dolce', url: this.imgUrl + 'categories-web/smart-stone/Dolce.jpg'},
    {name: 'Gustoso', url: this.imgUrl + 'categories-web/smart-stone/Gustoso.jpg'},
    {name: 'Luminoso', url: this.imgUrl + 'categories-web/smart-stone/Luminoso.jpg'},
    {name: 'Maestoso', url: this.imgUrl + 'categories-web/smart-stone/Maestoso.jpg'},
    {name: 'Vibrato', url: this.imgUrl + 'categories-web/smart-stone/Vibrato.jpg'}
  ];
  relatedSubCats;
  webCategories;
  allProjects;

  @ViewChild('form') form: QuoteFormComponent;
  // @ViewChild('successModal') successModal: ModalDirective;


  chunkSize = 4;
  slides: any = [[]];
  static chunk (arr, chunkSize) {
    const R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }

  constructor (private route: ActivatedRoute, private handleErrorService: HandleErrorService,
               private contactService: ContactService, private authService: AuthService,
               private formBuilder: FormBuilder, private alertService: AlertService,
               public globals: Globals, private seoService: SEOService,
               private router: Router,
               private title: Title,
               private sanitizer: DomSanitizer,
               private productService: ProductService,
               private projectService: Projectervice) {

    if (window.screen.width < 768) {
      this.chunkSize = 1;
    } else if (window.screen.width === 768) {
      this.chunkSize = 2;
    }
    this.slides = CategoriesComponent.chunk(this.skirtingProfile, this.chunkSize);

    this.tokenSubscription = this.authService.token.subscribe(res => {
        this.initCatPage();
    });
  }

  ngOnInit () { }

  initCatPage() {
    // Get params from route
    this.routeParamsSubscription = this.route.url.subscribe(
      data => {
        if (data.length > 0) {
          // ** Older version url with params.
          if (data[0].path === 'category') {
            // ** Interpret older version url with word 'category'
            this.routeParamsSubscription = this.route.queryParams.subscribe(
              paramsData => {
                this.catWebSubscription = this.productService.getEWebCatListAll().subscribe( res => {
                  // @ts-ignore
                  this.subCats = res.filter( res1 => res1.parent === parseInt(paramsData.id) );
                  if (this.subCats.length === 0) {
                    // @ts-ignore
                    this.subCats = res.filter( res2 => res2.id === parseInt(paramsData.id));
                    this.router.navigate([this.subCats[0].link]);
                  }

                  this.tilesArrange();
                  /** Get single web category by Id */
                  if (paramsData.id) {
                    this.getWebCatDetailsById(res, paramsData.id);
                  }
                });
              }
            );
            // ** End Interpret older version url with word 'category'
          } else {
            // ** Interpret new version url without word 'category'
            this.catWebSubscription = this.productService.getEWebCatListAll().subscribe( res => {
              const path = data[0].path.split('_').join(' ');
              this.getBrands(path);
              // @ts-ignore
              let category = res.filter( res2 => res2?.link?.toLowerCase() === path.toLowerCase());
              // @ts-ignore
              if (category.length === 0) { category = res.filter( res2 => res2.name === path); }
              if (category.length === 0) {
                this.router.navigate(['home']);
              } else {
                // @ts-ignore
                this.subCats = res.filter( res1 => res1.parent === parseInt(category[0].id) );
              }

              this.tilesArrange();
              /** Get single web category by Id */
              if (category[0].id) {
                this.getWebCatDetailsById(res, category[0].id);
              }
            });
            // ** End Interpret new version url without word 'category'
          }
          // ** End older version url with params.
        } else {
          // ** new version url without params
          this.catWebSubscription = this.productService.getEWebCatListAll().subscribe(res => {
            let path;
            if (this.globals.currentUrl.includes('?')) {
              const tempUrl = this.globals.currentUrl.split('?');
              path = tempUrl[0].replace('/', '').split('_').join(' ');
            } else {
              path = this.globals.currentUrl.replace('/', '').split('_').join(' ');
            }
            if (path === 'aac-wall-systems') { path = 'AAC'; }
            this.getBrands(path);
            // @ts-ignore
            let category = res.filter(res2 => res2.link === path);
            if (category.length === 0) {
              // @ts-ignore
              category = res.filter(res2 => res2.name === path);
            }
            // ** Get projects when it is on installation service page;
            if (category.length > 0) {
              if (category[0].id === 41) {
                this.projectsSubscription = this.projectService.getProjectList().subscribe( projects => {
                  const tempP = projects.response.reverse();
                  this.allProjects = tempP.filter(value => value.id === 13 || value.id === 15 || value.id === 1);
                });
              }
              // @ts-ignore
              this.subCats = res.filter(res1 => res1.parent === parseInt(category[0].id));
            }

            // ** Related products
            // @ts-ignore
            const temp = res.filter(res1 => res1.id === 10 || res1.id === 12 || res1.id === 8);
            // ** Related products

            this.tilesArrange();
            /** Get single web category by Id */
            if (category.length > 0 && category[0].id) {
              this.getWebCatDetailsById(res, category[0].id);
            }
          });
          // ** End new version url without params
        }
      }
    );
  }

  getEwebCatDetails(categoryId) {
    this.productService.getEProductsWebCatDetails(categoryId).toPromise().then(res => {
      // @ts-ignore
      if (res.useGrouping) {
        // @ts-ignore
        this.eSubCats = res.productGroups;
      } else {
        // @ts-ignore
        this.eSubCats = res.ecomCategories;
      }
    }, error => {
        if (error.status === 401) {
          this.handleErrorService.subscribeErrorUnAuth(error, this.router.url);
        }
    });
  }

  openProductPage(item) {
    const subCatName = item.name ? item.name : item.description;
    /*const obj = {
      id: this.singleCat.id,
      subCatType: item.type,
      subCatName: (item.name ? item.name : item.description),
      name: this.singleCat.name,
      subCatCode: item.code ? item.code : item.id,
    };*/
    // localStorage.setItem('category', JSON.stringify(obj));
    this.router.navigate(['/products/' + this.singleCat.name.split(' ').join('_') + '/' + subCatName.split(' ').join('_')]);
  }

  openSub() {
    /*const obj = {
      id: this.singleCat.id,
      subCatType: item.type,
      subCatName: (item.name ? item.name : item.description),
      name: this.singleCat.name,
      subCatCode: item.code,
    };*/
    // localStorage.setItem('category', JSON.stringify(obj));
  }

  openList(singleCat) {
    if (!this.eSubCats) {
      this.getEwebCatDetails(singleCat.id);
    }
  }

  ngOnDestroy() {
    if (this.singleCatSub) { this.singleCatSub.unsubscribe(); }
    if (this.catWebSubscription) { this.catWebSubscription.unsubscribe(); }
    if (this.tokenSubscription) {this.tokenSubscription.unsubscribe(); }
    if (this.routeParamsSubscription) {this.routeParamsSubscription.unsubscribe(); }
    if (this.brandsSub) {this.brandsSub.unsubscribe(); }
  }

  getBrands (name) {
    this.brandsSub = this.productService.getBrands().subscribe(res => {
      /* Sequence number greater than 70 are for unimportant brands which aren't showing on the homepage */
      this.brands = res.response.filter(data => data.link === name.toLowerCase());
    });
  }

  getWebCatDetailsById(res, id) {
    /*** Related products ***/
    const temp = res.filter(res1 => res1.id === 10 || res1.id === 12 || res1.id === 8);

    // this.singleCatSub = this.catService.getCategoryWebById(id).subscribe(res1 => {
    this.singleCatSub = this.productService.getEWebCatById(id).subscribe(res1 => {
      this.singleCat = res1;
      this.seo(res1);

      // *** Listing sub-categories of the web category
      this.getEwebCatDetails(this.singleCat.id);

      /*** Related products ***/
      this.relatedSubCats = temp.filter( tempData => tempData.id !== this.singleCat.id);

      /* Meta data */
      this.title.setTitle(this.singleCat.name + ' available from Hume');

      this.pageContent = this.sanitizer.bypassSecurityTrustHtml(this.singleCat.pageContent);
    });
  }

  tilesArrange() {
    if (this.subCats.length <= 4) {
      for (let i = 0; i < this.subCats.length; i++) {
        if (this.subCats.length === 1 ) { this.subCats[i].tileNumber = 6; } else {
          this.subCats[i].tileNumber = 12 / this.subCats.length;
        }
      }
    } else if ( this.subCats.length > 4 && this.subCats.length % 4 === 0 ) {
      for (let i = 0; i < this.subCats.length; i++) {
        this.subCats[i].tileNumber = 12 / 4;
      }
    } else if ( this.subCats.length > 4 && this.subCats.length % 4 !== 0  && this.subCats.length % 2 === 0) {
      for (let i = 0; i < this.subCats.length; i++) {
        if (i < 2) {
          this.subCats[i].tileNumber = 12 / 2;
        } else {
          this.subCats[i].tileNumber = 12 / 4;
        }
      }
    } else if (this.subCats.length === 5 ) {
      for (let i = 0; i < this.subCats.length; i++) {
        if ( i < 2) {
          this.subCats[i].tileNumber = 12 / 2;
        } else { this.subCats[i].tileNumber = 12 / 3; }
      }
    } else if (this.subCats.length === 7 ) {
      for (let i = 0; i < this.subCats.length; i++) {
        if ( i < 3) {
          this.subCats[i].tileNumber = 12 / 3;
        } else { this.subCats[i].tileNumber = 12 / 4; }
      }
    } else if (this.subCats.length === 9 ) {
      for (let i = 0; i < this.subCats.length; i++) {
        this.subCats[i].tileNumber = 12 / 3;
      }
    } else if (this.subCats.length === 11 ) {
      for (let i = 0; i < this.subCats.length; i++) {
        if (i < 3) {
          this.subCats[i].tileNumber = 12 / 3;
        } else {
          this.subCats[i].tileNumber = 12 / 4;
        }
      }
    } else if (this.subCats.length === 12 ) {
      for (let i = 0; i < this.subCats.length; i++) {
        this.subCats[i].tileNumber = 12 / 4;
      }
    }
  }

  seo(category) {
    this.seoService.setPageTitle(category.name);
    this.seoService.setMetaObjectInfo(category);
    this.seoService.createLinkForCanonicalURL();
  }
}
