export const ALLCONTENT = [
  {id: 0, 'content': [
      {name: 'Furring Channel 28mm', size: [
          {name: '3000', code: 'RON12930'},
          {name: '3600', code: 'RON12936'},
          {name: '4800', code: 'RON12948'},
          {name: '6000', code: 'RON12960'},
        ]},
      {name: 'Furring Channel 16mm', size: [
          {name: '3000', code: 'RON30830'},
          {name: '3600', code: 'RON30836'},
          {name: '4800', code: 'RON30848'},
          {name: '6000', code: 'RON30860'},
        ]},
      {name: 'Suspension Rod 121', size: [
          {name: '3600', code: 'RON12136'},
        ]},
      {name: 'Furring Channel Wall Track 16mm', size: [
          {name: '3000', code: 'RON14230'},
          {name: '3600', code: 'RON14236'},
        ]},
      {name: 'Furring Channel Wall Track 28mm', size: [
          {name: '3000', code: 'RON14030'},
          {name: '3600', code: 'RON14036'},
        ]},
      {name: 'Top Cross Rail 25mm', size: [
          {name: '3600', code: 'RON12736'},
          {name: '4800', code: 'RON12748'},
        ]},
      {name: 'FC Sliding Adj. Clip', size: [
          {name: '30', code: 'RONFC-INFIN30'},
          {name: '80', code: 'RONFC-INFIN80'}]},
      {name: 'FC Anchor Clip', size: [
          {name: '237', code: 'RON237'},
          {name: '239', code: 'RON239'}]},
      {name: 'DFC A-Clip', size: [
          {name: '75', code: 'RON22675'},
          {name: '175', code: 'RON394175'},
        ]},
      {name: 'Betagrip Clip', size: [
          {name: 'Small', code: 'RONBG01'},
          {name: 'Large', code: 'RONBG02'}
        ]},
      {name: 'Adjustable Furrig Channel Clip', size: [
          {name: '', code: 'RONBETAFIX'}
        ]},
      {name: 'TCR Susp. Clip 2534', size: [{name: '', code: 'RON2534'}]},
      {name: 'Locking Key 139', size: [{name: '', code: 'RON139'}]},
      {name: 'Ceiling Anchor', size: [{name: '', code: 'GTSTWA660YZ'}]}, {name: 'Side Mount 167', size: [{name: '', code: 'RON167'}]},
      {name: 'Rod Joiner 254', size: [{name: '', code: 'RON254'}]}, {name: '247 L Shape Bracket', size: [{name: '', code: 'RON247'}]},
      {name: 'Bulkhead Trim', size: [{name: '', code: 'RON32136'}]}, {name: '274 Straight Bracket', size: [{name: '', code: 'RON274'}]},
      {name: 'Thru Bolt (mm)', size: [
          {name: '6x85', code: 'GTSTB6-085'},
          {name: '6x100', code: 'GTSTB6-100'},
          {name: '6x120', code: 'GTSTB6-120'},
          {name: '6x150', code: 'GTSTB6-150'},
          {name: '6x180', code: 'GTSTB6-180'}
        ]},
    ]},
  {id: 1, 'content': [
      {name: 'T Bar main Runner 3600mm', size: [{name: '', code: 'RONDUO136'}]},
      {name: 'T Bar Cross Runner 1200mm', size: [{name: '', code: 'RONDUO212'}]},
      {name: 'T Bar Cross Runner 600mm', size: [{name: '', code: 'RONDUO26'}]},
      {name: 'Wall Angle 3600mm', size: [
          {name: '25x19mm Steel', code: 'RONDUO536'},
          {name: '19x9x9x15mm Steel', code: 'RONDUO636'},
          {name: '19x9x9x15mm Aluminium', code: 'RONDUO736'},
          {name: '32x19mm Aluminium', code: 'RONDUO836'}
        ]},
      {name: 'Susp. Clip T Bar Main', size: [{name: '', code: 'RON700'}, {name: 'Hooked adjustable', code: 'RON719'}]}
    ]},
  {id: 2, 'content': [
      {name: '90 Perforated External Corner Bead', size: [
          {name: '2400', code: 'RONP0124'},
          {name: '2700', code: 'RONP0127'},
          {name: '3000', code: 'RONP0130'},
          {name: '3600', code: 'RONP0136'}
        ]},
      {name: '90 Mesh External Corner Bead', size: [
          {name: '2400', code: 'RONP3224'},
          {name: '2700', code: 'RONP3227'},
          {name: '3000', code: 'RONP3230'},
          {name: '3600', code: 'RONP3236'}
        ]},
      {name: '135 Perforated External Corner Bead', size: [
          {name: '2400', code: 'RONP01A24'},
          {name: '2700', code: 'RONP01A27'},
          {name: '3000', code: 'RONP01A30'},
          {name: '3600', code: 'RONP01A36'}
        ]},
      {name: '90 Internal Corner Bead', size: [
          {name: '2400', code: 'RONPS1724'},
          {name: '2700', code: 'RONPS1727'},
          {name: '3000', code: 'RONPS1730'},
          {name: '3600', code: 'RONPS1736'}
        ]},
      {name: '135 Internal Corner Bead', size: [
          {name: '2400', code: 'RONPSIA24'},
          {name: '2700', code: 'RONPSIA27'},
          {name: '3000', code: 'RONPSIA30'},
          {name: '3600', code: 'RONPSIA36'}
        ]},
      {name: 'Stopping L Bead 3m', size: [
          {name: 'HUME PTC 10mm', code: 'PTCLB1030'},
          {name: '6mm Perforated Arch Bead', code: 'RONP1030'},
          {name: 'Rondo 10mm', code: 'RONP2530'},
          {name: 'Rondo 13mm', code: 'RONP2630'},
          {name: 'Rondo 16mm', code: 'RONP2730'}
        ]},
      {name: 'Shadowline Bead 3m', size: [
          {name: 'HUME PTC 10mm', code: 'PTCSL1030'},
          {name: '10mm for 6mm board', code: 'RONP6030'},
          {name: '10mm for 10/13/16mm board', code: 'RONP2530'},
        ]},
      {name: 'Casing Bead 3m', size: [
          {name: '6mm', code: 'RONP0330'},
          {name: '10mm', code: 'RONP0530'},
          {name: '13mm', code: 'RONP0730'},
          {name: '16mm', code: 'RONP0830'}
        ]},
      {name: 'Set Casing Bead 3m', size: [
          {name: '6mm', code: 'RONP1130'},
          {name: '10mm', code: 'RONP1230'},
          {name: '13mm', code: 'RONP1330'},
          {name: '16mm', code: 'RONP1430'}
        ]},
      {name: 'Plaster Internal Backing Angle P18', size: [{name: '2400', code: 'RONP1824'}]},
      {name: 'Plaster Expansion Control Joint P35', size: [{name: '3000', code: 'RONP3530'}]}
    ]},
  {id: 3, 'content': [
      {name: 'Base Coat 45', size: [
          {name: 'HUME', code: 'ABCHBASE45'},
          {name: 'BORAL', code: 'BORBC45'},
          {name: 'CSR', code: 'CSRBC45'},
        ]},
      {name: 'Base Coat 60', size: [
          {name: 'HUME', code: 'ABCHBASE60'},
          {name: 'BORAL', code: 'BORBC60'},
          {name: 'CSR', code: 'CSRBC60'},
        ]},
      {name: 'Base Coat 90', size: [
          {name: 'HUME', code: 'ABCHBASE90'},
          {name: 'BORAL', code: 'BORBC90'},
          {name: 'CSR', code: 'CSRBC90'},
        ]},
      {name: 'Topping', size: [
          {name: 'HUME All Purpose', code: 'ABCHTOPLW-B'},
          {name: 'BORAL', code: 'BORFC'},
          {name: 'CSR', code: 'CSRJM'},
          {name: 'Hamilton All Purpose', code: 'PROHA-AP'}]},
      {name: 'Cornice Cement', size: [
          {name: 'BORAL 45', code: 'BORCC45'},
          {name: 'BORAL 60', code: 'BORCC60'},
          {name: 'CSR 45', code: 'CSRCC45'},
          {name: 'CSR 60', code: 'CSRCC60'}
        ]},
      {name: 'Masonry Cement', size: [{name: 'BORAL', code: 'BORMC'}]},
      {name: 'Stud Adhesive 5.2kg', size: [
          {name: 'HUME', code: 'PTCSA5.2KG'},
          {name: 'BORAL', code: 'BORSA'}
      ]},
      {name: 'Paper Tape', size: [
          {name: '75M', code: 'PT75'},
          {name: '150M', code: 'PT150'}
      ]},
      {name: 'Easy Tape 90m 24/ctn', size: [{name: '', code: 'ONXFJT90'}]},
      {name: 'James Hardie', size: [
          {name: 'Base Coat 15kg', code: 'JH304491'},
          {name: 'Top Coat 15kg', code: 'JH304493'}
      ]},
      {name: 'Blue Circle Sand & Cement Mix 20kg', size: [{name: '', code: 'BBCPDMM104'}]},
      {name: 'Adhesive', size: [
          {name: 'CSR Hebel Adhesive', code: 'CSRHA20'},
          {name: 'HUME AAC Panel', code: 'HUMEAACADH'}
      ]},
    ]},
  {id: 4, 'content': [
      {name: 'Collated Fine needle point', size: [
          {name: '6gx25mm', code : 'GTSCFT625Z-1000'},
          {name: '6gx32mm', code : 'GTSCFT632Z-1000'},
          {name: '6gx40mm', code : 'GTSCFT640Z-1000'}
      ]},
      {name: 'Collated Fine Self Drill', size: [
          {name: '6gx25mm', code : 'GTSCSD625-1000'},
          {name: '6gx30mm', code : 'GTSCSD630-1000'},
          {name: '6gx40mm', code : 'GTSCSD640-1000'},
          {name: '6gx45mm', code : 'GTSCSD645-1000'}
        ]},
      {name: 'Collated Coarse', size: [
          {name: '6gx25mm', code : 'GTSCCT625Z-1000'},
          {name: '6gx32mm', code : 'GTSCCT632Z-1000'},
          {name: '6gx40mm', code : 'GTSCCT640Z-1000'}
        ]},
      {name: 'Loose Fine needle point', size: [
          {name: '6gx25mm', code : 'GTSSP25-1000'},
          {name: '6gx32mm', code : 'GTSSP32-1000'},
          {name: '6gx45mm', code : 'GTSSP45-0500'},
          {name: '7gx50mm', code : 'GTSSP50-0500'},
          {name: '8gx65mm', code : 'GTSSP65-0250'},
          {name: '8gx75mm', code : 'GTSSP75-0250'}
        ]},
      {name: 'Loose Fine SD', size: [
          {name: '6gx25mm', code : 'GTSSD25-1000'},
          {name: '6gx30mm', code : 'GTSSD30-1000'},
          {name: '6gx40mm', code : 'GTSSD40-0500'},
          {name: '6gx45mm', code : 'GTSSD45-0500'},
          {name: '8gx65mm', code : 'GTSSD65-0250'}
        ]},
      {name: 'Loose Coarse', size: [
          {name: '6gx25mm', code : 'GTSCT25-1000'},
          {name: '6gx32mm', code : 'GTSCT32-1000'},
          {name: '6gx41mm', code : 'GTSCT41-0500'},
          {name: '6gx45mm', code : 'GTSCT45-0500'},
        ]},
      {name: 'Button Head Needle point', size: [
          {name: '8gx12mm', code : 'GTSBH12-1000'},
          {name: '8gx16mm', code : 'GTSBH16-1000'}
        ]},
      {name: 'Button Head SD', size: [
          {name: '10gx16mm FLAT', code : 'GTSBH16SDF-1000'},
          {name: '8gx12mm', code : 'GTSBH12SD-1000'},
          {name: '8gx16mm', code : 'GTSBH16SD-1000'}
        ]},
      {name: 'Villaboard Screws', size: [
          {name: '8gx20mm Needle', code : 'GTSVS20-1000'},
          {name: '8gx30mm Needle', code : 'GTSVS30-1000'},
          {name: '8gx20mm Self Drill', code : 'GTSVS20SD-1000'},
          {name: '8gx30mm Self Drill', code : 'GTSVS30SD-1000'}
        ]},
      {name: 'Laminating Screw', size: [
          {name: '38mm', code : 'GTSLAM38-0500'},
          {name: '50mm', code : 'GTSLAM50-0500'}
        ]},
      {name: 'Galvanised Clout 2.5kg', size: [
          {name: '30mm 2.5kg', code : 'GTSGC2.5KG30'},
          {name: '40mm 2.5kg', code : 'GTSGC2.5KG40'}
        ]},
      {name: 'Ring Shank', size: [
          {name: '30mm 2.5kg', code : 'GTSRS2.5KG30'},
          {name: '30mm 25kg', code : 'GTSRS25KG30'},
          {name: '40mm 2.5kg', code : 'GTSRS2.5KG40'}
        ]},
      {name: 'Nylon Anchor 6.5mm', size: [
          {name: '6.5x25mm', code : 'GTSNA6525-100'},
          {name: '6.5x40mm', code : 'GTSNA6540-100'},
          {name: '6.5x50mm', code : 'GTSNA6550-100'},
          {name: '6.5x75mm', code : 'GTSNA6575-050'}
        ]},
    ]},
  {id: 5, 'content': [
      {name: 'Cornices 3m', size: [
          {name: 'ABC 90mm', code : 'ABCCORN9030'},
          {name: '90mm', code : 'CORN9030'},
        ]},
      {name: 'Cornices 3.6m', size: [
          {name: '55mm', code : 'CORN5536'},
          {name: 'ABC 90mm', code : 'ABCCORN9036'},
          {name: '90mm', code : 'CORN9036'}
        ]},
      {name: 'Cornices 4.2m', size: [
          {name: 'ABC 90mm', code : 'ABCCORN9042'},
          {name: '90mm', code : 'CORN9042'}
        ]},
      {name: 'Cornices 4.8m', size: [
          {name: '55mm', code : 'CORN5548'},
          {name: 'ABC 90mm', code : 'ABCCORN9048'},
          {name: '90mm', code : 'CORN9048'}
        ]},
      {name: 'Cornices 5.4m', size: [
          {name: '75mm', code : 'CORN7554'},
          {name: 'ABC 90mm', code : 'ABCCORN9054'},
          {name: '90mm', code : 'CORN9054'}
        ]},
      {name: 'Paper Faced 2 Step Cornice 4.8m', size: [{name: '50mm', code : 'CORN2ST48'}]},
      {name: 'Paper Faced 3 Step Cornice 4.8m', size: [{name: '75mm', code : 'CORN3ST48'}]},
      {name: 'Paper Faced 4 Step Cornice 4.8m', size: [{name: '100mm', code : 'CORN4ST48'}]},
    ]},
  {id: 6, 'content': [
      {name: 'Sand Face Vinyl Foiled Tiles', size: [{name: '', code : 'PTCVFFWSCT'}]},
      {name: 'Gyprock Freshtone Vinyl Face Tiles', size: [{name: '', code : 'COM10115'}]},
      {name: 'Armstrong Fine Fissured Square Edge', size: [{name: '', code : 'ARM3570'}]},
    ]},
  {id: 7, 'content': [
      {name: 'Corner Bead 90 3m', size: [{name: '', code : 'WTC72-4010'}]},
      {name: 'Arch Bead 90 Archway 3m', size: [{name: '', code : 'WTC72-4110'}]},
      {name: 'Tearaway Shadowline 3m', size: [
          {name: '10x10mm', code : 'WTC72-5520T'},
          {name: '6x10mm', code : 'WTC72-5390T'}
        ]},
      {name: 'Arch L Bead 16mm', size: [{name: '', code : 'WTC72-3299'}]},
      {name: 'Tearaway L', size: [
          {name: '6mm', code : 'WTC72-9002'},
          {name: '10mm', code : 'WTC72-9004'},
          {name: '13mm', code : 'WTC72-9010'}
        ]},
      {name: 'Expansion Joint 3m', size: [{name: '', code : 'WTC72-2710'}]},
    ]}
];

export const TRACKSTUD = [
  {name: 'Track 3000mm', BMT: [
      {name: 'Normal Duty', size: [
          {name: '51mm', code: 'RON40030'},
          {name: '64mm', code: 'RON11130'},
          {name: '76mm', code: 'RON40230'},
          {name: '92mm', code: 'RON25030'},
        ]},
      {name: 'Medium Duty', size: [
          {name: '51mm', code: 'RON49030'},
          {name: '64mm', code: 'RON49230'},
          {name: '76mm', code: 'RON49430'},
          {name: '92mm', code: 'RON49630'}
        ]},
      {name: 'Heavy Duty', size: [
          {name: '64mm', code: 'RON66030'},
          {name: '76mm', code: 'RON67030'},
          {name: '92mm', code: 'RON68030'}
        ]}
    ]},
  {name: 'Stud 2400mm', BMT: [
      {name: 'Normal Duty', size: [
          {name: '51mm', code: 'RON40124'},
          {name: '64mm', code: 'RON11224'},
          {name: '76mm', code: 'RON40324'},
          {name: '92mm', code: 'RON25124'}
        ]},
      {name: 'Medium Duty', size: [
          {name: '64mm', code: 'RON49124'},
          {name: '76mm', code: 'RON49324'},
          {name: '92mm', code: 'RON49524'}
        ]},
      {name: 'Heavy Duty', size: [
          {name: '76mm', code: 'RON67124'},
          {name: '92mm', code: 'RON68124'}
        ]}
    ]},
  {name: 'Stud 2700mm', BMT: [
      {name: 'Normal Duty', size: [
          {name: '51mm', code: 'RON40127'},
          {name: '64mm', code: 'RON11227'},
          {name: '76mm', code: 'RON40327'},
          {name: '92mm', code: 'RON25127'}
        ]},
      {name: 'Medium Duty', size: [
          {name: '51mm', code: 'RON48927'},
          {name: '64mm', code: 'RON49127'},
          {name: '76mm', code: 'RON49327'},
          {name: '92mm', code: 'RON49527'}
        ]},
      {name: 'Heavy Duty', size: [
          {name: '64mm', code: 'RON66127'},
          {name: '76mm', code: 'RON67127'},
          {name: '92mm', code: 'RON68127'}]}
    ]},
  {name: 'Stud 3000mm', BMT: [
      {name: 'Normal Duty', size: [
          {name: '51mm', code: 'RON40130'},
          {name: '64mm', code: 'RON11230'},
          {name: '76mm', code: 'RON40330'},
          {name: '92mm', code: 'RON25130'}
        ]},
      {name: 'Medium Duty', size: [
          {name: '51mm', code: 'RON48930'},
          {name: '64mm', code: 'RON49130'},
          {name: '76mm', code: 'RON49330'},
          {name: '92mm', code: 'RON49530'}
        ]},
      {name: 'Heavy Duty', size: [
          {name: '64mm', code: 'RON66130'},
          {name: '76mm', code: 'RON67130'},
          {name: '92mm', code: 'RON68130'}
        ]}
    ]},
  {name: 'Stud 3600mm', BMT: [
      {name: 'Normal Duty', size: [
          {name: '51mm', code: 'RON40136'},
          {name: '64mm', code: 'RON11236'},
          {name: '76mm', code: 'RON40336'},
          {name: '92mm', code: 'RON25136'}
        ]},
      {name: 'Medium Duty', size: [
          {name: '51mm', code: 'RON48936'},
          {name: '64mm', code: 'RON49136'},
          {name: '76mm', code: 'RON49336'},
          {name: '92mm', code: 'RON49536'}
        ]},
      {name: 'Heavy Duty', size: [
          {name: '64mm', code: 'RON66136'},
          {name: '76mm', code: 'RON67136'},
          {name: '92mm', code: 'RON68136'}
        ]}
    ]},
  {name: 'Stud 4200mm', BMT: [
      {name: 'Normal Duty', size: [
          {name: '64mm', code: 'RON11242'},
          {name: '76mm', code: 'RON40342'},
          {name: '92mm', code: 'RON25142'}
        ]},
      {name: 'Medium Duty', size: [
          {name: '64mm', code: 'RON49142'},
          {name: '76mm', code: 'RON49342'},
          {name: '92mm', code: 'RON49542'}
        ]},
      {name: 'Heavy Duty', size: [
          {name: '64mm', code: 'RON66142'},
          {name: '76mm', code: 'RON67142'},
          {name: '92mm', code: 'RON68142'}
        ]}
    ]},
  {name: 'Stud 4800mm', BMT: [
      {name: 'Normal Duty', size: [
          {name: '64mm', code: 'RON11248'},
          {name: '76mm', code: 'RON40348'},
          {name: '92mm', code: 'RON25148'}
        ]},
      {name: 'Medium Duty', size: [
          {name: '64mm', code: 'RON49148'},
          {name: '76mm', code: 'RON49348'},
          {name: '92mm', code: 'RON49548'}
        ]},
      {name: 'Heavy Duty', size: [
          {name: '64mm', code: 'RON66148'},
          {name: '76mm', code: 'RON67148'},
          {name: '92mm', code: 'RON68148'}
        ]}
    ]},
  {name: 'Stud 6000mm', BMT: [
      {name: 'Normal Duty', size: [
          {name: '64mm', code: 'RON11260'},
          {name: '92mm', code: 'RON25160'}
        ]},
      {name: 'Medium Duty', size: [
          {name: '64mm', code: 'RON49160'},
          {name: '92mm', code: 'RON49560'}
        ]},
      {name: 'Heavy Duty', size: [
          {name: '64mm', code: 'RON66160'},
          {name: '92mm', code: 'RON68160'}
        ]}
    ]}
];
