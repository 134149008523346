import {AfterViewInit, Component, EventEmitter, Input, NgZone, OnInit, Output, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {AlertService} from '../../../services/alert.service';
import {UserService} from '../../../services/user/user.service';
import {BsModalService} from 'ngx-bootstrap/modal';
declare var google;
declare var $;

@Component({
  selector: 'app-google-places',
  templateUrl: './google-places.component.html',
  styleUrls: ['./google-places.component.scss']
})
export class GooglePlacesComponent implements OnInit, AfterViewInit {
  @Output() setAddress: EventEmitter<any> = new EventEmitter();
  @Input() length;
  @Input() pageUrl;
  @Input() address;
  @Input() submitted;
  @Input() modalRef;

  // autocomplete: { input: string; };
  buttonText = '(Can\'t find the address)';
  titleText = 'Address search';
  addressForm: FormGroup;
  place;
  urlId;

  get f() { return this.addressForm; }

  constructor(private formBuilder: FormBuilder, private alertService: AlertService, private userService: UserService) {
  }

  ngOnInit(): void {
    if (this.pageUrl === 'cod') {
      this.addressForm = this.formBuilder.group({address: ['', Validators.required],
        suburb: ['', Validators.required], firstName: [''], lastName: [''],
        mobile: [''], state: ['', Validators.required], postCode: ['', Validators.required]});
    } else {
      this.addressForm = this.formBuilder.group({address: ['', Validators.required],
        suburb: ['', Validators.required], firstName: ['', Validators.required], lastName: ['', Validators.required],
        mobile: ['', Validators.required], state: ['', Validators.required], postCode: ['', Validators.required]});
      if (this.address) {
        const name = this.address.name.split(' ');
        let firstName;
        for (let i = 0; i < name.length - 1; i++) {
          if (i === 0) {
            firstName = name[i];
          } else {
            firstName = firstName + ' ' + name[i];
          }
        }
        this.addressForm.controls['firstName'].setValue(firstName);
        this.addressForm.controls['lastName'].setValue(name[name.length - 1]);
        this.addressForm.controls['mobile'].setValue(this.address.mobile);
      }
    }

    // this.GoogleAutocomplete = new google.maps.places.Autocomplete();
    // this.autocomplete = { input: '' };
  }

  ngAfterViewInit() {

    document.getElementById('mannual_address1').addEventListener('show.bs.collapse', () => {
      this.buttonText = '(Can\'t find the address)';
      this.titleText = 'Address search';
    });
    this.initialize();
  }


  initialize() {
    const input = document.getElementById('id_address');
    const options = {
      types: ['address'],
      componentRestrictions: {
        country: 'au'
      }
    };
    const autocomplete = new google.maps.places.Autocomplete(input, options);
    google.maps.event.addListener(autocomplete, 'place_changed', () => {
      const place = autocomplete.getPlace();
      let streetNo;
      let address;
      place.address_components.forEach(res => {
        switch (res.types[0]) {
          case 'street_number':
            streetNo = res.long_name;
            break;
          case 'route':
            address = res.long_name;
            break;
          case 'locality':
            this.addressForm.controls['suburb'].setValue(res.long_name);
            break;
          case 'administrative_area_level_1':
            this.addressForm.controls['state'].setValue(res.long_name);
            break;
          case  'postal_code':
            this.addressForm.controls['postCode'].setValue(res.long_name);
            break;
        }
      });
      if (streetNo) {
        this.addressForm.controls['address'].setValue(streetNo + ' ' + address);
      } else {
        this.addressForm.controls['address'].setValue(address);
      }
    });
  }

  addAddress() {
    const formValue = this.addressForm.value;
    if (this.addressForm.valid) {
      this.alertService.loading('Saving contact...');
      this.userService.addDeliveryContact(formValue).toPromise().then(res => {
        this.alertService.saveSuccess();
        this.addressForm.reset();
        this.modalRef.hide();
        this.submitted = false;
        this.setAddress.next();
      }, error => {
        this.alertService.alertClose();
        this.alertService.showError(error, true);
      });
    }
  }

  editAddress() {
    const formValue = this.addressForm.value;
    if (this.addressForm.valid) {
      this.alertService.loading('Updating contact...');
      this.userService.editDeliveryContact(this.address?.id, formValue).toPromise().then(res => {
        this.alertService.saveSuccess();
        this.addressForm.reset();
        this.modalRef.hide();
        this.submitted = false;
        this.setAddress.next();
      }, error => {
        this.alertService.alertClose();
        this.alertService.showError(error, true);
      });
    }
  }

  submitAddress(page) {
    this.submitted = true;
    switch (page) {
      case 'addContact':
        this.addAddress();
        break;
      case 'editContact':
        this.editAddress();
        break;
    }
  }

}
