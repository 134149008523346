import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import { Subscription} from 'rxjs';
import {ProductService} from '../../services/product.service';
import {Globals} from '../../global';
import {ShoppingCartService} from '../../services/shopping-cart.service';
import {AuthService} from '../../services/auth/auth.service';

// @ts-ignore
@Component({
  selector: 'app-home-featured-products',
  templateUrl: './home-featured-products.component.html',
  styleUrls: ['./home-featured-products.component.scss']
})
export class HomeFeaturedProductsComponent implements OnInit, OnDestroy {
  @Input() page;
  @Input() products;
  imgUrl = this.globals.picStorageRef;
  productsSubscription: Subscription;
  chunkSize = 4;
  currentUser;
  showAlert;
  hover = [];
  slideConfig;

  slides: any = [[]];

  constructor( private productService: ProductService, private authService: AuthService,
               private shoppingCartService: ShoppingCartService,
               public globals: Globals) {}

  async ngOnInit() {
    this.authService.currentUser.subscribe(x => {
      this.currentUser = x;
    });
    if (this.page === 'home') {
      this.setSlideConfig(true, true);
      await this.authService.refreshToken().then(res => {
        this.globals.token = res;
        this.getSpecialsProducts();
      });
    } else {
      this.setSlideConfig(false, false);
    }
  }

  setSlideConfig(showDots, showArrows) {
    this.slideConfig = {'slidesToShow': 5, 'slidesToScroll': 5, 'autoplay': true, 'dots': showDots,
      'autoplaySpeed': 3000, 'arrows': showArrows,
      // tslint:disable-next-line:max-line-length
      'prevArrow': '<div class="js-prev u-arrow-v1 g-pos-abs g-width-45 g-height-45 g-color-gray-dark-v5 g-bg-secondary g-color-white--hover g-bg-primary--hover rounded fa fa-angle-left slick-arrow" style="bottom: -50px"></div>',
      // tslint:disable-next-line:max-line-length
      'nextArrow': '<div class="js-next u-arrow-v1 g-pos-abs g-width-45 g-height-45 g-color-gray-dark-v5 g-bg-secondary g-color-white--hover g-bg-primary--hover rounded fa fa-angle-right slick-arrow" style="position: absolute !important;\n' +
        '    right: 0;"></div>',
      'responsive': [{
        breakpoint: 1200,
        settings: {
          slidesToShow: 5
        }
      }, {
        breakpoint: 992,
        settings: {
          slidesToShow: 3
        }
      }, {
        breakpoint: 768,
        settings: {
          slidesToShow: 2
        }
      }]
    };
  }

  getSpecialsProducts() {
    this.productsSubscription = this.productService.getESpecialProducts(0, 100).subscribe(res => {
      // @ts-ignore
      this.products = res.data ? res.data : res;
      this.slides = this.chunk(this.products, this.chunkSize);
    });
  }

  chunk (arr, chunkSize) {
    const R = [];
    for (let i = 0, len = arr.length; i < len; i += chunkSize) {
      R.push(arr.slice(i, i + chunkSize));
    }
    return R;
  }

  addToCart(product, basicModal, quantity) {
    this.shoppingCartService.addELineToShoppingCart(product, basicModal, quantity);
    this.showAlert = 'add';
    setTimeout(() => {
      this.showAlert = 'close';
    }, 1000);
  }

  addToWishlist(product) {
    let wishList = [];
    const temp = localStorage.getItem('wishList');
    if (temp) {
      wishList = JSON.parse(temp);
    }
    wishList.push(product);
    localStorage.setItem('wishList', JSON.stringify(wishList));

    if (this.currentUser) {
      this.shoppingCartService.addToWishlist(product);
      this.showAlert = 'wish';
      setTimeout(() => {
        this.showAlert = 'close';
      }, 1000);
    } else {
      this.authService.redirectToLoginPage();
    }
  }

  ngOnDestroy() {
    if (this.productsSubscription) { this.productsSubscription.unsubscribe(); }
  }
}
