<carousel [isAnimated]="true">
  <slide *ngFor="let slide of carousels">
    <!--<img [src]="imgUrl + slide?.photoUrl" alt="first slide" style="display: block; width: 100%;">-->
    <div class="category-grid-img g-pos-rel g-min-height-60vh"
         [ngStyle]="{'background-image': 'url(' + (page === 'home' ? slide?.photoUrl : (globals.picStorageRef + slide?.bannerUrl)) + ')'}"></div>
    <div class="carousel-caption d-md-block">
      <div class="animated-title">
        <div class="text-top">
          <div class="g-pa-0">
            <span>{{page === 'home' ? slide?.title : slide?.name}}</span>
            <span class="hume-slide-heading mt-3 mt-sm-1 g-font-size-17 g-font-size-25--md">{{page === 'home' ? slide?.heading : slide?.location}}</span>
          </div>
        </div>
      </div>
    </div>
    <div class="hume-carousel-background"></div>
  </slide>
</carousel>
