<div class="single-component-container">
  <!-- Breadcrumbs -->
  <!--<section class="g-brd-bottom g-brd-gray-light-v4 g-py-30">
    <div class="container">
      <ul class="u-list-inline">
        <li class="list-inline-item g-mr-5">
          <a class="u-link-v5 g-color-text" href="#!">Home</a>
          <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
        </li>
        <li class="list-inline-item g-mr-5">
          <a class="u-link-v5 g-color-text" href="#!">Pages</a>
          <i class="g-color-gray-light-v2 g-ml-5 fa fa-angle-right"></i>
        </li>
        <li class="list-inline-item g-color-primary">
          <span>Password Recovery</span>
        </li>
      </ul>
    </div>
  </section>-->
  <!-- End Breadcrumbs -->

  <!-- Login -->
  <section class="container g-py-100">
    <div class="row justify-content-center" *ngIf="showForm else showVerification">
      <div class="col-sm-9 col-md-7 col-lg-5">
        <div class="g-brd-around g-brd-gray-light-v3 g-bg-white rounded g-px-30 g-py-50 mb-4">
          <header class="text-center mb-4">
            <h1 class="h4 g-color-black g-font-weight-400">Forgot Password?</h1>
            <p>A 6 digit verification code will be sent to the email address/mobile number, if the email address or mobile number is registered in the system.</p>
          </header>

          <!-- Form -->
          <form class="g-py-15" [formGroup]="uploadForm" (ngSubmit)="onSubmit()">
            <div class="input-group g-rounded-left-5">
                    <span class="input-group-prepend g-width-45 g-brd-gray-light-v3 g-color-gray-dark-v5">
                      <span class="input-group-text justify-content-center w-100 g-bg-transparent g-brd-gray-light-v3">
                        <i class="icon-communication-154 g-color-primary g-font-size-20 pt-2 u-line-icon-pro"></i>
                      </span>
                    </span>
              <input autocomplete="off" autocapitalize="none" spellcheck="false" id="formEmail" class="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-rounded-left-0 g-rounded-right-5 g-py-15 g-px-15"
                     formControlName="email" type="email" placeholder="Registered email address">
            </div>
            <div class="error" *ngIf="upf.get('email').hasError('email')">
              Email must be a valid email address
            </div>

            <h4 class="py-3 text-center">Or</h4>
            <div class="mb-5">
              <div class="input-group g-rounded-left-5">
                    <span class="input-group-prepend g-width-45 g-brd-gray-light-v3 g-color-gray-dark-v5">
                      <span class="input-group-text justify-content-center w-100 g-bg-transparent g-brd-gray-light-v3">
                        <i class="icon-electronics-005 g-font-size-20 pt-2 g-color-primary u-line-icon-pro"></i>
                      </span>
                    </span>
                <input autocomplete="off" autocapitalize="none" spellcheck="false" id="formMobile" class="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-rounded-left-0 g-rounded-right-5 g-py-15 g-px-15"
                       formControlName="mobile"  placeholder="Registered mobile number">
              </div>
              <div class="error" *ngIf="upf.get('mobile').hasError('minlength')">
                Must be at least 10 characters!
              </div>
            </div>

            <button class="btn btn-block u-btn-primary g-font-size-14 font-weight-bold text-uppercase g-py-15 g-px-25 shadow-none" [disabled]="!(upf.get('email').value || upf.get('mobile').value)"
                    type="submit">Get Verification Code</button>
          </form>
          <!-- End Form -->
        </div>
      </div>
    </div>

    <ng-template #showVerification>
      <div class="row justify-content-center">
        <div class="col-sm-9 col-md-7 col-lg-5">
          <div class="g-brd-around g-brd-gray-light-v3 g-bg-white rounded g-px-30 g-py-50 mb-4">
            <header class="text-center mb-4">
              <h1 class="h4 g-color-black g-font-weight-400">Reset your password</h1>
            </header>

            <!-- Form -->
            <form class="g-py-15" [formGroup]="resetForm" (ngSubmit)="resetFormSubmit()">
              <div class="g-rounded-left-5 my-2">
                <strong><span class="g-color-primary mr-1">*</span>Verification Code (<span class="g-color-primary">6 digits code</span>)</strong>
                <!--<input autocomplete="off" autocapitalize="none" spellcheck="false" class="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-rounded-left-0 g-rounded-right-5 g-py-15 g-px-15"
                       formControlName="token" placeholder="Verification Code*">-->
                <div id="tokenDiv" class="row mx-auto mt-3">
                  <input formControlName="token1" class="verification-input" autocomplete="off" autocapitalize="none" spellcheck="false" type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" />
                  <input formControlName="token2" class="verification-input" autocomplete="off" autocapitalize="none" spellcheck="false" type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" />
                  <input formControlName="token3" class="verification-input" autocomplete="off" autocapitalize="none" spellcheck="false" type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" />
                  <input formControlName="token4" class="verification-input" autocomplete="off" autocapitalize="none" spellcheck="false" type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" />
                  <input formControlName="token5" class="verification-input" autocomplete="off" autocapitalize="none" spellcheck="false" type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" />
                  <input formControlName="token6" class="verification-input" autocomplete="off" autocapitalize="none" spellcheck="false" type="text" maxLength="1" size="1" min="0" max="9" pattern="[0-9]{1}" />
                </div>

                <div class="error" *ngIf="(f.get('token1').hasError('required') || f.get('token2').hasError('required') || f.get('token3').hasError('required')
                || f.get('token4').hasError('required') || f.get('token5').hasError('required') || f.get('token6').hasError('required')) && resetFormSubmitted && ( f.get('token').dirty || f.get('token').touched || f.get('token').invalid)">
                  <div *ngIf="f.get('token1').hasError('required') || f.get('token2').hasError('required') || f.get('token3').hasError('required')
                  || f.get('token4').hasError('required') || f.get('token5').hasError('required') || f.get('token6').hasError('required')">
                    Verification code is required.
                  </div>
                </div>
                <p class="g-font-size-15 g-color-grey pl-2 py-2">Have not received a code? <a>
                  <span class="g-color-primary" (click)="click()">Click here</span>
                </a></p>
              </div>


              <div class="mt-5 mb-3">
                <div class="g-rounded-left-5 mb-2">
                  <strong><span class="g-color-primary mr-1">*</span>New Password</strong>
                  <div class="input-group">
                    <input autocomplete="off" autocapitalize="none" spellcheck="false" class="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-rounded-left-0 g-rounded-right-0 g-py-15 g-px-15"
                           formControlName="password" [type]="showPassword ? 'text' : 'password'" placeholder="New Password*" autocomplete="off" autocapitalize="none" spellcheck="false">
                    <span class="input-group-append g-width-45 g-brd-gray-light-v3 g-color-gray-dark-v5">
                      <span class="input-group-text justify-content-center w-100 g-bg-transparent g-brd-gray-light-v3">
                        <i class="fa fa-eye-slash g-font-size-20 g-color-primary" *ngIf="showPassword" (click)="toggleShowPassword()"></i>
                        <i class="fa fa-eye g-font-size-20 g-color-primary" *ngIf="!showPassword" (click)="toggleShowPassword()"></i>
                      </span>
                    </span>
                  </div>
                </div>
                <div class="error" *ngIf="f.get('password').errors && resetFormSubmitted && ( f.get('password').dirty || f.get('password').touched || f.get('password').invalid)">
                  <div *ngIf="f.get('password').hasError('required')">
                    Password is required.
                  </div>
                </div>
                <div *ngIf="( f.get('password').errors && f.get('password').dirty || f.get('password').touched)">
                  <label class="col" [ngClass]="f.controls['password'].hasError('required') || f.controls['password'].hasError('minlength')  ? 'text-danger' : 'text-success'">
                    <i class="fa fa-close" *ngIf="f.controls['password'].hasError('required') ||
                  f.controls['password'].hasError('minlength') else tick"></i>
                    Must be at least 8 characters!
                  </label>
                  <label class="col" [ngClass]="f.controls['password'].hasError('required') || f.controls['password'].hasError('hasNumber')  ? 'text-danger' : 'text-success'">
                    <i class="fa fa-close" *ngIf="f.controls['password'].hasError('required') ||
                  f.controls['password'].hasError('hasNumber') else tick"></i>
                    Must contain at least 1 number!
                  </label>
                  <label class="col" [ngClass]="f.controls['password'].hasError('required') || f.controls['password'].hasError('hasCapitalCase')  ? 'text-danger' : 'text-success'">
                    <i class="fa fa-close" *ngIf="f.controls['password'].hasError('required') ||
                  f.controls['password'].hasError('hasCapitalCase') else tick"></i>
                    Must contain at least 1 in Capital Case!
                  </label>
                  <label class="col" [ngClass]="f.controls['password'].hasError('required') || f.controls['password'].hasError('hasSmallCase')  ? 'text-danger' : 'text-success'">
                    <i class="fa fa-close" *ngIf="f.controls['password'].hasError('required') ||
                  f.controls['password'].hasError('hasSmallCase') else tick"></i>
                    Must contain at least 1 Letter in Small Case!
                  </label>
                  <label class="col" [ngClass]="f.controls['password'].hasError('required') || f.controls['password'].hasError('hasSpecialCharacters') ? 'text-danger' : 'text-success'">
                    <i class="fa fa-close" *ngIf="f.controls['password'].hasError('required') ||
                  f.controls['password'].hasError('hasSpecialCharacters') else tick"></i>
                    Must contain at least 1 Special Character!
                  </label>
                </div>

                <ng-template #tick>
                  <i class="fa fa-check"></i>
                </ng-template>
              </div>

              <div class="mb-5">
                <div class="input-group g-rounded-left-5">
                  <input autocomplete="off" autocapitalize="none" spellcheck="false" class="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-rounded-left-0 g-rounded-right-5 g-py-15 g-px-15"
                         formControlName="confirmPassword" [type]="showPassword ? 'text' : 'password'" placeholder="Confirm Password*">
                </div>
                <div class="error" *ngIf="f.get('confirmPassword').errors && resetFormSubmitted && ( f.get('confirmPassword').dirty || f.get('confirmPassword').touched || f.get('confirmPassword').invalid)">
                  <div *ngIf="f.get('confirmPassword').hasError('required')">
                    Confirmed password is required.
                  </div>
                </div>
                <div class="error mt-2" *ngIf="f.controls['confirmPassword'].hasError('NoPassswordMatch')">
                  Password do not match
                </div>
              </div>

              <button class="btn btn-block u-btn-primary g-font-size-14 font-weight-bold text-uppercase g-py-15 g-px-25 shadow-none" [disabled]="resetForm.invalid"
                      type="submit">Reset Password</button>
            </form>
            <!-- End Form -->
          </div>
        </div>
      </div>
    </ng-template>
  </section>
</div>
