<div class="single-component-container">
  <!-- Signup -->
  <section class="container g-pt-100 g-pb-20">
    <div class="row">
      <div class="col-12 g-mb-80">
        <div class="g-brd-around g-brd-gray-light-v3 g-bg-white rounded g-px-30 g-py-50 mb-4">
          <header class="text-center mb-4">
            <h1 class="h4 g-color-black g-font-weight-400">Create New Account</h1>
          </header>

          <!-- Form -->
          <form class="g-py-15" [formGroup]="form" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col g-mb-20">
                <input class="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 rounded g-py-15 g-px-15" type="text" placeholder="*First name" formControlName="firstName">
                <div class="error" *ngIf="form.get('firstName').hasError('required') && form.get('firstName').touched">
                  First name is required
                </div>
              </div>

              <div class="col g-mb-20">
                <input class="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 rounded g-py-15 g-px-15" type="text" placeholder="*Last name" formControlName="lastName">
                <div class="error" *ngIf="form.get('lastName').hasError('required') && form.get('lastName').touched">
                  Last name is required
                </div>
              </div>
            </div>

            <div class="g-mb-20">
              <input id="mobile" class="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 rounded g-py-15 g-px-15" type="text" placeholder="*Mobile" formControlName="mobile">
              <div class="error" *ngIf="form.get('mobile').hasError('required') && form.get('mobile').touched">
                Mobile is required
              </div>
              <div class="error" *ngIf="form.get('mobile').hasError('minlength')">
                Mobile must be at least 10 digits
              </div>
            </div>

            <div class="g-mb-20">
              <input class="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 rounded g-py-15 g-px-15" type="email" placeholder="*Email address" formControlName="email">
              <div class="error" *ngIf="form.get('email').hasError('required') && form.get('email').touched">
                Email is required
              </div>
              <div class="error" *ngIf="form.get('email').hasError('pattern')">
                Email is invalid
              </div>
            </div>

            <div class="g-mb-20">
              <input class="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 rounded g-py-15 g-px-15" type="password" placeholder="*Password" formControlName="password">
              <div class="error" *ngIf="f.get('password').hasError('required') && f.get('password').touched">
                Password is required
              </div>
            </div>

            <div>
              <div *ngIf="( f.get('password').errors && f.get('password').dirty || f.get('password').touched)">
                <label class="col" [ngClass]="f.controls['password'].hasError('required') || f.controls['password'].hasError('minlength')  ? 'text-danger' : 'text-success'">
                  <i class="fa fa-close" *ngIf="f.controls['password'].hasError('required') ||
                  f.controls['password'].hasError('minlength') else tick"></i>
                  Must be at least 8 characters!
                </label>
                <label class="col" [ngClass]="f.controls['password'].hasError('required') || f.controls['password'].hasError('hasNumber')  ? 'text-danger' : 'text-success'">
                  <i class="fa fa-close" *ngIf="f.controls['password'].hasError('required') ||
                  f.controls['password'].hasError('hasNumber') else tick"></i>
                  Must contain at least 1 number!
                </label>
                <label class="col" [ngClass]="f.controls['password'].hasError('required') || f.controls['password'].hasError('hasCapitalCase')  ? 'text-danger' : 'text-success'">
                  <i class="fa fa-close" *ngIf="f.controls['password'].hasError('required') ||
                  f.controls['password'].hasError('hasCapitalCase') else tick"></i>
                  Must contain at least 1 in Capital Case!
                </label>
                <label class="col" [ngClass]="f.controls['password'].hasError('required') || f.controls['password'].hasError('hasSmallCase')  ? 'text-danger' : 'text-success'">
                  <i class="fa fa-close" *ngIf="f.controls['password'].hasError('required') ||
                  f.controls['password'].hasError('hasSmallCase') else tick"></i>
                  Must contain at least 1 Letter in Small Case!
                </label>
                <label class="col" [ngClass]="f.controls['password'].hasError('required') || f.controls['password'].hasError('hasSpecialCharacters') ? 'text-danger' : 'text-success'">
                  <i class="fa fa-close" *ngIf="f.controls['password'].hasError('required') ||
                  f.controls['password'].hasError('hasSpecialCharacters') else tick"></i>
                  Must contain at least 1 Special Character!
                </label>
              </div>


              <ng-template #tick>
                <i class="fa fa-check"></i>
              </ng-template>
            </div>



            <div class="g-mb-20">
              <input class="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 rounded g-py-15 g-px-15" type="password" placeholder="*Confirm password"  formControlName="confirmPassword">
              <div class="error" *ngIf="f.get('confirmPassword').hasError('required') && f.get('confirmPassword').touched">
                Confirm password is required
              </div>
              <div class="error" *ngIf="f.controls['confirmPassword'].hasError('NoPassswordMatch')">
                Password do not match
              </div>
            </div>

            <div class="mb-1">
              <p>
                <label class="form-check-inline u-check g-color-gray-dark-v5 g-font-size-13 g-pl-25 mb-2">
                  <input class="g-hidden-xs-up g-pos-abs g-top-0 g-left-0" type="checkbox" formControlName="acceptTC">
                  <span class="d-block u-check-icon-checkbox-v6 g-absolute-centered--y g-left-0">
                      <i class="fa" data-check-icon="&#xf00c;"></i>
                    </span>
                  <span class="g-color-primary mr-1">*</span>I accept <a class="ml-1" routerLink="/tos" target="_blank">Hume Building Products Terms of Sale and Delivery Policy</a>
                </label>
              </p>
              <div class="error" *ngIf="f.get('acceptTC').hasError('required') && f.get('acceptTC').touched">
                Please accept our Terms of Sale and Delivery Policy to register.
              </div>
              <p>
                <label class="form-check-inline u-check g-color-gray-dark-v5 g-font-size-13 g-pl-25 mb-2">
                  <input class="g-hidden-xs-up g-pos-abs g-top-0 g-left-0" type="checkbox" formControlName="acceptPP">
                  <span class="d-block u-check-icon-checkbox-v6 g-absolute-centered--y g-left-0">
                      <i class="fa" data-check-icon="&#xf00c;"></i>
                    </span>
                  <span class="g-color-primary mr-1">*</span>I accept <a routerLink="/privacy-policy" class="ml-1" target="_blank">Hume Building Products Privacy Policy</a>
                </label>
              </p>
              <div class="error" *ngIf="f.get('acceptPP').hasError('required') && f.get('acceptPP').touched">
                Please accept our Privacy Policy to register.
              </div>
            </div>

            <div class="mb-3">
              <label class="form-check-inline u-check g-color-gray-dark-v5 g-font-size-13 g-pl-25 mb-2">
                <input class="g-hidden-xs-up g-pos-abs g-top-0 g-left-0" type="checkbox" formControlName="marketingConsent">
                <span class="d-block u-check-icon-checkbox-v6 g-absolute-centered--y g-left-0">
                      <i class="fa" data-check-icon="&#xf00c;"></i>
                    </span>
                Subscribe to our newsletter
              </label>
            </div>

            <button class="btn btn-block u-btn-primary g-font-size-14 font-weight-bold text-uppercase g-py-15 g-px-25 shadow-none" type="submit" [disabled]="form.invalid">Signup</button>
          </form>
          <!-- End Form -->
        </div>

        <div class="text-center">
          <p class="g-color-gray-dark-v5 mb-0">Already have an account?
            <a class="g-font-weight-600 g-color-primary" routerLink="/auth/login">Signin</a></p>
        </div>
      </div>

      <!--<div class="col-lg-7 order-lg-1 g-mb-80">
        <div class="g-pr-20--lg">
          <div class="mb-5">
            <h2 class="h1 g-font-weight-400 mb-3">Welcome to Hume</h2>
            <span class="g-color-gray-dark-v4" style="font-size: large" >Australia’s leading construction and building products distributor.</span>
          </div>
        </div>
      </div>-->
    </div>
  </section>
</div>

