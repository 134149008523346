<section class="float-center submit-heading">
    <div class="container">
      <div class="col-md-10 col-lg-8 col-xl-7 mx-auto">
        <h1 class="jumbotron-heading delivery-title">{{ 'TRACKING.Title' | translate }}</h1>
        <h3 class="lead"singleCat>{{ 'TRACKING.Welcome' | translate }}</h3>
        <p></p>
        <h3 class="lead"singleCat>{{ 'TRACKING.Enter' | translate }}</h3>

        <form [formGroup]="trackForm" (ngSubmit)="onSubmit()">
          <div class="row">
            <div class="col-12 col-md-9 mb-2 mb-md-0">
              <label class="float-left" for="pickingSlip"singleCat>{{ 'TRACKING.Order No' | translate }}</label>
              <input type="text" formControlName="ps" class="form-control form-control-lg" id="pickingSlip" [ngClass]="{ 'is-invalid': submitted && f.ps.errors }" placeholder="E.g. SGR/123456">
              <div *ngIf="submitted && f.ps.errors" class="invalid-feedback">
                <div *ngIf="f.ps.errors.required"singleCat>{{ 'TRACKING.Picking slip is required' | translate }}</div>
                <div *ngIf="f.ps.errors.minlength"singleCat>{{ 'TRACKING.Incorrect Picking slip number' | translate }}</div>
              </div>
            </div>
            <div class="col-12 col-md-9 my-2 mb-md-0">
              <label class="float-left"singleCat>{{ 'TRACKING.Suburb' | translate }}</label>
              <input type="text" formControlName="suburb" class="form-control form-control-lg" [ngClass]="{ 'is-invalid': submitted && f.suburb.errors }" placeholder="E.g. Parramatta">
              <div *ngIf="submitted && f.suburb.errors" class="invalid-feedback">
                <div *ngIf="f.suburb.errors.required"singleCat>{{ 'TRACKING.Suburb is required' | translate }}</div>
                <div *ngIf="f.suburb.errors.pattern || f.suburb.errors.minlength"singleCat>{{ 'TRACKING.Invalid suburb name' | translate }}</div>
              </div>
            </div>
          </div>
          <p></p>
          <button type="submit" class="btn btn-lg btn-hume-color waves-light waves-effect shadow-none" id="track"singleCat>{{ 'TRACKING.Track' | translate }}</button>
          <button class="btn btn-lg btn-outline-dark waves-light waves-effect shadow-none" (click)="clearTrack()" id="clear"singleCat>{{ 'TRACKING.Clear' | translate }}</button>
          <p></p>
          <div [hidden]="noRecord" class="alert alert-danger"singleCat>
            <strong>Error: </strong>{{ 'TRACKING.No record' | translate }}
          </div>
        </form>

      </div>

    </div>
</section>

