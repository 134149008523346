import {Component, OnDestroy, OnInit} from '@angular/core';
import {FormBuilder, Validators} from '@angular/forms';
import {ActivatedRoute, Router} from '@angular/router';
import {Store} from '../../../store';
import {AuthService} from '../../services/auth/auth.service';
import {AlertService} from '../../services/alert.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-login',
  templateUrl: './login.component.html',
  styleUrls: ['./login.component.scss']
})

export class LoginComponent implements OnInit, OnDestroy {
  routeSub: Subscription;
  submitted = false;
  returnUrl: string;
  singleProductId;
  showPassword = false;


  signInForm = this.fb.group({
    email: ['', Validators.required],
    password: ['', Validators.required]
  });

  constructor(
    private fb: FormBuilder, private route: ActivatedRoute,
    private router: Router, private store: Store,
    private authService: AuthService,
    private alertService: AlertService
  ) {
  }

  get f() { return this.signInForm; }

  ngOnInit() {
   this.routeSub = this.route.params.subscribe(res => {
      if (res) { this.singleProductId = res.id; }
    });
    // get return url from route parameters or default to '/'
    if (localStorage.getItem('returnPageUrl')) {
      this.returnUrl = localStorage.getItem('returnPageUrl');
    } else {
      this.returnUrl = 'account/details';
    }
    if (localStorage.getItem('currentUser')) {
      this.router.navigate(['account/details']);
    }
  }

  onSubmit() {
    this.submitted = true;
    if (this.signInForm.valid) {
      this.alertService.loading('Logging in...');
      const { email, password } = this.signInForm.value;
      this.authService.loginUser(email, password, this.returnUrl, this.singleProductId)
        .subscribe( res => {
          this.alertService.alertClose();
        }, error => {
          this.alertService.alertClose();
          switch (error?.status) {
            case 401:
              this.alertService.showError('Incorrect login credential.', true, 5000);
              break;
            case 500:
              this.alertService.showError(
                // tslint:disable-next-line:max-line-length
                'We are unable to access to server currently.', true);
              break;
            default:
              // tslint:disable-next-line:max-line-length
              this.alertService.showError('Something went wrong.', true );
              break;
          }
      });
    } else {
      return;
    }
  }

  ngOnDestroy() {
    if (this.routeSub) { this.routeSub.unsubscribe(); }
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }
}
