import {Component, OnInit} from '@angular/core';
import {Globals} from '../global';
import {Projectervice} from '../services/project.service';

@Component({
  selector: 'app-projects',
  templateUrl: './projects.component.html',
  // styleUrls: ['./news.component.scss']
})
export class ProjectsComponent implements OnInit {
  showButton = true;
  allProjects;
  imgUrl = this.globals.picStorageRef;
  dataTransition = ['zoomout', 'fadetotopfadefrombottom', 'zoomin'];

  constructor(public globals: Globals,
              private projectService: Projectervice) {}

  ngOnInit () {
    this.projectService.getProjectList().toPromise().then( res => {
      this.allProjects = res.response.reverse();
    });
  }

  expandList() {
    document.getElementById('project-list-content').style.maxHeight = '100%';
    this.showButton = false;
  }
}
