import {Injectable} from '@angular/core';
import {HttpClient} from '@angular/common/http';

@Injectable({
  providedIn: 'root'
})
export class TrackingService {

  routeActivate: boolean;

  constructor(
    private http: HttpClient
  ) { }

  /**
   * Getting information of orders
   * @param ps
   * @param suburb
   */
  getTracking(ps, suburb) {
    this.routeActivate = true;
    const urlTracking = 'https://hls.humeplaster.com.au/api/tracking?pickingSlip=' + ps + '&suburb=' + suburb;
    return this.http.get(urlTracking);
  }

  /**
   * Getting geolocation information of truck
   * @param ps
   * @param suburb
   */
  getLocation(ps, suburb) {
    this.routeActivate = true;
    const urlLocation = 'https://hls.humeplaster.com.au/api/location/tracking?pickingSlip=' + ps + '&suburb=' + suburb;
    return this.http.get(urlLocation);
  }

  /**
   * Getting destination infomation
   * @param ps
   * @param suburb
   */
  getDestination(ps, suburb) {
    // get state name
    let destinationState = '';
    // let element;
    const sArray = ps.split('/');
    if (sArray[0] === 'PRE' || sArray[0] === 'SUN') {
      destinationState = 'VIC';
    } else {
      destinationState = 'NSW';
    }
    return suburb + ',' + destinationState;
  }
}
