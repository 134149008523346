<div class="single-component-container" *ngIf="singleCat">
  <!-- Section only show when AAC wall system -->
  <section id="category-aac-banner" *ngIf="singleCat.id === 8">
    <video autoplay muted loop id="myVideo">
      <source [src]="docUrl + 'Catalogues/walsc/Walsc_1.mp4'" type="video/mp4">
    </video>
    <div class="content">
      <div class="g-my-40 text-center">
        <p class="g-color-white g-font-weight-700 g-font-size-20 g-font-size-50--md text-uppercase g-line-height-1_2 g-letter-spacing-10 mb-4">{{singleCat.name}}</p>
        <span class="d-block lead g-color-white g-letter-spacing-2" style="font-weight: 500">Hume supplies 75mm and 50mm AAC systems</span>
      </div>
    </div>
  </section>
  <!-- End section only show when AAC wall system -->

  <!-- Category page title (exclueding from id = 8 cladding page)-->
  <!--<div class="text-center g-pt-60 g-pt-100--sm" *ngIf="singleCat.id !== 8">
    <h1 class="g-color-black g-font-weight-700 g-font-size-20 g-font-size-35--md text-uppercase g-line-height-1_2 g-letter-spacing-10 mb-2 mb-sm-4">{{singleCat?.id === 6 && globals.divisionTag === 'architectural' ? 'Facade products' : singleCat.name}}</h1>
    <div class="d-inline-block g-width-150 g-height-6 g-bg-primary my-4 g-hidden-sm-down"></div>
  </div>-->

  <section class="u-bg-overlay category-grid-img g-bg-bluegray-opacity-0_3--after g-height-250 g-height-350--md" *ngIf="singleCat.id !== 8"
           [ngStyle]="!globals?.isMobile ? {'background-image': 'url(' + imgUrl + singleCat?.bannerUrl + ')'} : {'background-image': 'url(' + imgUrl + singleCat?.thumbnailUrl + ')'}">
    <div class="container u-bg-overlay__inner text-center g-pos-rel" [ngClass]="singleCat?.id === 44 ? 'g-top-15x' : 'g-top-30x'">
      <div class="g-mb-100 about-title-overlay">
        <img *ngIf="singleCat?.id === 44" class="img-fluid mb-4" src="../../../assets/img/logo/Hume Logo White.png" alt="Hume" width="40%"/>
        <h1 class="g-color-white g-font-weight-700 g-font-size-20 g-font-size-50--md text-uppercase g-line-height-1_2 g-letter-spacing-10 mb-4">{{singleCat?.id === 6 && globals.divisionTag === 'architectural' ? 'Facade products' : singleCat.name}}</h1>
        <span class="d-block lead g-color-white g-letter-spacing-2" style="font-weight: 500"></span>
      </div>
    </div>
  </section>
  <!-- End Category page tile -->

  <!--Category page content-->
  <section class="container g-pt-10 g-pb-25">

    <!-- showing sub-categories grid when it's not Kitchen Bathroom -->
    <div *ngIf="singleCat?.id !== 7 else kbSelection">
      <!-- Show when web category has sub categories -->
      <div class="row mt-4" *ngIf="singleCat.name === 'Cladding' || 'Flooring' || 'Doors & skylights' || 'Kitchens, bathrooms & appliances'">
        <div [attr.class]="'col-sm-' + subCat.tileNumber + ' g-px-5 g-mb-10 col-6'" *ngFor="let subCat of subCats">
          <a (click)="openSub()" [routerLink]="subCat?.link.length > 0 ? [('/' + singleCat?.link + '/' + subCat?.link) | urlTransfer] : [( '/' + singleCat?.link + '/' + subCat?.name) | urlTransfer]">
            <article class="title-sm-230 g-bg-cover z-depth-1-half g-bg-size-cover g-bg-white-gradient-opacity-v1--after hoverable"
                     [ngStyle]="{'background-image': 'url(' + imgUrl + subCat?.thumbnailUrl + ')'}">
              <div class="g-flex-middle category-tiles g-pos-rel g-z-index-1 g-pa-30" [ngClass]="{'g-min-height-250': subCats?.length > 9, 'g-min-height-400': subCats?.length <= 9}">
                <span class="d-block g-color-white-opacity-0_8 g-font-size-11 text-uppercase mb-4"></span>
                <a class="h4 g-color-white g-font-weight-600 g-mb-30 stripe dark g-font-size-25 hoverable" [ngStyle]="subCats?.length > 9 ? {'bottom': '26%'} : {'bottom': '40%'}"
                   [routerLink]="subCat?.link.length > 0 ? [('/' + singleCat?.link + '/' + subCat?.link) | urlTransfer] : [(  '/' + singleCat?.link + '/' + subCat?.name) | urlTransfer]">{{subCat?.name}}</a>
                <div class="d-inline-block">
                </div>
              </div>
            </article>
          </a>
        </div>
      </div>
      <!-- Show when web category has sub categories -->

      <!-- Call and quote buttons -->
      <div class="row d-flex justify-content-center my-3">
        <a href="tel:0296819096" *ngIf="singleCat?.email === ('enquiry@humearchitectural.com.au' || 'enver.k@humearchitectural.com.au')" >
          <button class="ml-3 btn btn-hume-color g-bg-primary waves-light waves-effect font-weight-bold g-font-size-13 g-font-size-18--md"
                  [ngClass]="globals.isMobile ? 'btn-sm' : 'btn-lg'" type="button">Call 02 9681 9096</button>
        </a>
        <a href="tel:0287758617" *ngIf="singleCat?.email === 'allen.c@humecommercial.com.au'">
          <button class="ml-3 btn btn-hume-color g-bg-primary waves-light waves-effect font-weight-bold g-font-size-13 g-font-size-18--md"
                  [ngClass]="globals.isMobile ? 'btn-sm' : 'btn-lg'" type="button">Call 02 8775 8617</button>
        </a>
        <a [href]="singleCat?.id === 7 ? 'tel:134863' : 'tel:134863'" *ngIf="singleCat?.email !== ('enquiry@humearchitectural.com.au' && 'enver.k@humearchitectural.com.au' && 'allen.c@humecommercial.com.au')">
          <button class="ml-3 btn btn-hume-color g-bg-primary waves-light waves-effect font-weight-bold g-font-size-13 g-font-size-18--md"
                  [ngClass]="globals.isMobile ? 'btn-sm' : 'btn-lg'" type="button">
            {{singleCat?.id === 7 ? 'Call 13 4863' : 'Call 13 4863'}}
          </button>
        </a>
        <a (click)="frame.show()">
          <button type="button" mdbBtn class="btn btn-hume-color waves-light waves-effect font-weight-bold g-font-size-13 g-font-size-18--md"
                  [ngClass]="globals.isMobile ? 'btn-sm' : 'btn-lg'"
                  mdbWavesEffect *ngIf="singleCat?.name !== 'Tools & accesories'">Request Quote</button>
        </a>
      </div>
      <!-- Call and quote buttons -->
    </div>
    <!--End showing sub-categories grid when it's not Kitchen Bathroom -->

    <ng-template #kbSelection>
      <!-- Call and quote buttons -->
      <div class="row d-flex justify-content-center my-3">
        <a href="tel:0296819096" *ngIf="singleCat?.email === ('enquiry@humearchitectural.com.au' || 'enver.k@humearchitectural.com.au')" >
          <button class="ml-3 btn btn-hume-color g-bg-primary waves-light waves-effect font-weight-bold g-font-size-13 g-font-size-18--md"
                  [ngClass]="globals.isMobile ? 'btn-sm' : 'btn-lg'" type="button">Call 02 9681 9096</button>
        </a>
        <a href="tel:0287758617" *ngIf="singleCat?.email === 'allen.c@humecommercial.com.au'">
          <button class="ml-3 btn btn-hume-color g-bg-primary waves-light waves-effect font-weight-bold g-font-size-13 g-font-size-18--md"
                  [ngClass]="globals.isMobile ? 'btn-sm' : 'btn-lg'" type="button">Call 02 8775 8617</button>
        </a>
        <a [href]="singleCat?.id === 7 ? 'tel:134863' : 'tel:134863'" *ngIf="singleCat?.email !== ('enquiry@humearchitectural.com.au' && 'enver.k@humearchitectural.com.au' && 'allen.c@humecommercial.com.au')">
          <button class="ml-3 btn btn-hume-color g-bg-primary waves-light waves-effect font-weight-bold g-font-size-13 g-font-size-18--md"
                  [ngClass]="globals.isMobile ? 'btn-sm' : 'btn-lg'" type="button">
            {{singleCat?.id === 7 ? 'Call 13 4863' : 'Call 13 4863'}}
          </button>
        </a>
        <a (click)="frame.show()">
          <button type="button" mdbBtn class="btn btn-hume-color waves-light waves-effect font-weight-bold g-font-size-13 g-font-size-18--md"
                  [ngClass]="globals.isMobile ? 'btn-sm' : 'btn-lg'"
                  mdbWavesEffect *ngIf="singleCat?.name !== 'Tools & accesories'">Request Quote</button>
        </a>
      </div>
      <!-- Call and quote buttons -->

      <!-- Products selection grid -->
      <div *ngIf="eSubCats?.length > 0">
        <div class="u-heading-v1-4 g-bg-main g-brd-primary g-mb-20 text-center">
          <h2 class="g-font-size-25--sm g-font-size-16 u-heading-v1__title g-py-20">Browse online products</h2>
        </div>

        <div class="row">
          <div class="col-lg-3 col-6 g-mb-0 g-mb-30--sm" *ngFor="let item of eSubCats">
            <!-- Team Block -->
            <a class="text-decoration-none" [routerLink]="'/products/' + singleCat?.name.split(' ').join('_') + '/' + (item?.name ? (item?.name?.split(' ').join('_')) : (item?.description?.split(' ').join('_')))">
              <div class="u-info-v1-2">
                <figure class="u-block-hover text-center">
                  <img class="g-height-150--sm g-height-110 mx-auto" [ngClass]="img.src === globals.defaultImgUrl ? '' : 'w-100'" [src]="item?.photoUrl" [alt]="'Hume Building Products' + item?.description ? item?.description : item?.name" (error)="img.src = globals.defaultImgUrl" #img>
                </figure>

                <!-- Figure Info -->
                <div class="g-bg-white g-py-10 g-py-25--sm">
                  <div class="g-mb-15">
                    <h4 class="h5 g-color-black g-mb-5">{{item?.description ? item?.description : item?.name}}</h4>
                    <em class="d-block u-info-v1-2__item g-font-style-normal g-font-size-11 text-uppercase g-color-primary"></em>
                  </div>
                </div>
                <!-- End Figure Info-->
              </div>
            </a>
            <!-- End Team Block -->
          </div>
        </div>
      </div>
      <!-- End Products selection grid -->
      <hr>
    </ng-template>

    <!-- Show quote form modal -->
    <div mdbModal #frame="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="overflow: scroll">
      <div class="modal-dialog" role="document">
        <app-quote-form [catId]="singleCat.id" [frame]="frame"></app-quote-form>
      </div>
    </div>
    <!-- Show quote form modal -->

    <!-- Main page content section-->
    <div class="row" id="myTabContent" *ngIf="pageContent">
        <div class="col-md-12 g-mb-30" id="home" role="tabpanel" aria-labelledby="home-tab">
           <div class="mb-5 g-font-size-17" [innerHTML]="pageContent">
            </div>
        </div>
    </div>
    <!-- End Main page content section-->

    <!-- Products selection grid when it's not kitchen bathroom -->
    <div *ngIf="singleCat?.id !== 7 else kbSub">
      <div *ngIf="eSubCats?.length > 0">
        <div class="u-heading-v1-4 g-bg-main g-brd-primary g-mb-20 text-center">
          <h2 class="g-font-size-25--sm g-font-size-16 u-heading-v1__title g-py-20">Browse online products</h2>
        </div>

        <div class="row">
          <div class="col-lg-3 col-6 g-mb-0 g-mb-30--sm" *ngFor="let item of eSubCats">
            <a class="text-decoration-none" [routerLink]="'/products/' + singleCat?.name.split(' ').join('_') + '/' + (item?.name ? (item?.name?.split(' ').join('_')) : (item?.description?.split(' ').join('_')))">
              <div class="u-info-v1-2">
                <figure class="u-block-hover text-center">
                  <img class="g-height-150--sm g-height-110 mx-auto" [ngClass]="img.src === globals.defaultImgUrl ? '' : 'w-100'" [src]="item?.photoUrl" [alt]="'Hume Building Products' + item?.description ? item?.description : item?.name" (error)="img.src = globals.defaultImgUrl" #img>
                </figure>

                <div class="g-bg-white g-py-10 g-py-25--sm">
                  <div class="g-mb-15">
                    <h4 class="h5 g-color-black g-mb-5">{{item?.description ? item?.description : item?.name}}</h4>
                    <em class="d-block u-info-v1-2__item g-font-style-normal g-font-size-11 text-uppercase g-color-primary"></em>
                  </div>
                </div>
              </div>
            </a>
          </div>
        </div>
      </div>

    </div>
    <!-- End Products selection grid -->

    <ng-template #kbSub>
      <!-- Show when web category has sub categories -->
      <div class="row mt-4" *ngIf="singleCat.name === 'Cladding' || 'Flooring' || 'Doors & skylights' || 'Kitchens, bathrooms & appliances'">
        <div [attr.class]="'col-sm-' + subCat.tileNumber + ' g-px-5 g-mb-10'" *ngFor="let subCat of subCats">
          <a (click)="openSub()" [routerLink]="subCat?.link.length > 0 ? [('/' + singleCat?.link + '/' + subCat?.link) | urlTransfer] : [( '/' + singleCat?.link + '/' + subCat?.name) | urlTransfer]">
            <article class="title-sm-230 g-bg-cover z-depth-1-half g-bg-size-cover g-bg-white-gradient-opacity-v1--after hoverable"
                     [ngStyle]="{'background-image': 'url(' + imgUrl + subCat?.thumbnailUrl + ')'}">
              <div class="g-flex-middle category-tiles g-pos-rel g-z-index-1 g-pa-30" [ngClass]="{'g-min-height-250': subCats?.length > 9, 'g-min-height-400': subCats?.length <= 9}">
                <span class="d-block g-color-white-opacity-0_8 g-font-size-11 text-uppercase mb-4"></span>
                <a class="h4 g-color-white g-font-weight-600 g-mb-30 stripe dark g-font-size-25 hoverable" [ngStyle]="subCats?.length > 9 ? {'bottom': '26%'} : {'bottom': '40%'}"
                   [routerLink]="subCat?.link.length > 0 ? [('/' + singleCat?.link + '/' + subCat?.link) | urlTransfer] : [(  '/' + singleCat?.link + '/' + subCat?.name) | urlTransfer]">{{subCat?.name}}</a>
                <div class="d-inline-block">
                </div>
              </div>
            </article>
          </a>
        </div>
      </div>
      <!-- Show when web category has sub categories -->
    </ng-template>

    <!-- Gallery only show when smart stone -->
    <div id="home-grid"  class="row container mx-auto g-mx-minus-5 g-mb-70--md g-mb-20" *ngIf="singleCat?.id === 44">

      <div class="col-12 g-px-5 mb-5"  data-toggle="modal" data-target=".bd-example-modal-lg-special">
        <article class="g-bg-cover g-bg-size-cover g-bg-white-gradient-opacity-v1--after u-block-hover">
          <h2 class="h4 g-color-white g-font-weight-600 text-center g-mb-30 sub-stripe dark g-z-index-4">Calacatta Virtuoso</h2>
          <div class="g-flex-middle g-min-height-400 g-pos-rel g-z-index-1 g-pa-30 u-block-hover__main--zoom-v1"
               [ngStyle]="{'background-image': 'url(' + imgUrl + 'categories-web/smart-stone/Calacatta_Virtuoso_small.jpeg)'}">
            <span class="d-block g-color-white-opacity-0_8 g-font-size-11 text-uppercase mb-4"></span>
            <div class="d-inline-block">
            </div>
          </div>
        </article>
        <div class="modal fade bd-example-modal-lg-special" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg" style="margin-top: 17%">
            <div class="modal-content">
              <img class="img-fluid" [src]="imgUrl + 'categories-web/smart-stone/Calacatta_Virtuoso.jpeg'" alt="">
            </div>
            <h2 class="text-center mt-3 g-color-white g-font-weight-600 g-mb-30 g-z-index-4">Calacatta Virtuoso</h2>
          </div>
        </div>
      </div>

      <!-- Grid column -->
      <div class="col-sm-3 g-px-5 g-mb-10" *ngFor="let item of smartStone, let i = index" style="height: 330px" data-toggle="modal" [attr.data-target]="'.bd-example-modal-lg' + i">
        <article class="g-bg-cover g-bg-size-cover g-bg-white-gradient-opacity-v1--after u-block-hover" style="height: 330px">
          <h2 class="h4 g-color-white g-font-weight-600 g-mb-30 sub-stripe dark g-z-index-4">{{item?.name}}</h2>
          <div class="g-flex-middle g-min-height-400 g-pos-rel g-z-index-1 g-pa-30 u-block-hover__main--zoom-v1"
               [ngStyle]="{'background-image': 'url(' + item?.url + ')'}">
            <span class="d-block g-color-white-opacity-0_8 g-font-size-11 text-uppercase mb-4"></span>
            <div class="d-inline-block">
            </div>
          </div>
        </article>

        <div class="modal fade" [ngClass]="'bd-example-modal-lg' + i" tabindex="-1" role="dialog" aria-labelledby="myLargeModalLabel" aria-hidden="true">
          <div class="modal-dialog modal-lg" style="margin-top: 17%">
            <div class="modal-content">
              <img class="img-fluid" [src]="item?.url" alt="">
            </div>
            <h2 class="text-center mt-3 g-color-white g-font-weight-600 g-mb-30 g-z-index-4">{{item?.name}}</h2>
          </div>
        </div>
      </div>
    </div>
    <!-- End Gallery only show when smart stone-->

    <!-- Projects only show on Installation services page -->
    <div class="container" *ngIf="singleCat?.id === 41">
      <hr>
      <div class="row" *ngIf="allProjects">
        <!-- Grid column -->
        <div class="col-md-4 col-12 mb-3 projects-img" *ngFor="let project of allProjects">
          <a [routerLink]="['/single-project']" [queryParams]="{name: project.name, id: project.id}">
            <div class="view overlay">
              <img [src]="imgUrl + project.thumbnailUrl" style="max-height: 496px; min-height: 265px" class="img-fluid z-depth-1 wow fadeInUp"
                   alt="Responsive image">
              <div class="mask flex-center projects-white-slight">
                <a class="g-text-underline--none--hover" [routerLink]="['/single-project']" [queryParams]="{name: project.name, id: project.id}">
                  <p class="g-color-primary font-weight-bolder g-font-size-25 text-center">{{project.name}}</p>
                </a>
              </div>
            </div>
          </a>
        </div>
        <!-- Grid column -->

      </div>
    </div>
    <!-- End Projects only show on Installation services page-->

    <!-- Related Products -->
    <div class="container g-pt-50--md g-pt-20 g-pb-20" *ngIf="singleCat?.id === 10 || singleCat?.id === 8 || singleCat?.id === 12">
      <hr>
      <div class="text-center mx-auto g-max-width-600 g-mb-50">
        <h2 class="g-color-black mb-4">Related Products</h2>
      </div>
      <div class="row">
        <div class="col-md-3 col-12 mb-2" *ngFor="let relatedSubCat of relatedSubCats; let i = index">
          <a [href]="relatedSubCat?.link | urlTransfer" *ngIf="i < 4">
            <article class="title-sm-230 category-grid-img g-bg-cover z-depth-1-half g-bg-size-cover g-bg-white-gradient-opacity-v1--after hoverable"
                     [ngStyle]="{'background-image': 'url(' + imgUrl + relatedSubCat?.thumbnailUrl + ')'}">
              <div class="g-flex-middle category-tiles g-pos-rel g-z-index-1 g-pa-30" [ngClass]="{'g-min-height-250': relatedSubCats?.length > 9, 'g-min-height-400': relatedSubCats?.length <= 9}">
                <span class="d-block g-color-white-opacity-0_8 g-font-size-11 text-uppercase mb-4"></span>
                <a class="h4 g-color-white g-font-weight-600 g-mb-30 stripe dark g-font-size-25 hoverable" [ngStyle]="relatedSubCats?.length > 9 ? {'bottom': '26%'} : {'bottom': '40%'}"
                   [href]="relatedSubCat?.link | urlTransfer">{{relatedSubCat?.name}}</a>
              </div>
            </article>
          </a>
        </div>
      </div>

    </div>
    <!-- End Related Products -->

    <!-- Show brand log for insulation page -->
    <div class="container-fluid mb-3 text-uppercase" *ngIf="brands?.length > 0">
      <hr>
      <h4>Our Partners:</h4>
          <div class="row" *ngIf="brands?.length > 0 && brands?.length <2; else other">
            <div class="item col-12 col-md-3" *ngFor="let brand of brands">
              <div class="partners-logo" style="text-align:center;">
                <img class="container-fluid" src="{{imgUrl + brand.photoUrl}}" alt="{{brand.name}}">
              </div>
            </div>
          </div>
    </div>
    <ng-template #other>
      <div class="row">
        <div class="item col-6 col-md-3" *ngFor="let brand of brands">
          <div class="partners-logo" style="text-align:center;">
            <img class="container-fluid" src="{{imgUrl + brand.photoUrl}}" alt="{{brand.name}}">
          </div>
        </div>
      </div>
    </ng-template>


    <div id="timber-products-carousel" *ngIf="singleCat?.id === 11">
      <hr />
      <h4 class="mb-3"><strong>Skirting profiles:</strong></h4>

      <ngb-carousel *ngIf="skirtingProfile" [showNavigationArrows]="true" [showNavigationIndicators]="true">
        <ng-template ngbSlide *ngFor="let slide of slides">
          <div class="row g-ml-20--md">
            <div class="item px-3 mt-2 mx-auto" style="box-shadow:rgba(0, 0, 0, 0.33) 0 1px 3px 0; margin-bottom:6px; width: 230px;" *ngFor="let item of slide" >
              <img height="293" [src]="item?.src" width="203" [alt]="item?.title">
              <div class="text-center" style="background-color:#ffffff">
                <h3>{{item?.title}}</h3>
              </div>
            </div>
          </div>
        </ng-template>
      </ngb-carousel>
    </div>
  </section>
  <!-- End Category page content -->
</div>
