import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth/auth.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {Router} from '@angular/router';
import {Store} from '../../../store';

import {CustomValidators} from '../../shared/ecommerce/custom-validators/custom-validators';
import {AlertService} from '../../services/alert.service';

@Component({
  selector: 'app-sign-up',
  templateUrl: './signup.component.html',
  // styleUrls: ['./login.component.scss']
})

export class SignUpComponent implements OnInit{

  error: any;
  errorStatus = false;
  form: FormGroup;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private store: Store, private alertService: AlertService,
    private authService: AuthService
  ) {}

  ngOnInit() {
    this.form = this.fb.group({
        email: ['', Validators.compose([Validators.required, Validators.pattern('^[a-zA-Z0-9_.+-]+@[a-zA-Z0-9-]+.[a-zA-Z0-9-.]+$')])],
        firstName: ['', Validators.required], lastName: ['', Validators.required], marketingConsent: [false],
        acceptTC: ['', Validators.requiredTrue], acceptPP: ['', Validators.requiredTrue],
        mobile: ['', Validators.compose([Validators.required, Validators.minLength(10)])],
        password: ['', Validators.compose([Validators.required, CustomValidators.patternValidator(/\d/, { hasNumber: true }),
          CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
          CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
          CustomValidators.patternValidator(
            /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, { hasSpecialCharacters: true }),
          Validators.minLength(8)])],
        confirmPassword: ['', Validators.required]
      },
      {
        // check whether our password and confirm password match
        validator: CustomValidators.passwordMatchValidator
      }
    );
  }


  get f() { return this.form; }

  async onSubmit() {
    if (this.form.valid) {
      this.alertService.loading('Signing up...');
      const obj = {
        email: this.form.get('email').value,
        mobile: this.form.get('mobile').value,
        displayName: this.form.get('firstName').value + (this.form.get('lastName').value ? (' ' + this.form.get('lastName').value) : ''),
        marketingConsent: this.form.get('marketingConsent').value,
        password: this.form.get('password').value
      };

      this.authService.registerNewUser(obj).toPromise().then(res => {

        // @ts-ignore
      }).catch(error => {
        if (error.status === 200 ) {
          this.alertService.showSuccess('Your account is registered successfully. Please check your email for activation link',
            true, 30000);
          this.router.navigate(['/auth/login']);
        } else {
          // @ts-ignore
          this.alertService.showError(error.error, true);
        }
      });
    }
  }

}
