import { Pipe, PipeTransform } from '@angular/core';
import { orderBy } from 'lodash';

@Pipe({name: 'sortBy'})
export class SortByUnitPipe implements PipeTransform {
  transform(value: any[], order = '', column: string = ''): any[] {
    if (!value || order === '' || !order) { return value; } // no array
    if (value.length <= 1) { return value; } // array with only one item
    if (!column || column === '') {
    if (order === 'asc') {
      return value.sort((a, b) =>
        a.localeCompare(b, navigator.languages[0] || navigator.language, {numeric: true, ignorePunctuation: true}));
    } else {
      return value.sort((a, b) =>
        a.localeCompare(b, navigator.languages[0] || navigator.language, {numeric: true, ignorePunctuation: true})).reverse();
    }
    } // sort 1d array
    return orderBy(value, [column], [order]);
  }
}
