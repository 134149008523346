import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {NSWBRANCHES} from '../contact/location/model/branches';
import {Globals} from '../global';
import {ActivatedRoute, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {HumeStoresService} from '../services/hume-stores.service';
import {ProductService} from '../services/product.service';
import {QuoteFormComponent} from '../shared/quote-form/quote-form.component';
import {SEOService} from '../services/seo/seo.service';

declare var $: any;
declare var google: any;

@Component({
  selector: 'app-branches',
  templateUrl: './stores.component.html'
})
export class StoresComponent implements OnInit, OnDestroy {
  map: Object;
  marker: Object;
  branches = NSWBRANCHES;
  imgUrl = this.globals.picStorageRef;
  branchSub: Subscription;
  storesSubScription: Subscription;
  webCatSub: Subscription;
  eSubCats;
  nswWebCategories = [
    {name: 'Architectural Facade Products', link: 'architectural'},
    {name: 'Cladding', link: 'cladding'},
    {name: 'AAC Panels, Wall & Flooring Systems', link: 'AAC'},
    {name: 'Render, Texture & Paint Systems', link: 'render'},
    {name: 'Timber Products', link: 'timber'},
    {name: 'Flooring', link: 'flooring'},
    {name: 'Fibre Cement', link: 'fibre_cement'},
    {name: 'Tiles', link: 'tiles'},
    {name: 'Doors & Skylights', link: 'doors_and_skylights'},
    {name: 'Kitchens & Bathrooms', link: 'kitchens_bathrooms'},
    {name: 'Plasterboard & Plaster Products', link: 'plasterboard'},
    {name: 'Metal Wall & Ceiling Systems', link: 'metals'},
    {name: 'Insulation', link: 'insulation'},
    {name: 'Tools & Accessories', link: 'tools_and_accesories'}
  ];
  webCategories;
  store;

  @ViewChild('map1') mapRef: ElementRef;
  @ViewChild('form') form: QuoteFormComponent;

  constructor(public globals: Globals, private seoService: SEOService,
              private route: ActivatedRoute,
              private router: Router,
              private productService: ProductService,
              private humeStoresService: HumeStoresService) {}

  ngOnInit () {
    this.webCatSub = this.productService.webCat.subscribe(res => {
      this.webCategories = res;
    });

    this.route.url.subscribe(
      data => {
        let path;
        if (data[0].path?.toLowerCase().includes('hume-')) {
          if (data[0].path?.toLowerCase().includes('hume-in')) {
            path = data[0].path.replace('hume-in-', '').replace('-', ' ');
          } else {
            path = data[0].path.replace('hume-', '').replace('-', ' ');
          }
        } else {
          if (data[0].path.toLowerCase().includes('branch')) {
            path = data[0].path.toLowerCase().replace('_branch', '');
            if (path.includes('_')) {
              path = path.replace('_', ' ');
            }
          } else {
            path = data[0].path.replace('_', ' ');
          }
        }
        this.storesSubScription = this.humeStoresService.getStoresList().subscribe( data2 => {
          // const temp = data2.response.filter(data3 => data3.state === 'NSW' || data3.state === 'VIC');
          const store = data2.response.filter( data3 => data3.name.toLowerCase() === path.toLowerCase())[0];
          this.branchSub = this.humeStoresService.getStore(store?.id).subscribe(res => {
            this.store = res.response;

            /* SEO Meta data */
            this.seoService.setMetaObjectInfo({title: 'Hume Building Products' + this.store.name, summary: this.store.address});
            this.seoService.setPageTitle('Hume Building Products' + this.store.name);
            this.seoService.createLinkForCanonicalURL();
            /* SEO Meta data */

            if (this.store.state === 'NSW') {
              this.store.isNSW = true;
            } else {
              this.store.isVIC = true;
            }
            this.pushNearBy(this.store);
            setTimeout(() => {
              this.googleMap(this.store);
            }, 1000);
          });
        });
      }
    );
  }

  openList(singleCat) {}

  ngOnDestroy() {
    if (this.branchSub) { this.branchSub.unsubscribe(); }
    if (this.webCatSub) { this.webCatSub.unsubscribe(); }
    if (this.storesSubScription) { this.storesSubScription.unsubscribe(); }
  }

  googleMap(store) {
    this.map = new google.maps.Map(this.mapRef?.nativeElement, {
      zoom: 12,
      center: {lat: store.latitude, lng: store.longitude},
    });

    const newmarker = new google.maps.Marker({
      position: {lat: store.latitude, lng: store.longitude},
      map: this.map
    });

    newmarker['infowindow'] = new google.maps.InfoWindow({
      content: '<strong>' + store.name + ' Store' + '</strong>' + '<br />' + store.address
        + '<br />' + '<strong>Phone:</strong>' + store.phone
    });
    google.maps.event.addListener(newmarker, 'click', function() {
      this['infowindow'].open( this.map, this);
    });
    google.maps.event.addListener(newmarker, 'mouseout', function() {
      this['infowindow'].close( this.map, this);
    });
  }
  // Nearby hume-stores
  pushNearBy(store) {
    switch (store.name) {
      case 'Alexandria' :
        store.nearby = {lak: true, sil: false, yen: false, sgr: false, pym: false, alx: false, pres: false};
        break;
      case 'Pymble' :
        store.nearby = {lak: false, sil: true, yen: false, sgr: false, pym: false, alx: false, pres: false};
        break;
      case 'Yennora' :
        store.nearby = {lak: false, sil: false, yen: false, sgr: true, pym: false, alx: false, pres: true};
        break;
      case 'Lakemba' :
        store.nearby = {lak: false, sil: false, yen: false, sgr: false, pym: false, alx: true, pres: false};
        break;
      case 'Silverwater' :
        store.nearby = {lak: false, sil: false, yen: false, sgr: true, pym: true, alx: false, pres: false};
        break;
      case 'Prestons' :
        store.nearby = {lak: false, sil: false, yen: true, sgr: false, pym: false, alx: false, pres: false};
        break;
      case 'Preston' :
        store.nearby = {pre: false, sun: true};
        break;
      case 'Sunshine West' :
        store.nearby = {pre: true, sun: false};
        break;
    }
  }
}
