import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {ShoppingCartService} from '../../../services/shopping-cart.service';
import {AuthService} from '../../../services/auth/auth.service';
import {DataService} from '../../../services/data.service';
import {Globals} from '../../../global';
import {MDBModalRef, MDBModalService} from 'angular-bootstrap-md';
import {QuoteFormComponent} from '../../quote-form/quote-form.component';

@Component({
  selector: 'app-add-to-cart-button',
  templateUrl: './add-to-cart-button.component.html',
  styleUrls: ['./add-to-cart-button.component.scss']
})
export class AddToCartButtonComponent implements OnInit {
  @Input() pageUrl;
  @Input() product;
  @Input() addQuantity;
  @Output() addToCartEvent = new EventEmitter();
  @Input() modal;
  currentUser;
  itemsInCart;
  itemsInWish;
  modalRef: MDBModalRef;

  constructor(private shoppingCartService: ShoppingCartService, private dataService: DataService, public globals: Globals,
              private authService: AuthService, private modalService: MDBModalService) {
    this.dataService.changeCartStatus.subscribe(res => {
      this.getItemsInCart();
    });
  }

  ngOnInit(): void {
    this.authService.currentUser.subscribe(x => {
      this.currentUser = x;
    });
  }

  getItemsInCart() {
    this.itemsInCart = [];
    this.itemsInWish = [];

      if (localStorage.getItem('wishList')) {
        const wishList = JSON.parse(localStorage.getItem('wishList'));
        wishList.forEach(res => {
          this.itemsInWish.push(res.id);
        });
      }

      if (this.globals.cart && this.globals.cart.lines?.length > 0 ) {
        this.globals.cart.lines?.forEach(res1 => {
          this.itemsInCart.push(res1.productId);
        });
      }

  }

  addToCart(product, basicModal, quantity) {
    this.shoppingCartService.getProductPack(product?.id).toPromise().then(res => {
      // @ts-ignore
      const bunQ = res?.packs?.filter(item => item.type === 'BUN')[0]?.quantity;
      // @ts-ignore
      const minQ = res?.packs?.filter(item => item.type === 'MIN')[0]?.quantity;
      // @ts-ignore
      if (res?.packs?.length > 0) {
        if (this.globals.cart?.lines?.length > 0) {
          const result = this.globals.cart?.lines?.filter(item => item.productId?.toString() === product?.id.toString());
          if (result?.length > 0) {
            if (quantity <= bunQ) {
              quantity = bunQ;
            } else {
              const temp = Math.ceil((quantity - bunQ) / bunQ);
              quantity = minQ + bunQ * temp;
            }
          } else {
            if (quantity <= minQ) {
              quantity = minQ;
            } else {
              const temp = Math.ceil((quantity - bunQ) / bunQ);
              quantity = minQ + bunQ * temp;
            }
          }
        }
      }
      if (this.currentUser) {
        this.shoppingCartService.addELineToShoppingCart(product, basicModal, quantity);
        this.addToCartEvent.emit('add');
        setTimeout(() => {
          this.addToCartEvent.emit('close');
        }, 1000);
      } else {
        if (this.modal) { this.modal.close('Close click'); }
        this.authService.redirectToLoginPage(product.id);
      }
    });
  }

  addToWishlist(product) {
    if (this.currentUser) {
      this.shoppingCartService.addToWishlist(product);
      this.addToCartEvent.emit('wish');
      setTimeout(() => {
        this.addToCartEvent.emit('close');
      }, 1000);
    } else {
      this.authService.redirectToLoginPage();
    }
  }

  showQuoteModal(id) {
    const modalOptions = {
      animated: true,
      data: { id }
    };
    this.modalRef = this.modalService.show(QuoteFormComponent, modalOptions);
  }

}
