<div class="single-component-container">
  <!-- Order tracking info block -->
  <div class="container" *ngIf="orderInfo else showFormSection">

    <!-- General Info -->
    <section class="g-pt-50 g-pb-30">
      <div class="row">
        <div class="col-md-6 g-mb-10 g-mb-30--sm">
          <h1 class="h5 g-color-primary g-font-weight-600 text-uppercase">Customer Info:</h1>
          <h2 class="h4 g-font-weight-700 text-uppercase">{{orderInfo?.header?.customerName}}</h2>
          <p class="g-color-gray-dark-v4">{{orderInfo?.header?.customerCode}}</p>
        </div>

        <div class="col-md-3 g-mb-10 g-mb-30--sm">
          <h3 class="h5 g-color-primary g-font-weight-600 text-uppercase">Order Info:</h3>
          <ul class="list-unstyled g-font-size-default">
            <li><span class="g-font-weight-700">Order No:</span> {{orderInfo?.header?.number}}</li>
            <li><span class="g-font-weight-700">Order Date:</span> {{orderInfo?.header?.date}}</li>
            <li><span class="g-font-weight-700">Reference:</span> {{orderInfo?.header?.reference}}</li>
            <li><span class="g-font-weight-700">Delivery Method:</span> {{orderInfo?.header?.deliveryMethod}}</li>
          </ul>
        </div>

        <div class="col-md-3 g-mb-10 g-mb-30--sm">
          <h3 class="h5 g-color-white g-font-weight-600 text-uppercase">Order Info:</h3>
          <ul class="list-unstyled g-font-size-default">
            <li><span class="g-font-weight-700">SQM:</span> {{orderInfo?.header?.sqm | number: '1.2-2'}} m<sup>2</sup></li>
            <li><span class="g-font-weight-700">Weight:</span> {{orderInfo?.header?.weight  | number: '1.2-2'}} Kg</li>
            <li><span class="g-font-weight-700">Time Slot:</span> {{orderInfo?.header?.timeSlot}}</li>
            <li><span class="g-font-weight-700">Branch:</span> {{orderInfo?.header?.branch}}</li>
          </ul>
        </div>
      </div>
    </section>
    <!-- End General Info -->

    <!-- Static Process -->
    <div class="row" *ngIf="orderInfo?.header?.status?.toLowerCase() !== 'written off' else cancel">
      <div class="col-12 col-sm-3 g-mb-60">
        <div class="text-center">
          <i class="u-dot-line-v1-2 g-brd-transparent--before g-mb-20"
             [ngClass]="orderInfo?.header?.statusValue > 0 ? 'g-brd-primary--after' : 'g-brd-gray-light-v2--after'">
            <span class="u-dot-line-v1__inner"  [ngClass]="orderInfo?.header?.statusValue >= 0 ? 'g-brd-primary g-bg-primary' : 'g-brd-gray-light-v2 g-bg-gray-light-v2'"></span>
          </i>
          <h3 class="h5 g-color-black mb-20">Ordered</h3>
          <p class="mb-0" *ngIf="orderInfo?.header?.statusValue >= 0">We are processing your order.</p>
        </div>
      </div>

      <div class="col-12 col-sm-3 g-mb-30 g-mb-60--sm">
        <div class="text-center">
          <i class="u-dot-line-v1-2 g-mb-20"
             [ngClass]="orderInfo?.header?.statusValue > 0 ? (orderInfo?.header?.statusValue === 1 ? 'g-brd-primary--before g-brd-gray-light-v2--after' : 'g-brd-primary--before g-brd-primary--after') :
'g-brd-gray-light-v2--before g-brd-gray-light-v2--after'">
            <span class="u-dot-line-v1__inner"  [ngClass]="orderInfo?.header?.statusValue >= 1 ? 'g-brd-primary g-bg-primary' : 'g-brd-gray-light-v2 g-bg-gray-light-v2'"></span>
          </i>
          <h3 class="h5 g-color-black mb-20">Picked</h3>
          <p class="mb-0" *ngIf="orderInfo?.header?.statusValue >= 1">Your order has been picked.</p>
        </div>
      </div>

      <div class="col-12 col-sm-3 g-mb-60">
        <div class="text-center">
          <i class="u-dot-line-v1-2 g-mb-20"
             [ngClass]="orderInfo?.header?.statusValue > 1 ? (!orderInfo?.header?.status?.toLowerCase().includes('shipped') ? 'g-brd-primary--before g-brd-gray-light-v2--after' : 'g-brd-primary--before g-brd-primary--after') :
'g-brd-gray-light-v2--before g-brd-gray-light-v2--after'">
            <span class="u-dot-line-v1__inner" [ngClass]="orderInfo?.header?.statusValue >= 2 ? 'g-brd-primary g-bg-primary' : 'g-brd-gray-light-v2 g-bg-gray-light-v2'"></span>
          </i>
          <h3 class="h5 g-color-black mb-20">Dispatched</h3>
          <p class="mb-0" *ngIf="orderInfo?.header?.statusValue >= 2">Your order has been dispatched.</p>
        </div>
      </div>

      <div class="col-12 col-sm-3 g-mb-60">
        <div class="text-center">
          <i class="u-dot-line-v1-2 g-brd-transparent--after g-mb-20"
             [ngClass]="orderInfo?.header?.status?.toLowerCase().includes('shipped')  ? 'g-brd-primary--before' : 'g-brd-gray-light-v2--before'">
            <span class="u-dot-line-v1__inner" [ngClass]="orderInfo?.header?.status?.toLowerCase().includes('shipped') ? 'g-brd-primary g-bg-primary' : 'g-brd-gray-light-v2 g-bg-gray-light-v2'"></span>
          </i>
          <h3 class="h5 g-color-black mb-20">{{orderInfo?.header?.status?.toLowerCase() === 'part shipped' ? 'Partially Delivered' : 'Delivered'}}</h3>
          <p class="mb-0" *ngIf="orderInfo?.header?.status?.toLowerCase() === 'shipped'">Your order has been delivered successfully, Thank you for your order.</p>
          <p class="mb-0" *ngIf="orderInfo?.header?.status?.toLowerCase() === 'part shipped'">Your order has been partially delivered.</p>
        </div>
      </div>
    </div>
    <!-- End Static Process -->

    <!-- Order cancelled -->
    <ng-template #cancel>
      <div class="text-center g-pb-40">
        <h3 class="g-color-red">The order is cancelled</h3>
      </div>
    </ng-template>
    <!-- End Order cancelled -->

    <!-- Order lines -->
    <section class="g-pb-30 g-pb-70--sm">
      <div class="table-responsive">
        <table class="table table-striped">
          <col>
          <colgroup span="2"></colgroup>
          <col>
          <col>
          <col>
          <thead class="g-color-white g-bg-primary text-center text-uppercase">
          <tr>
            <td rowspan="2" class="align-middle">Description / Code</td>
            <th colspan="4" scope="colgroup">Quantity</th>
            <td rowspan="2" class="align-middle">Status</td>
            <td rowspan="2" class="align-middle">SQM</td>
            <td rowspan="2" class="align-middle">Weight</td>
          </tr>
          <tr>
            <th class="g-brd-top-none g-font-weight-500 g-py-15"  scope="col">Ordered</th>
            <th class="g-brd-top-none g-font-weight-500 g-py-15"  scope="col">Allocated</th>
            <th class="g-brd-top-none g-font-weight-500 g-py-15"  scope="col">Picked</th>
            <th class="g-brd-top-none g-font-weight-500 g-py-15"  scope="col">Supplied</th>
          </tr>
          </thead>

          <tbody class="text-center">
          <tr *ngFor="let line of orderInfo?.lines">
            <td class="g-max-width-300 text-left g-py-15 align-middle">
              <h4 class="g-color-gray-dark-v4 g-font-weight-700 g-font-size-16 align-middle">{{line?.description ? line?.description : line?.code}}</h4>
            </td>
            <td class="g-color-gray-dark-v4 g-font-weight-600 g-py-15 align-middle">{{line?.quantityOrdered}}</td>
            <td class="g-color-gray-dark-v4 g-font-weight-600 g-py-15 align-middle">{{line?.quantityAllocated}}</td>
            <td class="g-color-gray-dark-v4 g-font-weight-600 g-py-15 align-middle">{{line?.quantityPicked}}</td>
            <td class="g-color-gray-dark-v4 g-font-weight-600 g-py-15 align-middle">{{line?.quantitySupplied}}</td>
            <td class="g-font-weight-600 g-py-15 align-middle" [ngClass]="line?.status.toLowerCase() === 'cancelled' ? 'g-color-red' :
              line?.status.toLowerCase() === 'picked' ? 'g-color-blue' : line?.status.toLowerCase() === 'part picked' ? 'g-color-indigo' :
              line?.status.toLowerCase() === 'supplied' ? 'g-color-green' : line?.status.toLowerCase().includes('eta') ? 'g-color-darkpurple' :
              line?.status.toLowerCase().includes('outstanding') ? 'g-color-orange' : line?.status.toLowerCase().includes('received') ? 'g-color-deeporange' :
               'g-color-main'"
              >{{line?.status}}</td>
            <td class="g-color-gray-dark-v4 g-font-weight-600 g-py-15 align-middle">{{line?.sqm | number: '1.2-2'}} m<sup>2</sup></td>
            <td class="g-color-gray-dark-v4 g-font-weight-600 g-py-15 align-middle">{{line?.weight | number: '1.2-2'}} kg</td>
          </tr>
          </tbody>
        </table>
      </div>
    </section>
    <!-- End order lines -->
  </div>
  <!-- End Order tracking info block -->

  <ng-template #showFormSection>

    <section class="float-center submit-heading g-py-50 g-py-80--sm" *ngIf="showForm">
      <div class="container">
        <div class="col-md-10 col-lg-8 col-xl-7 mx-auto">
          <h1 class="jumbotron-heading delivery-title">Track your order</h1>
          <p></p>
          <h3 class="lead g-py-15">Please enter the details below to track your order</h3>

          <form [formGroup]="trackingForm" (ngSubmit)="onSubmit()">
            <div class="row">
              <div class="col-12 col-md-9 mb-3 mb-md-0">
                <label class="float-left">Order No</label>
                <input type="text" formControlName="orderNo" class="form-control form-control-lg" [ngClass]="{ 'is-invalid': submitted && f.orderNo.errors }" placeholder="Order No">
                <div *ngIf="submitted && f.orderNo.errors" class="invalid-feedback">
                  <div *ngIf="f.orderNo.errors.required">Order number is required</div>
                </div>
              </div>
              <div class="col-12 col-md-9 my-4">
                <label class="float-left">Email or Mobile</label>
                <input type="text" formControlName="email" class="form-control form-control-lg" [ngClass]="{ 'is-invalid': submitted && f.email.errors }" placeholder="Email or Mobile">
                <div *ngIf="submitted && f.email.errors" class="invalid-feedback">
                  <div *ngIf="f.email.errors.required">Email is required</div>
                </div>
              </div>
            </div>
            <p></p>
            <button type="submit" class="btn btn-lg btn-hume-color waves-light waves-effect g-font-size-14 font-weight-bold shadow-none" [disabled]="!trackingForm.valid">Track</button>
            <button class="btn btn-lg btn-outline-dark waves-light waves-effect g-font-size-14 font-weight-bold shadow-none" (click)="clearTrack()" id="clear">Clear</button>
            <p></p>
            <div [hidden]="noRecord" class="alert alert-danger">
              <strong>Error: </strong>{{ 'TRACKING.No record' | translate }}
            </div>
          </form>

        </div>

      </div>
    </section>

    <!-- Tracking error block-->
    <div *ngIf="errorMessage">
      <section class="container g-py-100">
        <div class="row justify-content-center">
          <div class="col-sm-9 col-md-7 col-lg-5">
            <div class="g-brd-around g-brd-gray-light-v3 g-bg-white rounded g-px-30 g-py-50 mb-4">
              <header class="text-center mb-4">
                <p class="h1">Error!</p>
                <h1 class="h3 py-4 g-font-weight-400 g-color-primary">{{errorMessage}}</h1>
                <p>We are redirecting you to products page...</p>
              </header>
            </div>
          </div>
        </div>
      </section>
    </div>
    <!--End tracking error block-->

    <!--- loading skeleton-->
    <div class="container" *ngIf="!errorMessage && !showForm">
      <!-- General Info -->
      <section class="g-pt-50">
        <div class="row">
          <div class="col-md-6 g-mb-10 g-mb-30--sm">
            <ngx-skeleton-loader
              [theme]="{ width: '100%', height: '133px'}" ></ngx-skeleton-loader>
          </div>

          <div class="col-md-6 g-mb-10 g-mb-30--sm">
            <ngx-skeleton-loader
              [theme]="{ width: '100%', height: '133px'}" ></ngx-skeleton-loader>
          </div>

        </div>
      </section>
      <!-- End General Info -->

      <!-- Static Process -->
      <div class="g-pb-20">
        <ngx-skeleton-loader
          [theme]="{ width: '100%', height: '100px'}" ></ngx-skeleton-loader>
      </div>
      <!-- End Static Process -->

      <!-- Order lines -->
      <section class="g-pb-30 g-pb-50--sm">
        <ngx-skeleton-loader
          [theme]="{ width: '100%', height: '200px'}" ></ngx-skeleton-loader>
      </section>
      <!-- End order lines -->
    </div>
    <!--- End loading skeleton-->

  </ng-template>

</div>



