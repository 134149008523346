import {Component, Input, OnInit} from '@angular/core';

@Component({
  selector: 'app-alert-components',
  templateUrl: './alerts.component.html',
  styleUrls: ['./alerts.component.scss']
})
export class AlertsComponent implements OnInit {
  @Input() type;
  @Input() showAlert;

  constructor() { }

  ngOnInit(): void {
  }

}
