import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';
import {Globals} from '../../global';
import {Location} from '@angular/common';

declare var $: any;

@Component({
  selector: 'app-products-sub-categories',
  templateUrl: './sub-categories.component.html'
})

export class ProductsSubCategoriesComponent implements OnInit {
  @Input() item;
  @Output() categoryEmit = new EventEmitter<object>();
  @Output() accordionEmit = new EventEmitter<boolean>();
  category;
  categories;

  constructor(private location: Location,
              public globals: Globals) {}

  ngOnInit() {
  }

  toggled(i) {
    if (this.item.name === 'Ceiling tiles') { }
    for (let b = 0; b < this.globals.categoriesWeb.length; b++) {
      if (this.globals.categoriesWeb[b].id !== i) {
        for (let a = 0; a < this.globals.categoriesWeb[b].children.length; a ++) {
          this.globals.categoriesWeb[b].children[a].isSelected = false;
        }
      }
    }
    document.getElementById('arrow' + i).classList.add('hs-icon-arrow-top');
    document.getElementById('arrow' + i).classList.remove('hs-icon-arrow-bottom');
    $('#collapse' + i).toggle();
    for (let a = 0; a < this.globals.categoriesWeb.length; a++) {
      if (this.globals.categoriesWeb[a].id !== i) {
        $('#collapse' + this.globals.categoriesWeb[a].id).hide();
        document.getElementById('arrow' + this.globals.categoriesWeb[a].id).classList.add('hs-icon-arrow-bottom');
        document.getElementById('arrow' + this.globals.categoriesWeb[a].id).classList.remove('hs-icon-arrow-top');
      }
    }
  }

  filterProducts(single) {
    if (this.globals.isMobile) {
      this.accordionEmit.emit(true);
    }
        for (let a = 0; a < this.item.children.length; a ++) {
          if (this.item.children[a].id) {
            this.item.children[a].isSelected = this.item.children[a].id === single.id;
        }
      }
    const newName = single.name.split('/').join(' ').split(' ').join('_');
    this.location.replaceState('/products/category/' + newName);
    this.category = {'name': single.name, 'code': single.code, 'webName': single.webCategory.name};
    this.categoryEmit.emit(this.category);
    // document.getElementsByClassName('category-heading').style.color = 'blue' ;
    // document.getElementById('categoryHeading' + code).classList.replace('g-color-gray-dark-v4', 'g-color-primary');
  }
}
