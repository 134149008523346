import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { RouterModule, Routes} from '@angular/router';
import {AuthGuard} from '../auth/guards/auth.guard';
import {AccountComponent} from './containers/account.component';
import {SideMenuComponent} from './side-menu/side-menu.component';
import {CommonModule} from '@angular/common';
import {BadgeModule, WavesModule} from 'angular-bootstrap-md';
import {ReactiveFormsModule} from '@angular/forms';
import {SkeletonComponent} from './skeleton/skeleton.component';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';

export const ROUTES: Routes = [
  // ** Development mode
  { path: 'account', component: AccountComponent,
    children: [
      { path: '', outlet: 'side-menu', canActivate: [AuthGuard], component: SideMenuComponent},
      { path: 'account-menu', canActivate: [AuthGuard], component: SideMenuComponent},
      { path: 'details', canActivate: [AuthGuard], loadChildren: () => import('./details/details.module').then(m => m.DetailsModule)},
      { path: 'cod', canActivate: [AuthGuard], loadChildren: () => import('./cod/cod.module').then(m => m.AccountCodModule)},
      { path: 'list', canActivate: [AuthGuard], loadChildren: () => import('./orders/orders.module').then(m => m.OrdersModule)},
      { path: 'wishlist', canActivate: [AuthGuard], loadChildren: () => import('./wishlist/wishlist.module').then(m => m.WishlistModule)},
      // tslint:disable-next-line:max-line-length
      { path: 'addresses', canActivate: [AuthGuard], loadChildren: () => import('./addresses/addresses.module').then(m => m.AddressesModule)},
      { path: 'payment', canActivate: [AuthGuard], loadChildren: () => import('./payment/payment.module').then(m => m.PaymentModule)},
      // tslint:disable-next-line:max-line-length
      { path: 'link', canActivate: [AuthGuard], loadChildren: () => import('./link/link.module').then(m => m.AccountLinkModule)},
      { path: 'users', canActivate: [AuthGuard], loadChildren: () => import('./users/users.module').then(m => m.AccountUsersModule)},
      { path: 'forms', canActivate: [AuthGuard], loadChildren: () => import('./forms/forms.module').then(m => m.FormsMoudule)},
      // tslint:disable-next-line:max-line-length
      { path: 'notifications', canActivate: [AuthGuard], loadChildren: () => import('./notifications/notifications.module').then(m => m.NotificationsModule)},
      { path: '**', redirectTo: 'details' }
    ]
  }
  // ** End development mode
];

@NgModule({
  imports: [
    RouterModule.forChild(ROUTES),
    CommonModule,
    BadgeModule,
    WavesModule,
    ReactiveFormsModule,
    NgxSkeletonLoaderModule
  ],
  declarations: [AccountComponent, SideMenuComponent, SkeletonComponent],
  providers: [],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AccountModule { }
