<div class="single-component-container">

  <div class="container g-pt-50 g-pb-80">
    <h1 class="text-capitalize g-mb-50">Hume Building Products brochures</h1>
    <div class="row">
      <div class="col-md-3 col-6 g-mb-30" *ngFor="let item of brochures">
        <!-- Team Block -->
        <div class="u-info-v6-1">
          <!-- Figure -->
          <figure class="u-block-hover">
            <!-- Figure Image -->
            <img class="g-width-90x"  [src]="item?.thumbnailUrl" [alt]="item?.title">
            <!-- End Figure Image-->

            <!-- Figure Caption -->
            <figcaption class="u-block-hover__additional--fade g-bg-bluegray-opacity-0_5 g-pa-30">
              <div class="u-block-hover__additional--fade u-block-hover__additional--fade-down g-flex-middle">

                <!-- Figure Social Icons -->
                <ul class="list-inline text-center g-flex-middle-item">

                  <li class="list-inline-item align-middle g-mx-3">
                    <a type="button" class="btn u-btn-primary g-font-size-13" [href]="item?.fileUrl" target="_blank"><i class="fa fa-download mr-3"></i>Download</a>
                  </li>
                </ul>
                <!-- End Figure Social Icons -->
              </div>
            </figcaption>
            <!-- End Figure Caption -->
          </figure>
          <!-- End Figure -->

          <!-- Figure Info -->
          <div class="g-bg-white g-pt-25">
            <div class="g-mb-15">
              <em class="d-block mb-3 u-info-v6-1__item g-font-style-normal g-font-size-11 text-uppercase g-color-primary"></em>
              <h2 class="h5 g-color-black g-font-weight-600">{{item?.title}}</h2>
            </div>

          </div>
          <!-- End Figure Info-->
        </div>
        <!-- End Team Block -->
      </div>
    </div>
  </div>
</div>
