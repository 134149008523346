import {Injectable, Inject} from '@angular/core';
import { Meta, Title } from '@angular/platform-browser';
import {filter, map, mergeMap} from 'rxjs/operators';
import {ActivatedRoute, NavigationEnd, Router, RouterEvent} from '@angular/router';
import {Globals} from '../../global';
import {DOCUMENT} from '@angular/common';

@Injectable()
export class SEOService {
  url = '';

  constructor(private title: Title, @Inject(DOCUMENT) private doc,
              public globals: Globals,
              private meta: Meta,
              private activatedRoute: ActivatedRoute,
              private router: Router) {
  }

  updateTitle(title: string) {
    if (this.router.url === '/architectural') {
      title = 'Hume Architectural';
    } else if (this.router.url === '/commercial') {
      title = 'Hume Commercial';
    }
    if (title) {
      this.title.setTitle(title);
      this.meta.updateTag({property: 'og:title', content: title});
      this.meta.updateTag({name: 'twitter:card', content: 'summary'});
      this.meta.updateTag({name: 'twitter:title', content: title});
    }
  }

  updateOgUrl(url: string) {
    this.url = url;
    if (this.router.url === '/architectural') {
      url = 'https://www.humebuildingproducts.com.au/architectural';
    }
    if (url) {
      this.meta.updateTag({property: 'og:url', content: url});
    }
  }

  updateOgImage(url: string) {
    if (url) {
      this.meta.updateTag({property: 'og:image', content: url});
      this.meta.updateTag({name: 'twitter:image', content: url});
    }
  }

  updateDescription(desc: string) {
    if (this.globals.divisionTag === 'architectural' && this.router.url === '/home') {
      desc = 'Hume offers a beautiful range of architectural solutions for builders, architects and designers. ' +
        'With leading brand supply partnerships, a fast delivery network and expert installation teams, ' +
        'we are the perfect partner for your next building project.';
    }

    if (desc) {
      this.meta.updateTag({property: 'og:description', content: desc});
      this.meta.updateTag({name: 'twitter:description', content: desc});
    }
  }

  setMetaInfo () {
    this.router.events.pipe(
      filter((event) => event instanceof RouterEvent),
      map(() => this.activatedRoute),
      map((route) => {
        while (route.firstChild) { route = route.firstChild; }
        return route;
      }),
      filter((route) => route.outlet === 'primary'),
      mergeMap((route) => route.data)
    )
      .subscribe((event) => {
        this.updateOgUrl(event['ogUrl']);
        this.updateTitle(event['title']);
        this.updateOgImage(event['imgUrl']);
        // Updating Description tag dynamically with title
        this.updateDescription(event['description']);
      });
  }

  setMetaObjectInfo (item) {
    const url = this.globals.picStorageRef + item?.thumbnailUrl;
    this.updateTitle(item?.title);
    this.updateDescription(item?.summary ? item?.summary : item?.appContent);
    this.updateOgUrl(window.location.href);
    this.updateOgImage(url);
  }

  setPageTitle(title: string) {
    this.title.setTitle(title);
  }

  createLinkForCanonicalURL() {
    const link: HTMLLinkElement = this.doc.createElement('link');
    link.setAttribute('rel', 'canonical');
    this.doc.head.appendChild(link);
    link.setAttribute('href', this.doc.URL);
  }
}
