<div id="home-testimonials" class="container-fluid g-pt-30 g-pb-20 g-px-40 g-bg-gray-light-v5">
    <!-- Heading -->
    <!--<div class="text-center g-mb-70">
      <h2 class="h3 text-uppercase mb-4">Partner & Client Testimonials</h2>
      <div class="d-inline-block g-width-60 g-height-6 g-bg-primary mb-2"></div>
    </div>-->
    <!-- End Heading -->

  <ngx-slick-carousel class="carousel"
                      #slickModal="slick-carousel"
                      [config]="slideConfig">
    <div ngxSlickItem *ngFor="let testimonial of testimonials" class="slide g-px-15">
      <blockquote class="lead u-blockquote-v1 rounded g-pl-60 g-pr-30 g-py-30 g-mb-40--md g-mb-20">{{testimonial?.comment}}</blockquote>
      <div class="media">
        <img class="d-flex align-self-center rounded-circle g-brd-around g-brd-3 g-brd-white g-width-50 mr-3"
             src="{{imgUrl + testimonial?.photoUrl}}" alt="hume testimonial">
        <div class="media-body align-self-center">
          <h4 class="h6 g-font-weight-700 g-mb-0">{{testimonial?.name}}</h4>
          <span class="g-color-gray-dark-v4 g-font-style-normal">{{testimonial?.company}}</span>
        </div>
      </div>
    </div>
  </ngx-slick-carousel>
</div>

