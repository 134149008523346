<li class="my-3 product-accordion__list">
      <a data-toggle="collapse" role="tab" [id]="'productSubHeading'+ item.id" [href]="'#collapse'+ item.id"
         [attr.aria-expanded]="!!item.isToggled" [attr.aria-controls]="'collapse'+ item.id"
         class="collapsed d-block u-link-v5 g-color-gray-dark-v4 font-weight-bold g-color-primary--hover" (click)="toggled(item.id)" *ngIf="item?.children?.length !== 0">
        {{item.name}}
        <i class="productSubHeading-icon hs-icon g-font-size-11 g-ml-7" [ngClass]="item.isToggled ? 'hs-icon-arrow-top' : 'hs-icon-arrow-bottom'" [id]="'arrow' + item.id" ></i>
        <!--<i id="productSubHeadingIcon-bottom" class="hs-icon g-font-size-11 g-ml-7 hs-icon-arrow-bottom"></i>
        <i id="productSubHeadingIcon-top" class="hs-icon g-font-size-11 g-ml-7 hs-icon-arrow-top"></i>-->
       </a>
      <a data-toggle="collapse" role="tab" [id]="'productSubHeading'+ item.id" [routerLink]="['/categories/' + item.name | urlTransfer]"
         [attr.aria-expanded]="!!item.isToggled" [attr.aria-controls]="'collapse'+ item.id"
         class="collapsed d-block u-link-v5 g-color-gray-dark-v4 font-weight-bold g-color-primary--hover" *ngIf="item?.children?.length === 0">
        {{item.name}}
        <i class="productSubHeading-icon hs-icon g-font-size-11 g-ml-7" [ngClass]="item.isToggled ? 'hs-icon-arrow-top' : 'hs-icon-arrow-bottom'" [id]="'arrow' + item.id" ></i>
      </a>

    <!--<div [id]="'collapse'+ item.id" class="collapse" role="tabpanel" [attr.aria-labelledby]="'heading'+ item.id"
         [attr.data-parent]="{'#accordionEx': globals.isMobile === false, '#accordionEx-mobile': globals.isMobile === true}" style="">-->
      <div [id]="'collapse'+ item.id" class="collapse" [ngClass]="item.isToggled ? 'show' : ''" role="tabpanel" [attr.aria-labelledby]="'productSubHeading'+ item.id"
           data-parent="#accordionEx" style="">
      <ul class="list-unstyled" *ngFor="let singleCate of item.children" style="margin-left: 15px">
        <li class="my-3">
          <a class="d-block u-link-v5 g-color-primary--hover category-heading" [ngClass]="singleCate?.isSelected ? 'g-color-primary font-weight-bold' : 'g-color-gray-dark-v4'"
             [id]="'categoryHeading'+ singleCate?.code" (click)="filterProducts(singleCate)">
            {{singleCate?.name}}
            <i class="hs-icon g-font-size-11 g-ml-7"></i>
          </a>
        </li>
      </ul>
    </div>
</li>

