import { Component, Input, OnDestroy, OnInit} from '@angular/core';
import {InventoryCategoriesService} from '../../services/inventory-categories.service';
import {Globals} from '../../global';
import {Subscription} from 'rxjs';
import { OrderPipe } from 'ngx-order-pipe';
import {Router} from '@angular/router';
import {HumeStoresService} from '../../services/hume-stores.service';
import {ProductService} from '../../services/product.service';
import {AuthService} from '../../services/auth/auth.service';
declare var $: any;

@Component({
  selector: 'app-categories-grid',
  templateUrl: './categories-grid.component.html'
})
export class CategoriesGridComponent implements OnInit, OnDestroy {
  items;
  tokenSubscription: Subscription;
  imgUrl = this.globals.picStorageRef;
  @Input()
  item: any;
  allWebCat;

  constructor (public globals: Globals,
               private router: Router,
               private orderPipe: OrderPipe,
    private humeStoresService: HumeStoresService, private productService: ProductService,
    private inventoryCatServices: InventoryCategoriesService, private authService: AuthService
  ) {}

  async ngOnInit() {
    this.tokenSubscription = await this.authService.token.subscribe(res1 => {
      if (res1) {
        this.globals.token = res1;
        this.getWebCat();
      }
    })
  }

  getWebCat() {
    const tag = this.humeStoresService.getDivisionTag();

    this.productService.getEWebCatByDivision(tag).toPromise().then(ref => {
      if (tag === 'arch') {
        this.items = this.orderPipe.transform(ref, 'seq2');
      } else if (tag === 'main') {
        this.items = this.orderPipe.transform(ref, 'seq');
      } else if (tag === 'com') {
        const items = ref;
        // @ts-ignore
        items.forEach(res => {
          if (res.name.toLowerCase().includes('cladding')) {
            res.seq = 0;
          }
          if (res.name.toLowerCase().includes('flooring')) {
            res.seq = 1;
          }
        });
        this.items = this.orderPipe.transform(items, 'seq');
      } else {
        this.items = ref;
      }

      /*** Dynamically adding route links ****/

      /* Determine the tiles size */
      for (let i = 0; i < this.items.length; i++) {
        if (this.items[i].size === 0) {
          this.items[i].class = 'col-lg-3';
        } else {
          this.items[i].class = ' ';
        }
      }
    });
  }


  ngOnDestroy() {
    if (this.tokenSubscription) { this.tokenSubscription.unsubscribe(); }
  }

}
