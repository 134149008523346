import {Injectable} from '@angular/core';
import {Globals} from '../global';
import {ajax} from 'rxjs/ajax';

@Injectable()

export class CarouselService {

  constructor(
    public globals: Globals,
  ) {}

  /*getCarousel() {
    return ajax(this.globals.humeApiRef + 'carousel');
  }*/

  getCarousel() {
    return ajax(this.globals.humeEcomApiRef + 'info/carousels');
  }

  getCarouselBySite(site) {
    return ajax(this.globals.humeEcomApiRef + '/info/carousels/site/' + site);
    // return ajax(this.globals.humeApiRef + 'carousel?site=' + site);
  }
}

