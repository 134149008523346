import {Component, OnDestroy, OnInit} from '@angular/core';
import { Globals} from '../global';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {TranslateService} from '@ngx-translate/core';

declare var $: any;


@Component({
  selector: 'app-tracking',
  templateUrl: './tracking.component.html'
})
export class TrackingComponent implements OnInit, OnDestroy {
  sub: Subscription;

  constructor(public globals: Globals,
              private translateService: TranslateService,
              private route: ActivatedRoute) {}

  ngOnInit () {
    this.globals.showSubmit = true;
    this.sub = this.route.queryParams.subscribe(params => {
      this.globals.showSubmit = !(params.pickingSlip && params.suburb);
    });
  }

  // Switching between languages
  switchLanguage(language: string) {
    this.translateService.use(language);
  }

  ngOnDestroy() {
  }
}
