import {Component, OnInit, ViewChild} from '@angular/core';
import {Globals} from '../../global';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ModalDirective} from 'angular-bootstrap-md';
import {ALLCONTENT, TRACKSTUD} from './step3-data';
import {PLASTERBOARDS} from './step2-data';
import {ContactService} from '../../services/contact.service';
import { BsDatepickerConfig } from 'ngx-bootstrap/datepicker';

@Component({
  selector: 'app-shared-order-form',
  templateUrl: './shared-order-form.component.html',
})
export class SharedOrderFormComponent implements OnInit {

  @ViewChild('cartFrame') frame: ModalDirective;
  @ViewChild('successModal', { static: true }) successModal: ModalDirective;
  @ViewChild('alertModal', { static: true }) alertModal: ModalDirective;
  formSubmitError = false;
  previousStepName = '';
  addToQuoteSuccess = false;
  addingItemNo = 0;
  colorTheme = 'theme-red';
  bsConfig: Partial<BsDatepickerConfig>;

  /*** Step1 variables define ***/
  submittedStep1 = false;
  orderFormStep1: FormGroup;
  /*** End Step1 variables define ***/

  /*** Step2 variables define ***/
  boardSelectForm: FormGroup;
  showThicknessOption = false;
  showWidthOption = false;
  showLengthOption = false;
  showHebelType = false;
  itemThickness;
  itemWidth;
  itemLength;
  /** final data for step2   **/
  step2Submit = [];
  selectedBoard = {name: '', thickness: '', width: '', length: '', type: '', quantity: 0};
  plasterBoards = PLASTERBOARDS;
  /*** End Step2 variables define ***/

  /*** Step3 variables define ***/
  step3CategoriesForm: FormGroup;
  step3StudForm: FormGroup;
  step3Index;
  step3Submit = [];
  showTrackStudSizeOption = false;
  showTrackStudBMTOption = false;
  filteredTrackStud;
  BMTTrackStud = {name: '', size: ''};
  accessories = ['Plasterboard concealed ceiling system', 'Metal stud & Track', 'Metal plaster angles and accessories',
    'Setting products', 'Fixings products', 'Cornices', 'Ceiling Tiles', 'PVC products', 'Other products'];
  trackStud = TRACKSTUD;
  allContent = ALLCONTENT;
  step3CatContentShow = false;
  /*** End Step3 variables define ***/

  get f1() { return this.orderFormStep1.controls; }

  constructor (public globals: Globals,
               private contactService: ContactService,
               private formBuilder: FormBuilder) { }

  ngOnInit() {
    this.bsConfig = Object.assign({}, { containerClass: this.colorTheme, dateInputFormat: 'DD/MM/YYYY' });
    // Get the element with id="defaultOpen" and click on it
    document.getElementById('step3').click();

    this.orderFormStep1 = this.formBuilder.group({
      customer: ['', Validators.required], orderNo: [''], contactName: ['', Validators.required],
      phone: [''], email: ['', Validators.required],
      orderDate: ['', Validators.required], deliveryDate: ['', Validators.required], deliveryAdd: ['', Validators.required],
      instructions: [''], craneLift: [''], longWalk: [''], passUp: ['']
      /*customer: [''], orderNo: [''], contactName: [''], orderDate: [''], phone: [''], email: [''], deliveryDate: [''], deliveryAdd: [''],
        instructions: [''], craneLift: [''], longWalk: [''], passUp: ['']*/
      });

      this.boardSelectForm = this.formBuilder.group ({
        boardType: [''], boardThickness: ['']
      });
      this.step3CategoriesForm = this.formBuilder.group ({
        step3CategoriesType: ['']
      });
      this.step3StudForm = this.formBuilder.group ({
        studType: [''], studBMT: ['']
      });
    }


    openTab(stepName, currentStep) {
      const value = this.orderFormStep1.value;
      this.previousStepName = currentStep;
      if (stepName === 'step3') { document.getElementById('step2').classList.add('done'); }
      let i, stepContent, stepLinks;
      stepContent = document.getElementsByClassName('stepContent');
      for (i = 0; i < stepContent.length; i++) {
        stepContent[i].style.display = 'none';
      }
      stepLinks = document.getElementsByClassName('stepLinks');
      for (i = 0; i < stepLinks.length; i++) {
        if (stepLinks[i].classList.contains('active')) {
          stepLinks[i].classList.remove('active');
        }
        stepLinks[i].classList.add('disabled');
      }
      const contentId = stepName + 'Content';
      document.getElementById(contentId).style.display = 'block';
      if (stepName !== 'step4') {
        document.getElementById(stepName).classList.add('active');
        document.getElementById(stepName).className.replace('disabled', ' ');
      }
    }

    onSubmitStep1() {
      this.submittedStep1 = true;
      if (this.orderFormStep1.valid) {
        localStorage.setItem('step1', JSON.stringify(this.orderFormStep1.value));
        this.openTab('step2', 'step1');
        document.getElementById('step1').classList.add('done');
      } else {
        return;
      }
    }

    /*** Step2 ***/
  mySelectHandler() {
    this.defineVariables();
    this.selectedBoard.name = this.boardSelectForm.value.boardType;
    this.showThicknessOption = true;
    this.itemThickness = this.plasterBoards.filter(res => res.name === this.boardSelectForm.value.boardType)[0];
  }

  thicknessSelectHandler() {
    this.selectedBoard.thickness = this.boardSelectForm.value.boardThickness;
    this.showWidthOption = true;
    this.itemWidth = this.itemThickness.thickness.filter(res => res.name === this.boardSelectForm.value.boardThickness)[0];
  }

  step2AddToCart() {
    this.addingItemNo = 0;
    const length = this.step2Submit.length;
    for (let a = 0; a < this.itemWidth.width.length; a++) {
      for (let b = 0; b < this.itemWidth.width[a].length.length; b++) {
        const id = this.itemWidth.width[a].length[b].code;
        // @ts-ignore
        const quantity = document.getElementById(id).value;
        if (quantity > 0 ) {
          const selectedBoard = {code: '', name: '', thickness: '', width: '', length: '', type: '', quantity: 0 };
          selectedBoard.code = id;
          selectedBoard.name = this.selectedBoard.name;
          selectedBoard.thickness = this.selectedBoard.thickness;
          selectedBoard.type = this.selectedBoard.type;
          selectedBoard.width = this.itemWidth.width[a].name;
          selectedBoard.length = this.itemWidth.width[a].length[b].name;
          selectedBoard.quantity = quantity;
          if (this.step2Submit.length > 0) {
            let result: any;
            /*result = this.step2Submit.filter(res =>
              res.name !== selectedBoard.name || res.thickness !== selectedBoard.thickness
              || res.width !== selectedBoard.width || res.type !== selectedBoard.type || res.length !== selectedBoard.length);*/
            result = this.step2Submit.filter(res =>
              res.code !== selectedBoard.code);
            this.step2Submit = result;
          }
          this.addToQuoteSuccess = true;
          this.step2Submit.push(selectedBoard);
          // this.allStepsSubmit.push(selectedBoard);
        }
      }
    }
    this.addingItemNo = this.step2Submit.length - length;
    document.getElementById('addCartAlert2').classList.replace('invisible', 'visible');
    setTimeout(function () {
      document.getElementById('addCartAlert2').classList.replace('visible', 'invisible');
    }, 1000);
  }

  deleteBoardStep2(i) {
    this.step2Submit.splice(i, 1);
  }
  /*** End Step2 ***/

  /*** Step3 ***/
  step3CategoriesSelectHandler() {
    this.step3Index = this.accessories.indexOf(this.step3CategoriesForm.value.step3CategoriesType);
    this.step3CatContentShow = true;
    this.showTrackStudBMTOption = false;
    this.showTrackStudSizeOption = false;
    this.BMTTrackStud = {name: '', size: ''};
  }

  trackStudSelectHandler() {
    this.showTrackStudBMTOption = true;
    this.showTrackStudSizeOption = false;
    this.filteredTrackStud = this.trackStud.filter(res => res.name === this.step3StudForm.value.studType)[0];
  }
  TSBMTSelectHandler() {
    this.showTrackStudSizeOption = true;
    this.BMTTrackStud = this.filteredTrackStud.BMT.filter(res => res.name === this.step3StudForm.value.studBMT)[0];
  }

  step3AddToCart() {
    this.addingItemNo = 0;
    const length = this.step3Submit.length;
    for (let a = 0; a < this.allContent[this.step3Index].content.length; a++) {
      for (let b = 0; b < this.allContent[this.step3Index].content[a].size.length; b++) {
        const id = this.allContent[this.step3Index].content[a].size[b].code;
        // @ts-ignore
        const quantity = document.getElementById(id).value;
        if (quantity > 0 ) {
          const step3Data = { code: '',  name: '', thickness: '', width: '', length: '', type: '', quantity: 0 };
          step3Data.code = id;
          step3Data.length = this.allContent[this.step3Index].content[a].size[b].name;
          step3Data.name = this.allContent[this.step3Index].content[a].name;
          step3Data.quantity = quantity;
          if (this.step3Submit.length > 0) {
            let result: any;
            result = this.step3Submit.filter(res =>
              res.code !== step3Data.code);
            this.step3Submit = result;
          }
          this.step3Submit.push(step3Data);
        }
      }
    }
    if (this.BMTTrackStud) {
      if (this.BMTTrackStud.size.length > 0) {
        for ( let i = 0; i < this.BMTTrackStud.size.length; i++) {
          // @ts-ignore
          const id = this.BMTTrackStud.size[i].code;
          // @ts-ignore
          const quantity = document.getElementById(id).value;
          if (quantity > 0) {
            const step3Data = { code: '', name: '', thickness: '', width: '', length: '', type: '', quantity: 0 };
            step3Data.code = id;
            // @ts-ignore
            step3Data.width = this.BMTTrackStud.size[i].name;
            step3Data.name = this.filteredTrackStud.name;
            step3Data.type = this.BMTTrackStud.name;
            step3Data.quantity = quantity;
            if (this.step3Submit.length > 0) {
              let result: any;
              result = this.step3Submit.filter(res =>
                res.code !== step3Data.code);
              this.step3Submit = result;
            }
            this.step3Submit.push(step3Data);
          }
        }
      }
    }
    this.addingItemNo = this.step3Submit.length - length;
    document.getElementById('addCartAlert3').classList.replace('invisible', 'visible');
    setTimeout(function () {
      document.getElementById('addCartAlert3').classList.replace('visible', 'invisible');
    }, 1000);
  }

  deleteBoardStep3(i) {
    this.step3Submit.splice(i, 1);
  }

  /*** End Step3 ***/

  orderFormSubmit() {
    this.alertModal.hide();
    const order = [];
    const header = { customer: '', orderNo: '', contactName: '', orderDate: '',
      phone: '', email: '', deliveryDate: '', deliveryAdd: '', instructions: '', };
    for (let a = 0; a < this.step2Submit.length; a++) {
      const step2Data = { code: '', quantity: '' };
      step2Data.code = this.step2Submit[a].code;
      step2Data.quantity = this.step2Submit[a].quantity;
      order.push(step2Data);
    }
    for (let a = 0; a < this.step3Submit.length; a++) {
      const step3Data = {  code: '', quantity: '' };
      step3Data.code = this.step3Submit[a].code;
      step3Data.quantity = this.step3Submit[a].quantity;
      order.push(step3Data);
    }

    const f = this.orderFormStep1.value;
    header.customer = f.customer;
    header.orderNo = f.orderNo;
    header.contactName = f.contactName;
    header.phone = f.phone;
    header.email = f.email;
    header.deliveryAdd = f.deliveryAdd;

    header.instructions = f.craneLift + ' ' + f.longWalk + ' ' + f.passUp + ' ' + f.instructions;
    // End combining long walk with special instructions;

    const orderDateMonth = f.orderDate.getUTCMonth() + 1;
    const deliveryDateMonth = f.deliveryDate.getUTCMonth() + 1;

    header.orderDate = f.orderDate.getUTCFullYear() + '-' + orderDateMonth + '-' + f.orderDate.getUTCDate();
    header.deliveryDate = f.deliveryDate.getUTCFullYear() + '-' + deliveryDateMonth + '-' + f.deliveryDate.getUTCDate();

    /*if (orderDateMonth.toString().length < 2) {
      header.orderDate = f.orderDate.getUTCFullYear() + '-0' + orderDateMonth + '-' + f.orderDate.getUTCDate();
    } else {
      header.orderDate = f.orderDate.getUTCFullYear() + '-' + orderDateMonth + '-' + f.orderDate.getUTCDate();
    }
    if (deliveryDateMonth.toString().length < 2) {
      header.deliveryDate = f.deliveryDate.getUTCFullYear() + '-0' + deliveryDateMonth + '-' + f.deliveryDate.getUTCDate();
    } else {
      header.deliveryDate = f.deliveryDate.getUTCFullYear() + '-' + deliveryDateMonth + '-' + f.deliveryDate.getUTCDate();
    }*/
    const data = {'header': header, 'order': order};
    const submitJSON = JSON.stringify(data);

    // -----------
    /*this.step2Submit = [];
    this.step3Submit = [];
    this.submittedStep1 = false;
    this.orderFormStep1.reset();*/
    // ---------

    /*this.contactService.orderFormPost(submitJSON).subscribe(
      res => {
        console.log('POST Request is successful ', res);
        this.successModal.show();
        // this.frame.hide();
        this.step2Submit = [];
        this.step3Submit = [];
        this.submittedStep1 = false;
        this.orderFormStep1.reset();

        const stepLinks = document.getElementsByClassName('stepLinks');
        for (let i = 0; i < stepLinks.length; i++) {
          if (stepLinks[i].classList.contains('done')) {
            stepLinks[i].classList.remove('done');
          }
          if (stepLinks[i].classList.contains('active')) {
            stepLinks[i].classList.remove('active');
          }
        }
        document.getElementById('step1').classList.add('active');
        document.getElementById('step2').classList.add('disabled');
        document.getElementById('step3').classList.add('disabled');
        this.openTab('step1', '');
      },
      error => {
        console.log('Error', error);
        this.formSubmitError = true;
      }
    );*/
  }

  defineVariables() {
    this.showThicknessOption = false;
    this.showWidthOption = false;
    this.showLengthOption = false;
    this.showHebelType = false;
    this.itemThickness = {};
    this.itemWidth = {};
    this.itemLength = {};
    this.selectedBoard = {
      name: '',
      thickness: '',
      width: '',
      length: '',
      type: '',
      quantity: 0
    };
  }


}
