<app-header *ngIf="!globals.onlineStore"></app-header>
<app-shopping-header *ngIf="globals.onlineStore"></app-shopping-header>

<!-- shopping cart float button -->
<div class="hume-fixed-shopping-cart g-top-178--md" *ngIf="globals.showFooter">
  <button mdbBtn type="button" rounded="true" class="g-color-white btn u-btn-primary" mdbWavesEffect (click)="openShoppingCart()" style="border-radius: 50px">
    <span class="u-badge-v1--sm g-color-white g-bg-transparent g-font-size-11 g-line-height-1_4 g-rounded-50x g-pa-4 font-weight-bold"
          style="top: 11px !important; font-size: 15px !important; right: 45px !important;">{{globals?.cart?.lines?.length ? (globals?.cart?.lines | getCartQuantity) : 0}}</span>
    <i class="fa fa-shopping-cart g-color-white g-font-size-25 position-relative" style="left: -19px; top: 5px;"></i>
  </button>
</div>
<!-- shopping cart float button -->

<!-- Shopping cart modal -->
<!--<div mdbModal #basicModal="mdbModal" class="modal fade right hume-modal-top" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel"
     aria-hidden="true">
  <app-shopping-cart-modal [basicModal]="basicModal"></app-shopping-cart-modal>
</div>-->
<!-- Shopping cart modal -->


<router-outlet></router-outlet>

<!-- Go To Top -->
<a class="js-go-to u-go-to-v2" style="background-color: #0000004f !important;" href="#!"
   data-type="fixed"
   data-position='{
           "bottom": 16,
           "right": 17
         }'
   data-offset-top="400"
   data-compensation="#js-header"
   data-show-effect="zoomIn">
  <i class="hs-icon hs-icon-arrow-top"></i>
</a>
<!-- End Go To Top -->

<app-footer *ngIf="globals.showFooter"></app-footer>



