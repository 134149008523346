import { NgModule } from '@angular/core';
import { CommonModule } from '@angular/common';
import {CareersComponent} from './careers.component';
import {RouterModule, Routes} from '@angular/router';
import {CarouselModule} from 'ngx-bootstrap/carousel';
import {ContactModule} from '../contact/contact.module';
import {PipesModule} from '../pipe/pipes.module';

export const ROUTES: Routes = [
  { path: 'careers', component: CareersComponent,
    data: {
      title: 'Hume Building Products Careers',
      description: 'As a leading Australian distributor of building products, tools and accessories, ' +
        'Hume has collaborated with a great many builders and contractors including on the following recent building projects in NSW, ' +
        'Victoria and ACT.',
      ogUrl: '/careers'
    }
  }
];


@NgModule({
  declarations: [
    CareersComponent
  ],
  imports: [
    CommonModule,
    RouterModule.forChild(ROUTES),
    CarouselModule,
    ContactModule,
    PipesModule
  ]
})
export class CareersModule { }
