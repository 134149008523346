import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {TranslateService} from '@ngx-translate/core';
import {Globals} from '../global';
import {NavigationEnd, NavigationStart, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {SEOService} from '../services/seo/seo.service';
import {AuthService} from '../services/auth/auth.service';
import {ShoppingCartService} from '../services/shopping-cart.service';
import {HandleErrorService} from '../services/handle-error.service';
import {GetLocalStorageService} from '../services/get-local-storage.service';
import {DataService} from '../services/data.service';
import {MDBModalService} from 'angular-bootstrap-md';
import {ShoppingCartModalComponent} from '../shared/ecommerce/shopping-cart-modal/shopping-cart-modal.component';
declare var $: any;

import Amplify, { Storage } from 'aws-amplify';
import awsconfig from '../../aws-exports';
import {ProductService} from '../services/product.service';
import {distinctUntilChanged} from 'rxjs/operators';
Amplify.configure(awsconfig);

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html',
  styleUrls: ['./app.component.scss']
})
export class AppComponent implements OnInit, AfterViewInit, OnDestroy {
  routerSubscription: Subscription;
  title = 'hume-website';
  currentUser;

  constructor(private translateService: TranslateService,
              private handleErrorService: HandleErrorService,
              private getLocalStorageService: GetLocalStorageService,
              private scService: ShoppingCartService, private dataService: DataService,
              private authService: AuthService, private productService: ProductService,
              private seoService: SEOService,
              private router: Router, public modalService: MDBModalService,
              public globals: Globals) {}
  async ngOnInit () {

    this.urlChecking();
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translateService.setDefaultLang('en');

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translateService.use('en');

    this.routerSubscription = this.router.events.subscribe( event => {
      if (event instanceof NavigationStart) {

        // Navigation started.
        const urlTemp = event.url.split('/');
        this.globals.showHeader = urlTemp[1] !== 'newsv2';
        this.globals.showFooter = urlTemp[1] !== 'newsv2';

        this.globals.currentUrl = event.url;
        this.globals.onlineStore = event.url.includes('online-store');
        if (event.url.includes('architectural') || event.url.includes('commercial')) {
          this.globals.isArchitectural = true;
        }
      }

      if (event instanceof NavigationEnd) {
        // Trick the Router into believing it's last link wasn't previously loaded
        this.router.navigated = false;
      }
    });

    this.authService.token.pipe(
      distinctUntilChanged()
    ).subscribe(res => {
      if (res) {
        this.productService.getWebCat();
        this.productService.getEWebCat();
        this.productService.getClearance();
        this.authService.getCartFromServer();
      }
    });

    this.windowLoad();
    await this.authService.refreshToken().then(res => {
      this.globals.token = res;
      // this.productService.getWebCat();
      // this.productService.getEWebCat();
    });

    this.authService.getLoginDetails().subscribe(async res => {
      this.currentUser = res;
      localStorage.setItem('currentUser', JSON.stringify(res));
      if (this.currentUser?.customer) {
        this.authService.getCartFromServer();
      } else {
        this.globals.cart = null;
      }
    });

    const self = this;
    this.checkWindowSize();
    $(window).on('resize', function () { self.checkWindowSize(); });

    // ** Checking and getting cart data from local storage.
    if (localStorage.getItem('cart')) {
      this.globals.cart = JSON.parse(localStorage.getItem('cart'));
    }

    /* SEO */
    this.seoService.setMetaInfo();
    /* SEO */
  }

  ngAfterViewInit() {
    this.urlChecking();
  }

  checkWindowSize() {
    this.globals.isMobile = window.screen.width <= 768;
  }

  openShoppingCart() {
    this.globals.shoppingCartModal = this.modalService.show(ShoppingCartModalComponent, {
      // backdrop: true,
      class: 'modal-dialog modal-full-height modal-right',
      animated: true});
  }

  urlChecking() {
    const url = window.location.href;
    if (url.includes('www.humebuildingproducts.com.au/stone_collection')) {
      // tslint:disable-next-line:max-line-length
      window.location.href = url.replace('www.humebuildingproducts.com.au/stone_collection', 'www.humebuildingproducts.com.au/kitchens_bathrooms/stone');
    }
    if (url.includes('www.humecommercial.com.au')) {
      window.location.href = url.replace('www.humecommercial.com.au', 'www.humebuildingproducts.com.au/commercial');
      this.globals.divisionTag = 'commercial';
    } else if (url.includes('www.humearchitectural.com.au')) {
      window.location.href = url.replace('www.humearchitectural.com.au', 'www.humebuildingproducts.com.au/architectural');
      this.globals.divisionTag = 'architectural';
    } else if (url.includes('www.humeplaster.com.au')) {
      window.location.href = url.replace('www.humeplaster.com.au', 'www.humebuildingproducts.com.au');
      this.globals.divisionTag = 'home';
    } else if (url.includes('commercial')) {
      this.globals.divisionTag = 'commercial';
    } else if (url.includes('home')) {
      this.globals.divisionTag = 'home';
    } else if (url.includes('architectural')) {
      this.globals.divisionTag = 'architectural';
    } else {
      this.globals.divisionTag = 'home';
    }
    /*if (url.includes('5eqKzKTTRu')) {
      window.open('https://hume-ecom-test.s3-ap-southeast-2.amazonaws.com/5eqKzKTTRu.txt', '_blank');
    }*/
    localStorage.setItem('divisionTag', this.globals.divisionTag);
  }

  ngOnDestroy() {
    if (this.routerSubscription) { this.routerSubscription.unsubscribe(); }
    this.globals.currentUrl = '';
  }

  windowLoad() {
    $(document).on('ready', function () {

      // initialization of header
      $.HSCore.components.HSHeader.init($('#js-header'));

      // initialization of HSMegaMenu component
      $('.js-mega-menu').HSMegaMenu({
        event: 'hover',
        pageContainer: $('.container'),
        breakpoint: 991
      });

      // initialization of go to
      $.HSCore.components.HSGoTo.init('.js-go-to');

    });
  }
}
