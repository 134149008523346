import {Injectable} from '@angular/core';

@Injectable()
export class GetLocalStorageService {

  getReturnPageUrl () {
    if (localStorage.getItem('returnPageUrl')) {
      return localStorage.getItem('returnPageUrl');
    }
  }

  getLocalStorage(itemName) {
    if(localStorage.getItem(itemName)) {
      return localStorage.getItem(itemName);
    } else {
      return null;
    }
  }

  setLocalStorage(itemName, itemValue) {
    localStorage.setItem(itemName, itemValue);
  }

}
