<!-- Related Products -->
<div class="container g-pt-50--md g-pt-20 g-pb-20" *ngIf="subCat?.id === 36 || subCat?.id === 37 || subCat?.id === 38 ||
subCat?.id === 39 || subCat?.id === 33 || subCat?.id === 18 || subCat?.id === 21">
  <hr>
  <div class="text-center mx-auto g-max-width-600 g-mb-50">
    <h2 class="g-color-black mb-4">Related Architectural Products</h2>
  </div>
  <div class="row">
    <div class="col-md-3 col-12 mb-2" *ngFor="let relatedSubCat of relatedSubCats; let i = index">
      <a [routerLink]="'/cladding/' + (relatedSubCat?.link | urlTransfer)" *ngIf="i < 4">
        <article class="title-sm-230 category-grid-img g-bg-cover z-depth-1-half g-bg-size-cover g-bg-white-gradient-opacity-v1--after hoverable"
                 [ngStyle]="{'background-image': 'url(' + imgUrl + relatedSubCat?.thumbnailUrl + ')'}">
          <div class="g-flex-middle category-tiles g-pos-rel g-z-index-1 g-pa-30" [ngClass]="{'g-min-height-250': relatedSubCats?.length > 9, 'g-min-height-400': relatedSubCats?.length <= 9}">
            <span class="d-block g-color-white-opacity-0_8 g-font-size-11 text-uppercase mb-4"></span>
            <a class="h4 g-color-white g-font-weight-600 g-mb-30 stripe dark g-font-size-25 hoverable" [ngStyle]="relatedSubCats?.length > 9 ? {'bottom': '26%'} : {'bottom': '40%'}"
               [routerLink]="'/cladding/' + (relatedSubCat?.link | urlTransfer)">{{relatedSubCat?.name}}</a>
          </div>
        </article>
      </a>
    </div>
  </div>

</div>
<!-- End Related Products -->
