import {AfterViewInit, Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Route, Router} from '@angular/router';
import {UserService} from '../../services/user/user.service';
import {Subscription} from 'rxjs';
import {AlertService} from '../../services/alert.service';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';

@Component({
  selector: 'app-order-tracking',
  templateUrl: './order-tracking.component.html',
  styleUrls: ['./order-tracking.component.scss']
})
export class OrderTrackingComponent implements OnInit, OnDestroy, AfterViewInit {
  routeSub: Subscription;
  trackingSub: Subscription;
  orderInfo = null;
  errorMessage;
  submitted = false;
  trackingForm: FormGroup;
  showForm = false; // Showing the submit form
  noRecord = true;

  constructor(private activatedRoute: ActivatedRoute, private userService: UserService, private alertService: AlertService,
              private router: Router, private fb: FormBuilder) { }

  get f() { return this.trackingForm.controls; }

  ngOnInit(): void {

    this.trackingForm = this.fb.group({
      orderNo: ['', [Validators.required]],
      email: ['', [Validators.required]]
    });

    this.routeSub = this.activatedRoute.queryParams.subscribe(res => {

      // this.showForm = !res.order;
      if (res.order) {
        this.showForm = false;
        this.trackingSub = this.userService.trackingOrder(res.order, res.phone, res.mail).subscribe(res1 => {
          const self = this;
          // @ts-ignore
          if (res1.data) {
            // @ts-ignore
            this.orderInfo = res1.data[0];
            // this.orderInfo.header.statusValue = 4;
          } else {
            // @ts-ignore
            this.errorMessage = res1.message;
            // this.alertService.showError(this.errorMessage, true, 5000);
            setTimeout(() => {
              self.router.navigate(['/products']);
            }, 6000);
          }
        }, error => {
          // console.log(error);
        });
      } else {
        this.orderInfo = null;
        this.showForm = true;
      }
    });
  }

  ngAfterViewInit() {
    this.orderInfo = null;
  }

  onSubmit() {
    let email = '';
    let phone = '';
    this.submitted = true;
    if (this.trackingForm.valid) {
      if (this.trackingForm.value.email.includes('@')) {
        email = this.trackingForm.value.email;
      } else {
        phone = this.trackingForm.value.email;
      }
      this.router.navigate(['/order-tracking'], {queryParams: {order: this.trackingForm.value.orderNo, email, phone}});
    }
  }

  clearTrack() {
    this.trackingForm.get('orderNo').setValue(null);
    this.trackingForm.get('email').setValue(null);
    this.noRecord = true;
  }

  ngOnDestroy() {
    if (this.routeSub) { this.routeSub.unsubscribe(); }
    if (this.trackingSub) { this.trackingSub.unsubscribe(); }
  }

}
