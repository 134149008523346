<div *ngIf="globals?.cart?.lines?.length > 0 else empty">
  <div class="row no-gutters g-pb-5 mb-3" *ngFor="let item of globals?.cart?.lines, let i = index" style="border-bottom: solid 1px; min-height: 110px; border-color: #6e6e6e3b">
    <!--<div class="col-4 pr-3">
      <a class="" href="#!">
        <img class="img-fluid" style="max-height: 130px;" src="{{item?.photoUrl?.includes('amazonaws') ? item?.photoUrl : ('https://s3-ap-southeast-2.amazonaws.com/hume-website-photo/' + item?.photoUrl)}}" [alt]="item?.name" (error)="img.src = globals.defaultImgUrl" *ngIf="item?.photoUrl" #img>
      </a>
    </div>-->
    <div class="col-12">
      <h6 class="g-font-weight-400 g-font-size-default py-2">
        <a class="g-color-black g-color-primary--hover g-text-underline--none--hover"
           [routerLink]="['/product/' + item?.productId]">{{item?.description}}</a>
      </h6>
      <div class="row">
        <div class="col-5 g-py-5 g-py-10--md d-flex">
          <button type="button" class="quantity-selector_button" (click)="item?.quantity > 1 ? minusQuantity(item, i) : delItem(item)">

            <span aria-hidden="true" class="g-font-size-25 g-color-gray-dark-v4 g-color-black--hover minus-symbol" *ngIf="item?.quantity > 1 else delete">-</span>
            <ng-template #delete>
              <span class="g-color-primary g-color-black--hover"><i class="fa fa-trash g-font-size-15"></i></span>
            </ng-template>
          </button>
          <input class="quantityChangeInput js-result form-control text-center g-font-size-13 rounded-0 g-pa-0" min="0" type="text" [id]="'quantityInput' + item?.id + '_' + page"
                 [(ngModel)]="item.quantity" (ngModelChange)="updateQuantity($event, item, i)">
          <button type="button" class="quantity-selector_button" (click)="addQuantity(item, i)">
            <span aria-hidden="true" class="g-font-size-20 g-color-gray-dark-v4 g-color-black--hover">+</span>
          </button>
        </div>
        <div class="col-3 col-sm-3 pt-2">
          <div class="d-flex">
            <small class="g-color-black g-font-size-13 mr-2"> x </small>
            <small class="g-color-black g-font-size-13"> {{(item?.netPrice ? item?.netPrice : 0) | currency:'AUD' : '$' :  '1.2-2'}} </small>
          </div>
          <div class="text-center d-flex">
            <small class="g-color-gray-dark-v4 g-font-size-13">(Ex.GST)</small>
          </div>
        </div>
        <div class="col-4">
          <div class="input-group-addon d-flex align-items-center g-width-30 g-brd-gray-light-v2 g-bg-white rounded-0 g-px-5 row">
            <!--<label class="mr-2 mt-2" *ngIf="item?.uom !== null">{{item?.uom}}</label>
            <i class="js-plus g-color-gray g-color-primary--hover fa fa-angle-up mr-2" (click)="addQuantity(item?.id)"></i>
            <i class="js-minus g-color-gray g-color-primary--hover fa fa-angle-down" (click)="minusQuantity(item?.id)"></i>-->

            <strong class="g-color-black g-font-size-20">{{(item?.netAmount ? item?.netAmount : 0) | currency:'AUD' : '$' :  '1.2-2'}}</strong>
            <!--<span class="g-color-gray-dark-v4 g-color-black--hover ml-4" (click)="delItem(item?.id, item?.quantity, item?.price)"><i class="fa fa-trash g-font-size-15"></i></span>-->
            <a><span class="g-color-primary g-color-black--hover g-font-size-13 text-right" (click)="delItem(item)">Remove</span></a>
          </div>
        </div>
      </div>
      <p *ngIf="alertInfo[i]?.mini > 0">
        <span class="g-color-primary">*This item requires minimum order quantity {{alertInfo[i]?.mini}}, increment quantity {{alertInfo[i]?.bun}}</span>
      </p>
    </div>

  </div>
  <a class="row text-center"><span class="g-color-primary g-color-black--hover pt-3 mx-auto g-font-size-17" (click)="emptyCart()" *ngIf="globals?.cart?.lines?.length > 1">Remove All</span></a>
</div>
<ng-template #empty>
  <div class="u-basket__product g-brd-none mt-5 text-center g-px-20">
    <span class="d-block g-color-gray-light-v1 g-font-size-60">
          <i class="icon-hotel-restaurant-105 u-line-icon-pro"></i>
    </span>
    <h5 class="text-center g-my-20 g-color-black">Your shopping cart is empty!</h5>
  </div>
</ng-template>
