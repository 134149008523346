import {Component, Input, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ReCaptcha2Component} from 'ngx-captcha';
import {ContactService} from '../../services/contact.service';
import {AlertService} from '../../services/alert.service';
import {Globals} from '../../global';
import {MDBModalRef} from 'angular-bootstrap-md';

@Component({
  selector: 'app-quote-form',
  templateUrl: './quote-form.component.html',
  styleUrls: ['./quote-form.component.scss']
})
export class QuoteFormComponent implements OnInit {
  @Input() catId;
  @Input() frame;
  submitted = false;
  siteKey = this.globals.siteKey;
  id;

  contactFormGroup: FormGroup;

  @ViewChild('captchaElem') captchaElem: ReCaptcha2Component;
  // @ViewChild('frame') frame: ModalDirective;

  get f() { return this.contactFormGroup; }

  constructor(private formBuilder: FormBuilder, private contactService: ContactService, private alertService: AlertService,
              public globals: Globals, public modalRef: MDBModalRef) { }

  ngOnInit(): void {
    this.contactFormGroup = this.formBuilder.group({
      recaptcha: ['', Validators.required],
      name: ['', Validators.required], customerType: [1, Validators.required],
      phone: ['', Validators.compose([Validators.required, Validators.minLength(8)])],
      email: ['', [Validators.required, Validators.pattern('[^ @]*@[^ @]*')]],
      state: ['', Validators.required],
      message: ['', Validators.required],
      subject: [''], source: [1, Validators.required]
    });

  }

  onSubmit() {
    this.submitted = true;
    if (this.contactFormGroup.valid) {
      const formValue = this.contactFormGroup.value;
      const recaptcha = formValue.recaptcha;
      formValue.type = this.id ? this.id : this.catId;
      delete formValue.recaptcha;
      const myJSON = JSON.stringify(formValue);
      this.contactService.quoteFormPost(myJSON, recaptcha).subscribe(
        data  => {
          this.submitSuccess();
        },
        error  => {
          if (error?.statusText?.toLowerCase() === 'ok' || error.status === 202 || error.status === 200) {
            this.submitSuccess();
          } else {
            this.alertService.showError('Submitted unsuccessfully', true);
            this.captchaElem.resetCaptcha();
          }
        }
      );
    } else {
      // stop here if form is invalid
      return;
    }
  }

  submitSuccess() {
    this.alertService.showSuccess('Submitted successfully.', false);
    this.closeModal();
  }


  closeModal() {
    this.frame?.hide();
    this.modalRef?.hide();
    this.contactFormGroup.reset();
    this.captchaElem.resetCaptcha();
  }

}
