import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule} from '@angular/common';
import { RouterModule, Routes} from '@angular/router';
import {AboutComponent} from './about/about.component';
import {HomeModule} from '../home/home.module';
import {PipesModule} from '../pipe/pipes.module';
import { TranslateModule} from '@ngx-translate/core';
import {TabDirective, TabsetConfig, TabsModule} from 'ngx-bootstrap/tabs';

export const ROUTES: Routes = [
  { path: 'about', component: AboutComponent },
  { path: 'about/commercial', component: AboutComponent },
  { path: 'about/architectural', component: AboutComponent },
  { path: 'about-cn', component: AboutComponent },
];

@NgModule({
  imports: [
    CommonModule,
    HomeModule,
    PipesModule,
    RouterModule.forChild(ROUTES),
    TranslateModule.forChild(),
    TabsModule
  ],
  declarations: [
    AboutComponent
  ],
  exports: [],
  providers: [TabsetConfig, TabDirective],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class AboutModule {
}
