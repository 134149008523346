import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {TrackingComponent} from './tracking.component';
import {TrackingResultComponent} from './result/result.component';
import {TrackingSubmitComponent} from './submit/submit.component';
import {TranslateModule} from '@ngx-translate/core';
import {MDBBootstrapModule} from 'angular-bootstrap-md';
import { OrderTrackingComponent } from './order-tracking/order-tracking.component';
import {NgxSkeletonLoaderModule} from "ngx-skeleton-loader";

export const ROUTES: Routes = [
  // { path: 'tracking', component: TrackingComponent}
];

@NgModule({
    imports: [
        FormsModule,
        ReactiveFormsModule,
        CommonModule,
        TranslateModule,
        MDBBootstrapModule,
        RouterModule.forChild(ROUTES),
        NgxSkeletonLoaderModule
    ],
  declarations: [
    TrackingComponent,
    TrackingResultComponent,
    TrackingSubmitComponent,
    OrderTrackingComponent
  ],
  exports: [TrackingComponent ],
  providers: [ ],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class TrackingModule { }
