<!-- This component showing on product list page-->
<div class="g-bg-primary">
  <div class="container g-py-20">
    <div class="row justify-content-center">

      <div class="col-md-4 mx-auto g-py-20">
        <!-- Media -->
        <div class="media g-px-50--lg">
          <i class="d-flex g-color-white g-font-size-40 g-pos-rel g-top-3 mr-4 fa fa-trophy"></i>
          <div class="media-body text-left">
            <span class="d-block g-color-white g-font-weight-500 g-font-size-17 text-uppercase">leading Australian supplier</span>
            <span class="d-block g-color-white-opacity-0_8">Building products, tools and accessories</span>
          </div>
        </div>
        <!-- End Media -->
      </div>

      <div class="col-md-4 mx-auto g-brd-x--md g-brd-white-opacity-0_3 g-py-20">
        <!-- Media -->
        <div class="media g-px-50--lg">
          <i class="d-flex g-color-white g-font-size-40 g-pos-rel g-top-3 mr-4 fas fa-shipping-fast"></i>
          <div class="media-body">
            <span class="d-block g-color-white g-font-weight-500 g-font-size-17 text-uppercase">Fast delivery</span>
            <span class="d-block g-color-white-opacity-0_8">Monday - Saturday to metropolitan and regional areas</span>
          </div>
        </div>
        <!-- End Media -->
      </div>

      <div class="col-md-4 mx-auto g-py-20">
        <!-- Media -->
        <div class="media g-px-50--lg">
          <i class="d-flex g-color-white g-font-size-40 g-pos-rel g-top-3 mr-4 fa fa-shopping-cart"></i>
          <div class="media-body">
            <span class="d-block g-color-white g-font-weight-500 g-font-size-17 text-uppercase">Warehouse stores</span>
            <span class="d-block g-color-white-opacity-0_8">Across Sydney and Melbourne</span>
          </div>
        </div>
        <!-- End Media -->
      </div>
    </div>
  </div>
</div>
