    <h1 class="text-center my-5">Our Store Locations</h1>
    <!-- Section description -->
    <p class="g-font-size-17 mx-auto col-md-10 col-12 text-center" *ngIf="page !== 'careers'">With warehouse stores across Sydney and Melbourne employing over 230 people, Hume has 100,000 square metres of space and a national delivery network servicing contractors large and small.</p>
    <div class="row text-center">
      <div class="col-md-10 col-12" style="margin-right: auto; margin-left: auto; margin-bottom: 15px">
        <button mdbBtn type="button" class="btn u-btn-primary g-font-size-12 text-uppercase g-py-12 g-px-15 mr-5 hoverable" (click)="stateSelect('nsw')">
          <span class="g-font-size-18">NSW</span>
        </button>
        <button mdbBtn type="button" class="btn u-btn-primary g-font-size-12 text-uppercase g-py-12 g-px-20 hoverable" (click)="stateSelect('vic')">
          <span class="g-font-size-18">VIC</span>
        </button>
      </div>
    </div>

    <!-- Grid row -->
    <div class="row">
      <!-- Grid column -->
      <div class="col-md-10 col-12 g-px-0 g-px-15-md mx-auto">
        <!-- Google map-->
        <div id="map-container" #map class="rounded z-depth-1-half map-container" style="height: 600px">
        </div>
        <br>

        <!-- Contact Info -->
        <div class="row" *ngIf="page !== 'careers' else careersPage">
          <div class="col-md-6 col-lg-8 mx-auto g-py-15">
            <!-- Media -->
            <div id="location-store" class="media g-brd-around g-brd-gray-light-v4 rounded g-pa-40--md g-pa-10">
              <div class="d-flex g-mr-30">
                <i class="d-inline-block g-color-primary g-font-size-25 g-font-size-40--md g-pos-rel g-top-3 icon-real-estate-027 u-line-icon-pro"></i>
              </div>
              <div class="media-body" *ngIf="showNSW; else vic">
                <span class="d-block g-font-weight-500 g-font-size-default text-uppercase mb-2">Location</span>
                <div *ngFor="let ng of nswGeo">
                  <span class="d-block g-color-gray-dark-v4 font-weight-bold">
                    <a [routerLink]="['/hume-stores/' + ng?.name | urlTransfer ]">
                      Hume Building Products {{ng?.name?.toLowerCase().includes('yennora') ? (ng?.name + ' (Head Office)') : ng?.name}}</a>:</span>
                  <span class="d-block g-color-gray-dark-v4">{{ng.address}}</span>
                </div>
              </div>
              <ng-template class="media-body" #vic>
                <div class="media-body">
                  <span class="d-block g-font-weight-500 g-font-size-default text-uppercase">Location</span>
                  <div *ngFor="let vg of vicGeo">
                    <span class="d-block g-color-gray-dark-v4 font-weight-bold">
                      <a [routerLink]="['/hume-stores/' + vg?.name | urlTransfer ]">Hume Building Products {{vg.name}}</a>:</span>
                    <span class="d-block g-color-gray-dark-v4">{{vg.address}}</span>
                    <ul class="list-unstyled mt-2 mb-3">
                      <li class="d-block g-color-gray-dark-v4">Mon - Fri: {{vg.hoursMF}}</li>
                      <li class="d-block g-color-gray-dark-v4">Saturday: {{vg.hoursSat}}</li>
                    </ul>
                  </div>
                </div>
              </ng-template>
            </div>
            <!-- End Media -->
          </div>

          <div class="col-lg-4 col-md-6">
            <div class="mx-auto g-py-15">
              <!-- Media -->
              <div class="media g-brd-around g-brd-gray-light-v4 rounded g-pa-40--md g-pa-20">
                <div class="d-flex g-mr-30">
                  <i class="d-inline-block g-color-primary g-font-size-25 g-font-size-40--md g-pos-rel g-top-3 icon-communication-033 u-line-icon-pro"></i>
                </div>
                <div class="media-body">
                  <span class="d-block g-font-weight-500 g-font-size-default text-uppercase mb-2">Call Us</span>
                  <ul class="list-unstyled mb-0">
                    <li class="d-block g-color-gray-dark-v4 g-font-size-30"><a href="tel:134863">13 4863</a></li>
                  </ul>
                </div>
              </div>
              <!-- End Media -->
            </div>

            <!-- Media -->
            <div class="mx-auto g-py-15" *ngIf="showNSW">
              <div class="media g-brd-around g-brd-gray-light-v4 rounded g-pa-40--md g-pa-15">
                <div class="d-flex g-mr-30">
                  <i class="d-inline-block g-color-primary g-font-size-25 g-font-size-40--md g-pos-rel g-top-3 icon-hotel-restaurant-003 u-line-icon-pro"></i>
                </div>
                <div class="media-body text-left">
                  <span class="d-block g-font-weight-500 g-font-size-default text-uppercase mb-2">Stores trading hours</span>
                  <ul class="list-unstyled mb-0">
                    <li class="d-block g-color-gray-dark-v4">Mon - Fri: <p>06AM to 05PM</p></li>
                    <li class="d-block g-color-gray-dark-v4">Saturday: <p>06AM to 03:30PM</p></li>
                  </ul>
                </div>
              </div>
              <!-- End Media -->
            </div>
            <!-- End Contact Info -->

          </div>
        </div>

        <ng-template #careersPage>
          <div class="row my-3 text-center d-block" *ngIf="showNSW else vicStore">
            <span class="h4 font-weight-bold" *ngFor="let ng of nswGeo, let i = index">
              <a *ngIf="!ng?.name?.toLowerCase().includes('granville')" [routerLink]="['/hume-stores/' + ng?.name | urlTransfer ]">{{ng?.name}}</a>{{((i === nswGeo?.length - 1) ? '' : ' | ')}}</span>
          </div>
          <ng-template #vicStore>
            <div class="row my-3 text-center d-block">
            <span class="h4 font-weight-bold" *ngFor="let ng of vicGeo, let i = index">
              <a [routerLink]="['/hume-stores/' + ng?.name | urlTransfer ]">{{ng?.name}}</a>{{((i === vicGeo?.length - 1) ? '' : ' | ')}}</span>
            </div>
          </ng-template>
        </ng-template>
      </div>

      <!-- Grid column -->

    </div>
    <!-- Grid row -->
