import {Component, EventEmitter, Input, OnDestroy, OnInit, Output} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../services/auth/auth.service';
import {ProductService} from '../../services/product.service';
import {Subscription} from 'rxjs';
import {OrderPipe} from 'ngx-order-pipe';
import {Globals} from '../../global';
import {NgbAccordionConfig} from '@ng-bootstrap/ng-bootstrap';

@Component({
  selector: 'app-products-side-bar',
  templateUrl: './left-side-bar.component.html',
  providers: [NgbAccordionConfig]
})
export class ProductsSideBarComponent implements OnInit, OnDestroy {
  @Output() messageEvent = new EventEmitter<object>();
  @Input() eCategories;
  categoriesWebSubscription: Subscription;
  categoriesSubscription: Subscription;
  urlSubscription: Subscription;
  categoriesWeb;
  categories;
  url;
  activeIds: string[] = [];
  subCategories = null;

  constructor(
    config: NgbAccordionConfig,
    private orderPipe: OrderPipe,
    private router: Router,
    private activatedRoute: ActivatedRoute,
    public globals: Globals,
    private authService: AuthService,
    private productService: ProductService) {
    config.type = 'hume';
  }

  ngOnInit () {}


  emitMessage(category) {
    this.messageEvent.emit(category);
  }

  openCategoryMenu(event) {
    this.openCategory(event.id, event.name, event.useGrouping);
  }

  openCategory(id, categoryName, useGrouping?) {
    const category = {id, name: categoryName, subCatName: ''};
    localStorage.setItem('category', JSON.stringify(category));
    switch (id) {
      case 'specials' :
        this.emitMessage(category);
        break;
      default:
        this.getProductsByCategory(id, useGrouping);
        break;
    }
  }

  openSubCategory(item) {
    const category = {id: item.code ? item.code : item.id, name: item.name ? item.name : item.description, subCatName: '', type: item.type};
    localStorage.setItem('category', JSON.stringify(category));
    this.emitMessage(category);
  }

  getProductsByCategory(id, useGrouping?) {
    this.subCategories = null;
    this.productService.getEProductsWebCatDetails(id).toPromise().then(res => {
      if (useGrouping) {
        // @ts-ignore
        this.subCategories = res.productGroups;
      } else {
        // @ts-ignore
        this.subCategories = res.ecomCategories;
      }
    },
      error => {
        // console.log(error);
      });
  }

  ngOnDestroy() {
    if (this.categoriesWebSubscription) { this.categoriesWebSubscription.unsubscribe(); }
    if (this.urlSubscription) { this.urlSubscription.unsubscribe(); }
  }
}
