<div class="single-component-container">
  <div class="container g-py-10 g-font-size-17">
    <h1 class="h1 text-center my-5">Hume Building Products Privacy Policy</h1>
    <h4 class="mb-3 font-weight-bolder">Last Updated: 02 October, 2020</h4>
    <p>The following Privacy Policy of Hume Plasterboard Pty Ltd ACN 70 101 562 270 (t/as Hume Building Products, and known as “Hume”) outlines Hume’s personal information management practices. This policy may change from time to time, notice of which will be provided to you upon receipt of your personal information. In the event that you do not provide us with sufficient information to action that notice, please refer back to this page periodically. Notice of any change to our Privacy Policy will be displayed on our website/s for a period of two (2) weeks prior to any change.</p>
    <div class="mt-4">
      <h3 class="text-capitalize mb-3">Personal Information Collection</h3>
      <p>Hume collects personal information to the extent that is reasonably necessary for carrying out our business functions. We may collect such information when you:</p>
      <ul>
        <li>Interact with us in person or by any other method, make purchases or enquiries via our website, telephone us, post to us, email us, or interact or contact us through social media;</li>
        <li>Create an account or profile on our website;</li>
        <li>Subscribe to our communications;</li>
        <li>Apply for a job with us;</li>
        <li>Otherwise interact with us and disclose personal information.</li>
      </ul>
      <p>When you interact with us, you may be asked for the following personal information</p>
      <ul>
        <li>Your name</li>
        <li>Gender</li>
        <li>Email address</li>
        <li>Company details</li>
        <li>Physical address</li>
        <li>Special delivery instructions</li>
        <li>Phone number</li>
        <li>Mobile number</li>
        <li>Fax number</li>
        <li>Your message</li>
      </ul>
      <p>Depending on the nature of your dealings with us, we may collect and hold other types of personal information. For example, information collected and held via our secure financial systems about the debit or credit card you might use for your purchases from us.</p>
    </div>
    <div class="mt-4">
      <h3 class="text-capitalize mb-3">Use of Personal Information:</h3>
      <p>We collect, use, hold and disclose personal information for purposes, including but not limited to:</p>
      <ul>
        <li>Providing you with products, information or services</li>
        <li>Conducting market research on products and services;</li>
        <li>Developing and improving the quality of our products and services;</li>
        <li>Ensuring that our content is presented in the most effective manner for you on your device;</li>
        <li>Determining your liability to Australian GST in the course of an order;</li>
        <li>Notifying you of any Hume services or products and special offers and promotions from time to time;</li>
        <li>For statistical purposes.</li>
      </ul>
    </div>
    <div class="mt-4">
      <h3 class="text-capitalize mb-3">Disclosure of Personal Information:</h3>
      <p>We may disclose your personal information to:</p>
      <ul>
        <li>Our associated companies, agents, contractors and external advisers who carry on our functions and activities or who assist us to carry on our functions and activities;</li>
        <li>Service providers where you have submitted an enquiry and given us permission to pass your details on;</li>
        <li>Organisations with whom we have arrangements for the purpose of promoting our products or services;</li>
        <li>Organisations who are contracted by us to perform certain services, including to assist with maintenance of our websites and advertising;</li>
        <li>Our legal and other professional advisors;</li>
        <li>Other parties to whom we are authorised or required by law to disclose information.</li>
      </ul>
    </div>
    <div class="mt-4">
      <h3 class="text-capitalize mb-3">Cookies</h3>
      <p>A cookie is a small amount of information sent from a web server to your computer. Hume may use cookies to maintain information for our service offerings. Hume uses cookies to store session information while users are browsing its websites. The type of information we collect as a result of a cookie may include the date and time and how you access our website and what parts of the website are visited. The foregoing information is collected to monitor website usage, for marketing activities and for statistical purposes. The data stored in the cookie for usage tracking is a randomly generated identifier and does not identify you personally. You may set your browser to notify you when you receive a cookie or to refuse receiving it.</p>
    </div>
    <div class="mt-4">
      <h3 class="text-capitalize mb-3">Mail (Electronic and Postal)</h3>
      <p>During the course of your association with Hume, you may receive e-mails from us that contain information relating to you and the service provided to you as a customer, including but not limited to website changes, product additions, news and current updates concerning the industry, our website and product availability. It is our policy to provide you with the opportunity to limit the use and/or disclosure of your personal information. You may do this when you first register, or at any other time you receive email from us, or at any other time by written notice to Hume. If at any time you no longer wish to receive email from us, you can send an email to info@humebuildingproducts.com.au and request removal from our mailing list.</p>
    </div>
    <div class="mt-4">
      <h3 class="text-capitalize mb-3">Links to External Sites</h3>
      <p>This Privacy Policy applies to information collected online of which Hume has direct and total control. Hume does not take any responsibility for its advertisers, outgoing links, or any other part of this site that is not under direct or total control of Hume. We recommend that users read the privacy policies of external sites before submitting personal information.</p>
    </div>
    <div class="mt-4">
      <h3 class="text-capitalize mb-3">Data Quality</h3>
      <p>Hume takes all reasonable steps to ensure that all personal information we collect and use is accurate, complete and up-to-date. It is your responsibility to make sure your information is accurate and complete.</p>
    </div>
    <div class="mt-4">
      <h3 class="text-capitalize mb-3">Data Security</h3>
      <p>Hume will take reasonable steps to ensure that all information we collect, use or disclose is accurate, complete, up-to-date and stored in a secure environment accessed only by authorised persons. Hume also takes reasonable steps to protect personal information it holds from misuse, loss, unauthorised access, modification and disclosure.</p>
      <p>We have implemented technology and security features, as well as strict internal guidelines, to safeguard the privacy of your personal information from unauthorised access. We will continue to enhance these features as technology changes and becomes available. Personal information we hold is generally stored in computer systems. These may be operated by us or by our service providers. In all cases, we have vigorous information security requirements aimed at eliminating risk of unauthorised access to, and loss, misuse or wrongful alteration of, personal information.</p>
      <p>Furthermore, Hume takes reasonable steps to destroy or permanently remove personal information if it is no longer needed for any purpose.</p>
    </div>
  </div>
</div>




