import { Component, OnInit } from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {FormBuilder, Validators} from '@angular/forms';
import {AuthService} from '../../../services/auth/auth.service';

@Component({
  selector: 'app-validation',
  templateUrl: './validation.component.html',
  styleUrls: ['./validation.component.scss']
})
export class ValidationComponent implements OnInit {
  isSubmitted = false;
  displayText;
  activateSuccess;

  activationForm = this.fb.group({
    mobile: ['', Validators.compose([Validators.required, Validators.minLength(10)])]
  });

  constructor(private activatedRoute: ActivatedRoute, private fb: FormBuilder, private authService: AuthService,
              private router: Router) {
    this.activatedRoute.queryParams.subscribe(res => {
      if (res.token) {
        this.displayText = 'We are activating your account...';
        const formData = new FormData();
        formData.append('token', res.token);
        this.authService.activateAccount(formData).toPromise().then(response => {
        }).catch(error => {
          if (error.status === 200) {
            this.activateSuccess = true;
            this.displayText = 'Your account is activated successfully, we are redirecting you to the login page.';
            setTimeout(() => {
              this.router.navigate(['auth/login']);
            }, 5000);
          } else {
            this.activateSuccess = false;
            this.displayText = 'Sorry, This is an invalid token. Please make sure you typed in an accurate token.';
            setTimeout(() => {
              this.router.navigate(['auth/sign-up']);
            }, 15000);
          }
        });
      } else {
        this.activateSuccess = false;
        this.displayText = 'Sorry, This is an invalid token. Please make sure you typed in an accurate token.';
        setTimeout(() => {
          this.router.navigate(['auth/sign-up']);
        }, 15000);
      }
    });
  }

  get f() { return this.activationForm; }

  ngOnInit(): void {
  }

  /*onSubmit() {
    const formData = new FormData();
    formData.append('mobile', this.activationForm.get('mobile').value);
    this.isSubmitted = true;
    if (this.activationForm.valid) {
      this.authService.requestMobileLink(formData).toPromise().then(res => {

      });
    }
  }*/

}
