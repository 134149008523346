import {Injectable} from '@angular/core';
import {Globals} from '../global';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Router} from '@angular/router';
import {BehaviorSubject, Observable} from 'rxjs';
import {Cart} from '../products/models/product.interface';
import {DataService} from './data.service';
import {MDBModalService} from 'angular-bootstrap-md';
import {AlertService} from './alert.service';

@Injectable()

export class ShoppingCartService {
  basicModal;
  httpOptions = {
    headers: new HttpHeaders({
      'Content-Type':  'application/json'
    })
  };


  constructor(
    public globals: Globals, private alertService: AlertService,
    private http: HttpClient, private modalService: MDBModalService,
    private router: Router, private dataService: DataService
  ) {
    this.cartSubject = new BehaviorSubject<Cart>(JSON.parse(localStorage.getItem('cart')));
    this.currentCart = this.cartSubject.asObservable();
  }

  private cartSubject: BehaviorSubject<Cart>;
  public currentCart: Observable<Cart>;

  public get currentCartValue(): Cart {
    return this.cartSubject.value;
  }

  addToWishlist(product) {
    if (localStorage.getItem('wishList')) {
      this.globals.wishList = JSON.parse(localStorage.getItem('wishList'));
    }

    if (!this.globals.wishList) { this.globals.wishList = []; }

    if (this.globals.wishList && this.globals.wishList.length === 0) {
      this.globals.wishList.push(product);
    } else {
      let isPushingItem = true;
      this.globals.wishList.forEach(item => {
        // ** Check if the new item already exists in the cart.
        if (item.id.toString() === product.id.toString()) {
          isPushingItem = false;
        }
      });
      if (isPushingItem) {
        this.globals.wishList.push(product);
      }
    }
    localStorage.setItem('wishList', JSON.stringify(this.globals.wishList));
    this.dataService.changeCart('wishlist');
  }

  closeModal() {
    this.validateCartByUuid(this.globals.cart.uuid).toPromise().then(res => {
      this.globals.cart = res;
      localStorage.setItem('cart', JSON.stringify(res));
    });
    // @ts-ignore
    this.globals.shoppingCartModal.hide();
  }

  // ** step1 payment
  cardPayment(token, total, freight, grantTotal) {
    const object = {
      // @ts-ignore
      cartUuid: this.globals.cart.uuid,
      cardToken: token,
      total,
      freight,
      grantTotal
    };
    const myJSON = JSON.stringify(object);
    return this.http.post(this.globals.humeEcomApiRef + 'order/payment/create', myJSON, this.httpOptions);
  }

  cardPaymentV2(object) {
    const myJSON = JSON.stringify(object);
    return this.http.post(this.globals.humeEcomApiRef + 'order/payment/create', myJSON, this.httpOptions);
  }

  // ** step1 payment
  quotePaymentTest(object) {
    // object.cartUuid = this.globals.cart.uuid;
    const myJSON = JSON.stringify(object);
    return this.http.post(this.globals.humeEcomApiRef + 'order/payment/create/test', myJSON, this.httpOptions);
  }

  orderCreate(object) {
    const myJSON = JSON.stringify(object);
    return this.http.post(this.globals.humeEcomApiRef + 'order/order/create', myJSON, this.httpOptions);
  }

  quoteCreate(object) {
    const myJSON = JSON.stringify(object);
    return this.http.post(this.globals.humeEcomApiRef + 'order/quote/create', myJSON, this.httpOptions);
  }

  getECart() {
    return this.http.get(this.globals.humeEcomApiRef + 'order/cart');
  }

  getProductPack(productId) {
    return this.http.get(this.globals.humeEcomApiRef + 'product/pack/' + productId);
  }

  validateCartByUuid(uuid) {
    return this.http.post(this.globals.humeEcomApiRef + 'order/cart/pack/' + uuid, '', this.httpOptions);
  }

  getECartByUuid(uuid) {
    return this.http.get(this.globals.humeEcomApiRef + 'order/cart/load/' + uuid);
  }

  getEAllShoppingCartsByUser() {
    return this.http.get(this.globals.humeEcomApiRef + 'order/cart/list');
  }

  addELineToShoppingCart(obj, basicModal, quantity) {
    const isItemAlreadyInCart = false;
    // ** Check if it already has a shopping cart. If not, will initialize the shopping cart first.
    if (this.globals.cart) {
      // @ts-ignore
     this.postCartToServer(obj, basicModal, quantity, isItemAlreadyInCart);
    } else {
      this.getECart().subscribe(res => {
        this.globals.cart = res;
        this.postCartToServer(obj, basicModal, quantity, isItemAlreadyInCart);
        localStorage.setItem('cart', JSON.stringify(res));
      });
    }
  }

  postCartToServer(obj, basicModal, quantity, isItemAlreadyInCart) {
    if (this.globals.cart.uuid) {
      // @ts-ignore
      if (this.globals.cart.lines && this.globals.cart.lines.length > 0) {
        // ** If there are items in the cart
        // @ts-ignore
        this.globals.cart.lines.forEach(res => {
          if (res.productId.toString() === obj.id.toString()) {
            isItemAlreadyInCart = true;
            obj.quantity = res.quantity;
            obj.lineId = res.id;
            this.editELineOnShoppingCart(obj, 'plus', quantity);
            // document.getElementById('cart' + obj.id).innerText = 'Added';
            // basicModal.show();
            this.dataService.changeCart('add');
          }
        });
        if (!isItemAlreadyInCart) { this.submitLines(obj, basicModal, quantity); }
      } else {
        // ** When no items in the cart
        this.submitLines(obj, basicModal, quantity);
      }
    } else {
      localStorage.setItem('returnPageUrl', this.router.url);
      this.router.navigate(['/auth/login']);
    }
  }

  submitLines(obj, basicModal, quantity?) {
    const object = {
      // @ts-ignore
      cartUUID: this.globals.cart.uuid,
      productId: obj.id,
      quantity: quantity ? quantity : 1,
      lineId: ''
    };
    const myJSON = JSON.stringify(object);

    this.http.post(this.globals.humeEcomApiRef + 'order/cart/line/add', myJSON, this.httpOptions).subscribe(res1 => {
      this.updateLocalStorage(res1);
      // basicModal.show();
      this.dataService.changeCart('add');
      // document.getElementById('cart' + obj.id).innerText = 'Added';
    });
  }

  productWizardAddLines(lines) {
    if (this.globals.cart) {
      const object = {
        // @ts-ignore
        cartUUID: this.globals.cart.uuid,
        lineId: '',
        lines
      };
      const myJSON = JSON.stringify(object);
      this.http.post(this.globals.humeEcomApiRef + 'order/cart/line/multiadd', myJSON, this.httpOptions).subscribe(res1 => {
        this.updateLocalStorage(res1);
        // basicModal.show();
        this.dataService.changeCart('addWizard');
        // document.getElementById('cart' + obj.id).innerText = 'Added';
      });
    } else {
      localStorage.setItem('returnPageUrl', this.router.url);
      this.router.navigate(['/auth/login']);
    }
  }

  editELineOnShoppingCart(obj, tag, quantity) {
    const object = {
      // @ts-ignore
      cartUUID: this.globals.cart.uuid,
      productId: obj.productId ? obj.productId : obj.id,
      // tslint:disable-next-line:max-line-length
      quantity: tag === 'plus' ? (parseInt(obj.quantity) + parseInt(quantity)) : (tag === 'minus' ? (parseInt(obj.quantity) - parseInt(quantity)) : parseInt(quantity)),
      lineId: obj.lineId ? obj.lineId : obj.id
    };
    const myJSON = JSON.stringify(object);
    this.http.post(this.globals.humeEcomApiRef + 'order/cart/line/edit', myJSON, this.httpOptions).subscribe(res => {
      this.updateLocalStorage(res);
    });
  }

  removeELineOnShoppingCart(obj) {
    const object = {
      // @ts-ignore
      cartUUID: this.globals.cart.uuid,
      productId: obj.productId,
      quantity: obj.quantity,
      lineId: obj.id
    };
    const myJSON = JSON.stringify(object);
    this.http.post(this.globals.humeEcomApiRef + 'order/cart/line/remove', myJSON, this.httpOptions).subscribe(res => {
      this.updateLocalStorage(res);
      this.dataService.changeCart('remove');
    });
  }

  calculateFreightCost(obj) {
    // ** private String cartUuid;
    // private String address;
    // private String suburb;
    // private String state;
    // private int unloadMethod;
    // private float longWalk;
    // private float passUp;
    const myJSON = JSON.stringify(obj);
    return this.http.post(this.globals.humeEcomApiRef + 'order/freight', myJSON, this.httpOptions);
  }

  saveQuote(obj, tag?) {
    this.alertService.showAlertDialog('Are you sure to save your cart as a new quotation? You need to contact us' +
      ' to amend it once it is saved.', 'Save').then(res => {
      if (res) {
        this.quoteCreate(obj).toPromise().then(result => {
          this.alertService.showHtmlAlert('success', '<label class="g-font-size-17">Your new quotation number is ' +
            // @ts-ignore
            '<h4 class="g-color-primary">' + result?.quoteNumber + '</h4>Please save it for the future reference.</label>',
            true, 50000).then(res1 => {
            if (res1) {
              if (tag === 'modal') {
                this.closeModal();
              }
              this.globals.cart = null;
              localStorage.removeItem('cart');
              // @ts-ignore
              this.router.navigate(['/account/list/quotation/' + result?.quoteNumber + '?period=This month']);
            }
          });
        }).catch(error => {
          // console.log(error);
          this.alertService.showError('Something wrong happen', true);
        });
      }
    });
  }

  paymentSuccess() {
    this.globals.cart = null;
    localStorage.removeItem('cart');
    this.router.navigate(['/payment-complete']);
  }

  updateLocalStorage(cart) {
    this.globals.cart = cart;
    this.cartSubject.next(cart);
    localStorage.setItem('cart', JSON.stringify(cart));
  }
}
