import {Injectable} from '@angular/core';
import {Store} from '../../store';
import {Globals} from '../global';
import {ajax} from 'rxjs/ajax';

@Injectable()

export class NewsService {

  constructor(
    public globals: Globals,
  ) {}

  getNewsList() {
    return ajax(this.globals.humeEcomApiRef + 'info/news');
  }

  getNewsCategories() {
    // return ajax(this.globals.humeApiRef + 'news/categories');
    return ajax(this.globals.humeEcomApiRef + 'info/news/categories');
  }

  getSingleNews(id) {
    // return ajax(this.globals.humeApiRef + 'news/' + id);
    return ajax(this.globals.humeEcomApiRef + 'info/news/details/' + id);
  }

  getFeatureNews() {
    // return ajax(this.globals.humeApiRef + 'news/feature');
    return ajax(this.globals.humeEcomApiRef + 'info/news/featured');
  }

  getNewsListByCat(id) {
    // return ajax(this.globals.humeApiRef + 'news?category=' + id);
    return ajax(this.globals.humeEcomApiRef + 'info/news/category/' + id);
  }
  getBrochures() {
    // return ajax(this.globals.humeApiRef + 'news?category=' + id);
    return ajax(this.globals.humeEcomApiRef + 'info/brochures');
  }
}

