export const PLASTERBOARDS = [
  {name: 'Ceiling Board', thickness: [
      {name: '10mm', width: [
          {name: '1200', length: [
              {name: '3000', code: '10CB1230'},
              {name: '3600', code: '10CB1236'},
              {name: '4200', code: '10CB1242'},
              {name: '4800', code: '10CB1248'},
              {name: '6000', code: '10CB1260'}
            ]},
          {name: '1350', length: [
              {name: '3000', code: '10CB1330'},
              {name: '3600', code: '10CB1336'},
              {name: '4200', code: '10CB1342'},
              {name: '4800', code: '10CB1348'},
              {name: '6000', code: '10CB1360'}
            ]}
        ]}
    ]},
  {name: 'Recessed Edge Plasterboard', thickness: [
      {name: '10mm', width: [
          {name: '1200', length: [
              {name: '2400', code: '10RE1224'},
              {name: '2700', code: '10RE1227'},
              {name: '3000', code: '10RE1230'},
              {name: '3600', code: '10RE1236'},
              {name: '4200', code: '10RE1242'},
              {name: '4800', code: '10RE1248'},
              {name: '6000', code: '10RE1260'}
            ]},
          {name: '1350', length: [
              {name: '3000', code: '10RE1330'},
              {name: '3600', code: '10RE1336'},
              {name: '4200', code: '10RE1342'},
              {name: '4800', code: '10RE1348'},
              {name: '6000', code: '10RE1360'}
            ]}
        ]},
      {name: '13mm', width: [
          {name: '1200', length: [
              {name: '2400', code: '13RE1224'},
              {name: '2700', code: '13RE1227'},
              {name: '3000', code: '13RE1230'},
              {name: '3600', code: '13RE1236'},
              {name: '4200', code: '13RE1242'},
              {name: '4800', code: '13RE1248'},
              {name: '6000', code: '13RE1260'}
            ]},
          {name: '1350', length: [
              {name: '3000', code: '13RE1330'},
              {name: '3600', code: '13RE1336'},
              {name: '4200', code: '13RE1342'},
              {name: '4800', code: '13RE1348'}
            ]}
        ]}
    ]},
  {name: 'Water Resistant', thickness: [
      {name: '10mm', width: [
          {name: '1200', length: [
              {name: '2400', code: '10WR1224'},
              {name: '2700', code: '10WR1227'},
              {name: '3000', code: '10WR1230'},
              {name: '3600', code: '10WR1236'},
              {name: '4200', code: '10WR1242'}
            ]},
          {name: '1350', length: [
              {name: '3600', code: '10WR1336'}
            ]}
        ]},
      {name: '13mm', width: [
          {name: '1200', length: [
              {name: '2700', code: '13WR1227'},
              {name: '3000', code: '13WR1230'},
            ]}
        ]}
    ]},
  {name: 'Fire Rated', thickness: [
      {name: '13mm', width: [
          {name: '1200', length: [
              {name: '2400', code: '13FR1224'},
              {name: '2700', code: '13FR1227'},
              {name: '3000', code: '13FR1230'},
              {name: '3600', code: '13FR1236'}
            ]}
        ]},
      {name: '16mm', width: [
          {name: '1200', length: [
              {name: '2400', code: '16FR1224'},
              {name: '2700', code: '16FR1227'},
              {name: '3000', code: '16FR1230'},
              {name: '3600', code: '16FR1236'}
            ]}
        ]}
    ]},
  {name: 'Sound Rated', thickness: [
      {name: '10mm', width: [
          {name: '1200', length: [
              {name: '3600', code: '10SB1236'},
              {name: '4800', code: '10SB1248'}
            ]}
        ]},
      {name: '13mm', width: [
          {name: '1200', length: [
              {name: '3000', code: '13SB1230'},
              {name: '3600', code: '13SB1236'}
            ]}
        ]}
    ]},
  {name: '6mm V/B / Duraliner RE', thickness: [
      {name: '6mm', width: [
          {name: '900', length: [
              {name: '2400', code: 'JH400367'},
              {name: '3000', code: 'JH400366'}
            ]},
          {name: '1200', length: [
              {name: '1800', code: 'JH6VB1218'},
              {name: '2400', code: 'JH6VB1224'},
              {name: '2700', code: 'JH6VB1227'},
              {name: '3000', code: 'JH6VB1230'},
              {name: '3600', code: 'JH6VB1236'},
              {name: '4200', code: 'JH6VB1242'}
            ]},
          {name: '1350', length: [
              {name: '2400', code: 'JH6VB1324'},
              {name: '3000', code: 'JH6VB1330'},
              {name: '3600', code: 'JH6VB1336'},
              {name: '4200', code: 'JH6VB1342'}
            ]},
        ]}
    ]},
  {name: 'HEBEL / WALSC AAC Panel', thickness: [
      {name: '75mm', width: [
          {name: '600', length: [
              {name: '2700', code: 'WP075062700'},
              {name: '2850', code: 'WP075062850'},
              {name: '3000', code: 'WP075063000'},
              {name: '3300', code: 'WP075063300'},
              {name: '3600', code: 'WP075063600'},
              {name: '2850(TG)', code: 'WPTG075062850'},
              {name: '3000(TG)', code: 'WPTG075063000'},
              {name: '3300(TG)', code: 'WPTG075063300'},
              {name: '3600(TG)', code: 'WPTG075063600'}
            ]}
        ]}
    ]},
];
