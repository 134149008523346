import {Component, OnDestroy, OnInit} from '@angular/core';
import {Globals} from '../global';
import {Subscription} from 'rxjs';
import {HumeStoresService} from '../services/hume-stores.service';

@Component({
  selector: 'app-careers',
  templateUrl: './careers.component.html',
  // styleUrls: ['./news.component.scss']
})
export class CareersComponent implements OnInit, OnDestroy {
  imgUrl = 'https://hume-website-photo.s3-ap-southeast-2.amazonaws.com/Careers/';
  carousels = [
    { link: 'careers_at_Hume1.jpg'},
    { link: 'careers_at_Hume2.jpg'},
    { link: 'careers_at_Hume3.jpg'},
    { link: 'careers_at_Hume4.jpg'},
    { link: 'careers_at_Hume5.jpg'},
    { link: 'careers_at_Hume6.jpg'},
    { link: 'careers_at_Hume7.jpg'},
  ];
  iconList = [
    {title: '8 stores serving NSW & VIC', pic: 'HR01.jpg', link: '/contact'},
    {title: '200 employees', pic: 'HR02.jpg', link: '/about'},
    {title: '120,000 m<sup>2</sup> of warehouse & showroom space', pic: 'HR03.jpg', link: '/contact'}
  ];
  gallery = [
    {title: '', pic: 'g1.jpg'},
    {title: '', pic: 'g2.jpg'},
    {title: '', pic: 'g3.jpg'}
  ];
  branchesSub: Subscription;
  nswGeo;
  vicGeo;
  showNSW = true;

  constructor(public globals: Globals, private humeStoresService: HumeStoresService) {}

  ngOnInit () {
    this.branchesSub = this.humeStoresService.getStoresList().subscribe(res => {
      this.nswGeo = res.response.filter(data => data.state === 'NSW').reverse();
      this.vicGeo = res.response.filter(data => data.state === 'VIC');
    });
  }


  ngOnDestroy() {
    if (this.branchesSub) { this.branchesSub.unsubscribe(); }
  }
}
