<div class="g-pl-20--lg">
        <!-- Blog Minimal Blocks -->
        <article class="g-mx-15">
          <div class="g-px-100--md">
            <h3 class="g-color-black g-font-weight-600 mb-4">
              <a class="u-link-v5 g-color-black g-color-primary--hover" [routerLink]="['/news/' + item?.title | urlTransfer]">{{item?.title}}</a>
            </h3>
            <ul class="list-inline g-color-gray-dark-v4 g-font-weight-600 g-font-size-12">
              <!--<li class="list-inline-item mr-0">Alex Teseira</li>
              <li class="list-inline-item mx-2">&#183;</li>-->
              <li class="list-inline-item">{{item?.date}}</li>
              <li class="list-inline-item">
                <mdb-badge pill="true" primary="true" class="g-font-size-14">{{item?.category}}</mdb-badge>
                <!--<button class="badge g-bg-primary py-2 mx-1">{{item?.category.name}}</button>-->
              </li>
            </ul>
            <div class="btn-group" role="group" aria-label="Basic example">
            </div>
          </div>
          <div class="g-overflow-hidden u-block-hover" *ngIf="item?.bannerUrl && item?.thumbnailUrl">
            <img class="img-fluid w-100" [ngClass]="{'u-block-hover__main--zoom-v1': item?.category !== 'Events'}" *ngIf="!mobile" src="{{item?.bannerUrl }}" alt="Hume News">
            <img class="img-fluid w-100" [ngClass]="{'u-block-hover__main--zoom-v1': item?.category !== 'Events'}" *ngIf="mobile" src="{{item?.thumbnailUrl }}" alt="Hume News">
          </div>
          <div class="mt-5">
            <div class="mb-4">
              <p class="g-font-size-18 g-line-height-2 mb-0">{{item?.summary}}</p>
            </div>
            <a class="g-color-gray-dark-v2 g-color-primary--hover g-font-weight-600 g-font-size-12 g-text-underline--none--hover text-uppercase"
               [routerLink]="['/news/' + item?.title | urlTransfer]">Read more ...</a>
          </div>
        </article>
        <!-- End Blog Minimal Blocks -->
        <hr class="g-mx-15">
</div>

