import {Component, OnInit} from '@angular/core';
import {AuthService} from '../../services/auth/auth.service';
import {FormBuilder, Validators} from '@angular/forms';
import {CustomValidators} from '../../shared/ecommerce/custom-validators/custom-validators';
import {AlertService} from '../../services/alert.service';
import {ActivatedRoute} from '@angular/router';
declare var $: any;

@Component({
  selector: 'app-password-recovery',
  templateUrl: './password-recovery.component.html',
  styleUrls: ['./password-recovery.component.scss']
})

export class PasswordRecoveryComponent implements OnInit {
  showForm = true;
  // showForm = false; // for Test
  resetFormSubmitted = false;
  showPassword = false;

  uploadForm = this.fb.group({
    email: ['', Validators.email],
    mobile: ['', Validators.minLength(10)]
  });

  resetForm = this.fb.group({
    token1: ['', Validators.required], token2: ['', Validators.required], token3: ['', Validators.required],
      token4: ['', Validators.required], token5: ['', Validators.required], token6: ['', Validators.required],
    password: ['', Validators.compose([Validators.required, CustomValidators.patternValidator(/\d/, { hasNumber: true }),
      CustomValidators.patternValidator(/[A-Z]/, { hasCapitalCase: true }),
      CustomValidators.patternValidator(/[a-z]/, { hasSmallCase: true }),
      CustomValidators.patternValidator(
        /[ !@#$%^&*()_+\-=\[\]{};':"\\|,.<>\/?]/, { hasSpecialCharacters: true }),
      Validators.minLength(8)])],
    confirmPassword: ['', Validators.required]
  },
    {
      // check whether our password and confirm password match
      validator: CustomValidators.passwordMatchValidator
    });

  constructor(private authService: AuthService, private fb: FormBuilder, private alertService: AlertService,
              private route: ActivatedRoute) {
  }

  get f() { return this.resetForm; }
  get upf() { return this.uploadForm; }

  ngOnInit() {
    this.route.queryParams.subscribe(res => {
      if (res.code) {
        this.showForm = false;
        for (let i = 0; i < res.code.length; i++) {
          // ** Prefill verification code
          switch (i) {
            case 0:
              this.resetForm.controls['token1'].setValue(res.code.slice(i, i + 1));
              break;
            case 1:
              this.resetForm.controls['token2'].setValue(res.code.slice(i, i + 1));
              break;
            case 2:
              this.resetForm.controls['token3'].setValue(res.code.slice(i, i + 1));
              break;
            case 3:
              this.resetForm.controls['token4'].setValue(res.code.slice(i, i + 1));
              break;
            case 4:
              this.resetForm.controls['token5'].setValue(res.code.slice(i, i + 1));
              break;
            case 5:
              this.resetForm.controls['token6'].setValue(res.code.slice(i, i + 1));
              break;
          }
        }
      }
      if (res.email) { this.uploadForm.controls['email'].setValue(res.email); }
      if (res.mobile) { this.uploadForm.controls['mobile'].setValue(res.mobile); }
    });

    // ** Verification code input auto switch
    this.verificationInput();
    // ** End verification code input auto switch
  }

  onSubmit() {
    const formData = new FormData();
    const email = this.uploadForm.get('email').value;
    const mobile = this.uploadForm.get('mobile').value;
    if (email) {
      formData.append('email', email);

      this.authService.requestResetByEmail(formData).toPromise().then(res => {
      }).catch(error => {
        if (error.status === 200) {
          this.alertService.showSuccess('Verification code has been sent to your email.', true);
          this.showForm = false;
        } else {
          this.alertService.showError(error.error, true, 10000, 'email');
          this.showForm = true;
        }
      });
    } else {
      formData.append('mobile', mobile);
      this.authService.requestResetByMobile(formData).toPromise().then(res => {

      }).catch(error => {
        if (error.status === 200) {
          this.alertService.showSuccess('Verification code has been sent to your mobile.', true);
          this.showForm = false;
        } else {
          this.alertService.showError(error.error, true, 10000, 'mobile');
          this.showForm = true;
        }
      });
    }
  }

  resetFormSubmit() {

    this.resetFormSubmitted = true;
    const formData = new FormData();
    if (this.f.valid) {
      const token = this.f.get('token1').value.toString() + this.f.get('token2').value.toString() + this.f.get('token3').value.toString()
        + this.f.get('token4').value.toString() + this.f.get('token5').value.toString() + this.f.get('token6').value.toString();
      formData.append('token', token);
      formData.append('password', this.f.get('confirmPassword').value);

      this.authService.doReset(formData).toPromise().then(res => {
        this.alertService.showSuccess('Reset password successfully', true);
        this.authService.logoutUser();
      }).catch(error => {
        if (error.status === 200) {
          this.alertService.showSuccess('Reset password successfully', true);
          this.authService.logoutUser();
        } else {
          this.alertService.showError(error.error, true, 10000, 'verification code');
          this.showForm = false;
        }
      });
    }
  }

  click() {
    this.showForm = !this.showForm;
  }

  toggleShowPassword() {
    this.showPassword = !this.showPassword;
  }

  verificationInput() {
    $(function() {
      'use strict';

      const body = $('#tokenDiv');

      function goToNextInput(e) {
        const key = e.which;
        const t = $(e.target);
        let sib = t.next('input');

        if (key !== 9 && (key < 48 || key > 57)) {
          e.preventDefault();
          return false;
        }

        if (key === 9) {
          return true;
        }

        if (!sib || !sib.length) {
          sib = body.find('input').eq(0);
        }
        sib.select().focus();
      }

      function onKeyDown(e) {
        const key = e.which;

        if (key === 9 || (key >= 48 && key <= 57)) {
          return true;
        }

        e.preventDefault();
        return false;
      }

      function onFocus(e) {
        $(e.target).select();
      }

      body.on('keyup', 'input', goToNextInput);
      body.on('keydown', 'input', onKeyDown);
      body.on('click', 'input', onFocus);

    });
  }
}
