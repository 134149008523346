<div *ngIf="pageUrl === 'list'">
  <button *ngIf="!product?.hidePrice else quoteBtn" class="btn u-btn-outline-black g-font-weight-600 btn-border-no-shadow g-px-15"
          [ngClass]="itemsInCart?.toString().includes(product?.id?.toString()) ? 'g-color-primary' : ''"
          (click)="addToCart(product, basicModal,1)">
    <i class="fas fa-cart-plus mr-2"></i><span class="g-hidden-md-down">
    {{ itemsInCart?.toString().includes(product?.id?.toString()) ? 'In cart' : 'Add to cart'}}</span></button>
  <a class="u-icon-v1 u-icon-size--md pt-2 g-color-primary--hover g-font-size-20 rounded-circle pl-3" (click)="addToWishlist(product)">
    <!--data-toggle="tooltip" data-placement="bottom" title="Add to Wishlist">-->
    <span class="u-icon-v1 u-icon-effect-v2--hover g-color-white--hover g-rounded-3 g-mr-15 g-mb-15">
          <i class="icon-medical-022 u-line-icon-pro" [ngClass]="itemsInWish?.toString().includes(product?.id?.toString()) ? 'g-color-primary' : ''"></i>
    </span>
  </a>
</div>


<div class="row px-3" *ngIf="pageUrl === 'single'">
  <button *ngIf="!product?.hidePrice else quoteBtn" class="btn u-btn-outline-black g-px-30 g-font-weight-600 btn-border-no-shadow mr-3"
          [ngClass]="itemsInCart?.toString().includes(product?.id?.toString()) ? 'g-color-primary' : ''"
          [disabled]="addQuantity <= 0" (click)="addToCart(product, basicModal, addQuantity)">
    <i class="fas fa-cart-plus mr-2 g-hidden-xs-down"></i><span>
    {{ itemsInCart?.toString().includes(product?.id?.toString()) ? 'In cart' : 'Add to cart'}}</span></button>

  <button class="btn g-px-30 g-font-weight-600 shadow-none" (click)="addToWishlist(product)"
          [ngClass]="itemsInWish?.toString().includes(product?.id?.toString()) ? 'g-bg-primary u-btn-outline-white g-color-black--hover g-color-white' : 'hume-color-lightred--hover u-btn-outline-primary'">
    <i class="fa fa-heart g-color-lightred mr-2 g-hidden-xs-down"></i>
    {{ itemsInWish?.toString().includes(product?.id?.toString()) ? 'In the wishlist' : 'Add to wishlist'}}</button>
</div>

<div class="row" *ngIf="pageUrl === 'single-modal'">
  <button *ngIf="!product?.hidePrice else quoteBtn" class="btn u-btn-outline-black g-px-10 g-font-weight-600 btn-border-no-shadow"
          [ngClass]="itemsInCart?.toString().includes(product?.id?.toString()) ? 'g-color-primary' : ''"
          [disabled]="addQuantity <= 0" (click)="addToCart(product, basicModal, addQuantity)">
    <span>{{ itemsInCart?.toString().includes(product?.id?.toString()) ? 'In cart' : 'Add to cart'}}</span></button>
</div>


<div *ngIf="pageUrl === 'wishList'">
  <button *ngIf="!product?.hidePrice else quoteBtn" class="btn u-btn-outline-black g-px-30 g-font-weight-600 btn-border-no-shadow mr-3" (click)="addToCart(product, basicModal, 1)"
          [ngClass]="itemsInCart?.toString().includes(product?.id?.toString()) ? 'g-color-primary' : ''">
    <i class="fas fa-cart-plus mr-2 g-hidden-xs-down"></i>
    <span class="g-hidden-xs-down">{{ itemsInCart?.toString().includes(product?.id?.toString()) ? 'In cart' : 'Add to cart'}}</span>
  </button>
</div>

<!-- Shopping cart modal -->
<div mdbModal #basicModal="mdbModal" class="modal fade right hume-modal-top" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel"
     aria-hidden="true">
  <!--<app-shopping-cart-modal [basicModal]="basicModal"></app-shopping-cart-modal>-->
</div>
<!-- Shopping cart modal -->

<ng-template #quoteBtn>
  <button class="btn u-btn-outline-black g-font-weight-600 btn-border-no-shadow g-px-15" (click)="showQuoteModal(product?.id)">
    <i *ngIf="pageUrl !== 'list'" class="fa fa-pencil mr-2"></i><span class="g-hidden-md-down">
    </span>Request a quote</button>
</ng-template>


