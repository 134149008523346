<div class="single-component-container" *ngIf="projectRes">
  <!-- Promo Block -->
  <div>
  <section class="u-bg-overlay g-bg-img-hero g-bg-bluegray-opacity-0_3--after"
           [ngStyle]="!globals.isMobile ? {'background-image': 'url(' + globals.picStorageRef + projectRes?.bannerUrl + ')'} : {'background-image': 'url(' + globals.picStorageRef + projectRes?.thumbnailUrl + ')'}">
    <div class="container u-bg-overlay__inner text-center g-pt-150 g-pb-70">
      <div class="g-mb-100 about-title-overlay">
        <!--<span class="d-block g-color-white g-font-size-20 text-uppercase g-letter-spacing-5 mb-4">Hume In Syndey</span>-->
        <h1 class="g-color-white g-font-weight-700 g-font-size-20 g-font-size-50--md text-uppercase g-line-height-1_2 g-letter-spacing-10 mb-4">{{projectRes?.name}}</h1>
        <span class="d-block lead g-color-white g-letter-spacing-2" style="font-weight: 500"></span>
      </div>

      <!--<div class="g-pos-abs g-left-0 g-right-0 g-z-index-2 g-bottom-30">
        <a class="js-go-to btn u-btn-outline-white g-color-white g-bg-white-opacity-0_1 g-color-black--hover g-bg-white--hover g-font-weight-600 text-uppercase g-rounded-50 g-px-30 g-py-11" href="#!" data-target="#content">
          <i class="fa fa-angle-down"></i>
        </a>
      </div>-->
    </div>
  </section>
  </div>
  <!-- End Promo Block -->

  <!-- Portfolio Single Item -->
  <section class="container g-pt-10 g-pb-25">
    <div class="row g-mb-40">
      <div class="col-md-12 g-mb-30">
        <div class="mb-5 pt-5 g-font-size-17" [innerHTML]="pageContent">
        </div>
        <!--<a class="btn btn-md u-btn-primary g-font-size-default g-rounded-25 g-px-30 g-py-12" href="#!">Launch website</a>-->
      </div>
    </div>
  </section>
  <!-- End Portfolio Single Item -->

</div>
