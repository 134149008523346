import { Injectable } from '@angular/core';


@Injectable()
export class Globals {
  testVersion = 'V1108';
  // humeApiRef = 'https://api-web.humebuildingproducts.com.au/api/';
  humeEcomApiRef = 'https://api-live.humebuildingproducts.com.au/';
  picStorageRef = 'https://s3-ap-southeast-2.amazonaws.com/hume-website-photo/';
  docStorageRef = 'https://s3-ap-southeast-2.amazonaws.com/hume-website-documents/';
  showSubmit: boolean;
  categoriesWeb;
  webCat;
  eWebCat;
  isMobile = false;
  isArchitectural = false;
  divisionTag;
  siteKey = '6LdTJm4UAAAAAEtglEtbQqCYJvtiYJQKBhZjv-eZ';
  ecommerceDisabled = false;
  defaultImgUrl = 'https://s3-ap-southeast-2.amazonaws.com/hume-website-photo/image_placeholder.jpg';
  onlineStore = false;

  currentUrl = '';
  showHeader = true;
  showFooter = true;
  returnPageUrl = '';

 shoppingCartModal;

  token;
  cart;
  wishList = [];

  orderStep1 = true;
  orderStep2 = false;
  orderStep3 = false;

  currentUser;

  // ** Access leve: 2 - full, 1 - account, 0 - price
  // ** Type: 0 - cash sale, 1 - COD, 2 - credit account
  menuList = [
    {title: 'Account details', link: '/account/details', icon: 'fa fa-user-o', access: 'full account price', type: '0, 1, 2'},
    {title: 'Your Orders', link: '/account/list/orders', icon: 'fa fa-file-text-o', access: 'full account price', type: '0, 1, 2'},
    {title: 'Quotations', link: '/account/list/quotations', icon: 'fa fa-file-text-o', access: 'full account price', type: '0, 1, 2'},
    {title: 'Wishlist', link: '/account/wishlist', icon: 'fa fa-heart-o', access: 'full account price', type: '0, 1, 2'},
    {title: 'Contacts', link: '/account/addresses', icon: 'fa fa-vcard-o', access: 'full account price', type: '0, 1, 2'},
    {title: 'Payment History', link: '/account/payment', icon: 'fa fa-history', access: 'full', type: '2'},
    {title: 'Online Notifications', link: '/account/notifications', icon: 'fa fa-bell-o', access: 'full', type: '0, 1, 2'},
    {title: 'Extra Users', link: '/account/users', icon: 'fa fa-users', access: 'full', type: '0, 1, 2'},
    {title: 'Consent forms', link: '/account/forms', icon: 'fa fa-download', access: 'full', type: '0, 1, 2'},
    {title: 'Link Hume Account', link: '/account/link', icon: 'fa fa-exchange', access: 'full', type: '0'},
    {title: 'COD Application', link: '/account/cod', icon: 'fa fa-pencil-square-o', access: 'full', type: '0'},
  ];

  projectsList = [
    {name: 'Edmondson Park Residential (152 x3 storey townhouses)', developer: 'Growthbuilt', address: 'Lot 100, Campbelltown Rd, Edmondson Park, NSW', materials: 'Carpet throughout', time: 'Awarded - February 2020'},
    {name: '9 x3 storey townhouses', developer: 'Gencorp Pty Ltd', address: '475-477 Pacific Hwy, Asquith', materials: 'Carpet throughout', time: 'Awarded - January 2020'},
    {name: '8 apartments', developer: 'Highbuild Projects Group', address: '136 Military Rd, Neutral Bay, NSW', materials: 'Carpet to bedrooms, vinyl to living areas & lobbies', time: 'Awarded - January 2020'},
    {name: 'Aristocrat of Rose Bay Apartments (24 apartments)', developer: 'Luxcon Group', address: '520-536 Old South Head Rd, Rose Bay, NSW', materials: 'Carpet to bedrooms', time: 'Awarded - December 2019'},
    {name: 'Ultimo Public School (3 levels)', developer: 'Hindmarsh', address: '177 Wattle St, Glebe, NSW', materials: 'Carpet, vinyl & entry mats throughout', time: 'Current'},
    {name: 'Waterfall By Crown Apartments (331 apartments)', developer: 'Crown Group', address: '18-20 O’dea Ave, Waterloo, NSW', materials: 'Carpet to bedrooms & living areas', time: 'Current'},
    {name: 'Residential Development (100 x 2 storey houses)', developer: 'Smart Living Homes', address: 'Riverstone & Schofield areas, NSW', materials: 'Carpet, laminate & tiles throughout', time: 'Current'},
    {name: 'Avenida Apartments (64 apartments)', developer: 'Trinity Construction', address: '22-26 Essex St, Epping, NSW', materials: 'Carpet to bedroom areas', time: 'Completed - January 2020'},
    {name: 'Ashton Hill Apartments (101 apartments)', developer: 'HIGA (Hansen Investment Group Australia)', address: '1444-1454 Pacific Hwy, Turramurra, NSW', materials: 'Carpet to bedrooms & lobbies', time: 'Completed – November 2019'},
    {name: 'Hornsby Baptist Church (3 levels)', developer: 'Northpoint Construction Group', address: '2-4 College Crescent, Hornsby, NSW', materials: 'Carpet, vinyl, treads, tactile & entry mats throughout', time: 'Completed - October 2019'},
    {name: 'The One Apartments (74 apartments)', developer: 'Gencorp Pty Ltd', address: '1-5 Treacy St, Hursville, NSW', materials: 'Carpet to bedrooms & lobbies', time: 'Completed – October 2019'},
    {name: 'Astoria Apartments (75 apartments)', developer: 'Piper Constructions', address: '1562-1568 Canterbury Rd, Punchbowl, NSW', materials: 'Laminate to living areas', time: 'Completed – August 2019'},
    {name: 'Mays Garden Apartments (27 apartments)', developer: 'JayF Interiors Pty Ltd', address: '3-5 Robilliard St, Mays Hill, NSW', materials: 'Carpet to bedrooms & living areas', time: 'Completed – August 2019'},
    {name: 'Residential Development (4 x 2 storey townhouses)', developer: 'Piper Constructions', address: '1347-1348 Botany Rd, Botany, NSW', materials: 'Carpet to bedrooms & laminate to living areas', time: 'Completed – August 2019'},
    {name: 'The Loft Apartments (67 apartments)', developer: 'Gledhill Constructions', address: '680-684 Princess Hwy, Sutherland, NSW', materials: 'Carpet to bedrooms & lobbies', time: 'Completed – June 2019'},
    {name: 'Highview Central Apartments (59 apartments)', developer: 'Modco Constructions', address: '508-512 Kingsway, Miranda, NSW', materials: 'Carpet to bedrooms & timber to living areas', time: 'Completed – May 2019'},
    {name: 'Residential (18 apartments)', developer: 'Hofly Construction', address: '11-13 Hinkler Ave, Caringbah, NSW', materials: 'Carpet to bedrooms & timber to living areas', time: 'Completed – May 2019'},
    {name: 'Cumberland Green Apartments (135 apartments)', developer: 'Northpoint Construction Group', address: '822 Windsor Rd, Rouse Hill, NSW', materials: 'Carpet to bedrooms & lobbies', time: 'Completed – May 2019'},
    {name: 'Concord Hospital Site Office (1 level)', developer: 'Foxville Projects Group Pty Ltd', address: 'Hospital Rd, Concord, NSW', materials: 'Carpet & vinyl throughout', time: 'Completed – March 2019'},
    {name: 'Mt Gilead Retirement Village (51 apartments)', developer: 'LDW Constructions Pty Ltd', address: '72 Glendower St, Gilead, NSW', materials: 'Carpet to bedrooms, vinyl to living areas & lobbies', time: 'Completed – November 2018'},
    {name: 'Vision Apartments (89 apartments)', developer: 'Northpoint Construction Group', address: '1-3 Pretoria Parade, Hornsby, NSW', materials: 'Timber to bedrooms & living areas', time: 'Completed – June 2018'},
    {name: 'Amari Apartments (32 apartments)', developer: 'Piper Constructions', address: '38 Ocean St, North Bondi, NSW', materials: 'Carpet to bedrooms & timber to living areas', time: 'Completed – April 2018'},
    {name: 'West Connex East M4 Office (4 levels)', developer: 'Foxville Projects Group Pty Ltd', address: 'Motor Control Centre Building, Homebush, NSW', materials: 'Carpet, vinyl, treads, tactile & entry mats throughout', time: 'Completed – March 2018'},
    {name: 'CMC Markets L20 International Towers Office (1 level)', developer: 'Mayce Constructions', address: '300 Barangaroo Ave, Barangaroo, NSW', materials: 'Carpet & vinyl throughout', time: 'Completed – February 2018'},
    {name: 'Residential (26 apartments)', developer: 'Henshaw Global Sourcing', address: '2-4 Morotai Ave, Riverwood, NSW', materials: 'Carpet to bedrooms', time: 'Completed – September 2017'}
    // {name: '', developer: '', address: '', materials: '', time: ''},
  ];
  architecturalCategories = [
    {name: 'Cladding', link: 'cladding'},
    {name: 'BiPV Solar', link: 'BiPV'},
    {name: 'Insulation', link: 'insulation'},
    {name: 'Fibre cement', link: 'fibre cement'},
    {name: 'Compressed fibre cement', link: 'cfc'},
    {name: 'Flooring', link: 'flooring'},
    {name: 'Kitchens & Bathrooms', link: 'kitchens_bathrooms'},
    {name: 'AAC panels & wall systems', link: 'AAC'},
    {name: 'Doors & skylights', link: 'doors_and_skylights'},
    {name: 'Tiles', link: 'tiles'},
    {name: 'Timber products', link: 'timber_products'},
    {name: 'Render products and coatings', link: 'render'}];
  buildingCategories = [
    {name: 'Plasterboard & plaster products', link: 'plasterboard'},
    {name: 'Metals, wall & ceiling systems', link: 'metals'},
    {name: 'Ceiling tiles', link: 'ceiling_tiles'},
    {name: 'Tools & Accessories', link: 'tools_and_accesories'}];
  webCategories = [
    {name: 'AAC Panels, Wall & Flooring Systems', link: 'AAC'},
    {name: 'Architectural Facades', link: 'architectural'},
    {name: 'Ceiling Tiles', link: 'ceiling_tiles'},
    {name: 'Cladding', link: 'cladding'},
    {name: 'Doors & Skylights', link: 'doors_and_skylights'},
    {name: 'Fibre Cement', link: 'fibre_cement'},
    {name: 'Flooring', link: 'flooring'},
    {name: 'Insulation', link: 'insulation'},
    {name: 'Kitchens & Bathrooms', link: 'kitchens_bathrooms'},
    {name: 'Metal Wall & Ceiling Systems', link: 'metals'},
    {name: 'Plasterboard & Plaster Products', link: 'plasterboard'},
    {name: 'Render, Texture & Paint Systems', link: 'render'},
    {name: 'Tiles', link: 'tiles'},
    {name: 'Timber Products', link: 'timber_products'},
    {name: 'Tools & Accessories', link: 'tools_and_accesories'}
  ];
}
