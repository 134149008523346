import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {CarouselService} from '../../services/carousel.service';
import {Globals} from '../../global';
import {Subscription} from 'rxjs';
import {HumeStoresService} from '../../services/hume-stores.service';
import {Projectervice} from '../../services/project.service';

@Component({
  selector: 'app-shared-carousel',
  templateUrl: './shared-carousel.component.html',
})
export class SharedCarouselComponent implements OnInit, OnDestroy {
  carouselSub: Subscription;
  featuredProjectsSubscription: Subscription;
  carousels;
  @Input() page;
  imgUrl = this.globals.picStorageRef;

  constructor(
    public globals: Globals, private projectService: Projectervice,
    private humeStoresService: HumeStoresService,
    private carouselService: CarouselService
  ) {}

  ngOnInit() {
    if (this.page === 'home') {
      this.carouselSub = this.carouselService.getCarousel().subscribe(res => {
        this.carousels = res.response;
      });
    } else {
      this.featuredProjectsSubscription = this.projectService.getFeatureProject().subscribe( res => {
        this.carousels = res.response;
      });
    }
  }

  ngOnDestroy() {
    if (this.carouselSub) { this.carouselSub.unsubscribe(); }
  }


}
