<hr *ngIf="globals?.divisionTag === 'home'">
<div class="container-fluid">
    <div class="col-sm-12">
      <div id="partners_slider2" class="owl-carousel" style="display:block; margin:41px 0 41px;">
        <div class="row">
          <div class="item col-sm-4 col-lg-3 col-xs-6" *ngFor="let brand of brands">
            <div class="partners-logo" style="text-align:center;">
              <img class="home-partner-img" src="{{imgUrl + brand.photoUrl}}" alt="{{brand.name}}">
            </div>
          </div>
        </div>
      </div>
    </div>
  </div>
