<div class="single-component-container">
  <div class="g-min-height-450 g-mb-0 g-mb-50--sm g-min-height-600--lg " *ngIf="globals.divisionTag === 'home'">
    <app-shared-carousel [page]="'home'"></app-shared-carousel>
  </div>

  <!-- Architectural banner image -->
  <section class="u-bg-overlay g-bg-img-hero g-bg-bluegray-opacity-0_3--after mb-5" *ngIf="globals.divisionTag === 'architectural'"
           style="background-image: url('https://s3-ap-southeast-2.amazonaws.com/hume-website-photo/Carousel_2020-01-24_05-17-42.jpeg');">
    <div class="container u-bg-overlay__inner text-center g-pt-150 g-pb-70">
      <div class="g-mb-100 about-title-overlay">
        <!--<span class="d-block g-color-white g-font-size-20 text-uppercase g-letter-spacing-5 mb-4">Hume In Syndey</span>-->
        <h1 class="g-color-white g-font-weight-700 g-font-size-20 g-font-size-50--md text-uppercase g-line-height-1_2 g-letter-spacing-10 mb-4">Hume Architectural</h1>
        <span class="d-block lead g-color-white g-letter-spacing-2" style="font-weight: 500">Innovative solutions for architects and designers.</span>
      </div>
    </div>
  </section>
  <!-- End Architectural banner image -->

  <!-- Commercial banner image -->
  <section class="u-bg-overlay g-bg-img-hero g-bg-bluegray-opacity-0_3--after mb-5" *ngIf="globals.divisionTag === 'commercial'"
           style="background-image: url('https://s3-ap-southeast-2.amazonaws.com/hume-website-photo/Carousel_2020-05-12_12-13-15.jpg');">
    <div class="container u-bg-overlay__inner text-center g-pt-150 g-pb-70">
      <div class="g-mb-100 about-title-overlay">
        <!--<span class="d-block g-color-white g-font-size-20 text-uppercase g-letter-spacing-5 mb-4">Hume In Syndey</span>-->
        <h1 class="g-color-white g-font-weight-700 g-font-size-20 g-font-size-50--md text-uppercase g-line-height-1_2 g-letter-spacing-10 mb-4">Hume Commercial</h1>
        <span class="d-block lead g-color-white g-letter-spacing-2" style="font-weight: 500">Inspirational project solutions for builders and developers.</span>
      </div>
    </div>
  </section>
  <!-- End commercial banner image -->

  <div class="container">
    <!-- Description -->
    <div class="mb-3 mt-5 mt-sm-0 pb-4 text-center lead">
      <!--<div class="d-inline-block g-width-60 g-height-6 g-bg-primary mb-2"></div>-->
      <h2 class="g-font-size-25--md g-font-size-20" *ngIf="globals.divisionTag === 'home'">Hume Building Products is a leading Australian supplier of building products, tools and accessories, with warehouse stores located across Sydney and Melbourne, and a fast delivery network servicing metropolitan and regional areas.</h2>
      <h2 class="g-font-size-25--md g-font-size-20" *ngIf="globals.divisionTag === 'architectural'">The <a routerLink="/about/architectural">Hume Architectural</a> team at <a routerLink="/about">Hume Building Products</a> supplies architects, builders and developers with an exclusive range of leading brand facade products and solutions from around the world. Our team of dedicated professionals can support your project from initial design through to specification, procurement and installation.</h2>
      <h2 class="g-font-size-25--md g-font-size-20" *ngIf="globals.divisionTag === 'commercial'">The <a routerLink="/about/commercial">Hume Commercial</a> team at <a routerLink="/about">Hume Building Products</a> supplies builders, architects and developers with a wide range of building product solutions for residential, commercial, industrial or repair and remodelling projects.</h2>
    </div>
    <!-- Description -->
    <app-categories-grid></app-categories-grid>
  </div>

  <hr class="g-my-20">
  <app-home-featured-products *ngIf="globals.divisionTag === 'home'" [page]="'home'"></app-home-featured-products>
  <!-- Featured products for home page -->


  <!-- Hume commercial/architectural projects -->
  <div *ngIf="globals.divisionTag !== 'home'">
    <app-home-projects></app-home-projects>
    <hr>
  </div>
  <!-- End Hume commercial/architectural projects -->

  <!-- Catalogue section only for architectural page -->
  <div *ngIf="globals.divisionTag === 'architectural'">
    <div class="container g-pt-50--md g-pt-20 g-pb-50--md g-pb-20">
      <div class="text-center g-mb-50--md g-mb-20">
        <h1 class="text-uppercase mb-4">Catalogue</h1>
        <div class="d-inline-block g-width-60 g-height-6 g-bg-primary mb-2"></div>
      </div>
    <div class="container">
        <div class="row">
          <div class="col-12 col-md-6 text-center mb-3">
            <a [href]="archBrochure[0]?.fileUrl">
              <img class="u-shadow-v1-1" height="250" [src]="archBrochure[0]?.thumbnailUrl" [alt]="archBrochure[0]?.title">
              <h5 class="my-2 mx-auto" style="width: 250px">{{archBrochure[0]?.title}}</h5>
            </a>
          </div>
          <div class="col-12 col-md-6 text-center mb-3">
            <a [href]="claddingBrochure[0]?.fileUrl">
              <img class="u-shadow-v1-1" height="250" [src]="claddingBrochure[0]?.thumbnailUrl" [alt]="claddingBrochure[0]?.title">
              <h5 class="my-2 mx-auto" style="width: 250px">{{claddingBrochure[0]?.title}}</h5>
            </a>
          </div>
        </div>
      </div>
    </div>
    <hr class="g-my-20">
  </div>
  <!-- End Catalogue section -->

  <!-- news -->
  <app-home-news *ngIf="globals.divisionTag !== 'commercial'"></app-home-news>
  <!-- End news -->

  <div class="g-bg-white">
      <div class="container g-pt-50--md g-pt-20 g-pb-50--md g-pb-20">
        <div class="text-center g-mb-50--md g-mb-20">
        <h1 class="text-uppercase mb-4">Our Partners</h1>
        <div class="d-inline-block g-width-60 g-height-6 g-bg-primary mb-2"></div>
      </div>
      <app-home-testimonials *ngIf="globals.divisionTag === 'home'"></app-home-testimonials>
      <app-home-partners></app-home-partners>
        <hr *ngIf="globals.divisionTag == 'commercial'">
        <div class="row" *ngIf="globals.divisionTag == 'commercial'">
          <figure class="figure col-md-4">
            <img class="figure-img img-fluid" [src]="imgUrl + 'about/HIA_logo.jpg'" alt="Hume Building Products">
          </figure>
          <figure class="figure col-md-4">
            <img class="figure-img img-fluid" [src]="imgUrl + 'about/ATFA_logo.jpg'" alt="Hume Building Products">
          </figure>
        </div>
    </div>
  </div>

</div>
