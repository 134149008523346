import {Component, OnInit} from '@angular/core';
import {Globals} from '../../global';

@Component({
  selector: 'app-shopping',
  templateUrl: './shopping.component.html',
})
export class ShoppingComponent implements OnInit {

  constructor( public globals: Globals) {}

  ngOnInit() {
    // initialization of carousel
    // $.HSCore.components.HSCarousel.init('.js-carousel');
  }

}
