import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Globals} from '../../global';
import {ProductService} from '../../services/product.service';

@Component({
  selector: 'app-home-partners',
  // styleUrls: ['./home-partners.component.scss'],
  templateUrl: './home-partners.component.html'
})
export class HomePartnersComponent implements OnInit, OnDestroy {
  @Input() item;
  @Input() pageTag;
  brands;
  archBrands = [];
  brandsSub: Subscription;
  imgUrl = this.globals.picStorageRef;

  constructor (public globals: Globals,
               private productService: ProductService) {}

  ngOnInit() {
    this.brandsSub = this.productService.getBrandsByType().subscribe(res => {
      // @ts-ignore
      const result = res.response.filter(data => data.name !== 'Unassigned' && data.photoUrl !== null);
      if (this.pageTag) {
        if (this.pageTag === 'vic') {
          this.brands = result.filter(data => (data.type === 'VIC' || data.type === 'ALL') && data.seq < 90);
        } else {
          this.brands = result.filter(data => (data.type === 'NSW' || data.type === 'ALL') && data.seq < 90);
        }
      } else {
        if (this.globals.divisionTag === 'home') {
        this.brands = result.filter(data => (data.type === 'NSW' || data.type === 'ALL') && data.seq < 90);
        } else if (this.globals.divisionTag === 'architectural') {
          this.brands = result.filter(data => (data.type === 'ARCHITECTURAL') && data.seq < 90);
        } else {
          this.brands = result.filter(data => (data.type === 'COMMERCIAL') && data.seq < 90);
        }
      }
    });
  }

  ngOnDestroy() {
    if (this.brandsSub) {this.brandsSub.unsubscribe(); }
  }
}
