import {Injectable} from '@angular/core';
import {Globals} from '../global';
import {ajax} from 'rxjs/ajax';
import {map} from 'rxjs/operators';

@Injectable()

export class HumeStoresService {

  constructor(
    public globals: Globals,
  ) {}

  getStoresList() {
    return ajax(this.globals.humeEcomApiRef + 'info/branches');
  }

  getStore(id) {
    // return ajax(this.globals.humeApiRef + 'branch/' + id);
    return ajax(this.globals.humeEcomApiRef + 'info/branches/' + id);
  }

  getDivisionTag() {
    switch (this.globals.divisionTag) {
      case 'home':
        return 'main';
      case 'commercial':
        return'com';
      case 'architectural':
        return 'arch';
    }
  }
}

