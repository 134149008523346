import {Injectable} from '@angular/core';
import {Globals} from '../global';
import {ajax} from 'rxjs/ajax';

@Injectable()

export class TestimonialService {

  constructor(
    public globals: Globals,
  ) {}

  getTestimonial() {
    // return ajax(this.globals.humeApiRef + 'testimonial');
    return ajax(this.globals.humeEcomApiRef + 'info/testimonials');
  }
}

