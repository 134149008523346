<div class="single-component-container">
  <div class="container g-pt-50 g-pb-20">
    <div class="col-md-10 col-lg-8 col-xl-7 mx-auto">
      <h1 class="jumbotron-heading delivery-title">Subscribe to Hume Newsletter</h1>
      <h3 class="lead">Want to be the first to know about what is happening at Hume? Simply fill out your details below and stay up to date. It only takes a minute, subscribe now.</h3>

      <form [formGroup]="subscribeFormGroup" (ngSubmit)="onSubmit()">
        <div class="row">
          <div class="col-12 col-md-9 mt-4 mb-md-0">
            <input type="text" formControlName="name" class="form-control form-control-lg" placeholder="Name*">
            <div class="error" *ngIf="f.get('name').errors && submitted && ( f.get('name').dirty || f.get('name').touched || f.get('name').invalid)">
              <div *ngIf="f.get('name').hasError('required')">
                Name is required.
              </div>
            </div>
          </div>
          <div class="col-12 col-md-9 my-4 mb-md-0">
            <input type="text" formControlName="email" class="form-control form-control-lg" placeholder="Email*">
            <div class="error" *ngIf="f.get('email').errors && submitted && (f.get('email').dirty || f.get('email').touched)">
              <div *ngIf="f.get('email').hasError('required')">
                Email is required.
              </div>
              <div *ngIf="f.get('email').hasError('pattern')">
                Email is invalid.
              </div>
            </div>
          </div>
        </div>
          <div class="mt-4">
            <ngx-recaptcha2 #captchaElemSub
                            [siteKey] = siteKey
                            formControlName="recaptchaSub">
            </ngx-recaptcha2>
            <div class="error" *ngIf="f.get('recaptchaSub').errors && submitted">
              <div *ngIf="f.get('recaptchaSub').hasError('required')">
                Recaptcha is required.
              </div>
            </div>
            <div class="mt-4">
              <input type="checkbox" class="g-font-size-25" formControlName="marketingAgreement">
              <span class="ml-2">I agree to receive emails from Hume in accordance with the terms of the <a routerLink="/privacy-policy">Hume Privacy Policy</a>.</span>
              <div class="error" *ngIf="f.get('marketingAgreement').errors && submitted">
                <div *ngIf="f.get('marketingAgreement').hasError('required')">
                  Please agree before subscribe.
                </div>
              </div>
            </div>
            <button type="submit" mdbBtn outline="true" class="waves-effect u-btn-primary mt-3 mb-3" [ngClass]="{'disabled': !subscribeFormGroup.valid, ' ': subscribeFormGroup.valid}" mdbWavesEffect>Subscribe
            </button>
          </div>

      </form>

    </div>

  </div>


  <!-- Success Alert modal -->
  <!--<div mdbModal #successModalSub="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
        </div>
        <div class="modal-body text-center">
          <div class="alert-success g-font-size-18 my-2 mb-4">Submitted Successfully!</div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <button type="button" mdbBtn class="waves-light btn btn-hume-color" aria-label="Close" (click)="successModalSub.hide()" mdbWavesEffect>Close</button>
        </div>
      </div>
    </div>
  </div>-->
  <!-- Success Alert modal -->
</div>


