import {Injectable} from '@angular/core';
import {Globals} from '../global';
import {ajax} from 'rxjs/ajax';

@Injectable()

export class Projectervice {
  projectApi = this.globals.humeEcomApiRef + 'info/projects/';

  constructor(
    public globals: Globals,
  ) {}

  getProjectList() {
    return ajax(this.projectApi);
  }

  getSingleProject(id) {
    return ajax(this.projectApi + 'details/' + id);
  }

  getFeatureProject() {
    return ajax(this.projectApi + 'featured');
  }

}

