import {Injectable} from '@angular/core';
import {Globals} from '../global';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';



@Injectable()

export class ContactService {
  url = this.globals.humeEcomApiRef + 'info/form/contact';
  quoteUrl = this.globals.humeEcomApiRef + 'info/form/quote';
  subscriptionUrl = this.globals.humeEcomApiRef + 'info/form/subscribe';

  /*url = this.globals.humeApiRef + 'form/contact';
  quoteUrl = this.globals.humeApiRef + 'form/quote';
  subscriptionUrl = this.globals.humeApiRef + 'form/subscribe';*/
  // orderUrl = this.globals.humeApiRef + 'quote/v1';


  constructor(
    public globals: Globals,
    private http: HttpClient,
  ) {}

  formPost (myJSON, params) {
   const httpOptions = {
      params: new HttpParams().set('g-recaptcha-response', params),
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.http.post(this.url, myJSON, httpOptions);
  }

  quoteFormPost (myJSON, params) {
    const httpOptions = {
      params: new HttpParams().set('g-recaptcha-response', params),
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.http.post(this.quoteUrl, myJSON, httpOptions);
  }

  subscriptionFormPost (myJSON, params) {
    const httpOptions = {
      params: new HttpParams().set('g-recaptcha-response', params),
      headers: new HttpHeaders({
        'Content-Type':  'application/json'
      })
    };
    return this.http.post(this.subscriptionUrl, myJSON, httpOptions);
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }
}
