
<div id="blog-section" class="container g-pt-50">
  <!--Section heading-->
  <div class="text-center g-mb-50">
    <h1 class="text-uppercase mb-4">Our Projects</h1>
    <div class="d-inline-block g-width-60 g-height-6 g-bg-primary mb-2"></div>
    <p class="lead g-pt-10">
      <a class="d-inline-block g-brd-bottom g-brd-white g-font-weight-500 g-font-size-20 text-uppercase g-text-underline--none--hover g-mb-30--md"
         routerLink="/projects">More Projects...</a></p>
  </div>

  <div class="row" *ngIf="allProjects">
    <!-- Grid column -->
    <div class="col-12 mb-3 projects-img" [ngClass]="'col-md-' + (12/allProjects?.length)" *ngFor="let project of allProjects">
      <a [routerLink]="['/single-project']" [queryParams]="{name: project.name, id: project.id}">
        <div class="view overlay">
          <img [src]="imgUrl + project.thumbnailUrl" style="max-height: 496px; min-height: 265px" class="img-fluid z-depth-1 wow fadeInUp mx-auto"
               alt="Responsive image">
          <div class="mask flex-center projects-white-slight">
            <a class="g-text-underline--none--hover" [routerLink]="['/single-project']" [queryParams]="{name: project.name, id: project.id}">
              <p class="g-color-primary font-weight-bolder g-font-size-25 text-center">{{project.name}}</p>
            </a>
            <!--<button class="btn u-btn-primary waves-effect py-3" type="button">view more</button>-->
          </div>
        </div>
      </a>
    </div>
    <!-- Grid column -->

  </div>
</div>
