<div class="single-component-container">
  <div class="container g-pt-50 g-pb-20">
    <h1 class="g-mb-30 g-mb-50--md g-mb-30 g-px-5">Hume Building Products range</h1>

    <div class="row">
      <a class="col-6 g-px-25--sm g-px-5 g-mb-10 g-mb-30--sm g-height-150 g-height-275--sm" *ngFor="let item of webCat"
         [routerLink]="item?.link.length > 0 ? ((item?.type === 'SUB' &&
       globals.divisionTag === 'architectural') ? ['/cladding/' + item?.link | urlTransfer] : ['/' + item?.link | urlTransfer]) : ['/' + item?.name | urlTransfer]">
        <article class="g-bg-cover g-bg-size-cover g-bg-white-gradient-opacity-v1--after u-block-hover g-height-150 g-height-275--sm">
          <h2 class="g-font-size-22--md g-font-size-17 g-color-white g-font-weight-600 g-mb-30 stripe g-height-70--sm g-height-85 g-z-index-4">{{item?.name}}</h2>
          <div class="category-grid-img g-flex-middle g-min-height-400 g-pos-rel g-z-index-1 g-pa-30 u-block-hover__main--zoom-v1"
               [ngStyle]="{'background-image': 'url(' + globals.picStorageRef + item?.thumbnailUrl + ')'}">
            <span class="d-block g-color-white-opacity-0_8 g-font-size-11 text-uppercase mb-4"></span>
            <div class="d-inline-block">
            </div>
          </div>
        </article>
      </a>
    </div>

  </div>


  <!--<app-list-products></app-list-products>-->
</div>
