<!-- Profile Picture -->
<div class="text-center g-pos-rel g-mb-30">
  <div class="g-height-50 g-height-100--md text-capitalize g-font-size-20 font-weight-bold mx-auto px-2">
    <!--<img class="img-fluid rounded-circle" src="assets/img-temp/100x100/img1.jpg" alt="Image Decor">-->
    {{currentUser?.customer?.name}}
  </div>

  <span class="d-block g-font-weight-500 text-center">
    <mdb-badge pill="true" class="g-font-size-14 badge-success shadow-none">{{currentUser?.type === 2 ? 'Credit Account' : ( currentUser?.type === 1 ? 'COD Account' : 'Cash Sale')}}</mdb-badge>
  </span>
</div>
<!-- End Profile Picture -->

<hr class="g-brd-gray-light-v4 g-my-30">

<!-- Profile Settings List -->
<ul class="list-unstyled mb-0 g-font-size-16--md g-font-size-20 link-active">
  <li class="g-pb-20 g-pb-3--md" *ngFor="let item of globals?.menuList, let i = index">
    <a id="account-menu" class="d-block align-middle u-link-v5 g-color-black g-color-primary--hover g-bg-gray-light-v5--hover rounded g-pa-3" [ngClass]="activeLink.includes(item?.link) ? 'link-active' : ''"
       *ngIf="item?.access?.includes(currentUser?.access[0] ? currentUser?.access[0]?.toLowerCase() : 'account') && item?.type?.includes(currentUser?.type.toString())"
       [routerLink]="item?.link" (click)="changeLink(item?.link)" routerLinkActive="g-bg-gray-light-v5--active g-color-primary font-weight-bold" [routerLinkActiveOptions]="{ exact: true }" >
      <i [ngClass]="item?.icon" routerLinkActive="g-color-primary" class="u-icon-v1 g-color-gray-dark-v5 mr-2 pt-2"></i>
      <span>{{item?.title}}</span><span class="u-label g-rounded-50 g-px-15 g-bg-primary ml-4" *ngIf="item?.title === 'Wishlist'">{{globals?.wishList?.length}}</span>
    </a>
  </li>
  <!--<li class="g-pb-20 g-pb-3--md" *ngIf="currentUser?.type === 2">
    <a class="d-block align-middle u-link-v5 g-color-black g-color-primary--hover g-bg-gray-light-v5--hover rounded g-pa-3"
       [routerLink]="'/account/payment'" routerLinkActive="g-bg-gray-light-v5--active g-color-primary active">
      <i routerLinkActive="g-color-primary" class="u-icon-v1 g-color-gray-dark-v5 mr-2 pt-2 fa fa-history"></i>
      <span>Payment History</span>
    </a>
  </li>
  <li class="g-pb-20 g-pb-3--md" *ngIf="currentUser?.type === 0">
    <a class="d-block align-middle u-link-v5 g-color-black g-color-primary--hover g-bg-gray-light-v5--hover rounded g-pa-3"
       [routerLink]="'/account/link'" routerLinkActive="g-bg-gray-light-v5--active g-color-primary active">
      <i routerLinkActive="g-color-primary" class="u-icon-v1 g-color-gray-dark-v5 mr-2 pt-2 fa fa-exchange"></i>
      <span>Link Hume Account</span>
    </a>
  </li>
  <li class="g-pb-20 g-pb-3--md" *ngIf="currentUser?.type === 0">
    <a class="d-block align-middle u-link-v5 g-color-black g-color-primary--hover g-bg-gray-light-v5--hover rounded g-pa-3"
       [routerLink]="'/account/cod'" routerLinkActive="g-bg-gray-light-v5--active g-color-primary active">
      <i routerLinkActive="g-color-primary" class="u-icon-v1 g-color-gray-dark-v5 mr-2 pt-2 fa fa-pencil-square-o"></i>
      <span>COD Application</span>
    </a>
  </li>-->
</ul>
<!-- End Profile Settings List -->
