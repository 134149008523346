import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule} from '@angular/common';
import { RouterModule, Routes} from '@angular/router';
import {ProjectsComponent} from './projects.component';
import {SingleProjectComponent} from './single-project/single-project.component';
import {SharedModule} from '../shared/shared.module';

export const ROUTES: Routes = [
  { path: 'projects', component: ProjectsComponent,
    data: {
      title: 'Hume Building Products Projects',
      description: 'As a leading Australian distributor of building products, tools and accessories, ' +
        'Hume has collaborated with a great many builders and contractors including on the following recent building projects in NSW, ' +
        'Victoria and ACT.',
      ogUrl: '/projects'
    }
  },
  { path: 'single-project', component: SingleProjectComponent }
];

@NgModule({
    imports: [
        CommonModule,
        RouterModule.forChild(ROUTES),
        SharedModule
    ],
  declarations: [
    ProjectsComponent,
    SingleProjectComponent
  ],
  exports: [],
  providers: [],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ProjectsModule { }
