import {ChangeDetectorRef, Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ContactService} from '../services/contact.service';
import {Globals} from '../global';
import {ReCaptcha2Component} from 'ngx-captcha';
import {AlertService} from '../services/alert.service';
declare var $: any;

@Component({
  selector: 'app-contact',
  templateUrl: './contact.component.html',
  // styleUrls: ['./news.component.scss']
})
export class ContactComponent implements OnInit {
  submitted: boolean;
  contactFormGroup: FormGroup;
  message = '';
  public captchaSuccess = false;
  public captchaIsExpired = false;
  public captchaResponse?: string;

  public readonly siteKey = this.globals.siteKey;
  @ViewChild('captchaElem', { static: false }) captchaElem: ReCaptcha2Component;

  constructor(private formBuilder: FormBuilder, private cdr: ChangeDetectorRef,
              public globals: Globals, private alertService: AlertService,
              private contactService: ContactService) {}

  // convenience getter for easy access to form fields
  get f() { return this.contactFormGroup; }

  ngOnInit () {
    /*$(document).ready(function() {
      $(this).scrollTop(0);
    });*/

    this.contactFormGroup = new FormGroup({
      recaptcha: new FormControl('', Validators.required),
      name: new FormControl('', Validators.required),
      suburb: new FormControl(''),
      customerType: new FormControl('', Validators.required),
      source: new FormControl('', Validators.required),
      state: new FormControl('', Validators.required),
      number: new FormControl('', Validators.compose([Validators.required, Validators.minLength(8)])),
      email: new FormControl('', [Validators.required, Validators.pattern('[^ @]*@[^ @]*')]),
      type: new FormControl(0)
    });

    // this.user = this.contactFormGroup.value.message;
  }

  onSubmit() {
    this.submitted = true;
    if (this.contactFormGroup.valid) {
      this.alertService.loading('Submitting your request, please wait...');
      const recaptcha = this.contactFormGroup.value.recaptcha;
      this.contactFormGroup.value.message = this.message;
      delete this.contactFormGroup.value.recaptcha;
      const myJSON = JSON.stringify(this.contactFormGroup.value);

      this.contactService.formPost(myJSON, recaptcha).toPromise().then(
        data  => {
          this.contactFormGroup.reset();
          this.message = '';
          this.notificationFade();
          this.reset();
        },
        error  => {
          if (error.status === 202 || error.status === 200) {
            this.contactFormGroup.reset();
            this.message = '';
            this.notificationFade();
            this.reset();
          } else {
            this.alertService.showError('something wrong while submitting. Please try again later.', true);
            this.reset();
          }
        }
      );
    } else {
      this.alertService.showError('something wrong while submitting. Please try again later.', true);
      // stop here if form is invalid
      return;
    }
  }

  reset(): void {
    this.captchaElem.resetCaptcha();
  }

  handleReset(): void {
    this.captchaSuccess = false;
    this.captchaResponse = undefined;
    this.captchaIsExpired = false;
    this.cdr.detectChanges();
  }

  notificationFade() {
    setTimeout(() => {
      this.submitted = false;
      this.alertService.showSuccess('Submitted successfully.', true);
    }, 1000);
  }
}
