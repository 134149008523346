import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {Globals} from '../../global';
import {ProductService} from '../../services/product.service';

@Component({
  selector: 'app-products',
  templateUrl: './products.component.html',
  styleUrls: ['./products.component.scss']
})
export class ProductsComponent implements OnInit, OnDestroy {
  routeParamsSubscription: Subscription;
  webCatSubscription: Subscription;
  eWebCatSubscription: Subscription;
  urlData;
  webCat;
  eWebCat;

  constructor(private route: ActivatedRoute, public globals: Globals, private productService: ProductService) {
  }

  ngOnInit () {
    this.webCatSubscription = this.productService.webCat.subscribe(res => {
      if (res) {
        this.webCat = res;
      }
    });

    this.eWebCatSubscription = this.productService.eWebCat.subscribe(res => {
      if (res) {
        this.eWebCat = res;
      }
    });
    this.routeParamsSubscription = this.route.queryParams.subscribe(data => {
      this.urlData = data;
    });
  }

  ngOnDestroy() {
    if (this.routeParamsSubscription) { this.routeParamsSubscription.unsubscribe(); }
    if (this.eWebCatSubscription) { this.eWebCatSubscription.unsubscribe(); }
    if (this.webCatSubscription) { this.webCatSubscription.unsubscribe(); }
  }
}
