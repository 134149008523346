import {AfterViewInit, Component, ElementRef, Input, OnDestroy, ViewChild} from '@angular/core';
import {Globals} from '../../global';
import {HumeStoresService} from '../../services/hume-stores.service';
import {Subscription} from 'rxjs';


declare var google: any;

@Component({
  selector: 'app-location',
  templateUrl: './location.component.html'
})

export class LocationComponent implements AfterViewInit, OnDestroy {
  @Input() page;
  branchesSub: Subscription;
  nswGeo;
  vicGeo;
  showNSW = true;
  map: Object;
  marker: Object;

  constructor(
    public globals: Globals,
    private humeStoresService: HumeStoresService
  ) {}

  @ViewChild('map', { static: true }) mapRef: ElementRef;

  ngAfterViewInit() {

    this.branchesSub = this.humeStoresService.getStoresList().subscribe(res => {
      this.nswGeo = res.response.filter(data => data.state === 'NSW' && data.name.toLowerCase() !== 'sydney').reverse();
      this.vicGeo = res.response.filter(data => data.state === 'VIC' && data.name.toLowerCase() !== 'melbourne');
    });
    // used setTimeout google map is delayed in loading, in stackBlitz
    setTimeout(() => {
      this.loadingNswMap();
    }, 500);

  }

  ngOnDestroy() {
    if (this.branchesSub) {
      this.branchesSub.unsubscribe();
    }
  }

  loadingNswMap () {
    this.map = new google.maps.Map(this.mapRef.nativeElement, {
      zoom: 11,
      center: {lat: -33.8652315, lng: 150.9618945}
    });
    if (this.nswGeo && this.nswGeo.length) {
      for (let i = 0; i < this.nswGeo.length; i++) {
        const newmarker = new google.maps.Marker({
          position: {lat: this.nswGeo[i].latitude, lng: this.nswGeo[i].longitude},
          map: this.map,
          // label: nswGeo[i].id.toUpperCase()
        });
        newmarker['infowindow'] = new google.maps.InfoWindow({
          content: '<strong>' + this.nswGeo[i].name + ' Store' + '</strong>' + '<br />' + this.nswGeo[i].address
            + '<br />' + '<strong>Phone:</strong>' + this. nswGeo[i].phone
        });
        google.maps.event.addListener(newmarker, 'click', function() {
          this['infowindow'].open( this.map, this);
        });
        google.maps.event.addListener(newmarker, 'mouseout', function() {
          this['infowindow'].close( this.map, this);
        });
      }
    }

  }

  loadingVicMap () {
    this.map = new google.maps.Map(this.mapRef.nativeElement, {
      zoom: 11,
      center: {lat: -37.786251, lng: 144.930498 }
    });
    if (this.vicGeo && this.vicGeo.length) {
      for (let i = 0; i < this.vicGeo.length; i++) {
        const newmarker = new google.maps.Marker({
          position: {lat: this.vicGeo[i].latitude, lng: this.vicGeo[i].longitude},
          map: this.map,
          // icon: 'https://www.shiels.com.au/pub/media/wysiwyg/Truck.png'
        });
        newmarker['infowindow'] = new google.maps.InfoWindow({
          content: '<strong>' + this.vicGeo[i].name + ' Store' + '</strong>' + '<br />' + this.vicGeo[i].address
            + '<br />' + '<strong>Phone:</strong>' + this. vicGeo[i].phone
        });
        google.maps.event.addListener(newmarker, 'click', function() {
          const self = this;
          this['infowindow'].open( self.map, this);
        });
        google.maps.event.addListener(newmarker, 'mouseout', function() {
          const self = this;
          this['infowindow'].close( self.map, this);
        });
      }
    }
  }
  stateSelect (state) {
    if (state === 'nsw') {
      this.showNSW = true;
      this.loadingNswMap();
    } else {
      this.showNSW = false;
      this.loadingVicMap();
    }

  }
}
