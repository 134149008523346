import {Injectable} from '@angular/core';
// import {AngularFireDatabase} from '@angular/fire/database';
import {BehaviorSubject, Observable} from 'rxjs';
import {Store} from '../../../store';
import {User} from '../../auth/models/user.interface';
import {HttpClient, HttpHeaders} from '@angular/common/http';
import {Globals} from '../../global';
import {map, tap} from 'rxjs/operators';
import {AlertService} from '../alert.service';



@Injectable()

export class UserService {
  private currentUserSubject: BehaviorSubject<User>;
  public currentUser: Observable<User>;
  auth$: Observable<User>;
  headers = new HttpHeaders({
    // 'Origin': '1234',
    'Content-Type':  'application/json'
  });


  constructor(
    private store: Store,
    private http: HttpClient,
    public global: Globals,
    private alertService: AlertService
  ) { }

  getLinkProcessStatus() {
    return this.http.get(this.global.humeEcomApiRef + 'customer/user/account/link');
  }

  getSalesOrdersByPeriod(fromDate, toDate) {
    return this.http.get(this.global.humeEcomApiRef + 'customer/document/order/from/' + fromDate + '/to/' + toDate);
  }

  getQuotationsByPeriod(fromDate, toDate) {
    return this.http.get(this.global.humeEcomApiRef + 'customer/document/quote/from/' + fromDate + '/to/' + toDate);
  }

  getInvoicesBySourceOrderId(orderId) {
    return this.http.get(this.global.humeEcomApiRef + 'customer/document/invoice/source/' + orderId);
  }

  getSalesOrderDetailsByNumber(orderNumber) {
    return this.http.get(this.global.humeEcomApiRef + 'customer/document/order/details/' + orderNumber);
  }

  getQuotationDetailsByNumber(quoteNumber) {
    return this.http.get(this.global.humeEcomApiRef + 'customer/document/quote/details/' + quoteNumber);
  }

  getInvoiceDetailsByNumber(invoiceNumber) {
    return this.http.get(this.global.humeEcomApiRef + 'customer/document/invoice/details/' + invoiceNumber);
  }

  getCustomerContactList() {
    return this.http.get(this.global.humeEcomApiRef + 'customer/contact/');
  }

  getCustomerPaymentHistory() {
    return this.http.get(this.global.humeEcomApiRef + 'customer/payment/');
  }

  getCustomerCreditBalance() {
    return this.http.get(this.global.humeEcomApiRef + 'customer/balance/');
  }

  getLastStatistics() {
    return this.http.get(this.global.humeEcomApiRef + 'customer/last/');
  }

  addDeliveryContact(obj) {
    const myJSON = JSON.stringify(obj);
    return this.http.post(this.global.humeEcomApiRef + 'customer/contact/add', myJSON, {headers: this.headers});
  }

  editDeliveryContact(id, obj) {
    const myJSON = JSON.stringify(obj);
    return this.http.post(this.global.humeEcomApiRef + 'customer/contact/edit/' + id, myJSON, {headers: this.headers});
  }

  updateAccountNotificationSetting(obj) {
    // ** private int contactType;
    // private boolean marketingConsent;
    // private boolean notifyOrderConfirmation;
    // private boolean notifyOrderDespatch;
    // contactType: 0 - Email 1 - SMS
    const myJSON = JSON.stringify(obj);
    return this.http.post(this.global.humeEcomApiRef + 'customer/user/notification/update', myJSON, {headers: this.headers});
  }

  getNotificationSetting() {
    return this.http.get(this.global.humeEcomApiRef + 'customer/user/notification');
  }


  getAccountSubUser() {
    return this.http.get(this.global.humeEcomApiRef + 'customer/user/account');
  }

  manageAccountSubUser(obj, action) {
    obj.action = action;
    const myJSON = JSON.stringify(obj);
    return this.http.post(this.global.humeEcomApiRef + 'customer/user/account/manage', myJSON, {headers: this.headers});
  }

  addAccountContactLink(obj) {
    const myJSON = JSON.stringify(obj);
    return this.http.post(this.global.humeEcomApiRef + 'customer/user/account/link', myJSON, {headers: this.headers});
  }

  downloadInvoiceByNumber(invoiceNumber): any {
    return this.http.get(this.global.humeEcomApiRef + 'customer/document/invoice/download/' + invoiceNumber,
      {responseType: 'arraybuffer'})
      .pipe(map(response => {
        this.alertService.alertClose();
        const a = document.createElement('a');
        document.body.appendChild(a);
        return new Blob([(response)], {type: 'application/pdf'});
      }));
  }

  CodAccountApplication(obj) {
    const myJSON = JSON.stringify(obj);
    return this.http.post(this.global.humeEcomApiRef + 'customer/user/account/cod', myJSON, {headers: this.headers});
  }

  checkCodStatus() {
    return this.http.get(this.global.humeEcomApiRef + 'customer/user/account/cod');
  }

  trackingOrder(orderNo, phone, email) {
    return this.http.get('https://portal.humeplaster.com.au/crm/order/ovp/' + orderNo + '?phone=' + (phone ? phone : '') +
      '&email=' + (email ? email : ''));
  }

  search(keyword, allData) {
    const temp = keyword.toLowerCase();
    return allData.filter(data => {
      let result;
      for (let i = 0; i < Object.values(data).length; i ++) {
        const value = JSON.stringify(Object.values(data)[i]);
        if (value.toLowerCase().includes(temp)) {
          result = true;
          break;
        } else { result = false; }
      }
      return result;
    });
  }

}
