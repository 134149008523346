import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule} from '@angular/common';
import { RouterModule, Routes} from '@angular/router';
import {CheckoutComponent} from './checkout/checkout.component';
import {ShoppingComponent} from './containers/shopping.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {PipesModule} from '../pipe/pipes.module';
import {HomeModule} from '../home/home.module';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {CheckboxModule, MDBBootstrapModule, ModalModule} from 'angular-bootstrap-md';
import {SharedModule} from '../shared/shared.module';
import {MatCheckboxModule} from '@angular/material/checkbox';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {MatButtonModule} from '@angular/material/button';
import {MatTooltipModule} from '@angular/material/tooltip';
import { PaymentCompletedComponent } from './payment-completed/payment-completed.component';
import {AuthGuard} from '../auth/guards/auth.guard';
import {MatSelectModule} from '@angular/material/select';
import {MatListModule} from '@angular/material/list';
import {BsDatepickerModule} from 'ngx-bootstrap/datepicker';
import {TooltipConfig, TooltipModule} from 'ngx-bootstrap/tooltip';

export const ROUTES: Routes = [
  {path: 'checkout', canActivate: [AuthGuard], component: CheckoutComponent},
  { path: 'online-store',
    children : [
      // { path: '', component : ShoppingMainComponent},
      // { path: 'single-product', component : SingleProductComponent},
      // { path: 'products', component: ProductsComponent},
      { path: 'checkout', canActivate: [AuthGuard], component: CheckoutComponent },
      { path: 'complete', component: PaymentCompletedComponent },
      { path: '**', component: ShoppingComponent}
    ]
  }
];

@NgModule({
    imports: [
        RouterModule.forChild(ROUTES),
        CommonModule,
        FormsModule,
        PipesModule,
        HomeModule,
        NgbModule,
        ModalModule,
        SharedModule,
        CheckboxModule,
        MatCheckboxModule,
        NgxSkeletonLoaderModule,
        MatButtonModule,
        MatTooltipModule,
        ReactiveFormsModule,
        MatSelectModule,
        MatListModule,
        MDBBootstrapModule,
        BsDatepickerModule,
        TooltipModule
    ],
  declarations: [
    CheckoutComponent,
    ShoppingComponent,
    PaymentCompletedComponent
  ],
  exports: [
    CheckoutComponent,
    ShoppingComponent
  ],
  providers: [TooltipConfig],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ShoppingModule { }
