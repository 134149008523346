import {Component, OnInit} from '@angular/core';
import {Globals} from '../../global';

@Component({
  selector: 'app-order-form',
  templateUrl: './order-form.component.html'
})

export class OrderFormComponent implements OnInit {

  imgUrl = this.globals.picStorageRef;
  docUrl = this.globals.docStorageRef;

  constructor (public globals: Globals) {}

  ngOnInit() {
  }
}
