<!-- Grid row -->
<div id="home-grid"  class="row g-mx-minus-5 g-mb-70--md g-mb-20" *ngIf="items">
  <!-- Grid column -->

    <a [attr.class]="'col-sm-6 g-px-5 g-mb-10 ' + item?.class" *ngFor="let item of items"
       [routerLink]="item?.link.length > 0 ? ((item?.type === 'SUB' &&
       globals.divisionTag === 'architectural') ? ['/cladding/' + item?.link | urlTransfer] : ['/' + item?.link | urlTransfer]) : ['/' + item?.name | urlTransfer]"
       style="height: 330px" >
      <article class="g-bg-cover g-bg-size-cover g-bg-white-gradient-opacity-v1--after u-block-hover" style="height: 330px">
        <h2 class="h4 g-color-white g-font-weight-600 g-mb-30 stripe dark g-z-index-4">{{item?.name}}</h2>
        <div class="category-grid-img g-flex-middle g-min-height-400 g-pos-rel g-z-index-1 g-pa-30 u-block-hover__main--zoom-v1"
             [ngStyle]="{'background-image': 'url(' + imgUrl + item?.thumbnailUrl + ')'}">
          <span class="d-block g-color-white-opacity-0_8 g-font-size-11 text-uppercase mb-4"></span>
          <div class="d-inline-block">
          </div>
        </div>
      </article>
    </a>
    <a class="col-sm-6 g-px-5 g-mb-10 " routerLink="/commercial" style="height: 330px" *ngIf="globals.divisionTag !== 'architectural' && globals.divisionTag !== 'commercial'">
      <article class="g-bg-cover g-bg-size-cover g-bg-white-gradient-opacity-v1--after u-block-hover" style="height: 330px">
        <h2 class="h4 g-color-white g-font-weight-600 g-mb-30 stripe1 dark g-z-index-4 g-height-100--md g-height-130">Hume Commercial
          <span class="d-block g-font-size-16--md g-font-size-13 mt-2"> Inspirational project solutions for builders and developers. </span>
        </h2>
        <div class="category-grid-img g-flex-middle g-min-height-400 g-pos-rel g-z-index-1 g-pa-30 u-block-hover__main--zoom-v1" style="background-image: url(/assets/img/project_hc.jpg);">

          <div class="d-inline-block">
          </div>
        </div>
      </article>
    </a>
  <a class="col-sm-6 g-px-5 g-mb-10 " routerLink="/architectural" style="height: 330px" *ngIf="globals.divisionTag !== 'architectural' && globals.divisionTag !== 'commercial'">
    <article class="g-bg-cover g-bg-size-cover g-bg-white-gradient-opacity-v1--after u-block-hover" style="height: 330px">
      <h2 class="h4 g-color-white g-font-weight-600 g-mb-30 stripe1 dark g-z-index-4 g-height-100--md g-height-130">Hume Architectural
        <span class="d-block g-font-size-16--md g-font-size-13 mt-2">Innovative solutions for architects and designers.</span>
      </h2>
      <div class="category-grid-img g-flex-middle g-min-height-400 g-pos-rel g-z-index-1 g-pa-30 u-block-hover__main--zoom-v1" style="background-image: url(/assets/img/project_ha.jpg);">
        <div class="d-inline-block">
        </div>
      </div>
    </article>
  </a>
</div>
