<footer class="g-bg-main-light-v1">
  <!-- Content -->
  <div class="g-brd-bottom g-brd-secondary-light-v1">
    <div class="container g-pt-50--md g-pt-20">
      <div class="row justify-content-start g-mb-0--md">
        <div class="col-md-5 g-mb-10">
          <h2 class="h5 g-color-gray-light-v3 mb-4">Products</h2>

          <div class="row mb-2">
            <div class="col-4 g-mb-20" *ngFor="let item of items">
              <!-- Links -->
              <ul class="list-unstyled g-font-size-14 mb-0" *ngFor="let singleItem of item">
                <li class="g-mb-10">
                  <a class="u-link-v5 g-color-gray-dark-v5 g-color-primary--hover"
                     [routerLink]="singleItem?.name === 'Compressed fibre cement' || singleItem?.name === 'Building Integrated Photovoltaics' ? ['/cladding/' + singleItem?.link] : ['/' + singleItem?.link | urlTransfer]">{{singleItem?.name}}</a>
                </li>
              </ul>
              <!-- End Links -->
            </div>
          </div>
          <!-- Links -->
          <ul class="list-unstyled g-color-gray-dark-v5 row pl-3">
            <li class="media my-1 mr-3 g-font-size-16">
              <i class="d-flex mt-1 mr-3 fas fa-id-badge"></i>
              <a class="media-body" style="color: unset" href="https://portal.humeplaster.com.au">Staff Login</a>
            </li>
            <li class="media my-1 g-font-size-16">
              <i class="d-flex mt-1 mr-3 fas fa-shipping-fast"></i>
                <a class="media-body" style="color: unset" href="https://hls.humeplaster.com.au">Logistics System</a>
            </li>
          </ul>
        </div>

        <div class="col-md-3 g-mb-30--md g-mb-10">
          <h2 class="h5 g-color-gray-light-v3 mb-4">Stores</h2>

          <div class="row">
            <div class="col-6 g-mb-20" *ngFor="let storeArray of branches, let i = index">
              <!-- Links -->
              <ul class="list-unstyled g-font-size-14 mb-0" *ngFor="let store of storeArray">
                <li class="g-mb-10"><a class="u-link-v5 g-color-gray-dark-v5 g-color-primary--hover"
                    [routerLink]="['/hume-stores/' + store?.name | urlTransfer ]">{{store?.name}} {{store?.state}}</a></li>
              </ul>
              <!-- End Links -->
            </div>
          </div>
        </div>

        <div class="col-sm-5 col-md-4 ml-auto g-mb-30 g-mb-0--sm g-font-size-14">
          <h2 class="h5 g-color-gray-light-v3 mb-4">Contacts</h2>

          <!-- Links -->
          <ul class="list-unstyled g-color-gray-dark-v5">
            <li class="media my-3">
              <i class="d-flex mt-1 mr-3 g-font-size-18 fas fa-building"></i>
              <div class="media-body">
                Head office: 32 Pine Road,
                <br>
                Yennora, NSW 2161, Australia
              </div>
            </li>
            <li class="media my-3 g-font-size-16">
              <i class="d-flex mt-1 mr-3 fa fa-envelope"></i>
              <a class="media-body" style="word-break: break-word;" [href]="currentUrl?.includes('architectural') ? 'mailto:enquiry@humebuildingproducts.com.au' : (currentUrl?.includes('commercial') ? 'mailto:inquiries@humebuildingproducts.com.au' : 'mailto:info@humebuildingproducts.com.au')">
                {{currentUrl?.includes('architectural') ? 'enquiry@humebuildingproducts.com.au' : (currentUrl?.includes('commercial') ? 'inquiries@humebuildingproducts.com.au' : 'info@humebuildingproducts.com.au')}}
              </a>
            </li>
            <li class="media my-3 g-font-size-20">
              <i class="d-flex mt-2 mr-3 fa fa-phone-square"></i>
              <div class="media-body">
                <a href="tel:134863">13 4863</a>
              </div>
            </li>
            <li class="media my-3">
              <h2 class="h5 g-color-gray-light-v3 mb-2">
                <a routerLink="/subscribe">
                  <button class="btn u-btn-primary shadow-none g-rounded-25 rounded g-py-8 g-px-18 g-font-size-14" type="button" mdbBtn rounded="true" data-toggle="modal"
                           mdbWavesEffect><i class="fa fa-envelope-open g-font-size-14 g-pos-rel g-top-1 mr-2"></i>Subscribe to Hume</button>
                </a>
              </h2>
            </li>
            <li class="media my-3 g-font-size-16">
              <div class="media-body justify-content-between">
                <a class="g-color-white mr-5" routerLink="/privacy-policy">Privacy Policy</a>
                <a class="g-color-white" href="https://s3-ap-southeast-2.amazonaws.com/hume-website-documents/Hume_Order_Form.pdf" target="_blank">Order Form</a>
              </div>
            </li>
            <li class="media my-3 g-font-size-16">
              <div class="media-body">
                <a class="g-color-white" routerLink="/tos">Terms of Sale and Delivery Policy</a>
              </div>
            </li>
          </ul>
          <!-- Social Icons -->
          <ul class="list-inline mb-50">
            <h2 class="h5 g-color-gray-light-v3 mb-4">Follow Us On:</h2>
            <li class="list-inline-item g-mr-2">
              <a class="u-icon-v1 u-icon-slide-up--hover g-color-gray-dark-v4 g-color-white--hover g-bg-facebook--hover rounded" href="https://www.facebook.com/HumeBuildingProducts">
                <i class="g-font-size-18 g-line-height-1 u-icon__elem-regular fa fa-facebook"></i>
                <i class="g-color-white g-font-size-18 g-line-height-0_8 u-icon__elem-hover fa fa-facebook"></i>
              </a>
            </li>
            <li class="list-inline-item g-mx-2">
              <a class="u-icon-v1 u-icon-slide-up--hover g-color-gray-dark-v4 g-color-white--hover g-bg-youtube--hover rounded"
                 href="https://www.youtube.com/user/HumePlasterboard?feature=watch">
                <i class="g-font-size-18 g-line-height-1 u-icon__elem-regular fa fa-youtube"></i>
                <i class="g-color-white g-font-size-18 g-line-height-0_8 u-icon__elem-hover fa fa-youtube"></i>
              </a>
            </li>
            <li class="list-inline-item g-mx-2">
              <a class="u-icon-v1 u-icon-slide-up--hover g-color-gray-dark-v4 g-color-white--hover g-bg-linkedin--hover rounded"
                 href="https://www.linkedin.com/company/humebuildingproducts">
                <i class="g-font-size-18 g-line-height-1 u-icon__elem-regular fa fa-linkedin"></i>
                <i class="g-color-white g-font-size-18 g-line-height-0_8 u-icon__elem-hover fa fa-linkedin"></i>
              </a>
            </li>
            <li class="list-inline-item g-mx-2">
              <a class="u-icon-v1 u-icon-slide-up--hover g-color-gray-dark-v4 g-color-white--hover g-bg-twitter--hover rounded" href="https://twitter.com/humebuilding">
                <i class="g-font-size-18 g-line-height-1 u-icon__elem-regular fa fa-twitter"></i>
                <i class="g-color-white g-font-size-18 g-line-height-0_8 u-icon__elem-hover fa fa-twitter"></i>
              </a>
            </li>
            <li class="list-inline-item g-mx-2">
              <a class="u-icon-v1 u-icon-slide-up--hover g-color-gray-dark-v4 g-color-white--hover g-bg-instagram--hover rounded" href="https://www.instagram.com/humebuildingproducts/">
                <i class="g-font-size-18 g-line-height-1 u-icon__elem-regular fa fa-instagram"></i>
                <i class="g-color-white g-font-size-18 g-line-height-0_8 u-icon__elem-hover fa fa-instagram"></i>
              </a>
            </li>
            <li class="list-inline-item g-mx-2">
              <a class="u-icon-v1 u-icon-slide-up--hover g-color-gray-dark-v4 g-color-white--hover g-bg-pinterest--hover rounded" href="https://www.pinterest.com.au/HumeBuildingProducts/">
                <i class="g-font-size-18 g-line-height-1 u-icon__elem-regular fa fa-pinterest"></i>
                <i class="g-color-white g-font-size-18 g-line-height-0_8 u-icon__elem-hover fa fa-pinterest"></i>
              </a>
            </li>
            <li class="list-inline-item g-mx-2">
              <a class="u-icon-v1 u-icon-slide-up--hover g-color-gray-dark-v4 g-color-white--hover g-bg-primary--hover rounded"
                 [href]="currentUrl?.includes('architectural') ? 'mailto:enquiry@humebuildingproducts.com.au' : (currentUrl?.includes('commercial') ? 'mailto:inquiries@humebuildingproducts.com.au' : 'mailto:info@humebuildingproducts.com.au')">
                <i class="g-font-size-18 g-line-height-1 u-icon__elem-regular fa fa-envelope"></i>
                <i class="g-color-white g-font-size-18 g-line-height-0_8 u-icon__elem-hover fa fa-envelope"></i>
              </a>
            </li>
          </ul>
          <!-- End Social Icons -->
          <!-- End Links -->
        </div>
      </div>


      <!-- Secondary Content -->
      <!--<div class="row">
        <div class="col-md-4 g-mb-20">
         <form class="input-group u-shadow-v19 rounded">
            <input class="form-control g-brd-none g-color-gray-dark-v5 g-bg-main-light-v2 g-bg-main-light-v2--focus g-placeholder-gray-dark-v3 rounded g-px-20 g-py-8" type="email" placeholder="Enter your email">
            <span class="input-group-addon u-shadow-v19 g-brd-none g-bg-main-light-v2 g-pa-5">
                    <button class="btn u-btn-primary rounded text-uppercase g-py-8 g-px-18" type="submit">
                      <i class="fa fa-angle-right"></i>
                    </button>
            </span>
          </form>
        </div>

        <div id="footer-follow" class="col-6 col-md-3 ml-auto g-mb-20">

        </div>
      </div>-->
      <!-- End Secondary Content -->
    </div>
  </div>
  <!-- End Content -->

  <!-- Copyright -->
  <div class="container g-pt-10 g-pb-10">
    <div class="row justify-content-between align-items-center">
      <div class="col-md-12 g-mb-20">
        <p class="g-font-size-14 mb-0">© {{today | date:'yyyy'}} Hume Plasterboard Pty Ltd. All rights reserved. Hume Building Products, 32 Pine Road Yennora, NSW 2161, Australia.</p>
      </div>
    </div>
  </div>
  <!-- End Copyright -->
</footer>
