<div class="single-component-container">

  <div class="g-min-height-450 g-mb-0 g-mb-50--sm">
    <app-shared-carousel [page]="'project'"></app-shared-carousel>
  </div>
  <!-- End projects slider -->


  <div class="container text-center g-mt-50 g-mb-50">
    <h1 class="text-capitalize mb-4">Featured Building Projects</h1>
  </div>

  <!-- All projects grid -->
  <div class="container g-mb-50--md g-mb-20">

    <div class="row project-content" *ngIf="allProjects">
      <div class="col-md-4 col-12 mb-3 projects-img " *ngFor="let project of allProjects">
        <a [routerLink]="['/single-project']" [queryParams]="{name: project.name, id: project.id}">
          <div class="view overlay">
            <img [src]="imgUrl + project.thumbnailUrl" style="max-height: 496px; min-height: 265px; min-width: 100%;" class="img-fluid z-depth-1 wow fadeInUp"
                 alt="Responsive image">
            <div class="mask flex-center projects-white-slight">
              <a class="g-text-underline--none--hover" [routerLink]="['/single-project']" [queryParams]="{name: project.name, id: project.id}">
                <p class="g-color-primary font-weight-bolder g-font-size-25 text-center">{{project.name}}</p>
              </a>
              <!--<button class="btn u-btn-primary waves-effect py-3" type="button">view more</button>-->
            </div>
          </div>
        </a>
      </div>
    </div>
  </div>
  <!-- All projects grid -->

  <!-- Project list -->
  <section class="g-py-10">
    <div class="container">
      <div class="container text-center g-mt-50 g-mb-50">
        <h1 class="text-uppercase mb-4">Projects List</h1>
        <p class="lead g-color-black">
          A selection of recent projects by the Hume Building Products team, featuring comprehensive flooring supply and installation solutions  for multi-residential, schools, aged care and offices:</p>
      </div>

      <div id="project-list-content" class="row g-mb-30">
        <div class="col-lg-4 g-mb-40 g-mb-0--lg" *ngFor="let item of globals.projectsList">
          <ul class="list-unstyled mb-0">
            <li class="media u-shadow-v11 rounded g-pa-20 g-mb-10">
              <!--<div class="d-flex align-self-center g-mt-3 g-mr-15">
                <img class="g-width-40 g-height-40" src="../../assets/img-temp/logos/img1.png" alt="Image Description">
              </div>-->
              <div class="media-body g-font-size-15 g-color-black" style="min-height: 237px !important;">
                <h4 class="d-block u-link-v5 g-color-main g-color-primary--hover g-font-weight-600 mb-3" href="">{{item?.developer}}</h4>
                <div class="row mb-1 g-px-20">
                  <span class="g-mr-15">
                      <i class="fa fa-building-o g-pos-rel g-top-1 mr-2"></i>{{item?.name}}
                    </span>
                </div>
                <div class="row mb-1 g-px-20">
                  <span class="g-mr-15">
                      <i class="fa fa-map-marker g-pos-rel g-top-1 mr-2"></i>{{item?.address}}
                    </span>
                </div>
                <div class="row mb-1 g-px-20">
                  <span class="g-mr-15 font-italic">
                      <i class="fa fa-bookmark-o g-pos-rel g-top-1 mr-2"></i>{{item?.materials}}
                    </span>
                </div>
                <div class="row g-px-20">
                  <span class=" g-color-primary g-font-weight-500 g-mr-15">
                      <i class="fa fa-trophy g-color-primary g-pos-rel g-top-1 mr-2"></i>{{item?.time}}
                    </span>
                </div>
              </div>
            </li>
          </ul>
        </div>
      </div>

      <div class="text-center mb-4" *ngIf="showButton">
        <button class="btn btn-xl u-btn-outline-primary text-uppercase g-font-weight-600 g-font-size-12" (click)="expandList()">View More Projects</button>
      </div>
    </div>
  </section>
  <!-- End Project list -->


</div>
