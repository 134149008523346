<div class="single-component-container">

  <!-- Login -->
  <section class="container g-py-100">
    <div class="row justify-content-center">
      <div class="col-sm-9 col-md-7 col-lg-5">
        <div class="g-brd-around g-brd-gray-light-v3 g-bg-white rounded g-px-30 g-py-50 mb-4">
          <header class="text-center mb-5">
            <h1 class="h3 py-4 g-font-weight-400" [ngClass]="activateSuccess ? 'text-success' : 'g-color-primary'">{{displayText}}</h1>

            <p *ngIf="!displayText.includes('successfully')">We are redirecting you to the sign up page...</p>
          </header>
          <hr>
          <p class="text-center">Any issue please contact us via <a href="tel:134863">13 4863</a> or send us an email at <a href="mailto:info@humebuildingproducts.com.au">info@humebuildingproducts.com.au</a>.</p>
          <!-- Form -->
          <!--<form class="g-py-15" [formGroup]="activationForm" (ngSubmit)="onSubmit()">
            <div class="mb-5">
              <div class="input-group g-rounded-left-5">
                    <span class="input-group-prepend g-width-45 g-brd-gray-light-v3 g-color-gray-dark-v5">
                      <span class="input-group-text justify-content-center w-100 g-bg-transparent g-brd-gray-light-v3">
                        <i class="icon-electronics-005 g-font-size-20 pt-2 g-color-primary u-line-icon-pro"></i>
                      </span>
                    </span>
                <input id="formMobile" class="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-rounded-left-0 g-rounded-right-5 g-py-15 g-px-15"
                       formControlName="mobile"  placeholder="Mobile Number">
              </div>
              <div class="error" *ngIf="f.get('mobile').errors && isSubmitted && ( f.get('mobile').dirty || f.get('mobile').touched || f.get('mobile').invalid)">
                <div *ngIf="f.get('mobile').hasError('required')">
                  Mobile is required.
                </div>
              </div>
              <div class="error" *ngIf="f.get('mobile').hasError('minlength')">
                Must be at least 10 characters!
              </div>
            </div>

            <button class="btn btn-block u-btn-primary g-font-size-14 font-weight-bold text-uppercase g-py-15 g-px-25 shadow-none" [disabled]="f.invalid"
                    type="submit">Activate your account</button>
          </form>-->
          <!-- End Form -->
        </div>
      </div>
    </div>
  </section>
</div>
