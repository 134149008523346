import {Component, EventEmitter, Input, OnInit, Output} from '@angular/core';

@Component({
  selector: 'app-bread-crumb',
  templateUrl: './bread-crumb.component.html',
  styleUrls: ['./bread-crumb.component.scss']
})
export class BreadCrumbComponent implements OnInit {
  @Input() catWebName;
  @Input() page;
  @Input() subCategories;
  @Input() subCatName;
  @Output() messageEvent = new EventEmitter<object>();

  constructor() { }

  ngOnInit(): void {
  }

  triggerOpenCategory(type) {
    // openCategory(alteredCategory)
    this.messageEvent.emit({type});
  }

}
