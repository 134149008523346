import {Component, OnDestroy, OnInit, TemplateRef, ViewChild} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {ProductService} from '../../services/product.service';
import {Subscription} from 'rxjs';
import {NgbModal, ModalDismissReasons, NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';
import {Globals} from '../../global';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {DomSanitizer} from '@angular/platform-browser';
import {InventoryCategoriesService} from '../../services/inventory-categories.service';
import {ShoppingCartService} from '../../services/shopping-cart.service';
import {AuthService} from '../../services/auth/auth.service';
import {DataService} from '../../services/data.service';
import {AlertService} from '../../services/alert.service';
import {OrderPipe} from 'ngx-order-pipe';
import {HandleErrorService} from '../../services/handle-error.service';

declare var $: any;

@Component({
  selector: 'app-single-product',
  templateUrl: './single-product.component.html',
  styleUrls: ['./single-product.component.scss'],
  providers: [NgbCarouselConfig],
  styles: [`
    .star {
      font-size: 1.5rem;
      color: #d3d3d3;
    }
    .full {
      color: #ae1e37;
    }
  `]
})
export class SingleProductComponent implements OnInit, OnDestroy {
  currentUser;
  submitted: boolean;
  reviewFormGroup: FormGroup;
  product;
  productSubscription: Subscription;
  routeParamsSubscription: Subscription;
  activatedRouteSub: Subscription;
  userSub: Subscription;
  dataSub: Subscription;
  relatedProducts;
  closeResult: string;
  imgUrl = this.globals.picStorageRef;
  addQuantity = 1;
  showAlert;
  catWebName;
  subCatName;
  catType;
  wizardList = null;
  isWizardOpen = false;
  wizardId = [];
  wizardQuantity = [];
  stocks;
  multiSizeStatus;
  childrenProducts;
  quantityG = [];
  childrenProperties = [];
  selectedPic = null;
  childIndex = null;
  hidePrice = true;
  hover = [];
  hoverW = [];
  subCategories;
  modal;
  modalImgIndex;
  discountRate;
  isLoadingDone = false;

  constructor(private router: Router, private dataService: DataService,
              private alertService: AlertService, private orderPipe: OrderPipe,
              private modalService: NgbModal, private activatedRoute: ActivatedRoute,
              private productService: ProductService, private handleErrorService: HandleErrorService,
              private shoppingCartService: ShoppingCartService,
              private inventoryService: InventoryCategoriesService,
              private authService: AuthService,
              private route: ActivatedRoute,
              public globals: Globals,
              public sanitizer: DomSanitizer,
              private formBuilder: FormBuilder,
              private config: NgbCarouselConfig) {
  }

  get f () { return this.reviewFormGroup; }

  // modal window
  open(content) {
    this.modalService.open(content, {ariaLabelledBy: 'modal-basic-title'}).result.then((result) => {
      this.closeResult = `Closed with: ${result}`;
    }, (reason) => {
      this.closeResult = `Dismissed ${this.getDismissReason(reason)}`;
    });
  }

  private getDismissReason (reason: any): string {
    if (reason === ModalDismissReasons.ESC) {
      return 'by pressing ESC';
    } else if (reason === ModalDismissReasons.BACKDROP_CLICK) {
      return 'by clicking on a backdrop';
    } else {
      return  `with: ${reason}`;
    }
  }
  // modal window end

  async ngOnInit() {

    this.userSub = this.authService.currentUser.subscribe(x => { this.currentUser = x; });

    this.dataSub = this.dataService.changeCartStatus.subscribe(res => {
      if (res === 'addWizard') {
        this.wizardQuantity.forEach((item, index, arr) => { arr[index] = 0; });
        this.wizardId.forEach((item, index, arr) => { arr[index] = 0; });
        this.isWizardOpen = false;
      }
    });

    this.activatedRouteSub = this.activatedRoute.url.subscribe(res => {
      this.product = null;
      this.relatedProducts = null;
      this.routeParamsSubscription = this.route.queryParams.subscribe(data => {
        if (data?.id) {
          this.catWebName = data.catWebName;
          this.subCatName = data.subCatName;
          this.catType = data.type;
          if (data.page) { localStorage.setItem('productPage', data.page); }
          this.getDetails(data?.id);
        } else {
          const urlArray = this.router.url.split('/');
          const id = urlArray[urlArray.length - 1];
          this.getDetails(id);
        }

      });
    });

    await this.authService.refreshToken().then(res => {
      this.globals.token = res;
    });

    this.reviewFormGroup = this.formBuilder.group({
      recaptcha: ['', Validators.required],
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern('[^ @]*@[^ @]*')]],
      message: ['', Validators.required],
      rate: ['', Validators.required],
    });
  }

  priceConverter(price, sqm) {
    return price / sqm.toFixed(2);
  }

  getDetails(id) {
    this.productSubscription = this.productService.getEProductDetailsById(id).subscribe(res => {
      this.isLoadingDone = true;
      this.product = res;
      this.calDiscount();
      if (this.product.photos?.length > 1) {
        this.config.showNavigationIndicators = true;
        this.config.showNavigationArrows = true;
      } else {
        this.config.showNavigationIndicators = false;
        this.config.showNavigationArrows = false;
      }
      this.catWebName = this.product?.webCategory?.description;
      // @ts-ignore
      const packs = res?.packs;
      if (packs.length > 0) {
        this.addQuantity = packs.filter(res => res.type === 'MIN')[0]?.quantity;
      } else { this.addQuantity = 1; }
      // @ts-ignore
      if (res?.groups?.length > 0) {
        this.subCatName = this.product?.groups[0].name;
      } else {
        this.subCatName = this.product?.category?.description;
      }
      // @ts-ignore
      this.multiSizeStatus = res.multiSizeStatus;
      this.getStock();
      if (this.multiSizeStatus === 1) {
        this.productService.getChildrenProductsById(this.product.id).toPromise().then(res1 => {
          this.childrenProperties = [];
          this.isWizardOpen = false;
          for ( const property in res1 ) {
            this.childrenProperties.push(property);
          }
          this.childrenProperties.sort();
          this.childrenProducts = res1;
          this.openModal(0);
        });
      } else {
        // @ts-ignore
        this.productService.getWizardProductsById(res.id).toPromise().then(item => {
          this.wizardList = item;
          if (this.wizardList[0]) {
            if (this.wizardList[0].products.length > 0) {
              for (let i = 0; i < this.wizardList[0].products.length; i++) {
                this.wizardId.push(0);
                this.wizardQuantity.push(0);
              }
            }
          }
        });
      }

      // @ts-ignore
      this.productService.getRelatedProductsById(res.id).subscribe(res1 => {
        this.relatedProducts = [];
        // @ts-ignore
        this.relatedProducts = this.getRandomRelatedProducts(res1?.filter(item => item.id !== this.product?.id));
      });
    }, error => {
      // console.log(error);
    });
  }

  getRandomRelatedProducts(products) {
    let length;
    const num = [];
    let result = [];
    if (products.length < 5) {
      result = products;
    } else {
      length = 4;

      for (let i = 0; i < length; i ++) {
        const a = Math.floor(Math.random() * products.length);
        if (i < 1) {
          num.push(a);
          result.push(products[a]);
        } else {
          if (num.includes(a)) {
            length = length + 1;
          } else {
            num.push(a);
            result.push(products[a]);
          }
        }
      }
    }
    return result;
  }

  getRandomInt(max) {
    return Math.floor(Math.random() * Math.floor(max));
  }

  openProductWizard() {
    this.isWizardOpen = ! this.isWizardOpen;
  }

  onSubmit() {
    this.submitted = true;
    if (this.reviewFormGroup.valid) {
      const recaptcha = this.reviewFormGroup.value.recaptcha;
      delete this.reviewFormGroup.value.recaptcha;
      const myJSON = JSON.stringify(this.reviewFormGroup.value);
    } else {
      // stop here if form is invalid
      return;
    }
  }

  updateQuantity(event, index, id) {
    this.wizardId[index] = id;
    this.wizardQuantity[index] = event;
  }

  alertUpdate(event) {
    this.showAlert = event;
    // ** reset quantity number;
    if (event === 'add') {
      if (this.product?.packs.length > 0) {
        this.addQuantity = this.product?.packs.filter(res => res.type === 'MIN')[0]?.quantity;
      } else {
        this.addQuantity = 1;
      }
    }
  }

  wizardAddToCart(product) {
    const lines = [];
    if (this.quantityValidation()) {
      for (let i = 0; i < this.wizardId.length; i++) {
        if (this.wizardId[i] && this.wizardQuantity[i] > 0) {
          lines.push({
            productId: this.wizardId[i],
            quantity: this.wizardQuantity[i]
          });
        }
      }
      if (this.currentUser) {
        this.shoppingCartService.productWizardAddLines(lines);
      } else {
        this.authService.redirectToLoginPage(product.id);
      }
    } else {
      this.alertService.showBriefError('Please input valid quantity', true);
    }
  }

  quantityValidation() {
    let a = 0;
    for (let i = 0; i < this.wizardQuantity.length; i++) {
      if (this.wizardQuantity[i] < 0 || this.wizardQuantity[i] === null) {
        return false;
      }
      if (this.wizardQuantity[i] > 0) {
        a += 1;
      }
    }
    if (a > 0) {
      return true;
    } else {
      return false;
    }
  }

  openFile(url) {
    window.open(url, '_blank');
  }

  getStock() {
    if (this.product?.id) {
      this.productService.getStocksById(this.product?.id).toPromise().then(res => {
        this.stocks = res;
      }, error => {
        this.stocks = null;
      });
    }
  }

  openDetails(product) {
    this.product = null;
    this.relatedProducts = null;
    this.router.navigate(['/product/' + product.id]);
  }

  openModal(i) {
    this.wizardQuantity = [];
    const list = this.childrenProducts[this.childrenProperties[i]];
    this.selectedPic = list[0].photoUrl;
    list.forEach( res2 => {
      this.wizardQuantity.push(0);
    });
    this.wizardList = [{'products': list}];
    this.childIndex = i;
    this.isWizardOpen = ! this.isWizardOpen;
    // this.modalService.open(longContent, { scrollable: true });
  }

  plusQuantity(i) {
    this.quantityG[i] += 1;
  }

  minusQuantity(i) {
    this.quantityG[i] -= 1;
  }

  delItem(item) {
    this.alertService.confirm().then(res => {
      if (res) {
        this.shoppingCartService.removeELineOnShoppingCart(item);
      }
    });
  }

  showUnitPrice() {
    this.hidePrice = !this.hidePrice;
  }

  breadCrumbMessage($event) {
    this.productService.getEProductsWebCatDetails(this.product?.webCategory?.code).toPromise().then(res => {
        if (this.product?.groups?.length > 0) {
          // @ts-ignore
          this.subCategories = res.productGroups;
          this.subCategories = this.orderPipe.transform(this.subCategories, 'description');
        } else {
          // @ts-ignore
          this.subCategories = res.ecomCategories;
          this.subCategories = this.orderPipe.transform(this.subCategories, 'name');
        }
      },
      error => {
        this.handleErrorService.subscribeErrorUnAuth(error, this.router.url);
      });
  }

  zoomImg(index) {
    this.modalImgIndex = index;
    const photo = this.product.photos[index];
    const modalImg = document.getElementById('img01');
    this.modal = document.getElementById('imgModal');
    this.modal.addEventListener('click', (event) => {
      if (!event.target.className.includes('arrow-icon')) {
        this.modal.style.display = 'none';
      }
    });
    this.modal.style.display = 'block';
    // @ts-ignore
    modalImg.src = photo?.includes('https://') ? photo : this.imgUrl + photo;
  }

  nextImg() {
    const modalImg = document.getElementById('img01');
      if (this.modalImgIndex < this.product.photos?.length - 1) {
        this.modalImgIndex += 1;
        const photo = this.product.photos[this.modalImgIndex];
        // @ts-ignore
        modalImg.src = photo?.includes('https://') ? photo : this.imgUrl + photo;
      }
  }

  preImg() {
    const modalImg = document.getElementById('img01');
    if (this.modalImgIndex > 0) {
      this.modalImgIndex -= 1;
      const photo = this.product.photos[this.modalImgIndex];
      // @ts-ignore
      modalImg.src = photo?.includes('https://') ? photo : this.imgUrl + photo;
    }
  }

  calDiscount() {
    this.discountRate = Math.round((1 - this.product?.grossPrice / this.product?.msrp) * 100) + '%';
  }

  calDiscountRel(product) {
    return Math.round((1 - product?.grossPrice / product?.msrp) * 100) + '%';
  }

  ngOnDestroy() {
    if (this.productSubscription) { this.productSubscription.unsubscribe(); }
    if (this.routeParamsSubscription) { this.routeParamsSubscription.unsubscribe(); }
    if (this.dataSub) { this.dataSub.unsubscribe(); }
    if (this.userSub) { this.userSub.unsubscribe(); }
    if (this.activatedRouteSub) { this.activatedRouteSub.unsubscribe(); }
  }
}
