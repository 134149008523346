import {Injectable} from '@angular/core';
import {Globals} from '../global';
import {HttpClient, HttpErrorResponse, HttpHeaders, HttpParams} from '@angular/common/http';
import {catchError} from 'rxjs/operators';
import {throwError} from 'rxjs';
import {HandleErrorService} from './handle-error.service';



@Injectable()

export class SearchService {
  // url = this.globals.humeApiRef + 'search';
  url = this.globals.humeEcomApiRef + 'product/search/';


  constructor(
    public globals: Globals, private handleErrorService: HandleErrorService,
    private http: HttpClient,
  ) {}

  searchPost (params) {
    return this.http.get(this.url + params).pipe( catchError(this.handleError) );
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
        this.handleErrorService.subscribeErrorUnAuth(error, '');
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }
}
