
export const NSWBRANCHES = [
  { id: 'alx', geo: {lat: -33.904533, lng: 151.189076},
    name: 'Alexandria Store', link: 'hume-alexandria', address: '63 Bourke Rd,' + '<br />' + 'Alexandria, NSW 2015',
    add: '63 Bourke Rd, Alexandria, NSW 2015', phone: '13 4863', isSGR: false,
    nearby: {lak: true, sil: false, yen: false, sgr: false, pym: false, alx: false, pres: false}
    },
  { id: 'pym', geo: {lat: -33.7510188, lng: 151.1409312},
    name: 'Pymble Store', link: 'hume-pymble', address: '14-16 Suakin St,' + '<br />' + 'Pymble, NSW 2073',
    add: '14-16 Suakin St, Pymble, NSW 2073', phone: ' 13 4863',  isSGR: false,
    nearby: {lak: false, sil: true, yen: false, sgr: false, pym: false, alx: false, pres: false}
    },
  { id: 'yen', geo: {lat: -33.8652315, lng: 150.9618945},
    name: 'Yennora Store', link: 'hume-yennora', address: '32 Pine Road,' + '<br />' + 'Yennora, NSW 2161',
    add: '32 Pine Road, Yennora, NSW 2161', phone: ' 13 4863',  isSGR: false,
    nearby: {lak: false, sil: false, yen: false, sgr: true, pym: false, alx: false, pres: true}
    },
  { id: 'lak', geo: {lat: -33.9115076, lng: 151.0725646},
    name: 'Lakemba Store', link: 'hume-lakemba', address: '6 Frazer Street,' + '<br />' + 'Lakemba, NSW 2195',
    add: '6 Frazer Street, Lakemba, NSW 2195', phone: ' 13 4863',  isSGR: false,
    nearby: {lak: false, sil: false, yen: false, sgr: false, pym: false, alx: true, pres: false}
    },
  { id: 'sil', geo: {lat: -33.8280715, lng: 151.046231},
    name: 'Silverwater Store', link: 'hume-silverwater', address: '9 Blaxland Street,' + '<br />' + 'Silverwater, NSW 2128',
    add: '9 Blaxland Street, Silverwater, NSW 2128', phone: ' 13 4863',  isSGR: false,
    nearby: {lak: false, sil: false, yen: false, sgr: true, pym: true, alx: false, pres: false}
    },
  { id: 'pres', geo: {lat: -33.9391427, lng: 150.867209},
    name: 'Prestons Store', link: 'hume-prestons', address: '7-9 Yato Road,' + '<br />' + 'Prestons, NSW 2170',
    add: '7-9 Yato Road, Prestons, NSW 2170', phone: ' 13 4863',  isSGR: false,
    nearby: {lak: false, sil: false, yen: true, sgr: false, pym: false, alx: false, pres: false}
  }
];

export const VICBRANCHES = [
  { id: 'pre', geo: {lat: -37.7490777, lng: 145.0291443},
    time: { weekdays: 'Mon - Fri,' + '<p>' + '06Am to 04:30PM' + '</p>', sat: 'Saturday,' + '<p>' + '06Am to 03:30PM' + '</p>' },
    name: 'Preston Store', link: 'hume-preston-vic',
    address: '1C Bell Street,' + '<br />' + 'Preston, VIC 3072',
    add: '1C Bell Street, Preston, VIC 3072', phone: '13 4863', nearby: {pre: false, sun: true}
    },
  { id: 'sun', geo: {lat: -37.8064196, lng: 144.8282324},
    time: { weekdays: 'Mon - Fri,' + '<p>' + '06Am to 03:00PM' + '</p>', sat: 'Saturday,' + '<p>' + '06Am to 03:30PM' + '</p>' },
    name: 'Sunshine West Store', link: 'hume-sunshine-west', address: '540 Somerville Road,' + '<br />' + 'Sunshine West, VIC 3020',
    add: '540 Somerville Road, Sunshine West, VIC 3020', phone: '13 4863', nearby: {pre: true, sun: false}
  }
];
