import {Component, ElementRef, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from '../../services/auth/auth.service';
import {ProductService} from '../../services/product.service';
import {Store} from '../../../store';
import {Observable, Subject, Subscription} from 'rxjs';
import {Globals} from '../../global';
import {Projectervice} from '../../services/project.service';
import {HumeStoresService} from '../../services/hume-stores.service';
import {SearchService} from '../../services/search.service';
import {ModalDirective} from 'angular-bootstrap-md';
import {TranslateService} from '@ngx-translate/core';
import {GetLocalStorageService} from '../../services/get-local-storage.service';
import {InventoryCategoriesService} from '../../services/inventory-categories.service';
import {debounceTime, distinctUntilChanged, switchMap} from 'rxjs/operators';
import {OrderPipe} from 'ngx-order-pipe';
import {Location} from '@angular/common';

declare var $: any;
declare var google: any;

@Component({
  selector: 'app-header',
  styleUrls: ['./app-header.component.scss'],
  templateUrl: './app-header.component.html',
  // changeDetection: ChangeDetectionStrategy.OnPush
})
export class AppHeaderComponent implements OnInit, OnDestroy {
  locationTitle = '';
  categories;
  isLoggedIn = false;
  projectsSubscription: Subscription;
  branchesSubscription: Subscription;
  searchSubscription: Subscription;
  categoriesSubscription: Subscription;
  userSubscription: Subscription;
  webCatSubscription: Subscription;
  eWebCatSubscription: Subscription;
  tokenSubscription: Subscription;
  clearanceSub: Subscription;
  branches;
  projects;
  topBarExcludeClass;
  topBarClass;
  imgUrl = this.globals.picStorageRef;
  distance = 0;
  nearestBranch;
  nearestBranchId;
  keywords;
  searchResult$: Observable<any>;
  filterSearchResult = null;
  noResult = false;
  screenHeight;
  screenWidth;
  currentUser;
  hover = [];
  archiCategories = [
    {name: 'Aluminium Cladding', link: '/cladding/aluminium'},
    {name: 'BiPV', link: '/cladding/BiPV'},
    {name: 'Ceramic Cladding', link: '/cladding/ceramic_cladding'},
    {name: 'Equitone', link: '/cladding/cfc'},
    {name: 'GRC Glassfibre Reinforced Concrete', link: '/cladding/GRC'},
    {name: 'Terracotta Cladding', link: '/cladding/terracotta_cladding'},
  ];
  commCategories = [
    {name: 'Ceiling Tiles', link: '/ceiling_tiles'},
    {name: 'Cladding', link: '/cladding'},
    {name: 'Doors & Skylights', link: '/doors_and_skylights'},
    {name: 'Fibre Cement', link: '/fibre_cement'},
    {name: 'Flooring', link: '/flooring'},
    {name: 'Installation Services', link: '/installation_services'},
    {name: 'Insulation', link: '/insulation'},
    {name: 'Kitchens', link: '/kitchens'},
    {name: 'Bathrooms', link: '/bathrooms'},
    {name: 'Render, Texture & Paint', link: '/render'},
    {name: 'Tiles', link: '/tiles'},
    {name: 'Timber Products', link: '/timber'},
  ];
  isEOFYAvailable = false;

  // ** menu test
  subCategories;
  // ** menu test

  private searchTerms = new Subject<string>();

  @ViewChild('searchBox2') searchField: ElementRef;
  @ViewChild('frame', { static: true }) frame: ModalDirective;
  @ViewChild('alertModal', { static: true }) alertModal: ModalDirective;

  constructor(
    private translateService: TranslateService, private location: Location,
    private router: Router, private inventoryCatServices: InventoryCategoriesService,
    private store: Store, private orderPipe: OrderPipe, public globals: Globals,
    private authService: AuthService,
    private productService: ProductService,
    private projectService: Projectervice,
    private humeStoresService: HumeStoresService,
    private searchService: SearchService,
    private ls: GetLocalStorageService,
    // private ref: ChangeDetectorRef
    ) {
    this.globals.divisionTag = this.ls.getLocalStorage('divisionTag');
    this.globals.wishList = JSON.parse(this.ls.getLocalStorage('wishList'));
  }

  async ngOnInit() {
    this.userSubscription = this.authService.currentUser.subscribe(x => {
      this.currentUser = x;
      this.filterSearchResult = null;
      this.keywords = null;
    });

    this.clearanceSub = this.productService.clearance.subscribe(async res => {
      const temp = res.data ? res.data : res;
      this.isEOFYAvailable = temp?.length > 0;
    });

    this.eWebCatSubscription = this.productService.eWebCat.subscribe(async res => {
      this.globals.eWebCat = res;
      this.globals.eWebCat?.forEach(res1 => res1.menu = false);
      this.globals.eWebCat?.forEach(res1 => {
        res1.link = this.globals.webCat?.filter(res2 => res2.id === res1.id)[0]?.link;
      });

      // ** Fetching sub categories in each web categories.
      this.globals.eWebCat = this.orderPipe.transform(this.globals.eWebCat, 'name');
      for (let i = 0; i < this.globals.eWebCat?.length; i ++) {
        this.globals.eWebCat[i].subCategories =  await new Promise(resolve => {
          this.productService.getEProductsWebCatDetails(this.globals.eWebCat[i]?.id).toPromise().then(res1 => {
              let subCategories;
              // @ts-ignore
              if (this.globals.eWebCat[i]?.useGrouping) {
                // @ts-ignore
                subCategories = this.orderPipe.transform(res1.productGroups, 'description');
              } else {
                // @ts-ignore
                subCategories = this.orderPipe.transform(res1.ecomCategories, 'name');
              }
              resolve(subCategories);
            },
            error => {
              // console.log(error);
            });
        });
      }
    });

    this.tokenSubscription = this.authService.token.subscribe(res1 => {
      if (res1) {
        this.globals.token = res1;
      }
    });

    document.addEventListener('click', function(event) {
      const container = document.getElementById('searchWindow');
      const container1 = document.getElementById('search-input');
      const menuContainer = document.getElementById('navBar');
      // @ts-ignore
      if (!container?.contains(event.target) && !container1?.contains(event.target)) {
        container.style.display = 'none';
      }
      // @ts-ignore
      if (!menuContainer?.contains(event.target)) {
        $('#navBar').collapse('hide');
      }
    });

    document.addEventListener('touchstart', (event) => {
      const menuContainer = document.getElementById('navBar');
      // @ts-ignore
      if (!menuContainer?.contains(event.target)) {
        $('#navBar').collapse('hide');
      }
    });

    this.searchResult$ = this.searchTerms.pipe(
      // wait 300ms after each keystroke before considering the term
      debounceTime(500),

      // ignore new term if same as previous term
      distinctUntilChanged(),

      // switch to new search observable each time the term changes
      switchMap((term: string) => this.searchService.searchPost(term)),
    );

    this.searchSubscription = this.searchResult$.subscribe(res => {
      this.showSearchResult(res);
    });

    this.getScreenSize();
    window.addEventListener('resize', () => { this.getScreenSize(); });
    if (window.screen.width < 600) {
      this.topBarExcludeClass = 'g-py-1';
      this.topBarClass = 'g-py-0';
    } else {
      this.topBarExcludeClass = 'g-py-1';
      this.topBarClass = 'g-py-0';
    }

    this.projectsSubscription = this.projectService.getFeatureProject().subscribe( res => {
      this.projects = res.response.slice(0, 3);
    });

    this.branchesSubscription = this.humeStoresService.getStoresList().subscribe(res => {
      this.branches = res.response.filter(store => store.name.toLowerCase() !== 'sydney' && store.name.toLowerCase() !== 'melbourne');
      this.findLocation();
    });

  }

  getScreenSize() {
    this.screenHeight = window.innerHeight;
    this.screenWidth = window.innerWidth;
  }

  // Push a search term into the observable stream.
  search(term: string): void {
    this.filterSearchResult = null;
    term = term.split(' ').join('%20').split('/').join('%2F');
    if (term.length >= 2) {
      this.searchTerms.next(term);
    }
  }

  showSearchResult(res) {
    this.openSearchWindow();
    this.filterSearchResult = res;
    if (res.length > 0) {
      this.noResult = false;
    } else {
      setTimeout( () => {
        this.noResult = true;
      }, 300 );
    }
    // this.frame.show();
    // this.searchField.nativeElement.focus();
  }

  closeSearchWindow() {
    const container = document.getElementById('searchWindow');
    container.style.display = 'none';
  }

  openSearchWindow() {
    const container = document.getElementById('searchWindow');
    container.style.display = 'block';
  }

  clearSearch() {
    this.noResult = false;
    this.keywords = '';
    this.filterSearchResult = null;
  }

  findLocation() {
    this.distance = 0;
    if (navigator.geolocation && this.branches) {
      if (this.branches) {
        navigator.geolocation.getCurrentPosition(position => {
          const origin = {lat: position.coords.latitude, lng: position.coords.longitude};
          for (let i = 0; i < this.branches.length; i++) {
            const destinationName = this.branches[i].name;
            const destinationId = this.branches[i].id;
            const destination = {lat: this.branches[i].latitude, lng: this.branches[i].longitude};
            this.distanceMatrix(origin, destination, destinationName, destinationId);
          }
        });
      }
    } else {
    }
  }

  distanceMatrix(origin, destination, destinationName, destinationId) {
    let element;
    const service = new google.maps.DistanceMatrixService();
    service.getDistanceMatrix({
      origins: [origin],
      destinations: [destination],
      travelMode: google.maps.TravelMode.DRIVING,
      unitSystem: google.maps.UnitSystem.METRIC,
    }, (response, status) => {
      if ( status === 'OK' ) {
        element = response.rows[0].elements[0];
        if (this.distance === 0 || this.distance > element.distance.value) {
          this.distance = element.distance.value;
          this.nearestBranch = destinationName;
          this.nearestBranchId = destinationId;
        }
        this.locationTitle = '';
      }
    });
  }

  closeMenu() {
    $('#navBar').collapse('hide');
  }

  // Generate Fake Object Array
  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }

  onlineStore() {
    // this.globals.onlineStore = true;
    this.router.navigate(['/order']);
    // this.alertModal.show();
  }

  // Switching between languages
  switchLanguage(language: string) {
    this.translateService.use(language);
  }

  signOut() {
    this.authService.logoutUser();
  }

  ngOnDestroy() {
    if (this.tokenSubscription) { this.tokenSubscription.unsubscribe(); }
    if (this.projectsSubscription) { this.projectsSubscription.unsubscribe(); }
    if (this.branchesSubscription) { this.branchesSubscription.unsubscribe(); }
    if (this.searchSubscription) { this.searchSubscription.unsubscribe(); }
    if (this.categoriesSubscription) { this.categoriesSubscription.unsubscribe(); }
    if (this.userSubscription) { this.userSubscription.unsubscribe(); }
    if (this.webCatSubscription) { this.webCatSubscription.unsubscribe(); }
    if (this.eWebCatSubscription) { this.eWebCatSubscription.unsubscribe(); }
    if (this.clearanceSub) { this.clearanceSub.unsubscribe(); }
  }

}
