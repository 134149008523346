import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Globals} from '../../../global';
import {ShoppingCartService} from '../../../services/shopping-cart.service';
import Swal from 'sweetalert2';
import {Subscription} from 'rxjs';
import {AuthService} from '../../../services/auth/auth.service';
import {UserService} from '../../../services/user/user.service';

@Component({
  selector: 'app-shopping-cart-modal',
  templateUrl: './shopping-cart-modal.component.html',
  styleUrls: ['./shopping-cart-modal.component.scss']
})
export class ShoppingCartModalComponent implements OnInit, OnDestroy {
  @Input() basicModal;
  quantity;
  userSub: Subscription;
  currentUser;

  constructor(public globals: Globals, private shoppingCartService: ShoppingCartService, private authService: AuthService) {
    this.userSub = this.authService.currentUser.subscribe(x => {
      this.currentUser = x;
    });
  }

  ngOnInit(): void {}

  /*calcTotal() {
    return this.globals.cart.reduce((acc, prod) => acc += prod.price * prod.quantity, 0);
  }*/

  closeModal() {
    this.shoppingCartService.closeModal();
  }

  openAlert() {
    Swal.fire({
      title: 'Are you sure to empty the cart?',
      text: 'You won\'t be able to revert this!',
      icon: 'warning',
      showCancelButton: true,
      confirmButtonColor: '#3085d6',
      cancelButtonColor: '#d33',
      confirmButtonText: 'Yes, empty the cart!'
    }).then((result) => {
      if (result.value) {
        Swal.fire(
          'Done!',
          'Your cart is empty.',
          'success'
        );
      }
    });
  }

  saveQuote() {
    const submitInfo = {
      // @ts-ignore
      cartUuid: this.globals.cart.uuid,
      orderNumber: '',
      orderType: 0, // type 0 is pickup
      instructions: '',
      dueDate: this.getToday(),
      timeSlot: 0,
      pickupDetails: {
        pickupBy: '',
        strap: 0,
        wrap: 0,
        onPallet: 0,
        craneLimit: 0,
        branch: 'YEN'
      }
    };
    this.shoppingCartService.saveQuote(submitInfo, 'modal');
  }

  getToday() {
    const date = new Date();
    let monthString;
    let dateString;
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    if (month < 10) {
      monthString = '0' + month.toString();
    } else { monthString = month.toString(); }
    if (day < 10) {
      dateString = '0' + day.toString();
    } else { dateString = day.toString(); }
    return  year.toString() + '-' + monthString + '-' + dateString;
  }

  ngOnDestroy() {
    if (this.userSub) { this.userSub.unsubscribe(); }
  }

}
