<form class="modal-content" [formGroup]="contactFormGroup" (ngSubmit)="onSubmit()">
  <div class="modal-header text-center">
    <h4 class="modal-title w-100 font-weight-bold">Request a Quote</h4>
    <button type="button" class="close" data-dismiss="modal" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body mx-3">

    <div class="row">
      <div class="col-12 form-group g-mb-20">
        <label><span class="g-color-primary">*</span>Are you enquiring as a:</label>
        <select class="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3
                g-brd-primary--hover rounded g-py-13 g-px-15" formControlName="customerType" placeholder="Are you enquiring as a">
          <option value="1">Home Owner</option>
          <option value="2" selected>Building Professional</option>
          <option value="3">Architect or Designer</option>
          <option value="4">Distributor</option>
          <option value="5">Installer</option>
          <option value="6">Other</option>
        </select>
        <div class="error" *ngIf="f.get('customerType').errors && submitted && ( f.get('customerType').dirty || f.get('customerType').touched || f.get('customerType').invalid)">
          <div *ngIf="f.get('customerType').hasError('required')">
            This field is required.
          </div>
        </div>
      </div>

      <div class="col-12 form-group g-mb-20">
        <input class="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-brd-primary--hover rounded g-py-13 g-px-15" type="text" formControlName="name" placeholder="Name*">
        <div class="error" *ngIf="f.get('name').errors && submitted && ( f.get('name').dirty || f.get('name').touched || f.get('name').invalid)">
          <div *ngIf="f.get('name').hasError('required')">
            Name is required.
          </div>
        </div>
      </div>

      <div class="col-12 form-group g-mb-20">
        <input class="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-brd-primary--hover rounded g-py-13 g-px-15" type="text" formControlName="state"  placeholder="State*">
        <div class="error" *ngIf="f.get('state').errors && submitted && (f.get('state').dirty || f.get('state').touched)">
          <div *ngIf="f.get('state').hasError('required')">
            State is required.
          </div>
        </div>
      </div>

      <div class="col-12 form-group g-mb-20">
        <input class="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-brd-primary--hover rounded g-py-13 g-px-15" type="text" formControlName="phone" placeholder="Phone*">
        <div class="error" *ngIf="f.get('phone').errors && submitted && ( f.get('phone').dirty || f.get('phone').touched)">
          <div *ngIf="f.get('phone').hasError('required')">
            Phone is required.
          </div>
          <div *ngIf="f.get('phone').hasError('minlength')">
            Phone must be at least 8 characters long.
          </div>
        </div>
      </div>

      <div class="col-12 form-group g-mb-20">
        <input class="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-brd-primary--hover rounded g-py-13 g-px-15" type="email"  formControlName="email"  placeholder="Email*" >
        <div class="error" *ngIf="f.get('email').errors && submitted && (f.get('email').dirty || f.get('email').touched)">
          <div *ngIf="f.get('email').hasError('required')">
            Email is required.
          </div>
          <div *ngIf="f.get('email').hasError('pattern')">
            Email is invalid.
          </div>
        </div>
      </div>

      <div class="col-12 form-group g-mb-20">
        <input class="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-brd-primary--hover rounded g-py-13 g-px-15" type="text" formControlName="subject" placeholder="Subject">
      </div>

      <div class="col-12 form-group g-mb-20">
        <label for="source"><span class="g-color-primary">*</span>How did you find out about this webpage:</label>
        <select class="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3
                        g-brd-primary--hover rounded g-py-13 g-px-15" formControlName="source" placeholder="How did you find out about this webpage">
          <option value="1">Internet search engine</option>
          <option value="2">Google ads</option>
          <option value="3">Facebook</option>
          <option value="4">Instagram</option>
          <option value="5">WeChat</option>
          <option value="6">Other social media</option>
          <option value="7">Email</option>
          <option value="8">My Hume sales rep</option>
          <option value="9">Word of mouth</option>
          <option value="10">Other</option>
        </select>
        <div class="error" *ngIf="f.get('source').errors && submitted && ( f.get('source').dirty || f.get('source').touched || f.get('source').invalid)">
          <div *ngIf="f.get('source').hasError('required')">
            This field is required.
          </div>
        </div>
      </div>

      <div class="col-md-12 form-group g-mb-40">
                <textarea class="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-brd-primary--hover g-resize-none rounded g-py-13 g-px-15"
                          formControlName="message" rows="7" placeholder="Message*" maxlength="500"></textarea>
        <div class="error" *ngIf="f.get('message').errors && submitted && ( f.get('message').dirty || f.get('message').touched || f.get('message').invalid)">
          <div *ngIf="f.get('message').hasError('required')">
            Message is required.
          </div>
        </div>
      </div>
    </div>

    <div class="d-flex justify-content-center">
      <div class="text-center">
        <ngx-recaptcha2 #captchaElem
                        [siteKey] = siteKey
                        formControlName="recaptcha">
        </ngx-recaptcha2>
        <div class="error" *ngIf="f.get('recaptcha').errors && submitted">
          <div *ngIf="f.get('recaptcha').hasError('required')">
            recaptcha is required.
          </div>
        </div>
        <button type="submit" class="btn btn-hume-color btn-lg waves-effect my-3">Send
          <i class="far fa-paper-plane ml-1"></i>
        </button>
      </div>
    </div>

  </div>
</form>
