<div class="container" *ngIf="products?.length > 0" [ngClass]="page === 'home' ? 'g-pt-50--md' : ''">
  <div class="text-center mx-auto g-max-width-600 g-mb-50--md g-mb-20" *ngIf="page === 'home'">
    <h1 class="text-uppercase mb-4">Featured Products</h1>
    <div class="d-inline-block g-width-60 g-height-6 g-bg-primary mb-2"></div>
    <p class="lead">Hume provides a wide range of building products tools and accessories. This month’s specials include:</p>
  </div>
  <h4 class="text-uppercase font-weight-bold g-pt-15 text-center" *ngIf="page === 'products'">Featured Products</h4>
  <div [ngClass]="page === 'home' ? 'g-pb-50--md g-pb-20' : ''">
    <ngx-slick-carousel class="carousel"
                        [config]="slideConfig">
      <div ngxSlickItem *ngFor="let product of products, let i = index" class="slide g-px-15">

        <a class="g-px-10 g-text-underline--none--hover" [ngClass]="page === 'home' ? 'g-py-15' : ''">
          <figure class="g-pos-rel g-mb-20 text-center u-block-hover u-block-hover__additional--jump hume-carousel-img" [routerLink]="['/product/' + product?.id]">
            <span class="helper"></span>
            <img class="img-fluid hume-product-img" (mouseover)="hover[i] = true" (mouseleave)="hover[i] = false"  [ngClass]="page === 'home' ? 'g-width-80x' : 'g-width-60x'"
                 [src]="hover[i] ? (product?.photoUrl?.length > 1 ? product?.photoUrl[1] : product?.photoUrl[0]) : product?.photoUrl[0]" (error)="img.src = globals.defaultImgUrl" alt="{{product?.description}}" #img>
            <span class="u-ribbon-v1 g-width-50 g-height-50 g-color-white g-bg-orange g-font-size-13 font-weight-bold text-center g-rounded-50x g-top-0 g-right-0 g-px-2 g-py-15" *ngIf="product.status === 3">Special</span>
          </figure>

          <div class="media" [ngStyle]="page === 'products' ? {'height' : '45px'} : {}">
            <div class="d-flex flex-column">
              <h4 class="h6 g-color-black mb-1" [ngClass]="page === 'home' ? 'title' : 'title-prod'">
                <a class="u-link-v5 g-color-black"  [routerLink]="['/product/' + product?.id]">
                  {{product?.description}}
                </a>
              </h4>
              <!--<a class="d-inline-block g-color-black g-font-size-13">{{product?.brand}}</a>-->
              <span class="d-block g-color-black g-font-size-20 font-weight-bold" *ngIf="!product?.hidePrice">{{product?.grossPrice | currency: 'AUD' : 'symbol-narrow'}}
                <!--<span class="g-font-size-13 g-color-gray-dark-v5">(Ex. GST)</span>-->
            </span>
            </div>

            <ul class="list-inline media-body text-right">
              <li class="list-inline-item align-middle mx-0" (click)="addToCart(product, basicModal, 1)">
                <span class="u-icon-v1 u-icon-size--sm g-color-gray-dark-v5 g-color-primary--hover g-font-size-18 rounded-circle"
                   data-toggle="tooltip"
                   data-placement="top"
                   title="Add to Cart">
                  <i class="fas fa-cart-plus"></i>
                </span>
              </li>
              <li class="list-inline-item align-middle mx-0" (click)="addToWishlist(product)">
                <span class="u-icon-v1 u-icon-effect-v2--hover u-icon-size--sm g-color-gray-dark-v5 g-color-white--hover g-font-size-16 g-rounded-3"
                   data-toggle="tooltip"
                   data-placement="top"
                   title="Add to Wishlist">
                  <i class="icon-medical-022 u-line-icon-pro"></i>
                </span>
              </li>
            </ul>
          </div>
        </a>
      </div>
    </ngx-slick-carousel>
  </div>

</div>

<hr class="g-my-20" *ngIf="products?.length > 0">

<!-- Alert -->
<app-alert-components id="wishlist-alert1" *ngIf="showAlert !== 'close'" [showAlert]="showAlert" type="mini"></app-alert-components>
<!-- End Alert -->


<!-- Shopping cart modal -->
<div mdbModal #basicModal="mdbModal" class="modal fade right hume-modal-top" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel"
     aria-hidden="true">
</div>
<!-- Shopping cart modal -->
