import {Component, OnInit} from '@angular/core';
import {NewsService} from '../services/news.service';

@Component({
  selector: 'app-brochure',
  templateUrl: './brochure.component.html',
  styleUrls: ['./brochure.component.scss']
})
export class BrochureComponent implements OnInit{
  brochures;

  constructor(private newsService: NewsService) { }

  ngOnInit() {
    this.newsService.getBrochures().toPromise().then(res => {
      this.brochures = res.response;
    });
  }
}
