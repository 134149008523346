import { Pipe, PipeTransform } from '@angular/core';

@Pipe({name: 'getCartQuantity'})
export class GetCartQuantityPipe implements PipeTransform {
  transform(value) {
    // return value.replace(' ', '_');
    let total = 0;
    value.forEach(res => {
      total += res.quantity;
    });
    return total;
  }
}
