<div class="modal-content shadow-none g-height-95vh g-height-100vh--sm ">
  <div class="modal-header g-bg-primary">
    <button type="button" class="close pull-right" aria-label="Close" (click)="closeModal()">
      <span aria-hidden="true" class="g-font-size-25">×</span>
    </button>
    <h4 class="font-weight-bold mx-auto text-uppercase g-color-white" id="myModalLabel">Shopping Cart</h4>
  </div>

  <div class="modal-body cart-content">

    <!--Cart content module-->
    <app-cart-content [page]="'cart-modal'"></app-cart-content>
    <!--Cart content module-->

  </div>

  <div class="modal-footer g-bg-white">
    <div class="d-flex g-px-20 g-color-main">
      <strong class="text-uppercase g-font-weight-500">Total weight<small class="g-color-gray-dark-v4 text-capitalize g-font-size-13 ml-1"></small>:</strong>
      <span class="g-font-weight-bold ml-2">{{(globals?.cart?.totalWeight ? globals?.cart?.totalWeight : 0) | number: '1.2-2'}} Kg</span>
    </div>
    <div class="d-flex g-px-20 g-color-main">
      <strong class="text-uppercase g-font-weight-500">Total SQM<small class="g-color-gray-dark-v4 text-capitalize g-font-size-13 ml-1"></small>:</strong>
      <span class="g-font-weight-bold ml-2">{{(globals?.cart?.totalSqm ? globals?.cart?.totalSqm : 0) | number: '1.2-2'}} m<sup>2</sup></span>
    </div>
    <div class="row g-px-20 g-color-main">
      <strong class="text-uppercase g-font-weight-500">Total net amount<small class="g-color-gray-dark-v4 text-capitalize g-font-size-13 ml-1"></small>:</strong>
      <span class="g-font-weight-bold ml-2">{{(globals?.cart?.netAmount ? globals?.cart?.netAmount : 0) | currency:'AUD' : '$' :  '1.2-2'}}</span>
    </div>

    <div class="row g-px-20 g-color-main">
      <strong class="text-uppercase g-font-weight-500">GST<small class="g-color-gray-dark-v4 text-capitalize g-font-size-13 ml-1">(10%)</small>:</strong>
      <span class="g-font-weight-bold ml-2">{{(globals?.cart?.netAmount ? (globals?.cart?.grossAmount - globals?.cart?.netAmount) : 0) | currency:'AUD' : '$' :  '1.2-2'}}</span>
    </div>

    <div class="row col-12 float-right g-px-20 g-color-main text-center">
      <strong class="text-uppercase g-font-size-20 g-font-weight-500">Total<small class="g-color-gray-dark-v4 text-capitalize g-font-size-13 ml-1"></small>:</strong>
      <strong class="g-font-weight-bold g-font-size-20 ml-2">{{(globals?.cart?.grossAmount ? globals?.cart?.grossAmount : 0) | currency:'AUD' : '$' :  '1.2-2'}}</strong>
    </div>
    <div class="row g-color-main text-center">
      <strong class="g-font-weight-bold g-font-size-20 g-color-primary">NSW ORDERS/ PRICING ONLY</strong>
    </div>
    <a class="row text-center g-color-primary g-color-black--hover" *ngIf="currentUser?.customer && globals.cart?.lines?.length > 0" (click)="saveQuote()">
      <span class="pt-2 pb-3 mx-auto g-font-size-17"><i class="fa fa-save mr-2"></i>Save as quote</span>
    </a>
    <button class="btn btn-border-no-shadow u-btn-outline-black btn-block g-font-size-15" [disabled]="globals?.cart?.grossAmount <= 0" (click)="closeModal()" routerLink="/checkout">Proceed to Checkout</button>

  </div>
</div>


