import {
  Component,
  EventEmitter,
  OnDestroy,
  OnInit,
  Output,
  ViewChild
} from '@angular/core';
import {Subscription} from 'rxjs';
import {Location} from '@angular/common';
import {ActivatedRoute, Router} from '@angular/router';
import {AuthService} from '../../services/auth/auth.service';
import {ProductService} from '../../services/product.service';
import {ShoppingCartService} from '../../services/shopping-cart.service';
import {InventoryCategoriesService} from '../../services/inventory-categories.service';
import {ProductsSubCategoriesComponent} from '../sub-categories/sub-categories.component';
import {Globals} from '../../global';
import { OrderPipe } from 'ngx-order-pipe';
import {HandleErrorService} from '../../services/handle-error.service';
import {MatAccordion} from '@angular/material/expansion';
import {DataService} from '../../services/data.service';
import {Title} from '@angular/platform-browser';
import {SEOService} from '../../services/seo/seo.service';
import {Paginator} from 'primeng/paginator';
import {distinctUntilChanged} from 'rxjs/operators';


@Component({
  selector: 'app-list-products',
  templateUrl: './list-products.component.html',
  styleUrls: ['./list-products.component.scss']
})
export class ListProductsComponent implements OnInit, OnDestroy {
  @Output() add: EventEmitter<any> = new EventEmitter();
  @ViewChild(ProductsSubCategoriesComponent) child;
  @ViewChild(Paginator) paginator;
  @ViewChild(MatAccordion) accordion: MatAccordion;
  imgUrl = this.globals.picStorageRef;
  showAlert = '';
  hover = [];
  selectedModelFilter = [];

  categories;
  authSubscription: Subscription;
  brandSubscription: Subscription;
  urlSubscription: Subscription;
  catWebSubscription: Subscription;
  tokenSubscription: Subscription;
  clearanceSub: Subscription;

  // ** ecommerce products
  searchResults;
  url;
  catWebName = 'Hume Specials';
  catWebType;
  subCatName;

  brands;
  viewGrid = true;
  class_name = 'grid';
  isLoggedIn = false;
  length;
  inventory;
  productsFilter: any = { description: '' };
  currentUser;
  eCategories;
  selectedCategory;
  refreshLimitedTimes = [2, 2, 2, 2];
  isExpanded;
  itemsInCart;
  useGrouping;
  isGridView;
  step;
  groupFilters;
  selectedFilter = [];
  submitFilter = [];
  isFilterDisabled = false;
  itemsSize = 20;
  productsNumber = {totalPages: 0, totalResult: 0, page: 0, rows: 20};
  subCategories;
  featuredProducts;
  isEOFYAvailable = false;
  clearanceProducts;
  sideBarLoadingF = false;


  public maxSize = 7;
  public autoHide = false;
  public responsive = false;
  public labels: any = {
    previousLabel: 'Previous',
    nextLabel: 'Next',
    screenReaderPaginationLabel: 'Pagination',
    screenReaderPageLabel: 'page',
    screenReaderCurrentLabel: `You're on page`
  };

  constructor(
    private router: Router, private location: Location,
    private authService: AuthService, private handleErrorService: HandleErrorService,
    private productService: ProductService, private seoService: SEOService,
    private shoppingCartService: ShoppingCartService,
    private inventCateService: InventoryCategoriesService,
    public globals: Globals, private title: Title,
    private route: ActivatedRoute, private dataService: DataService,
    private orderPipe: OrderPipe) {}

  async ngOnInit() {
    this.sideBarLoadingF = true;
    this.authSubscription = this.authService.currentUser.subscribe(x => {
      this.currentUser = x;
    });

    if (localStorage.getItem('isGridView')) {
      this.isGridView = localStorage.getItem('isGridView') === 'yes';
    } else { this.isGridView = true; }

    this.tokenSubscription = this.authService.token.pipe(
      distinctUntilChanged()
    ).subscribe(res1 => {
      if (res1) {
        // this.listProducts();
      }
    });

    // Check if there is available clearance products;
    // this.getClearance();
    this.clearanceSub = this.productService.clearance.subscribe(async res => {
      const temp = res.data ? res.data : res;
      this.isEOFYAvailable = temp?.length > 0;
    });

    // ** getting product web categories list
    this.catWebSubscription = this.productService.eWebCat.subscribe(async res2 => {
      if (res2) {
        this.sideBarLoadingF = false;
        this.eCategories = res2;
        this.eCategories?.forEach(res1 => res1.menu = false);
        this.eCategories = this.orderPipe.transform(this.eCategories, 'name');
        if (this.urlSubscription) { this.urlSubscription.unsubscribe(); }
        this.loadPage();
      }
    });
    // ** End getting product web categories list

    /* Meta data */
    this.title.setTitle('Hume Building Products Online Shopping');
    this.seoService.createLinkForCanonicalURL();
    /* Meta data */

    this.brandSubscription = this.productService.getBrands().subscribe(res => {
      this.brands = res.response;
    });
  }

  loadPage() {
    this.urlSubscription = this.route.url.subscribe(async res => {
      if (res.length > 0) {
        this.catWebName = res[0].path.split('_').join(' ');
        // tslint:disable-next-line:max-line-length
        if (this.catWebName.toLowerCase() === 'hume specials') { this.getSpecials(); } else if (this.catWebName.toLowerCase() === 'hume eofy') {
          this.getClearance();
        } else {
          // @ts-ignore
          this.selectedCategory = await this.eCategories?.filter(item => item.name?.toLowerCase() === this.catWebName?.toLowerCase())[0];
          this.getEWebCategoryDetails(this.selectedCategory?.id, this.selectedCategory?.useGrouping).then(result => {
            this.subCategories = result;

            if (res[1]) {
              this.subCatName = res[1].path.split('_').join(' ');
            }
            this.clearFilters();
            this.locationGO();
          });
        }
      } else {
        if (localStorage.getItem('category')) {
          const temp = JSON.parse(localStorage.getItem('category'));
          this.catWebName = temp.catWebName;
          this.subCatName = temp.subCatName;
          // @ts-ignore
          this.selectedCategory = await res2?.filter(item => item.name?.toLowerCase() === this.catWebName?.toLowerCase())[0];
          this.clearFilters();
          this.locationGO();
        } else {
          this.getSpecials();
        }
      }
    });
  }

  getEWebCategoryDetails(code, useGrouping) {
    return new Promise(async resolve => {
      if (code) {
        this.productService.getEProductsWebCatDetails(code).toPromise().then(res => {
          if (useGrouping) {
            // @ts-ignore
            resolve(this.orderPipe.transform(res.productGroups, 'description'));
          } else {
            // @ts-ignore
            resolve(this.orderPipe.transform(res.ecomCategories, 'name'));
          }
        });
      } else {
        resolve(null);
      }
    });
  }

  breadCrumbMessage(event) {
    if (event.type === 'main') {
    } else {
      // this.paginator?.changePage(0);
      this.productsNumber.page = 0;
    }
  }

  openCatMenu() {
    this.isExpanded = !this.isExpanded;
  }

  changeListView(type) {
    this.isGridView = type !== 'list';
    localStorage.setItem('isGridView', this.isGridView ? 'yes' : 'no');
  }

  getSpecials() {
    let filterString;
    if (localStorage.getItem('filterString')) {
      filterString = localStorage.getItem('filterString');
    } else {
      this.resetFilters();
    }
    this.subCatName = '';
    this.catWebName = 'Hume Specials';
    // this.paginator?.changePage(0);
    this.step = null;
    this.location.go('/products/hume_specials');
    this.productService.getESpecialProducts(this.productsNumber?.page, this.productsNumber?.rows, filterString)
      .toPromise().then(res => {
       this.displayProducts(res, filterString, 'specials');
      },
      async error => {
        await this.handleErrorService.subscribeErrorUnAuth(error, this.router.url);
      });
  }

  getClearance() {
    let filterString;
    if (localStorage.getItem('filterString')) {
      filterString = localStorage.getItem('filterString');
    } else {
      this.resetFilters();
    }
    this.location.go('/products/hume_eofy');
    // this.clearanceProducts = JSON.parse(localStorage.getItem('clearanceProducts'));

    this.productService.getEClearanceProducts(this.productsNumber?.page, this.productsNumber?.rows, filterString).subscribe(res => {
        // @ts-ignore
        const temp = res.data ? res.data : res;
        this.isEOFYAvailable = temp?.length > 0;
        this.displayProducts(res, filterString, 'eofy');
      },
      error => {
        this.handleErrorService.subscribeErrorUnAuth(error, this.router.url);
      });
  }

  displayProducts(res, filterString, tag?) {
    // @ts-ignore
    const temp = res.data ? res.data : res;
    this.inventory = temp;
    this.searchResults = temp;

    // @ts-ignore
    this.productsNumber.totalPages = res.totalPages;
    if (this.productsNumber.totalPages <= this.productsNumber.page) {
      this.paginator.changePage(0);
    }
    // @ts-ignore
    this.productsNumber.totalResult = res.totalResult;
    this.getFiltersList(res, filterString);
    localStorage.removeItem('filterString');
  }

  getProductsBySubCategoryCode(code) {
    let filterString;
    if (localStorage.getItem('filterString')) {
      filterString = localStorage.getItem('filterString');
    } else {
      this.resetFilters();
    }
    this.productService.getProductsByEcomCategory(code, this.productsNumber?.page, this.productsNumber?.rows, filterString)
      .toPromise().then(res => {
        this.displayProducts(res, filterString);
    },
      error => {
        if ((error.status === 500 || error.status === 401) && this.refreshLimitedTimes[2] > 0) {
          this.getProductsBySubCategoryCode(code);
          this.refreshLimitedTimes[2] = this.refreshLimitedTimes[2] - 1;
        } else {
          this.handleErrorService.subscribeErrorUnAuth(error, this.router.url);
        }
      });

    this.productService.getFeatureProductsByEcomCategory(code).toPromise().then(res => {
      this.featuredProducts = res;
    }).catch(error => {
      // console.log(error);
    });
  }

  getProductsByGroupCode(id) {
    let filterString;
    if (localStorage.getItem('filterString')) {
      filterString = localStorage.getItem('filterString');
    } else {
      this.resetFilters();
    }
    this.searchResults = null;
    this.productService.getGroupProductsById(id, this.productsNumber?.page, this.productsNumber?.rows, filterString)
      .toPromise().then(res => {
     this.displayProducts(res, filterString);
    }).catch(error => {
      if ((error.status === 500 || error.status === 401) && this.refreshLimitedTimes[2] > 0) {
        this.getProductsByGroupCode(id);
        this.refreshLimitedTimes[2] = this.refreshLimitedTimes[2] - 1;
      } else {
        this.handleErrorService.subscribeErrorUnAuth(error, this.router.url);
      }
    });

    this.productService.getFeatureProductsByGroup(id).toPromise().then(res => {
      this.featuredProducts = res;
    }).catch(error => {
    });
  }

  getFiltersList(res, filterString?) {
    if (!filterString && res?.filters) {
      this.groupFilters = [];
      const filterTypes = Object.keys(res?.filters);
      const filterTypesValue = Object.values(res?.filters);
      for (let i = 0; i < filterTypes.length; i++) {
        this.groupFilters.push(
          {
            label: filterTypes[i],
            items: []
          }
        );
        // @ts-ignore
        for (let x = 0; x < filterTypesValue[i]?.length; x ++) {
          this.groupFilters[i].items.push(
            {
              label: filterTypesValue[i][x]?.description,
              value: filterTypesValue[i][x]?.code
            }
          );
        }
      }
    }
  }

  filter(value) {
    if (!this.selectedFilter.includes(value)) {
      this.selectedFilter.push(value);
    } else {
      this.selectedFilter = this.selectedFilter.filter(res => res.value !== value.value );
    }
    for (let i = 0; i < this.groupFilters?.length; i ++) {
      this.submitFilter[i] = {
        type: '',
        values: []
      };
      for (let x = 0; x < this.selectedFilter?.length; x ++) {
        if (this.groupFilters[i]?.items?.includes(this.selectedFilter[x])) {
          this.submitFilter[i].type = this.groupFilters[i].label;
          this.submitFilter[i].values.push(this.selectedFilter[x].value + '|' + this.selectedFilter[x].label);
        }
      }
    }
    this.submitFilter = this.submitFilter.filter(res => res.type);
    if (this.submitFilter?.length > 0) {
      localStorage.setItem('filterString', btoa(JSON.stringify(this.submitFilter)));
    } else {
      localStorage.removeItem('filterString');
    }
    this.paginator?.changePage(0);
  }

  listProducts () {
    if (this.subCatName) {
      const subCat = this.subCategories?.filter(item => (item?.description?.toLowerCase() === this.subCatName?.toLowerCase() ||
        item?.name?.toLowerCase() === this.subCatName?.toLowerCase()));
      if (subCat) {
        const id = subCat[0]?.code ? subCat[0]?.code : subCat[0]?.id;
        this.inventory = null;
        this.searchResults = null;
        if (this.selectedCategory?.useGrouping) {
          this.getProductsByGroupCode(id);
        } else {
          this.getProductsBySubCategoryCode(id);
        }
      }
    } else {
      if (this.catWebName.toLowerCase() === 'hume specials') { this.getSpecials(); }
    }

  }

  onPageChange(event) {
    if (this.submitFilter?.length > 0) {
      localStorage.setItem('filterString', btoa(JSON.stringify(this.submitFilter)));
    } else {
      localStorage.removeItem('filterString');
    }
    this.productsNumber.rows = event.rows;
    this.productsNumber.page = event.page;
    // if (this.urlSubscription) { this.urlSubscription.unsubscribe(); }
    this.listProducts();
  }

  priceChange(string) {
    if (string === 'low') {
      this.searchResults = this.orderPipe.transform(this.searchResults, 'grossPrice');
    } else {
      this.searchResults = this.orderPipe.transform(this.searchResults, 'grossPrice', true);
    }
  }

  // ** Get web categories for ecommerce
  locationGO() {
    this.location.go('/products/' + this.catWebName?.toLowerCase().split(' ').join('_') + '/'
      + this.subCatName?.toLowerCase().split(' ').join('_'));
  }

  viewToggle (view: string) {
    this.viewGrid = view === 'grid';
    this.class_name = view;
  }

  // Generate Fake Object Array
  generateFake(count: number): Array<number> {
    const indexes = [];
    for (let i = 0; i < count; i++) {
      indexes.push(i);
    }
    return indexes;
  }

  alertUpdate($event) {
    this.showAlert = $event;
  }

  /*openSubCatMenu (subCategory, index?, category?) {
    console.log(category);
    this.webCatDetails = category;
    this.catWebName = category.name;
    // this.paginator?.changePage(0);
    this.alteredCategory = {id: category.id, type: (category.useGrouping ? 'GROUP' : 'CATEGORY')};
    if (index >= 0) {
      this.subCatName = subCategory.name ? subCategory.name : subCategory.description;
      localStorage.setItem('category',
        JSON.stringify({id: '', catWebName: this.catWebName, subCatName: this.subCatName, page: this.productsNumber.page}));
      this.step = index;
      this.resetFilters('newPage');
      this.router.navigate(['/products/' + category.name.split(' ').join('_') + '/' +
      (subCategory?.name ? (subCategory?.name?.split(' ').join('_')) : (subCategory?.description?.split(' ').join('_')))]);
    }
  }*/

  resetFilters(string?) {
    if (string === 'newPage') {
      this.groupFilters =  null;
    }
    this.selectedFilter = [];
    this.submitFilter = [];
    this.selectedModelFilter = [];
  }

  clearFilters() {
    this.resetFilters();
    // if (this.urlSubscription) { this.urlSubscription.unsubscribe(); }
    if (localStorage.getItem('filterString')) { localStorage.removeItem('filterString'); }
    this.listProducts();
  }

  openProductDetails(productId, catWebName, catWebType, subCatName) {
    localStorage.setItem('category', JSON.stringify({productId, catWebName, catWebType, subCatName, page: this.productsNumber.page}));
    // window.open('/product/' + productId, '_blank');
  }

  calDiscount(product) {
    return '-' + Math.round((1 - product?.grossPrice / product?.msrp) * 100) + '%';
  }

  ngOnDestroy() {
    if (this.catWebSubscription) { this.catWebSubscription.unsubscribe(); }
    if (this.urlSubscription) { this.urlSubscription.unsubscribe(); }
    if (this.authSubscription) { this.authSubscription.unsubscribe(); }
    if (this.brandSubscription) {this.brandSubscription.unsubscribe(); }
    if (this.tokenSubscription) {this.tokenSubscription.unsubscribe(); }
    if (this.clearanceSub) {this.clearanceSub.unsubscribe(); }
  }
}
