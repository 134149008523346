<div class="single-component-container g-font-size-17">

  <div class="g-mb-0 g-mb-50--sm">
    <carousel [isAnimated]="true">
      <slide *ngFor="let slide of carousels">
        <div class="category-grid-img g-pos-rel g-min-height-45vh"
             [ngStyle]="{'background-image': 'url(' + imgUrl + slide?.link + ')'}"></div>

        <div class="hume-carousel-background g-min-height-130 g-min-height-200--sm">
          <div class="carousel-caption d-md-block">
            <div class="animated-title">
              <div class="text-top">
                <div class="g-pa-0">
                  <span>Careers at Hume</span>
                </div>
              </div>
            </div>
          </div>
        </div>
      </slide>
    </carousel>
  </div>
  <!-- End projects slider -->

  <div class="container g-mt-50 g-mb-60">
    <h2 class="text-capitalize mb-4 h1 text-center">Join the team at Hume</h2>
    <p><a routerLink="/about">Hume Building Products</a> is a leading Australian supplier of building products, tools and accessories, with <a routerLink="/contact">eight warehouse stores</a> located across
      Sydney and Melbourne. We are a customer focused business that partners with iconic local and international brands to deliver the best products and solutions available. Hume is looking for outstanding
      candidates to join our growing team. We offer career opportunities and progression across Sales, Customer Service, Warehousing, Logistics, Finance, Marketing, IT and a range of related corporate services.</p>
    <div class="row g-mt-50">
      <div class="col-12 col-sm-4 text-center" *ngFor="let item of iconList">
        <a [routerLink]="item?.link">
          <img class="g-width-30x g-bg-primary mb-4" [src]="imgUrl + item?.pic" alt="Hume Building Products careers">
        </a>
        <p class="font-weight-bold col-11" [innerHTML]="item?.title"></p>
      </div>
    </div>
  </div>

  <div class="container g-mt-50 g-mb-70 g-mb-300--sm text-center">
    <a href="https://www.seek.com.au/jobs?advertiserid=23634037" target="_blank">
      <h2 class="text-capitalize text-center h1 mb-4 g-color-primary g-text-underline">Current Vacancies</h2>
    </a>

    <p>View a list of our current job vacancies and opportunities on seek.com.au <a class="g-text-underline" target="_blank" href="https://www.seek.com.au/jobs?advertiserid=23634037">HERE</a>.</p>
  </div>

  <!-- Gallery only show when flooring tiles -->
  <div class="container">
    <div class="row mb-3">
      <div class="col-12 col-md-4 mb-3 projects-img text-center" *ngFor="let item of gallery">
        <img [src]="imgUrl + item?.pic" class="img-fluid"
             alt="Hume Building Products careers">
      </div>
    </div>
  </div>
  <!-- End Gallery only show when flooring tiles-->

  <div class="container g-mt-50 g-mb-50">

    <app-location [page]="'careers'"></app-location>

  </div>

</div>
