<div class="single-component-container container">

  <!-- Horizontal Steppers -->
  <div class="row">
    <div class="col-md-8">
      <h1 class="jumbotron-heading delivery-title g-pt-40 g-mb-10">Hume Order Form</h1>
      <div class="d-inline-block g-width-60 g-height-1 g-bg-primary mb-2"></div>
      <p>To make an order:</p>
      <ul>
        <li>Visit a Hume store <a routerLink="/contact">near you</a></li>
        <li>Submit an order quote below</li>
        <li><a [href]="globals.docStorageRef + 'Hume-Order-Form.pdf'">Download</a> an order form</li>
        <li>Call us now on <a href="tel:134863">13 4863</a></li>
      </ul>
    </div>
    <div class="col-md-12">

      <!-- Steppers Links Wrapper -->
      <ul class="stepper stepper-horizontal">

        <li class="stepLinks active" id="step1">
          <a>
            <span class="circle">1</span>
            <span class="label">Account Details</span>
          </a>
        </li>

        <li class="stepLinks disabled" id="step2">
          <a class="text-left">
            <span class="circle">2</span>
            <span class="label">Plasterboard & Hebel/Walsc AAC Panel</span>
          </a>
        </li>

        <li class="stepLinks disabled" id="step3">
          <a>
            <span class="circle">3</span>
            <span class="label">Accessories</span>
          </a>
        </li>

        <!--<li class="stepLinks disabled" id="step4">
          <a (click)="openTab('step4')">
            <span class="circle">4</span>
            <span class="label">Quote List</span>
          </a>
        </li>-->

      </ul>
      <!-- Steppers links Wrapper -->

      <!-- Steppers Content -->

      <!-- Step1 -->
      <div id="step1Content" class="stepContent" style="display: block">
        <form [formGroup]="orderFormStep1" (ngSubmit)="onSubmitStep1()" class="px-3 px-md-5">

          <div class="row mb-2">
            <div class="col-12 col-md-6 mb-2 mb-md-0">
              <label class="float-left font-weight-bold">Customer:<span class="g-color-primary align-middle"> *</span></label>
              <input type="text" formControlName="customer" class="form-control form-control-lg" [ngClass]="{ 'is-invalid': submittedStep1 && f1.customer.errors }" placeholder="">
              <div *ngIf="submittedStep1 && f1.customer.errors" class="invalid-feedback">
                <div *ngIf="f1.customer.errors.required">Account number is required</div>
              </div>
            </div>
            <div class="col-12 col-md-6 mb-2 mb-md-0">
              <label class="float-left font-weight-bold">Order No:</label>
              <input type="text" formControlName="orderNo" class="form-control form-control-lg" [ngClass]="{ 'is-invalid': submittedStep1 && f1.orderNo.errors }" placeholder="">
              <div *ngIf="submittedStep1 && f1.orderNo.errors" class="invalid-feedback">
                <div *ngIf="f1.orderNo.errors.required">Order number is required</div>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12 col-md-6 mb-2 mb-md-0">
              <label class="float-left font-weight-bold">Contact Person:<span class="g-color-primary align-middle"> *</span></label>
              <input type="text" formControlName="contactName" class="form-control form-control-lg" [ngClass]="{ 'is-invalid': submittedStep1 && f1.contactName.errors }" placeholder="">
              <div *ngIf="submittedStep1 && f1.contactName.errors" class="invalid-feedback">
                <div *ngIf="f1.contactName.errors.required">Contact person name is required</div>
              </div>
            </div>
            <div class="col-12 col-md-3 mb-md-0 g-mt-7">
              <label class="float-left font-weight-bold mr-3">Order Date:<span class="g-color-primary align-middle"> *</span></label>
              <div class="form-group g-width-70x--md">
                <div class="input-group">
                  <input type="text" placeholder="dd/mm/yyyy" formControlName="orderDate" [ngClass]="{ 'is-invalid': submittedStep1 && f1.orderDate.errors }"
                         class="form-control" bsDatepicker #od="bsDatepicker" [bsConfig]="bsConfig">
                  <!--<input class="form-control" placeholder="yyyy-mm-dd"
                         formControlName="orderDate" [ngClass]="{ 'is-invalid': submittedStep1 && f1.orderDate.errors }" ngbDatepicker #od="ngbDatepicker">-->
                  <div class="input-group-append">
                    <button class="btn btn-outline-hume calendar m-0 shadow-none" (click)="od.toggle()" type="button"></button>
                  </div>
                  <div *ngIf="submittedStep1 && f1.orderDate.errors" class="invalid-feedback float-left">
                    <div *ngIf="f1.orderDate.errors.required">Order date is required</div>
                  </div>
                </div>
              </div>

            </div>
            <div class="col-12 col-md-3 mb-md-0 g-mt-7">
              <label class="float-left font-weight-bold mr-3">Delivery Date:<span class="g-color-primary align-middle"> *</span></label>
              <div class="form-group g-width-70x--md">
                <div class="input-group">
                  <input type="text" placeholder="dd/mm/yyyy" formControlName="deliveryDate" [ngClass]="{ 'is-invalid': submittedStep1 && f1.deliveryDate.errors }"
                         class="form-control" bsDatepicker #dd="bsDatepicker" [bsConfig]="bsConfig">
                  <!--<input class="form-control" placeholder="yyyy-mm-dd"
                         formControlName="deliveryDate" [ngClass]="{ 'is-invalid': submittedStep1 && f1.deliveryDate.errors }"  ngbDatepicker #dd="ngbDatepicker">-->
                  <div class="input-group-append">
                    <button class="btn btn-outline-hume calendar m-0 shadow-none" (click)="dd.toggle()" type="button"></button>
                  </div>
                  <div *ngIf="submittedStep1 && f1.deliveryDate.errors" class="invalid-feedback float-left">
                    <div *ngIf="f1.deliveryDate.errors.required">Delivery date is required</div>
                  </div>
                </div>
              </div>

            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12 col-md-6 mb-2 mb-md-0">
              <label class="float-left font-weight-bold">Phone/Mobile:</label>
              <input type="text" formControlName="phone" class="form-control form-control-lg" placeholder="">
              <!--<div *ngIf="f1.phone.errors" class="invalid-feedback float-left">
                <div *ngIf="f1.phone.errors.minlength">Phone must be at least 8 characters long</div>
              </div>-->
            </div>
            <div class="col-12 col-md-6 mb-2 mb-md-0">
              <label class="float-left font-weight-bold">Email:<span class="g-color-primary align-middle"> *</span></label>
              <input type="text" formControlName="email" class="form-control form-control-lg" [ngClass]="{ 'is-invalid': submittedStep1 && f1.email.errors }" placeholder="">
              <div *ngIf="submittedStep1 && f1.email.errors" class="invalid-feedback">
                <div *ngIf="f1.email.errors.required">Email is required</div>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12 col-md-10 mb-2 mb-md-0">
              <label class="float-left font-weight-bold">Delivery Address:<span class="g-color-primary align-middle"> *</span></label>
              <input type="text" formControlName="deliveryAdd" class="form-control form-control-lg" [ngClass]="{ 'is-invalid': submittedStep1 && f1.deliveryAdd.errors }" placeholder="">
              <div *ngIf="submittedStep1 && f1.deliveryAdd.errors" class="invalid-feedback">
                <div *ngIf="f1.deliveryAdd.errors.required">Delivery address is required</div>
              </div>
            </div>
          </div>
          <div class="row mb-2">
            <div class="col-12 col-md-10 mb-2 mb-md-0">
              <label class="float-left font-weight-bold">Special Instructions:</label>
              <textarea type="text" formControlName="instructions" class="form-control form-control-lg" placeholder=""></textarea>
            </div>
          </div>
          <div class="row col-md-6 d-flex justify-content-start">
            <label class="float-left font-weight-bold">Crane Lift:
              <select class="ml-3" formControlName="craneLift">
                <option value="">No</option>
                <option value="Crane lift">Yes</option>
              </select>
            </label>
          </div>
          <div class="row col-md-6 d-flex justify-content-start">
            <label class="float-left font-weight-bold">Long walk over 20m:
              <select class="ml-3" formControlName="longWalk">
                <option value="">No</option>
                <option value="Long walk">Yes</option>
              </select></label>
          </div>
          <div class="row col-md-6 d-flex justify-content-start">
            <label class="float-left font-weight-bold">Is Delivery a PassUp / PassDown:
              <select class="ml-3" formControlName="passUp">
                <option value="">No</option>
                <option value="Pass up">Yes</option>
              </select>
            </label>
          </div>

          <div class="text-center my-2">
            <button [disabled]="!orderFormStep1.valid" class="btn btn-lg u-btn-outline-black u-btn-skew g-font-weight-600 g-letter-spacing-0_5 text-uppercase g-brd-2 g-mr-10 g-mb-15">
              <span class="u-btn-skew__inner">Step2<i class="fa fa-arrow-right g-ml-4"></i></span>
            </button>
          </div>

          <!--<button mdbBtn class="btn btn-lg btn-hume-color waves-light waves-effect my-4" [disabled]="!orderFormStep1.valid" type="submit">Next</button>-->
        </form>

      </div>
      <!-- End Step1 -->

      <!-- Step2 -->
      <div id="step2Content" class="stepContent">
          <form class="row mb-2" [formGroup]="boardSelectForm">
            <div class="col-12 col-md-6 mb-2 mb-md-0">
              <label class="float-left font-weight-bold">Board type<span class="g-color-primary align-middle"> *</span></label>
              <select class="form-control form-control-lg" id="boardType" (change)="mySelectHandler()" formControlName="boardType">
                <option value="">Please select the type of plasterboard</option>
                <option *ngFor="let item of plasterBoards" [value]="item?.name">{{item?.name}}</option>
              </select>
            </div>
            <div class="col-12 col-md-6 mb-2 mb-md-0">
              <label class="float-left font-weight-bold">Board Thickness / Type<span class="g-color-primary align-middle"> *</span></label>
              <select class="form-control form-control-lg" (change)="thicknessSelectHandler()" formControlName="boardThickness">
                <option value="">Please select the thickness of plasterboard</option>
                <option *ngFor="let thickness of itemThickness?.thickness" [hidden]="!showThicknessOption">{{thickness?.name}}</option>
              </select>
            </div>
          </form>
        <!--  show plasterboard table -->
          <div class="mb-2 py-3 text-center" [hidden]="!showWidthOption">
            <div class="row">
              <div class="col-12 my-2 mb-md-0" *ngFor="let width of itemWidth?.width">
                <!-- mobile version  -->
                <div *ngIf="globals.isMobile && width?.length.length > 4 else desktop">
                  <table class="mb-2">
                    <tr>
                      <td>(mm)</td>
                      <td *ngFor="let item of width?.length; let i = index">
                        <label *ngIf="i < 4" class="font-weight-bold g-font-size-15">{{item?.name}}</label></td>
                    </tr>
                    <tr>
                      <td><label class="font-weight-bold g-font-size-15">{{width?.name}}</label></td>
                      <td *ngFor="let item of width?.length; let i = index">
                        <!--<input *ngIf="i < 4" class="mr-2 text-center g-color-primary g-font-size-15 g-max-width-100 form-control"
                               value="0" [id]="selectedBoard.name + '_' + selectedBoard.thickness + '_' + width.name + '_' + i" type="number">-->
                        <input *ngIf="i < 4" class="mr-2 text-center g-color-primary g-font-size-15 g-max-width-100 form-control"
                               value="0" [id]="item?.code" type="number">
                      </td>
                    </tr>
                  </table>
                  <table class="mb-2">
                    <tr>
                      <td>(mm)</td>
                      <td *ngFor="let item of width?.length; let i = index">
                        <label *ngIf="i >= 4" class="font-weight-bold g-font-size-15">{{item?.name}}</label></td>
                    </tr>
                    <tr>
                      <td><label class="font-weight-bold g-font-size-15">{{width?.name}}</label></td>
                      <td *ngFor="let item of width?.length; let i = index">
                        <input *ngIf="i >= 4" class="mr-2 text-center g-color-primary g-font-size-15 g-max-width-100 form-control"
                               value="0" [id]="item?.code" type="number">
                      </td>
                    </tr>
                  </table>
                </div>
                <!-- End mobile version  -->
                <!-- desktop version  -->
                <ng-template #desktop>
                  <table class="mb-2">
                    <tr>
                      <td>(mm)</td>
                      <td *ngFor="let item of width?.length"><label class="font-weight-bold g-font-size-15">{{item?.name}}</label></td>
                    </tr>
                    <tr>
                      <td><label class="font-weight-bold g-font-size-15">{{width?.name}}</label></td>
                      <td *ngFor="let item of width?.length; let i = index">
                        <input class="mr-2 text-center g-color-primary g-font-size-15 g-max-width-100 form-control"
                               value="0" [id]="item?.code" type="number">
                      </td>
                    </tr>
                  </table>
                </ng-template>
                <!-- desktop version  -->
              </div>
            </div>
            <div class="row text-center">
              <div class="float-left mt-3">
                <button (click)="step2AddToCart()" class="btn btn-lg u-btn-outline-bluegray u-btn-hover-v1-4 g-letter-spacing-0_5 text-uppercase g-rounded-50 g-px-30 g-mr-10 g-mb-15">
                  <span class="pull-left text-left"><i class="fas fa-plus float-left mr-2" style="margin-top: 0.15rem !important;"></i>Add to quote</span>
                </button>
              </div>
              <div class="alert alert-success invisible fadeIn fadeOut pt-3 mt-3" id="addCartAlert2" role="alert">
                You successfully add <strong>{{addingItemNo}}</strong><span *ngIf="addingItemNo <= 1"> item</span><span *ngIf="addingItemNo > 1"> items</span>.
              </div>
            </div>
          </div>

        <!-- End show plasterboard table -->
        <div class="row d-flex justify-content-between my-2 mt-5">
          <button (click)="openTab('step1', 'step2')" class="btn btn-lg u-btn-outline-black u-btn-skew g-font-weight-600 g-letter-spacing-0_5 text-uppercase g-brd-2 g-mr-10 g-mb-15">
            <span class="u-btn-skew__inner"><i class="fa fa-arrow-left g-mr-4"></i>Step1</span>
          </button>
          <button (click)="openTab('step4', 'step2')" class="btn btn-lg u-btn-outline-bluegray u-btn-hover-v1-4 g-letter-spacing-0_5 text-uppercase g-rounded-50 g-px-30 g-mr-10 g-mb-15">
          <span class="u-badge-v1--sm g-color-white g-bg-black g-font-size-11 g-line-height-1_4 g-rounded-50x g-pa-4" style="top: 14px !important; right: 18px !important;">{{step2Submit.length + step3Submit.length}}</span>
          <span class="pull-left text-left"><i class="fas fa-file-text float-left mr-2" style="margin-top: 0.15rem !important;"></i>View quote list</span>
        </button>
          <button (click)="openTab('step3', 'step2')" class="btn btn-lg u-btn-outline-black u-btn-skew g-font-weight-600 g-letter-spacing-0_5 text-uppercase g-brd-2 g-mr-10 g-mb-15">
            <span class="u-btn-skew__inner">Step3<i class="fa fa-arrow-right g-ml-4 mr-2"></i></span>
          </button>

        </div>
      </div>
      <!-- End Step2 -->

      <!-- Step3 -->
      <div id="step3Content" class="stepContent">
        <div class="row mb-2">
          <div class="col-12 col-md-6 mb-4" [formGroup]="step3CategoriesForm">
            <label class="float-left font-weight-bold">Accessories categories</label>
            <select class="form-control form-control-lg"  (change)="step3CategoriesSelectHandler()" formControlName="step3CategoriesType">
              <option value="">Please select the category</option>
              <option *ngFor="let accessory of accessories">{{accessory}}</option>
            </select>
          </div>
        </div>
        <div class="mb-2 border-top" *ngIf="step3CatContentShow">
            <h3 class="text-center font-weight-bold my-3 my-md-5">{{accessories[step3Index]}}</h3>

            <!-- all other contents -->
            <div class="row">
              <div class="mb-2" *ngFor="let item of allContent[step3Index].content" [ngClass]="item.size.length >= 4 ? 'col-md-8' : (item.size.length === 1 ? 'col-md-4' : 'col-md-6')">

                <!--Mobile version-->
                <div *ngIf="globals.isMobile && item.size.length > 4 else desktop">
                  <table class="mb-2 border-bottom">
                    <tr>
                      <th rowspan="2"  [style]="globals.isMobile ? 'width: 100px' : 'width: 200px'">
                        <label class="font-weight-bold g-font-size-14 mr-3">{{item.name}}</label></th>
                      <td *ngFor="let size of item.size; let i = index" class="text-center g-max-width-100">
                        <label *ngIf="i < 4" class="font-weight-bold">{{size.name}}</label>
                      </td>
                    </tr>
                    <tr>
                      <td *ngFor="let size of item.size; let i = index">
                        <input *ngIf="i < 4" class="mr-2 text-center g-font-size-15 g-max-width-100 form-control" value="0" [id]="size.code" type="number">
                      </td>
                    </tr>
                  </table>
                  <table class="mb-2 border-bottom">
                    <tr>
                      <th rowspan="2"  [style]="globals.isMobile ? 'width: 100px' : 'width: 200px'">
                        <label class="font-weight-bold g-font-size-14 mr-3">{{item.name}}</label></th>
                      <td *ngFor="let size of item.size; let i = index" class="text-center g-max-width-100">
                        <label *ngIf="i >= 4" class="font-weight-bold">{{size.name}}</label>
                      </td>
                    </tr>
                    <tr>
                      <td *ngFor="let size of item.size; let i = index">
                        <input *ngIf="i >= 4" class="mr-2 text-center g-font-size-15 g-max-width-100 form-control" value="0" [id]="size.code" type="number">
                      </td>
                    </tr>
                  </table>
                </div>
                <!--Mobile version-->

                <!--Desktop version-->
                <ng-template #desktop>
                  <table class="mb-2 border-bottom">
                    <tr>
                      <th rowspan="2"  [style]="globals.isMobile ? 'width: 100px' : 'width: 200px'">
                        <label class="font-weight-bold g-font-size-14 mr-3">{{item.name}}</label></th>
                      <!--<th rowspan="2" *ngIf="item?.other">
                        <label>{{item?.other?.name}}:</label>
                        <select>
                          <option [id]="item.name + '_' + item?.other?.name" *ngFor="let itemSize of item?.other?.size">{{itemSize}}</option>
                        </select>
                      </th>-->
                      <td *ngFor="let size of item.size" class="text-center g-max-width-100"><label class="font-weight-bold">{{size.name}}</label></td>
                    </tr>
                    <!--<tr *ngIf="item.size.length > 0 else noSize">-->
                    <tr>
                      <td *ngFor="let size of item.size">
                        <input class="mr-2 text-center g-font-size-15 g-max-width-100 form-control" value="0"
                               [id]="size.code" type="number">
                      </td>
                    </tr>
                    <!--<ng-template #noSize>
                      <tr>
                        <td>
                          <input class="mr-2 text-center g-font-size-15 g-max-width-100 form-control" value="0"
                                 [id]="item.name" type="number">
                        </td>
                      </tr>
                    </ng-template>-->
                  </table>
                </ng-template>
                <!--Desktop version-->
              </div>
            </div>

            <!--- Stud and Tack --->
            <div class="mb-4" *ngIf="step3Index === 1">
                <div class="row mb-3 pt-3" [formGroup]="step3StudForm">
                  <div class="col-12 col-md-4 mb-2 mb-md-0">
                    <label class="float-left font-weight-bold">Track / Stud type</label>
                    <select class="form-control form-control-lg" (change)="trackStudSelectHandler()" formControlName="studType">
                      <option>Please select the type of Track or Stud</option>
                      <option *ngFor="let item of trackStud">{{item?.name}}</option>
                    </select>
                  </div>
                  <div class="col-12 col-md-4 mb-2 mb-md-0" *ngIf="showTrackStudBMTOption">
                    <label class="float-left font-weight-bold">BMT</label>
                    <select class="form-control form-control-lg" (change)="TSBMTSelectHandler()" formControlName="studBMT">
                      <option>Please select the depth</option>
                      <option *ngFor="let bmt of filteredTrackStud.BMT">{{bmt?.name}}</option>
                    </select>
                  </div>
                </div>
                <div class="col-12 col-md-8 mb-2 mb-md-0" *ngIf="showTrackStudSizeOption">
                  <table>
                    <tr class="text-center">
                      <td *ngFor="let item of BMTTrackStud.size"><label class="font-weight-bold">{{item.name}}</label></td>
                    </tr>
                    <tr>
                      <td *ngFor="let item of BMTTrackStud.size; let i = index">
                        <input class="mr-2 text-center g-color-primary g-font-size-15 g-max-width-100 form-control"
                               value="0" [id]="item.code" type="number">
                      </td>
                    </tr>
                  </table>
                </div>
              </div>
            <!--- end Stud and Tack --->

            <div class="row text-center">
              <div class="float-left mt-3">
                <button (click)="step3AddToCart()" class="btn btn-lg u-btn-outline-bluegray u-btn-hover-v1-4 g-letter-spacing-0_5 text-uppercase g-rounded-50 g-px-30 g-mr-10 g-mb-15">
                  <span class="pull-left text-left"><i class="fas fa-plus float-left mr-2" style="margin-top: 0.15rem !important;"></i>Add to quote</span>
                </button>
              </div>
              <div class="alert alert-success invisible fadeIn fadeOut pt-3 mt-3" id="addCartAlert3" role="alert">
                You successfully add <strong>{{addingItemNo}}</strong> <span *ngIf="addingItemNo <= 1"> item</span><span *ngIf="addingItemNo > 1"> items</span>.
              </div>
            </div>
            <!-- end all other contents -->

        </div>
        <div class="row d-flex justify-content-between my-2 mt-5">
          <button (click)="openTab('step2', 'step3')" class="btn btn-lg u-btn-outline-black u-btn-skew g-font-weight-600 g-letter-spacing-0_5 text-uppercase g-brd-2 g-mr-10 g-mb-15">
            <span class="u-btn-skew__inner"><i class="fa fa-arrow-left mr-3"></i>Step2</span>
          </button>
          <button (click)="openTab('step4', 'step3')" class="btn btn-lg u-btn-outline-bluegray u-btn-hover-v1-4 g-letter-spacing-0_5 text-uppercase g-rounded-50 g-px-30 g-mr-10 g-mb-15">

            <span class="pull-left text-left"><i class="fas fa-file-text float-left mr-2" style="margin-top: 0.15rem !important;"></i>View quote list <span class="u-badge-v1--sm g-color-white g-bg-black g-font-size-11 g-line-height-1_4 g-rounded-50x g-pa-4" style="top: 14px !important; right: 18px !important;">{{step2Submit.length + step3Submit.length}}</span></span>
          </button>
          <button style="border:white; background-color:transparent"></button>
        </div>
      </div>
      <!-- End Step3 -->

      <!-- Quote list -->
      <div id="step4Content" class="stepContent">
        <div class="modal-dialog modal-notify modal-success" role="document">
          <!--Content-->
          <div class="modal-content">

            <!--header-->
            <div class="modal-header g-bg-primary">
              <h2 class="heading lead g-font-weight-600 mx-auto">Your Quote</h2>
            </div>
            <!--header-->

            <!-- body-->
            <div class="modal-body" *ngIf="orderFormStep1.valid || step2Submit.length > 0 || step3Submit.length > 0 else emptyList">
              <h3 class="text-center">Account information</h3>
              <ul class="list-unstyled">
                <li><strong class="mr-2">Customer No:</strong>{{orderFormStep1.value.customer}}</li>
                <li><strong class="mr-2" *ngIf="orderFormStep1.value.orderNo">Order No:</strong>{{orderFormStep1.value.orderNo}}</li>
                <li><strong class="mr-2">Contact Person:</strong>{{orderFormStep1.value.contactName}}</li>
                <li><strong class="mr-2">Contact No:</strong>{{orderFormStep1.value.phone}}</li>
                <li><strong class="mr-2">Email:</strong>{{orderFormStep1.value.email}}</li>
                <li *ngIf="orderFormStep1.value.orderDate"><strong class="mr-2">Order Date:</strong>{{orderFormStep1.value.orderDate.getDate() + '/' + (orderFormStep1.value.orderDate.getUTCMonth()+1) + '/' + orderFormStep1.value.orderDate.getUTCFullYear()}}</li>
                <li *ngIf="orderFormStep1.value.deliveryDate"><strong class="mr-2">Delivery Date:</strong>{{orderFormStep1.value.deliveryDate.getDate() + '/' + (orderFormStep1.value.deliveryDate.getUTCMonth()+1) + '/' + orderFormStep1.value.deliveryDate.getUTCFullYear()}}</li>
                <li><strong class="mr-2">Delivery Address:</strong>{{orderFormStep1.value.deliveryAdd}}</li>
                <li><strong class="mr-2" *ngIf="orderFormStep1.value.craneLift || orderFormStep1.value.longWalk || orderFormStep1.value.passUp">Delivery Site:</strong>
                  <span class="mr-2" *ngIf="orderFormStep1.value.craneLift">Crane Lift<span *ngIf="orderFormStep1.value.longWalk || orderFormStep1.value.passUp">,</span></span>
                  <span class="mr-2" *ngIf="orderFormStep1.value.longWalk">Long walk over 20m<span *ngIf="orderFormStep1.value.passUp">,</span></span>
                  <span class="mr-2" *ngIf="orderFormStep1.value.passUp">PassUp / PassDown</span>
                </li>
                <li><strong class="mr-2" *ngIf="orderFormStep1.value.instructions">Special Instructions:</strong>{{orderFormStep1.value.instructions}}</li>

              </ul>
              <!--<div class="js-scrollbar g-height-600">-->
                <!-- step2 plasterboards -->
                <div *ngIf="step2Submit.length > 0">
                  <hr>
                  <h3 class="text-center">Plasterboards</h3>
                  <div class="u-basket__product g-brd-none" *ngFor="let item of step2Submit; let i = index" style="padding: unset">
                    <div class="row no-gutters g-pb-5">
                      <div class="col-1 pr-1">
                        <h6 class="g-font-weight-400 g-font-size-default mt-3">{{i+1}}</h6>
                      </div>

                      <div class="col-7">
                        <h6 class="g-font-weight-400 g-font-size-default">
                          <span class="g-color-black g-color-primary--hover g-text-underline--none--hover">{{item.name}}({{item?.thickness}})</span>
                        </h6>
                        <small class="g-color-primary g-font-size-12 mr-2" *ngIf="item?.type.length > 0">{{item?.type}}</small>
                        <small class="g-color-primary g-font-size-12">{{item.width}}mm x {{item.length}}mm</small>
                      </div>
                      <div class="col-3">
                        <label>
                          <span class="mr-2">X</span>
                          <input type="number" class="g-font-weight-400 g-font-size-default mt-4 g-width-45" [(ngModel)]="item.quantity">
                        </label>
                      </div>
                      <i class="far fa-trash-alt float-right mt-4 hume-cart-delete" (click)="deleteBoardStep2(i)"></i>
                    </div>
                  </div>
                </div>
                <!-- End step2 plasterboards -->

                <!-- Step3 accessories -->
                <div *ngIf="step3Submit.length >0">
                  <hr>
                  <h3 class="text-center">Accessories</h3>
                  <div class="u-basket__product g-brd-none" *ngFor="let item of step3Submit; let i = index" style="padding: unset">
                    <div class="row no-gutters g-pb-5">
                      <div class="col-1 pr-1">
                        <h6 class="g-font-weight-400 g-font-size-default mt-3">{{i+1}}</h6>
                      </div>

                      <div class="col-7">
                        <h6 class="g-font-weight-400 g-font-size-default">
                          <span class="g-color-black g-color-primary--hover g-text-underline--none--hover">{{item?.name}}</span>
                        </h6>
                        <small class="g-color-primary g-font-size-12 mr-2" *ngIf="item?.type.length > 0">{{item?.type}}</small>
                        <small class="g-color-primary g-font-size-12"><span *ngIf="item?.width">{{item?.width}} x</span> {{item?.length}}</small>
                      </div>
                      <div class="col-3">
                        <label>
                          <span class="mr-2">X</span>
                          <input type="number" class="g-font-weight-400 g-font-size-default mt-4 g-width-45" [(ngModel)]="item.quantity">
                        </label>
                      </div>
                      <i class="far fa-trash-alt float-right mt-4 hume-cart-delete" (click)="deleteBoardStep3(i)"></i>
                    </div>
                  </div>
                </div>
                <!-- End step3 accessories -->
            </div>

            <ng-template #emptyList>
              <div class="text-center g-py-50">
                <h2 class="g-color-primary">Empty List!</h2>
              </div>
            </ng-template>
            <!-- body-->

            <!--Footer-->
            <div class="modal-footer justify-content-center">
              <button mdbBtn class="btn-lg btn btn-hume-color waves-light my-3 mr-3" mdbWavesEffect (click)="openTab(previousStepName, '')">Add more items</button>
              <button mdbBtn type="submit" class="btn-lg btn btn-hume-color waves-light my-3" mdbWavesEffect (click)="alertModal.show()">Submit</button>
              <div class="error my-2 mb-4" *ngIf="formSubmitError">Submitted unsuccessfully, please try again later</div>
            </div>
          </div>
          <!-- End Footer-->
          <!--End Content-->
        </div>
      </div>
      <!-- Quote list -->

      <!-- Steppers Content -->
    </div>
  </div>
  <!-- /.Horizontal Steppers -->
  <!--<router-outlet></router-outlet>-->

  <!-- Floating shopping cart -->
  <!--<div class="fixed-action-btn" *ngIf="orderFormStep1.valid" >
    <a class="hume-float-shopping-cart g-color-white btn-lg waves-effect waves-light" (click)="openTab('step4')"
       data-dropdown-type="css-animation"
       data-dropdown-duration="300"
       data-dropdown-hide-on-scroll="false"
       data-dropdown-animation-in="fadeIn"
       data-dropdown-animation-out="fadeOut">
      <span class="u-badge-v1--sm g-color-white g-bg-black g-font-size-11 g-line-height-1_4 g-rounded-50x g-pa-4" style="top: 14px !important; right: 18px !important;">{{step2Submit.length + step3Submit.length}}</span>
      <i class="fa fa-file-text g-color-primary mt-2"></i>
    </a>
  </div>-->


  <!-- Success Alert modal -->
  <div mdbModal #successModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-body pt-5 text-center">
          <div class="g-color-primary g-font-size-18 my-2 mb-4">
            Submitted Successfully. Our customers care team will process your quote as soon as possible!
          </div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <button type="button" mdbBtn class="waves-light btn btn-hume-color" aria-label="Close" (click)="successModal.hide()" mdbWavesEffect>Close</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Success Alert modal -->

  <!-- Success Alert modal -->
  <div mdbModal #alertModal="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myBasicModalLabel" aria-hidden="true">
    <div class="modal-dialog" role="document">
      <div class="modal-content">
        <div class="modal-header">
        </div>
        <div class="modal-body text-center">
          <div class="g-color-primary g-font-size-18 my-2 mb-4">Are you sure to submit the order?</div>
        </div>
        <div class="modal-footer d-flex justify-content-center">
          <button mdbBtn type="submit" class="waves-light btn btn-hume-color mr-3" mdbWavesEffect (click)="orderFormSubmit()">Submit</button>
          <button type="button" mdbBtn class="waves-light btn btn-hume-color" aria-label="Close" (click)="alertModal.hide()" mdbWavesEffect>Close</button>
        </div>
      </div>
    </div>
  </div>
  <!-- Success Alert modal -->
</div>
