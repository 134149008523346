import {Component, OnDestroy, OnInit} from '@angular/core';
import {Store} from '../../../store';
import {Globals} from '../../global';
import {AuthService} from '../../services/auth/auth.service';
import {Subscription} from 'rxjs';

@Component({
  selector: 'app-profile',
  templateUrl: './side-menu.component.html',
  // styleUrls: ['./side-menu.component.scss']
})

export class SideMenuComponent implements OnInit, OnDestroy {
  currentUser: any;
  getUserDetailsSub: Subscription;
  activeLink;

  constructor(private store: Store, public globals: Globals,
              private authService: AuthService) { }

  ngOnInit() {
    this.getUserDetailsSub = this.authService.getLoginDetails().subscribe(async res => {
      this.currentUser = res;
      localStorage.setItem('currentUser', JSON.stringify(res));
    });
    this.changeLink(window.location.href);
    // this.currentUser = this.store.value.user;
  }

  changeLink(link) {
    const url = link.split('/');
    // this.activeLink = url[url.length - 1];
    this.activeLink = link;
  }

  ngOnDestroy() {
    if (this.getUserDetailsSub) { this.getUserDetailsSub.unsubscribe(); }
  }
}
