import { User } from './app/auth/models/user.interface';
import { Observable} from 'rxjs';
import { BehaviorSubject} from 'rxjs';
import { distinctUntilChanged, pluck} from 'rxjs/operators';
import {Cart, Product} from './app/products/models/product.interface';
import { Injectable } from "@angular/core";

export interface State {
  user: User;
  products: Product[];
  date: Date;
  cart: Cart[];
  [key: string]: any;
}

const state: State = {
  user: undefined,
  products: undefined,
  date: undefined,
  cart: undefined,
};

@Injectable()
export class Store {

  private subject = new BehaviorSubject<State>(state);
  private store = this.subject.asObservable().pipe(
    distinctUntilChanged()
  );

  get value() {
    return this.subject.value;
  }

  select<T>(name: string): Observable<T> {
    return this.store.pipe(
      pluck(name)
    );
  }

  set(name: string, state: any) {
    this.subject.next({ ...this.value, [name]: state });
  }

}
