<div class="single-component-container payment-content">
  <div class="g-width-60x--md g-width-95x px-3 px-md-5 mx-auto" *ngIf="order else noOrder">
    <h2 class="text-center g-color-black font-weight-bold text-uppercase p-5">Payment for <span class="g-color-primary">{{quotation}}</span></h2>

    <!-- Summary -->
    <div class="g-brd-around g-brd-gray-light-v4 rounded mb-3" *ngIf="quotation">
      <header class="g-bg-gray-light-v5 g-pa-10 g-pa-20--md u-shadow-v1-3">
        <div class="row">
          <div class="col-12">
            <div class="row g-mb-10 g-mb-20--sm pl-3">
              <div class="col-8 col-md-4 row">
                <h4 class="g-color-primary g-font-weight-500 text-uppercase g-mb-2">#{{order?.header?.number}}</h4>
              </div>

              <div class="col-6 col-md-3 row" *ngIf="currentUser?.access[0]?.toLowerCase() === 'full' || currentUser?.access[0]?.toLowerCase() === 'price'">
                <h4 class="g-color-gray-dark-v4 g-font-weight-400 pt-2 g-font-size-12 text-uppercase g-mb-2 mr-2">Total: </h4>
                <h4 class="g-color-primary g-font-weight-400 g-font-size-20 text-uppercase g-mb-2">{{order?.header?.grossAmount | currency: 'AUD': 'symbol-narrow'}}</h4>
              </div>

            </div>
            <div class="row">
              <div class="col-4 col-md-2 g-mb-10 g-mb-20--sm">
                <h4 class="g-color-gray-dark-v4 g-font-weight-400 g-font-size-12 text-uppercase g-mb-2">Order Placed</h4>
                <span class="g-color-black g-font-size-15">{{order?.header?.date}}</span>
              </div>

              <div class="col-4 col-md-2 g-mb-10 g-mb-20--sm">
                <h4 class="g-color-gray-dark-v4 g-font-weight-400 g-font-size-12 text-uppercase g-mb-2">Branch</h4>
                <span class="g-color-black g-font-size-15">{{order?.header?.branch}}</span>
              </div>

              <div class="col-4 col-md-2 g-mb-10 g-mb-20--sm">
                <h4 class="g-color-gray-dark-v4 g-font-weight-400 g-font-size-12 text-uppercase g-mb-2">Method</h4>
                <span class="g-color-black g-font-size-15">{{order?.header?.deliveryMethod}}</span>
              </div>

              <div class="col-4 col-md-2 g-mb-10 g-mb-20--sm">
                <h4 class="g-color-gray-dark-v4 g-font-weight-400 g-font-size-12 text-uppercase g-mb-2">Weight</h4>
                <span class="g-color-black g-font-size-15">{{order?.weight | number: '1.2-2'}} Kg</span>
              </div>

              <div class="col-4 col-md-2 g-mb-10 g-mb-20--sm" *ngIf="order?.header?.weight">
                <h4 class="g-color-gray-dark-v4 g-font-weight-400 g-font-size-12 text-uppercase g-mb-2">Sqm</h4>
                <span class="g-color-black g-font-size-15">{{order?.sqm | number: '1.2-2'}} m<sup>2</sup></span>
              </div>

              <div class="col-4 col-md-2 g-mb-10 g-mb-20--sm">
                <h4 class="g-color-gray-dark-v4 g-font-weight-400 g-font-size-12 text-uppercase g-mb-2">Status</h4>
                <span class="g-color-black g-font-size-15">{{order?.header?.status}}</span>
              </div>
            </div>

            <div class="row" *ngIf="order?.header?.reference">
              <div class="col-8 g-mb-10 g-mb-20--sm">
                <h4 class="g-color-gray-dark-v4 g-font-weight-400 g-font-size-12 text-uppercase g-mb-2">Reference</h4>
                <span class="g-color-black g-font-size-15">{{order?.header?.reference}}</span>
              </div>
            </div>

            <div class="row" *ngIf="order?.contact">
              <div class="col-12 g-mb-10 g-mb-20--sm">
                <h4 class="g-color-gray-dark-v4 g-font-weight-400 g-font-size-12 text-uppercase g-mb-2">Deliver to</h4>
                <p class="g-color-black g-font-size-15">{{order?.contact?.fullName}}</p>
                <p class="g-color-black g-font-size-15">{{order?.contact?.mobile}}</p>
                <p class="g-color-black g-font-size-15">{{order?.contact?.email}}</p>
                <p class="g-color-black g-font-size-15">{{formatAddress(order?.contact?.address)}}</p>
              </div>
            </div>
          </div>
        </div>
      </header>

      <!-- Order Content -->
      <div class="g-pa-5 g-pa-20--sm" id="order-content">
        <div class="row g-mb-20">
          <div class="col-md-6 col-4">
            <h4 class="g-color-gray-dark-v4 g-font-weight-400 g-font-size-12 text-uppercase g-mb-2">Product</h4>
          </div>
          <div class="col-2">
            <h4 class="g-color-gray-dark-v4 g-font-weight-400 g-font-size-12 text-uppercase g-mb-2">Qty Ordered</h4>
          </div>
          <div class="col-2">
            <h4 class="g-color-gray-dark-v4 g-font-weight-400 g-font-size-12 text-uppercase g-mb-2">Weight</h4>
          </div>
          <div class="col-2" *ngIf="currentUser?.access[0]?.toLowerCase() === 'full' || currentUser?.access[0]?.toLowerCase() === 'price'">
            <h4 class="g-color-gray-dark-v4 g-font-weight-400 g-font-size-12 text-uppercase g-mb-2">Gross Amount</h4>
          </div>
        </div>
          <div class="row g-mb-20" *ngFor="let item of order?.lines">
            <div class="col-md-6 col-4 col-sm-4">
              <a [routerLink]="'/product/' + item?.code">
                <span class="g-color-black g-font-size-13 g-font-size-16--md">{{item?.description}}</span>
              </a>
            </div>

            <div class="col-2">
              <span class="g-color-black g-font-size-15">{{item?.quantity}}</span>
            </div>
            <div class="col-2">
              <span class="g-color-black g-font-size-15">{{item?.weight | number: '1.2-2'}} kg</span>
            </div>
            <div class="col-2" *ngIf="currentUser?.access[0]?.toLowerCase() === 'full' || currentUser?.access[0]?.toLowerCase() === 'price'">
              <span class="g-color-black g-font-size-15">{{item?.grossAmount | currency: 'AUD': 'symbol-narrow'}}</span>
            </div>
          </div>

      </div>
      <!-- End Order Content -->

      <div class="text-center py-3 content-more" *ngIf="order?.lines?.length > 2">
        <button class="btn btn-xl u-btn-outline-primary text-uppercase g-font-weight-600 g-font-size-12 shadow-none" (click)="expandList()">{{ showButton ? 'View More' : 'Hide'}}</button>
      </div>
    </div>
    <!-- End Summary -->


    <div id="accordion-06" class="u-accordion mt-3 mt-sm-5" role="tablist" aria-multiselectable="false">


      <!-- Card -->
      <div class="card rounded-0 g-pa-50--lg g-py-20 g-bg-gray-light-v4 g-brd-none mb-4">
        <h2 class="text-center mb-3">Total: <span class="g-color-primary">{{order?.header?.grossAmount | currency: 'AUD': 'symbol-narrow'}}</span></h2>
        <hr>
        <div id="accordion-07" class="u-accordion mx-auto" role="tablist" aria-multiselectable="false">

          <!-- Card -->
          <div class="card rounded-0 g-bg-gray-light-v4 g-brd-none mb-3">
            <div id="accordion-07-heading-01" class="u-accordion__header g-pa-20" role="tab">
              <h5 class="mb-0 text-uppercase g-font-size-default g-font-weight-700 g-color-black">
                <label class="container">
                  <input type="checkbox" [checked]="orderButton === 'card'" [disabled]="accountBalance?.creditStatus.toLowerCase() !== 'active'" (change)="paymentChange($event, 'card')" class="checkbox">
                  <span class="checkmark mr-2"></span>Credit/debit card
                </label>
                <a class="d-flex g-color-main g-text-underline--none--hover" href="#accordion-06-body-01" data-toggle="collapse" data-parent="#accordion-06" [attr.aria-expanded]="orderButton === 'card'" aria-controls="accordion-06-body-01">
                </a>
              </h5>
            </div>
            <div id="accordion-07-body-01" class="collapse" [ngClass]="orderButton === 'card' ? 'show' : ''" role="tabpanel" aria-labelledby="accordion-06-heading-01" data-parent="#accordion-06">

              <!-- Secure pay-->
              <form onsubmit="return false;" class="g-ml-30--sm g-ml-10 border-0">
                <div id="pay-container" class="g-width-100x g-pl-10"></div>
              </form>
              <!-- End secure pay-->
            </div>
          </div>
          <!-- End Card -->

          <!-- Hume Credit -->
          <div class="card rounded-0 g-bg-gray-light-v4 g-brd-none mb-3" *ngIf="accountBalance?.creditStatus.toLowerCase() === 'active'">
            <div id="accordion-07-heading-02" class="u-accordion__header g-pa-20" role="tab">
              <h5 class="mb-0 text-uppercase g-font-size-default g-font-weight-700 g-color-black">
                <label class="container">
                  <input type="checkbox" [checked]="orderButton === 'credit'" (change)="paymentChange($event, 'credit')" class="checkbox">
                  <span class="checkmark mr-2"></span>Pay by Hume credit balance <span class="ml-2 g-color-primary text-capitalize">Available credit({{accountBalance?.availableCredit | currency: 'AUD': 'symbol-narrow'}})</span>
                </label>
                <a class="collapsed d-flex g-color-main g-text-underline--none--hover" href="#accordion-06-body-02" data-toggle="collapse" data-parent="#accordion-06" [attr.aria-expanded]="orderButton === 'paypal'" aria-controls="accordion-06-body-02">
                </a>
              </h5>
            </div>
            <div id="accordion-07-body-02" class="collapse" [ngClass]="orderButton === 'credit' ? 'show' : ''" role="tabpanel" aria-labelledby="accordion-06-heading-02" data-parent="#accordion-06">
            </div>
          </div>
          <!-- End Hume Credit -->
        </div>

      </div>
      <!-- End Card -->

    </div>

    <div class="my-5 text-center">
      <button class="hume-btn btn-border-no-shadow u-btn-black text-uppercase g-font-size-15"
              (click)="paymentSubmit()">Make payment
        </button>
      <div class="error mt-2" *ngIf="placeButtonError">
        {{placeButtonError}}
      </div>
    </div>

  </div>
  <ng-template #noOrder>
    <section class="container g-py-100">
      <div class="row justify-content-center">
        <div class="col-sm-9 col-md-7 col-lg-5">
          <div class="g-brd-around g-brd-gray-light-v3 g-bg-white rounded g-px-30 g-py-50 mb-4">
            <header class="text-center mb-4">
              <p class="h1">Error!</p>
              <h3 class="py-4 g-font-weight-400 g-color-primary">No record found matching this quotation number. Please try other number.</h3>
              <!--<p>We are redirecting you to products page...</p>-->
            </header>
          </div>
        </div>
      </div>
    </section>
  </ng-template>
</div>
