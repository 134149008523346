import {Component, OnDestroy, OnInit} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import {Globals} from '../../global';
import {Subscription} from 'rxjs';
import {NewsService} from '../../services/news.service';
import {Image } from '@ks89/angular-modal-gallery';
import {NgbCarouselConfig} from '@ng-bootstrap/ng-bootstrap';
import {DomSanitizer} from '@angular/platform-browser';
import {SEOService} from '../../services/seo/seo.service';

declare var $: any;

@Component({
  selector: 'app-single-news',
  templateUrl: './single-news.component.html'
})
export class SingleNewsComponent implements OnInit, OnDestroy {
  singleNews;
  relatedNews;
  singleNewsSub: Subscription;
  newsListSub: Subscription;
  routeParamsSubscription: Subscription;
  routeUrlSubscription: Subscription;
  imgUrl = this.globals.picStorageRef;
  htmlContent;


  imageIndex = 1;
  autoPlay = true;
  showArrows = true;
  showDots = true;

  imagesRect: Image[] = [];

  constructor(
    private router: Router,
    private route: ActivatedRoute,
    public  globals: Globals,
    private newsService: NewsService,
    private sanitizer: DomSanitizer,
    private seoService: SEOService,
    config: NgbCarouselConfig) {
    config.showNavigationIndicators = false;
  }

  ngOnInit () {
    for (let i = 1; i < 10; i ++ ) {
      this.imagesRect.push(new Image(i,
        { img: 'https://s3-ap-southeast-2.amazonaws.com/hume-website-photo/news/Hume_Prestons_Demo/demo' + i + '.jpeg' },
        { img: 'https://s3-ap-southeast-2.amazonaws.com/hume-website-photo/news/Hume_Prestons_Demo/demo' + i + '.jpeg' }));
    }


    this.routeUrlSubscription = this.route.url.subscribe(
      urlData => {
        if (urlData[0].path === 'single-news') {
          this.routeParamsSubscription = this.route.queryParams.subscribe(
            data => {
              if (data.id) { this.getSingleNews(data.id); }
            }
          );
        } else {
            const path = urlData[0].path.split('_').join(' ');
            this.newsListSub = this.newsService.getNewsList().subscribe( res => {
              const singleNews = res.response.filter( res1 => res1.title === path)[0];
              this.getSingleNews(singleNews.newsId);
            });
        }
      });
  }

  getSingleNews(data) {
    this.singleNewsSub = this.newsService.getSingleNews(data).subscribe( res2 => {
      this.singleNews = res2.response;
      /* SEO */
      this.seoService.setMetaObjectInfo(this.singleNews);
      this.seoService.setPageTitle(this.singleNews?.title);
      this.seoService.createLinkForCanonicalURL();
      /* SEO */
      this.htmlContent = this.sanitizer.bypassSecurityTrustHtml(this.singleNews.content);
      this.getRelatedNews(this.singleNews);
    });
  }

  getRelatedNews (data) {
    this.newsListSub = this.newsService.getNewsListByCat(data.category.id).subscribe(news => {
      this.relatedNews = news.response.filter(ref => ref.newsId !== parseInt(data.id, 10));
      this.relatedNews.reverse();
      this.relatedNews = this.relatedNews.slice(0, 3);
    });
  }

  ngOnDestroy() {
    if (this.newsListSub) { this.newsListSub.unsubscribe(); }
    if (this.singleNewsSub) { this.singleNewsSub.unsubscribe(); }
    if (this.routeParamsSubscription) { this.routeParamsSubscription.unsubscribe(); }
    if (this.routeUrlSubscription) { this.routeUrlSubscription.unsubscribe(); }
  }
}
