import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {ProductsComponent} from './containers/products.component';
import {ListProductsComponent} from './list-products/list-products.component';
import {NgxPaginationModule} from 'ngx-pagination';
import {ButtonsModule, CollapseModule, DropdownModule, ModalModule, WavesModule} from 'angular-bootstrap-md';
import {SingleProductComponent} from './single-product/single-product.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {ProductsSubCategoriesComponent} from './sub-categories/sub-categories.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {NgxCaptchaModule} from 'ngx-captcha';
import {ProductsSideBarComponent} from './left-side-bar/left-side-bar.component';
import {OrderModule} from 'ngx-order-pipe';
import {FilterPipeModule} from 'ngx-filter-pipe';
import {PipesModule} from '../pipe/pipes.module';
import {FontAwesomeModule} from '@fortawesome/angular-fontawesome';
import {ShoppingModule} from '../shopping/shopping.module';
import {SharedModule} from '../shared/shared.module';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {MatMenuModule} from '@angular/material/menu';
import {AccordionModule} from 'primeng/accordion';
import {MatExpansionModule} from '@angular/material/expansion';
import {NgxImageZoomModule} from 'ngx-image-zoom';
import {CheckboxModule} from 'primeng/checkbox';
import {PaginatorModule} from 'primeng/paginator';
import {HomeModule} from '../home/home.module';
// import {AccordionModule} from 'ngx-bootstrap/accordion';

export const ROUTES: Routes = [
  // Disable this one when for e-commerce
  // { path: 'products', component: ProductsComponent}

  // Enable this part when not for e-commerce
  { path: 'products',
    children : [
      { path: '', component: ProductsComponent,
        children : [
          { path: 'specials', component: ListProductsComponent },
          { path: 'clearance', component: ListProductsComponent },
          { path: 'eofy', component: ListProductsComponent },
        ]
      },
      { path: '**', component: ListProductsComponent}
    ],
    data: {
      title: 'Hume Products',
      ogUrl: '/products',
      description: 'Hume provides a wide range of building products tools and accessories.'
    }
  },
  { path: 'single-product', component: SingleProductComponent,
    children : [
      { path: ':title', component: SingleProductComponent }
    ]
  },
  { path: 'product', component: SingleProductComponent,
    children : [
      { path: ':title', component: SingleProductComponent }
    ]
  },
];

@NgModule({
  imports: [
    FormsModule,
    ReactiveFormsModule,
    CommonModule,
    NgxPaginationModule,
    NgxCaptchaModule,
    NgbModule,
    OrderModule,
    FilterPipeModule,
    PipesModule,
    CollapseModule.forRoot(),
    RouterModule.forChild(ROUTES),
    WavesModule,
    ButtonsModule,
    FontAwesomeModule,
    DropdownModule,
    ModalModule,
    ShoppingModule,
    SharedModule,
    NgxSkeletonLoaderModule,
    MatMenuModule,
    AccordionModule,
    MatExpansionModule,
    NgxImageZoomModule,
    AccordionModule,
    CheckboxModule,
    PaginatorModule,
    AccordionModule,
    AccordionModule,
    HomeModule,
  ],
  declarations: [
    ProductsComponent,
    ListProductsComponent,
    SingleProductComponent,
    ProductsSubCategoriesComponent,
    ProductsSideBarComponent
  ],
  exports: [
    ProductsComponent,
    ListProductsComponent,
    SingleProductComponent,
    ProductsSubCategoriesComponent,
    ProductsSideBarComponent
  ],
  providers: [],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class ProductsModule { }
