

<div class="single-component-container">

  <div class="container g-pt-20 g-pt-70--md g-pb-30">
    <div class="row">
      <!-- Profile Settings -->
      <div class="col-lg-3 g-mb-50--md">
        <aside class="g-brd-around g-brd-gray-light-v4 rounded g-px-20 g-py-30 g-hidden-sm-down">
          <router-outlet name="side-menu"></router-outlet>

        </aside>
        <div class="g-py-30--md g-pt-30 g-hidden-sm-up" *ngIf="width < 768">
          <router-outlet></router-outlet>
          <div class="py-0 py-sm-5 g-mt-60 g-mt-0--sm">
            <button class="btn btn-block u-btn-outline-primary shadow-none g-rounded-25 rounded g-py-8 g-px-18 g-font-size-16 font-weight-bold" (click)="signOut()" type="button">Sign Out</button>
          </div>
        </div>

        <!-- key accounts team contact-->
        <aside class="g-brd-around g-brd-gray-light-v4 rounded g-px-20 g-py-30 mt-3"  *ngIf="width > 768 && currentUser?.type === 2">
          <h3 class="h5 mb-3">Key Accounts Team Contacts</h3>
          <div class="row">
            <div class="col-12">
              <span class="d-block g-color-text g-font-size-13 mb-1">Contact Number:</span>

              <span class="d-block g-color-black"><i class="fa fa-phone mr-2"></i><a href="tel:0297314166">02 9731 4166</a></span>
            </div>
            <div class="col-12">
              <span class="d-block g-color-text g-font-size-13 mb-1">Operating Hours:</span>
              <span class="d-block g-color-black"><i class="fa fa-clock-o mr-2"></i>6:30 AM - 5:00 PM, Mon to Fri</span>
            </div>
          </div>
        </aside>
        <!--End key accounts team contact-->

        <div class="py-0 py-sm-5 g-mt-60 g-mt-0--sm"  *ngIf="width > 768">
          <button class="btn btn-block u-btn-outline-primary shadow-none g-rounded-25 rounded g-py-8 g-px-18 g-font-size-16 font-weight-bold" (click)="signOut()" type="button">Sign Out</button>
        </div>
      </div>
      <!-- End Profile Settings -->
      <div class="col-lg-9 g-mb-50 g-color-black" *ngIf="width >= 768">
        <router-outlet></router-outlet>
      </div>
    </div>
  </div>
</div>




