import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-privacy',
  templateUrl: './privacy.component.html',
})
export class PrivacyComponent implements OnInit {

  ngOnInit() {
  }
}
