import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {HumeStoresService} from '../../services/hume-stores.service';
import {Globals} from '../../global';
import {TestimonialService} from '../../services/testimonial.service';
import {Title} from '@angular/platform-browser';
import {TranslateService} from '@ngx-translate/core';
import {SEOService} from '../../services/seo/seo.service';
import {TabsetConfig} from 'ngx-bootstrap/tabs';
import {NewsService} from '../../services/news.service';
declare var $: any;

export function getTabsetConfig(): TabsetConfig {
  return Object.assign(new TabsetConfig(), { type: 'pills', isKeysAllowed: true });
}

@Component({
  selector: 'app-about',
  styleUrls: ['./about.component.scss'],
  templateUrl: './about.component.html',
  providers: [{ provide: TabsetConfig, useFactory: getTabsetConfig }]
})
export class AboutComponent implements OnInit, OnDestroy {
  branchesSub: Subscription;
  nswBranches;
  vicBranches;
  imgUrl = this.globals.picStorageRef;
  testimonials;
  arcTestimonials;
  testimonialSub: Subscription;
  brochures;
  archBrochure;

  constructor(private testimonialService: TestimonialService,
              private translateService: TranslateService, private newsService: NewsService,
              private humeStoresService: HumeStoresService,
              private title: Title, private seoService: SEOService,
              public globals: Globals) {
    const ref = window.location.href;
    if (ref.includes('about-cn')) {
      translateService.use('cn');
    } else {
      translateService.use('en');
    }
    if (ref.includes('archi')) {
      this.globals.divisionTag = 'architectural';
    } else if (ref.includes('commercial')) {
      this.globals.divisionTag = 'commercial';
    } else {
      this.globals.divisionTag = 'home';
    }
  }

  ngOnInit () {
    /* Meta data */
    this.title.setTitle('About Hume Building Products');
    this.seoService.createLinkForCanonicalURL();
    /* End Meta data */

    this.newsService.getBrochures().toPromise().then(res => {
      this.brochures = res.response;
      this.archBrochure = res.response.filter(res1 => res1.title?.toLowerCase().includes('architectural'));
    });

    this.testimonialService.getTestimonial().subscribe(ref => {
      this.testimonials = ref.response;
      if (this.globals.divisionTag === 'architectural') {
        this.arcTestimonials = ref.response.filter( data => data.id === 8 || data.id === 3 || data.id === 4 || data.id === 7);
      }
    });
    $(document).ready(function() {
      $(this).scrollTop(0);

      // initialization of tabs
       /*$.HSCore.components.HSTabs.init('[role="tablist"]');

      // initialization of cubeportfolio
      setTimeout(function () {
        $('#grid-filterControls').cubeportfolio({
          filters: '#filterControls',
          defaultFilter: '.js-history',
          height: '470px',
        });
      }, 500);

      $(window).on('resize', function () {
        setTimeout(function () {
          $.HSCore.components.HSTabs.init('[role="tablist"]');
        }, 200);
      });*/
    });

    this.branchesSub = this.humeStoresService.getStoresList().subscribe(res => {
      this.nswBranches = res.response.filter(data => data.state === 'NSW');
      this.vicBranches = res.response.filter(data => data.state === 'VIC');
    });
  }

  ngOnDestroy() {
    this.branchesSub.unsubscribe();
  }
}
