 import { Injectable } from '@angular/core';
import {HttpRequest, HttpHandler, HttpEvent, HttpInterceptor } from '@angular/common/http';
import {Observable, throwError} from 'rxjs';
import {Globals} from '../../global';
 import {AuthService} from './auth.service';
 import { Router} from '@angular/router';
 import {catchError} from 'rxjs/operators';

@Injectable()
export class JwtInterceptor implements HttpInterceptor {
  isRefreshingToken = false;

  constructor(public globals: Globals, private authService: AuthService, private router: Router) {
  }

  addToken(req: HttpRequest<any>, token: string): HttpRequest<any> {
    return req.clone({ setHeaders: { Authorization: token }});
  }

  intercept(request: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {


    return next.handle(this.addToken(request, localStorage.getItem('HumeR') ? localStorage.getItem('HumeR') : '')).pipe(catchError(err => {
      if ([401, 403].includes(err.status) && !err.url.includes('/auth/')) {
        this.isRefreshingToken = true;
        // @ts-ignore
        if (this.authService.currentUserValue?.username !== 'info@humeplaster.com.au' &&
          (window.location.href.includes('/account') || window.location.href.includes('/checkout'))) {
          this.authService.logoutUser();
          return next.handle(request);
        } else {
          this.authService.setUserInfo();
          this.authService.getCashSaleToken().toPromise().then(async res => {
            this.isRefreshingToken = false;
            this.globals.token = res;
            return next.handle(this.addToken(request, await res));
          });
        }
      } else {
        // const error = (err && err.error && err.error.message) || err.statusText;
        return throwError(err);
      }
    }));
  }
}
