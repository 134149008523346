import { NgModule } from '@angular/core';
import { CommonModule} from '@angular/common';
import { RouterModule, Routes} from '@angular/router';
import {StoresComponent} from './stores.component';
import {HomeModule} from '../home/home.module';
import {MatMenuModule} from '@angular/material/menu';
import {ModalModule} from 'angular-bootstrap-md';
import {SharedModule} from '../shared/shared.module';

export const ROUTES: Routes = [
  { path: 'hume-stores', children: [
      { path: ':title', component: StoresComponent}
    ]
  },
  { path: 'hume-in-sydney', component: StoresComponent},
  { path: 'hume-in-melbourne', component: StoresComponent},
  { path: 'hume-preston', component: StoresComponent},
  { path: 'hume-south-granville', component: StoresComponent},
  { path: 'hume-yennora', component: StoresComponent},
  { path: 'hume-silverwater', component: StoresComponent},
  { path: 'hume-alexandria', component: StoresComponent},
  { path: 'hume-lakemba', component: StoresComponent},
  { path: 'hume-pymble', component: StoresComponent},
  { path: 'hume-prestons', component: StoresComponent},
  { path: 'hume-sunshine-west', component: StoresComponent}
];

@NgModule({
  imports: [
    CommonModule,
    HomeModule,
    RouterModule.forChild(ROUTES),
    MatMenuModule,
    SharedModule,
    ModalModule
  ],
  declarations: [
    StoresComponent
  ],
  exports: [],
  providers: []
})
export class StoresModule { }
