import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Globals} from '../../global';
import {Subscription} from 'rxjs';
import {ActivatedRoute} from '@angular/router';
import {ProductService} from '../../services/product.service';
import {InventoryCategoriesService} from '../../services/inventory-categories.service';

@Component({
  selector: 'app-categories-sub-cladding',
  templateUrl: './cladding.component.html',
})
export class CladdingComponent implements OnInit, OnDestroy {
  @Input() subCat;
  imgUrl = this.globals.picStorageRef;
  catWebSubscription: Subscription;
  routeParamsSubscription: Subscription;
  relatedSubCats;

  constructor(public globals: Globals, private catService: InventoryCategoriesService,
              private route: ActivatedRoute, private productService: ProductService) {}

  ngOnInit() {
    this.routeParamsSubscription = this.route.url.subscribe( resUrl => {
      this.catWebSubscription = this.productService.getEWebCatListAll().subscribe( res => {
        // @ts-ignore
        const temp = res.filter(res1 => res1.parent === 6 && (res1.id === 36 ||
          res1.id === 37 || res1.id === 38 || res1.id === 39 ||
          res1.id === 33 || res1.id === 18 || res1.id === 21));
        const result = temp.filter(res1 => res1.link !== resUrl[0].path.split('_').join(' '));
        this.relatedSubCats = this.shuffle(result);
      });
    });
  }

  shuffle(array) {
    let counter = array.length;
    // While there are elements in the array
    while (counter > 0) {
      // Pick a random index
      const index = Math.floor(Math.random() * counter);
      // Decrease counter by 1
      counter--;
      // And swap the last element with it
      const temp = array[counter];
      array[counter] = array[index];
      array[index] = temp;
    }
    return array;
  }

  /*** Testing Open PDF ***/
  openLink(url) {
    this.catService.downloadPDF(url).subscribe(res => {
    });
  }
  /*** Testing Open PDF ***/

  ngOnDestroy() {
    if (this.catWebSubscription) { this.catWebSubscription.unsubscribe(); }
    if (this.routeParamsSubscription) { this.routeParamsSubscription.unsubscribe(); }
  }

}
