import {Injectable} from '@angular/core';
import {
  ActivatedRouteSnapshot,
  CanActivate,
  Router,
  RouterStateSnapshot
} from '@angular/router';
import {AuthService} from '../../services/auth/auth.service';
import {Globals} from '../../global';

@Injectable()
export class AuthGuard implements  CanActivate {
  constructor (
    private router: Router,
    public globals: Globals,
    private  authService: AuthService,
  ) {}

  canActivate(route: ActivatedRouteSnapshot, state: RouterStateSnapshot) {

    if (!this.authService.isTokenExpired()) {
      // @ts-ignore
      if (this.authService.currentUserValue && this.authService.currentUserValue?.username !== 'info@humeplaster.com.au') {
        return true;
      }
    }

    this.router.navigate(['auth/login']);
    return false;
  }
}

