<div class="single-component-container">
  <!-- Promo Block -->
  <section class="u-bg-overlay category-grid-img g-bg-bluegray-opacity-0_3--after g-height-250 g-height-350--md"
           [ngStyle]="!mobile ? {'background-image': 'url(' + imgUrl + subCat?.bannerUrl + ')'} : {'background-image': 'url(' + imgUrl + subCat?.thumbnailUrl + ')'}" *ngIf="subCat">
    <div class="container u-bg-overlay__inner text-center g-pos-rel g-top-30x">
      <div class="g-mb-100 about-title-overlay">
        <!--<span class="d-block g-color-white g-font-size-20 text-uppercase g-letter-spacing-5 mb-4">Hume In Syndey</span>-->
        <img *ngIf="subCat?.id === 44" class="img-fluid mb-4" src="../../../assets/img/logo/Hume Logo White.png" alt="Hume" width="40%"/>
        <h1 class="g-color-white g-font-weight-700 g-font-size-20 g-font-size-50--md text-uppercase g-line-height-1_2 g-letter-spacing-10 mb-4">{{subCat?.name}}</h1>
        <span class="d-block lead g-color-white g-letter-spacing-2" style="font-weight: 500"></span>
      </div>
    </div>
  </section>
  <!-- End Promo Block -->

  <!-- Portfolio Single Item -->
  <section class="container g-pt-10">
    <div class="row my-3">
      <a class="col-6 text-right" href="tel:0296819096" *ngIf="subCat?.email === 'enquiry@humearchitectural.com.au' || subCat?.email === 'enver.k@humearchitectural.com.au'; else others" >
        <button class="ml-3 btn btn-hume-color g-bg-primary waves-light waves-effect font-weight-bold g-font-size-16 g-font-size-18--md"
                [ngClass]="globals.isMobile ? 'btn-sm' : 'btn-lg'" type="button">
          <!-- ID 33 is CFC category -->
          Call 02 9681 9096</button>
      </a>
      <ng-template #others>
        <a class="col-6 text-right" [href]="subCat?.id === 33 ? 'tel:0296819096' : 'tel:134863'">
          <button class="ml-3 btn btn-hume-color g-bg-primary waves-light waves-effect font-weight-bold g-font-size-16 g-font-size-18--md"
                  [ngClass]="globals.isMobile ? 'btn-sm' : 'btn-lg'" type="button">
            {{subCat?.id === 33 ? 'Call 02 9681 9096' : 'Call 13 4863'}}</button>
        </a>
      </ng-template>
      <a class="col-6 text-left" (click)="frame.show()">
        <button type="button" mdbBtn class="btn btn-hume-color waves-light waves-effect font-weight-bold g-font-size-16 g-font-size-18--md"
                [ngClass]="globals.isMobile ? 'btn-sm' : 'btn-lg'" mdbWavesEffect>Request Quote</button>
      </a>
    </div>

    <div class="row" [ngClass]="subCat?.id !== 36 ? 'g-mb-30' : ''" *ngIf="pageContent">
      <div class="col-md-12 g-mb-10">
        <div class="mb-3 pt-0 pt-sm-5 g-font-size-17" [innerHTML]="pageContent">
        </div>
        <!--<a class="btn btn-md u-btn-primary g-font-size-default g-rounded-25 g-px-30 g-py-12" href="#!">Launch website</a>-->
      </div>
    </div>
  </section>
  <!-- End Portfolio Single Item -->


  <!-- Gallery for kitchens, and our partners for kitchens and bathrooms-->
  <div class="container" *ngIf="subCat?.id === 14 || subCat?.id === 22">
    <hr *ngIf="subCat?.id === 14">
    <div class="row mb-3" *ngIf="subCat?.id === 14">
      <div class="col-12 col-md-4 mb-3 projects-img" >
        <img [src]="imgUrl + 'categories-web/kitchens/Kitchen.jpg'" style="height: 270px" class="img-fluid"
             alt="Hume Kitchens">
        <!--<span class="hume-absolute-left-bottom g-color-white g-font-size-15" style="bottom:1px !important; left: 140px !important;"></span>-->
      </div>
      <div class="col-12 col-md-4 mb-3 projects-img" >
        <img [src]="imgUrl + 'categories-web/kitchens/Kitchen2.jpg'" style="height: 270px" class="img-fluid"
             alt="Hume Kitchens">
      </div>
      <div class="col-12 col-md-4 mb-3 projects-img" >
        <img [src]="imgUrl + 'categories-web/kitchens/Kitchen3.jpg'" style="height: 270px" class="img-fluid"
             alt="Hume Kitchens">
        <!--<span class="hume-absolute-left-bottom g-color-white g-font-size-15" style="bottom:-12px !important; left: 149px !important;"></span>-->
      </div>
    </div>
    <div class="my-5">
      <h4>Our Partners:</h4>
      <div class="row">
        <div class="item col-6 col-md-3" *ngFor="let brand of brands">
          <div class="partners-logo" style="text-align:center;">
            <img class="container-fluid" src="{{imgUrl + brand.photoUrl}}" alt="{{brand.name}}">
          </div>
        </div>
      </div>
    </div>
  </div>

  <!-- Gallery for kitchens -->

  <!-- Show when it is certain sub category of cladding -->
  <app-categories-sub-cladding [subCat]="subCat"></app-categories-sub-cladding>
  <!-- Show when it is certain sub category of cladding -->

  <!-- Show quote form modal -->
  <div mdbModal #frame="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="overflow: scroll">
    <div class="modal-dialog" role="document">
      <app-quote-form [catId]="catWebId" [frame]="frame"></app-quote-form>
    </div>
  </div>
  <!-- Show quote form modal -->
</div>
