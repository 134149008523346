import {AfterViewInit, Component, OnInit, ViewChild} from '@angular/core';
import {Store} from '../../../store';
import {AuthService} from '../../services/auth/auth.service';
import {Globals} from '../../global';
import {HumeStoresService} from '../../services/hume-stores.service';
import {AlertService} from '../../services/alert.service';
import {ShoppingCartService} from '../../services/shopping-cart.service';
import {Router} from '@angular/router';
import {FormBuilder, Validators} from '@angular/forms';
import {first} from 'rxjs/operators';
import {UserService} from '../../services/user/user.service';
import {GooglePlacesComponent} from '../../shared/ecommerce/google-places/google-places.component';

declare var $: any;
declare var securePayUI: any;
declare var google;

@Component({
  selector: 'app-checkout',
  templateUrl: './checkout.component.html',
  styleUrls: ['./checkout.component.scss']
})

export class CheckoutComponent implements OnInit, AfterViewInit {
  @ViewChild(GooglePlacesComponent) googlePlaceCom;
  cart: any;
  total: number;
  orderButton = 'card';
  selectedAddress = null;
  selectedAddressOption = 'Please select your delivery address';
  stores;
  selectedStore;
  deliveryFee;
  mySecurePayUI;
  currentUser;
  placeButtonError = null;
  isSubmitted = false;
  addressSubmitted = false;
  deliveryError = null;
  cardFormValid;
  isNsw = true;

  contacts;
  address;
  showPickUp = true;
  timeSlot = [{id: 0, name: '1st AM'}, {id: 1, name: '2nd AM'}, {id: 2, name: 'AM'}, {id: 3, name: 'Anytime'}];
  unloadMethods = [{id: 0, name: 'Hand unload by our drivers'}, {id: 1, name: 'Crane/Forklift unload'},
    {id: 2, name: 'Your own men unload'}];
  showNewAddress = false;
  minDate;

  signInForm = this.fb.group({
    email: ['', Validators.required],
    password: ['', Validators.required]
  });

  pickUpForm = this.fb.group({
    pickupBy: ['', Validators.required], dueDate: ['', Validators.required],
    timeSlot: [0, Validators.required], orderNumber: [''], craneLimit: [0],
    instructions: [''], strap: [''], wrap: [''], onPallet: [''], branch: ['YEN'],
  });

  deliveryForm = this.fb.group({
    unloadMethod: [0, Validators.required], dueDate: ['', Validators.required], longWalk: [0, Validators.required],
    timeSlot: [0, Validators.required], passUp: [0, Validators.required], orderNumber: [''], craneLimit: [0],
    instructions: [''], strap: [''], wrap: [''], onPallet: [''],
  });

  submitted;
  submitInfo;
  accountBalance;

  get pf() { return this.pickUpForm; }
  get df() { return this.deliveryForm; }

  constructor(
    public globals: Globals,
    private fb: FormBuilder,
    private storeService: HumeStoresService,
    private alertService: AlertService,
    private shoppingCartService: ShoppingCartService,
    private authService: AuthService, private userService: UserService,
    private router: Router,
    private store: Store,
  ) {
    this.minDate = new Date();
    this.minDate.setDate(this.minDate.getDate() + 1);
    this.getStores();

    this.authService.currentUser.subscribe(x => {
      this.currentUser = x;
      if (this.currentUser?.type === 2 && this.currentUser?.access[0] === 'FULL') {
        this.userService.getCustomerCreditBalance().toPromise().then(res => {
          this.accountBalance = res;
        });
      }
    });
  }

  ngOnInit() {
    this.getContacts();
  }

  ngAfterViewInit() {
    this.securePayInit();
    this.eventListeners();
  }

  addNewAddress() {
    this.selectedAddress = null;
    this.showNewAddress = true;
  }

  selectAddress(index) {
    this.showNewAddress = false;
    this.selectedAddress = this.address[index];
    this.selectedAddressOption = this.selectedAddress.address + ' - ' + this.selectedAddress.name;
    this.estimateDelivery();
    this.isNsw = this.selectedAddress.address.indexOf('NSW') !== -1 || this.selectedAddress.address.indexOf('New South Wales') !== -1;
  }

  cancelAddAddress() {
    this.selectedAddressOption = 'Please select your delivery address';
    this.showNewAddress = false;
    this.addressSubmitted = false;
  }

  async saveAddress() {
    this.addressSubmitted = true;
    if (this.googlePlaceCom.addressForm.valid) {
      this.alertService.loading('Saving contact...');
      const formValue = this.googlePlaceCom.addressForm.value;

      this.userService.addDeliveryContact(formValue).subscribe(res => {
        this.addressSubmitted = false;
        this.alertService.saveSuccess();
        this.googlePlaceCom.addressForm.reset();
        this.googlePlaceCom.autocomplete = { input: '' };
        this.getContacts('add');
      }, error => {
        this.addressSubmitted = false;
        this.alertService.showError(error, true);
      });
    }
  }

  changeStore(e) {
    this.selectedStore = this.stores.filter(res => res.code === e.target.value)[0];
    this.isNsw = this.selectedStore.state === 'NSW';
  }

  selectType(type) {
    this.showPickUp = type === 'pickup';
    if (type === 'delivery') {

    } else {
      this.deliveryFee = null;
      this.deliveryError = '';
    }
  }

  onSubmit() {
    this.isSubmitted = true;
    if (this.signInForm.valid) {
      const { email, password } = this.signInForm.value;
      this.authService.loginUser(email, password)
        .pipe(first())
        .subscribe({
          next: (data) => {
            this.currentUser = data;
            localStorage.setItem('currentUser', JSON.stringify(this.currentUser));
          },
          error: error => {
            // console.log(error);
            // this.error = error;
          }
        });
    } else {
      return;
    }
  }

  // ** EventListeners for switching payment method.
  eventListeners() {
    document.getElementById('accordion-06-heading-01')
      .addEventListener('click', () => { this.orderButton = 'card'; });
    /*document.getElementById('accordion-06-heading-02')
      .addEventListener('click', () => { this.orderButton = 'paypal'; });
    document.getElementById('accordion-06-heading-03')
      .addEventListener('click', () => { this.orderButton = 'afterpay'; });*/
  }

  continueAsAGuest() {
    const self = this;
    setTimeout(() => {self.eventListeners(); }, 1000);
  }

  calcTotal() {
    if (this.deliveryFee && !this.deliveryError) {
      // @ts-ignore
      return parseFloat(this.globals.cart?.grossAmount) + this.deliveryFee.grossFreight;
    } else {
      return parseFloat(this.globals.cart?.grossAmount);
    }
  }

  calcTotalNoFreight() {
    // @ts-ignore
    return parseFloat(this.globals.cart?.grossAmount);
  }

  getStores() {
    this.storeService.getStoresList().toPromise().then(res => {
      this.stores = res.response.filter(store => store.state === 'NSW');
      this.selectedStore = this.stores.filter(res1 =>  res1.code === 'YEN')[0];
    });
  }

  getContacts(type?) {
    this.userService.getCustomerContactList().subscribe(res => {
        // @ts-ignore
        this.contacts = res.contacts;
        // @ts-ignore
        this.address = res.deliveryContacts;

        if (type === 'add') {
          this.selectedAddress = this.address[this.address.length - 1];
          this.estimateDelivery();
          this.cancelAddAddress();
        }
      },
      error => {
        // console.log(error);
      });
  }

  saveQuote() {
    this.submitted = true;
    let submitInfo;
    if (this.showPickUp) {
      const pf = this.pickUpForm.value;
      submitInfo = {
        // @ts-ignore
        cartUuid: this.globals.cart.uuid,
        orderNumber: pf.orderNumber,
        orderType: 0, // type is pick up
        instructions: pf.instructions,
        dueDate: this.formatDate(pf.dueDate ? pf.dueDate : new Date()),
        timeSlot: pf.timeSlot,
        pickupDetails: {
          pickupBy: pf.pickupBy,
          strap: pf.strap ? 1 : 0,
          wrap: pf.wrap ? 1 : 0,
          onPallet: pf.onPallet ? 1 : 0,
          craneLimit: pf.craneLimit,
          branch: pf.branch
        }
      };
    } else {
      const df = this.deliveryForm.value;
      submitInfo = {
        // @ts-ignore
        cartUuid: this.globals.cart.uuid,
        orderNumber: df.orderNumber,
        orderType: 1, // type is delivery
        instructions: df.instructions,
        dueDate: this.formatDate(df.dueDate ? df.dueDate : new Date()),
        timeSlot: df.timeSlot,
        freight: this.deliveryFee ? this.deliveryFee.netFreight : 0,
        deliveryDetails: {
          deliveryContactId: this.selectedAddress.id,
          strap: df.strap ? 1 : 0,
          wrap: df.wrap ? 1 : 0,
          unload: df.unloadMethod,
          onPallet: df.onPallet ? 1 : 0,
          craneLimit: df.craneLimit,
          passUp: df.passUp,
          longWalk: df.longWalk
        }
      };
    }
    this.shoppingCartService.saveQuote(submitInfo);
  }

  paypalSubmit() {}

  paymentChange(event, tag) {
    if (event.target.checked) {
      this.orderButton = tag;
    } else  {
      this.orderButton = '';
    }
  }

  paymentMethodError() {
    this.placeButtonError = 'Please select at least one payment method!';
    setTimeout(() => {
      this.placeButtonError = null;
    }, 8000);
  }

  securePaySubmit() {
    this.submitted = true;
    if (this.orderButton === '') {
      this.paymentMethodError();
    } else {
      if (this.showPickUp) {
        if (this.pickUpForm.valid && this.cardFormValid) {
          this.cardFormValidProcess();
        } else {
          this.cardFormInvalid();
        }
      } else {
        if (this.deliveryForm.valid && this.cardFormValid) {
          this.cardFormValidProcess();
        } else {
          this.cardFormInvalid();
        }
      }
    }
  }

  humePaySubmit() {
    this.submitted = true;
    if (this.orderButton === '') {
      this.paymentMethodError();
    } else {
    if (this.showPickUp) {
      if (this.pickUpForm.valid) {
        this.alertService.loading('Payment is processing... Please do not refresh the page!');
        this.processPayment();
      } else {
        this.placeButtonError = 'Please fill required fields in shipping section';
      }
    } else {
      if (this.deliveryForm.valid) {
        this.alertService.loading('Payment is processing... Please do not refresh the page!');
        this.processPayment();
      } else {
        this.placeButtonError = 'Please fill required fields in shipping section';
      }
    }
    }
  }

  processPayment(token?) {
    const object = {
      cartUuid: this.globals.cart.uuid,
      cardToken: this.orderButton === 'card' ? token : 'credit',
      total: this.globals.cart.grossAmount,
      freight: this.deliveryFee ? this.deliveryFee.grossFreight : 0,
      grantTotal: this.deliveryFee ? this.calcTotal() : this.calcTotalNoFreight(),
      // ** 0: order, 1: quote, 2: account
      type: this.orderButton === 'card' ? 0 : 2
    };

    // ** For live payment ** //
    this.shoppingCartService.cardPaymentV2(object).subscribe(res => {
      // @ts-ignore
      switch (res.status) {
        case 'failed':
          // @ts-ignore
          const error = res.gatewayResponseMessage.split(':')[0];
          this.alertService.showError(error, true);
          break;
        case 'paid':
        case 'Pass-through':
          this.alertService.showSuccess('Creating your order...', false);
          this.createOrder(res);
          break;
      }
    }, error => {
      // console.log(error);
      this.alertService.showError(
        'Your payment was unsuccessful, Please contact us via the below contact details.',
        true, 5000);
    });
    // ** For live payment ** //
  }

  cardFormValidProcess() {
    this.alertService.loading('Payment is processing... Please do not refresh the page!');
    this.mySecurePayUI.tokenise();
    // this.testFunc();
  }

  cardFormInvalid() {
    if (!this.cardFormValid) {
      this.placeButtonError = 'Please fill correct payment information';
      setTimeout(() => {
        this.placeButtonError = null;
      }, 8000);
    }
  }

  securePayInit() {
    const self = this;
    const uuid = this.globals.cart.cartUuid;
    this.mySecurePayUI = new securePayUI.init(
      {
        /** For Live **/
        containerId: 'pay-container', scriptId: 'securepay-ui-js', clientId: '0oa9a5c3fdZNAl2VB3l6', merchantCode: '57F0017',
        /** For Live **/

      card: {
        allowedCardTypes: ['visa', 'mastercard'],
        showCardIcons: true,
        onCardTypeChange: function(cardType) {
          // card type has changed
        },
        onBINChange: function(cardBIN) {
          // card BIN has changed
        },
        onFormValidityChange: function(valid) {
          self.cardFormValid = valid;
          // form validity has changed
        },
        onTokeniseSuccess: function(tokenisedCard) {
          self.placeButtonError = null;
          // @ts-ignore
          const total = self.globals.cart.grossAmount;
          self.processPayment(tokenisedCard.token);
        },
        onTokeniseError: function(errors) {
          // tokenization failed
          self.placeButtonError = 'Please make sure filled card information is accurate!';
          self.alertService.showError(self.placeButtonError, true);

          setTimeout(() => {
            self.placeButtonError = null;
          }, 8000);
        }
      },
      style: {
        backgroundColor: 'rgb(238,238,238)',
        label: {
          font: {
            family: 'Arial, Helvetica, sans-serif',
            size: '0.75rem',
          }
        },
        input: {
          font: {
            family: 'Arial, Helvetica, sans-serif',
            size: '1.1rem',
            color: 'darkblue'
          }
        }
      },
      onLoadComplete: function () {
        // the UI Component has successfully loaded and is ready to be interacted with
      }
    });
  }

  createOrder(res) {
    if (this.showPickUp) {
      const pf = this.pickUpForm.value;
      this.submitInfo = {
        // @ts-ignore
        cartUuid: this.globals.cart.uuid,
        orderNumber: pf.orderNumber,
        orderType: 0, // type is pick up
        instructions: pf.instructions,
        dueDate: this.formatDate(pf.dueDate),
        timeSlot: pf.timeSlot,
        pickupDetails: {
          pickupBy: pf.pickupBy,
          strap: pf.strap ? 1 : 0,
          wrap: pf.wrap ? 1 : 0,
          onPallet: pf.onPallet ? 1 : 0,
          craneLimit: pf.craneLimit,
          branch: pf.branch
        },
        payment: {
          amount: res.amount,
          transactionId: res.bankTransactionId,
          referenceId: res.paymentId
        }
      };
    } else {
      const df = this.deliveryForm.value;
      this.submitInfo = {
        // @ts-ignore
        cartUuid: this.globals.cart.uuid,
        orderNumber: df.orderNumber,
        orderType: 1, // type is delivery
        instructions: df.instructions,
        dueDate: this.formatDate(df.dueDate),
        timeSlot: df.timeSlot,
        freight: this.deliveryFee ? this.deliveryFee.netFreight : 0,
        deliveryDetails: {
          deliveryContactId: this.selectedAddress.id,
          strap: df.strap ? 1 : 0,
          wrap: df.wrap ? 1 : 0,
          unload: df.unloadMethod,
          onPallet: df.onPallet ? 1 : 0,
          craneLimit: df.craneLimit
        },
        payment: {
          amount: res.amount,
          transactionId: res.bankTransactionId,
          referenceId: res.paymentId
        }
      };
    }
    // @ts-ignore
    this.shoppingCartService.orderCreate(this.submitInfo).toPromise().then(result => {
      this.alertService.showSuccess('Your order and payment processed successfully', true, 5000);
      this.shoppingCartService.paymentSuccess();
    }).catch(error => {
      this.alertService.showError('Something wrong happen', true);
    });
  }

  formatDate(date) {
    let monthString;
    let dateString;
    const year = date.getFullYear();
    const month = date.getMonth() + 1;
    const day = date.getDate();
    if (month < 10) {
      monthString = '0' + month.toString();
    } else { monthString = month.toString(); }
    if (day < 10) {
      dateString = '0' + day.toString();
    } else { dateString = day.toString(); }
    return  year.toString() + '-' + monthString + '-' + dateString;
  }

  get passwordInvalid( ) {
    const control = this.signInForm.get('password');
    return control.hasError('required') && control.touched;
  }

  get accountIDInvalid( ) {
    const control = this.signInForm.get('email');
    return control.hasError('required') && control.touched;
  }

  estimateDelivery() {
    let state = '';
    this.deliveryError = '';
    this.deliveryFee = null;
    const temp = this.selectedAddress.address.toLowerCase();
    if (temp.includes('new south wales') || temp.includes('nsw')) {
      state = 'NSW';
    }
    if (temp.includes('victoria') || temp.includes('vic')) {
      state = 'VIC';
    }
    const obj = {
      cartUuid : this.globals.cart.uuid,
      address: this.selectedAddress.address,
      suburb: '',
      state: state,
      unloadMethod: this.deliveryForm.value.unloadMethod,
      longWalk: this.deliveryForm.value.longWalk,
      passUp: this.deliveryForm.value.passUp
    };
    this.shoppingCartService.calculateFreightCost(obj).subscribe(res => {
      this.deliveryFee = res;
      if (this.deliveryFee.grossFreight === 0) {
        this.deliveryError = 'We apologise that the delivery freight for this address can not be advised at this stage. ' +
          'A customer service representative will call you back shortly to advise the delivery charge. ' +
          'Alternatively, you could also contact us via 13 4863 for further assistance.';
      }
    }, error1 => {
      if (error1.status === 400) {
        this.deliveryError = 'We apologise that the delivery freight for this address can not be advised at this stage. ' +
          'A customer service representative will call you back shortly to advise the delivery charge. ' +
          'Alternatively, you could also contact us via 13 4863 for further assistance.';
      }
    });
  }

  // **** test
  testFunc() {
    const obj = {
      amount: 100,
      transactionId: 13432432,
      referenceId: 1435454
    };
    this.createOrder(obj);
  }
  // **** test
}
