import {Component, OnDestroy, OnInit, ViewChild} from '@angular/core';
import {ActivatedRoute, NavigationEnd, Router} from '@angular/router';
import {Subscription} from 'rxjs';
import {InventoryCategoriesService} from '../../services/inventory-categories.service';
import {Globals} from '../../global';
import {DomSanitizer} from '@angular/platform-browser';
import {FormBuilder, FormControl, FormGroup, Validators} from '@angular/forms';
import {ContactService} from '../../services/contact.service';
import {ReCaptcha2Component} from 'ngx-captcha';
import {ModalDirective} from 'angular-bootstrap-md';
import {ProductService} from '../../services/product.service';
import {AlertService} from '../../services/alert.service';
import {AuthService} from '../../services/auth/auth.service';
import {SEOService} from '../../services/seo/seo.service';

declare var $: any;

@Component({
  selector: 'app-categories-sub-categories',
  templateUrl: './sub-categories.component.html'
})
export class WebCategoriesSubCategoriesComponent implements OnInit, OnDestroy {
  mobile = false;
  imgUrl = this.globals.picStorageRef;
  catWebSub: Subscription;
  subCatSub: Subscription;
  routeSub: Subscription;
  brandsSub: Subscription;
  routeUrlSubscription: Subscription;
  subCat;
  pageContent;
  catWebId;
  terracottaCladdingUrl = [];
  brands;

  @ViewChild('successModal', { static: true }) successModal: ModalDirective;

  constructor (private route: ActivatedRoute, private productService: ProductService,
               private formBuilder: FormBuilder, private seoService: SEOService,
               private contactService: ContactService,
               public globals: Globals, private alertService: AlertService,
               private router: Router, private authService: AuthService,
               private sanitizer: DomSanitizer) {}

  async ngOnInit() {
    await this.authService.refreshToken().then(res1 => {
      this.globals.token = res1;
    });

    this.getBrands('kitchens bathrooms');

    this.routeUrlSubscription = this.route.url.subscribe(urlData => {
      if (urlData[0].path === 'sub') {
        this.routeSub = this.route.queryParams.subscribe(
          data => {
            this.catWebId = data.id;
            if (data.name) {
              this.catWebSub = this.productService.getEWebCatListAll().subscribe(res2 => {
                // @ts-ignore
                const subCat = res2.filter(data2 => data2.name === data.name);
                this.subCatSub = this.productService.getEWebCatById(subCat[0].id).subscribe(res3 => {
                  this.subCat = res3;
                  this.seo(res3);
                  this.pageContent = this.sanitizer.bypassSecurityTrustHtml(this.subCat.pageContent);

                  if (this.subCat && this.subCat.name === 'Terracotta Cladding') {
                    for (let i = 0; i < 6; i++) {
                      const a = i + 1;
                      this.terracottaCladdingUrl[i] = this.imgUrl + 'categories-web/tc' + a + '.jpg';
                    }
                  }
                });
              });
            }
          }
        );
      } else {
        // ** When url doesn't contain word 'sub'
        this.catWebSub = this.productService.getEWebCatListAll().subscribe(res => {
          const path = urlData[0].path.split('_').join(' ');
          // @ts-ignore
          const subCat = res.filter(res2 => res2.link === path);
          this.catWebId = subCat[0].id;

          this.subCatSub = this.productService.getEWebCatById(subCat[0].id).subscribe(res3 => {
            this.subCat = res3;
            this.seo(res3);
            this.pageContent = this.sanitizer.bypassSecurityTrustHtml(this.subCat.pageContent);
          });
        });
      }
    });
  }

  getBrands (name) {
    this.brandsSub = this.productService.getBrands().subscribe(res => {
      /* Sequence number greater than 70 are for unimportant brands which aren't showing on the homepage */
      this.brands = res.response.filter(data => data.link === name.toLowerCase());
    });
  }

  seo(category) {
    this.seoService.setPageTitle(category.name);
    this.seoService.setMetaObjectInfo(category);
    this.seoService.createLinkForCanonicalURL();
  }

  ngOnDestroy() {
    if (this.subCatSub) { this.subCatSub.unsubscribe(); }
    if (this.brandsSub) { this.brandsSub.unsubscribe(); }
    if (this.catWebSub) { this.catWebSub.unsubscribe(); }
    if (this.routeSub) { this.routeSub.unsubscribe(); }
    if (this.routeUrlSubscription) { this.routeUrlSubscription.unsubscribe(); }
  }
}
