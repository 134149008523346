<!-- Checkout Form -->
<div class="g-pt-10 g-pt-20--md g-pb-10 g-pb-50--sm px-2 g-width-90x--md g-width-100x mx-auto">
  <div class="js-validate js-step-form" data-progress-id="#stepFormProgress" data-steps-id="#stepFormSteps">

    <div id="stepFormSteps" class="row g-px-10 g-px-0--sm">
      <!-- STEP1 Shopping Cart -->
      <div class="active col-xl-4 col-12 px-3 px-md-5">
        <h2 class="text-center g-color-black font-weight-bold text-uppercase pt-3">Shopping Cart</h2>
        <hr>

        <!--Cart content module-->
        <div class="g-pb-50 cart-content">
          <app-cart-content [page]="'checkout'"></app-cart-content>
        </div>

        <!--Cart content module-->

        <!-- Summary -->
        <div class="g-bg-gray-light-v5 g-pa-20 g-pb-50 mb-4">
          <div class="row">
            <h4 class="h3 text-uppercase g-color-primary mb-3">Summary</h4>
          </div>

          <div class="d-flex justify-content-between mb-2" *ngIf="globals?.cart?.totalWeight">
            <span class="g-color-black">Total Weight</span>
            <span class="g-color-black g-font-weight-500">{{globals?.cart?.totalWeight | number: '1.2-2'}} kg</span>
          </div>
          <div class="d-flex justify-content-between mb-2" *ngIf="globals?.cart?.totalSqm">
            <span class="g-color-black">Total SQM</span>
            <span class="g-color-black g-font-weight-500">{{globals?.cart?.totalSqm | number: '1.2-2'}} m<sup>2</sup></span>
          </div>
          <div class="d-flex justify-content-between mb-2">
            <span class="g-color-black">Total Net</span>
            <span class="g-color-black g-font-weight-500">{{(globals?.cart?.netAmount ? globals?.cart?.netAmount : 0) | currency:'AUD' : '$' :  '1.2-2'}}</span>
          </div>
          <div class="d-flex justify-content-between mb-2">
            <span class="g-color-black">GST<small class="g-color-gray-dark-v4 text-capitalize g-font-size-13 ml-1">(10%)</small></span>
            <span class="g-color-black g-font-weight-500">{{(globals?.cart?.netAmount ? (globals?.cart?.grossAmount - globals?.cart?.netAmount) : 0) | currency:'AUD' : '$' :  '1.2-2'}}</span>
          </div>
          <div class="d-flex justify-content-between mt-3 g-font-size-20">
            <span class="g-color-black">Order Total<small class="g-color-gray-dark-v4 text-capitalize g-font-size-13 ml-1">(Inc.GST)</small><br/></span>
            <span class="g-color-black font-weight-bold" *ngIf="calcTotalNoFreight() !== 0">{{calcTotalNoFreight() | currency:'AUD' : '$' :  '1.2-2'}}</span>
            <span class="g-color-black font-weight-bold" *ngIf="calcTotalNoFreight() === 0">{{0 | currency:'AUD' : '$' :  '1.2-2'}}</span>
          </div>
        </div>
        <!-- End Summary -->

      </div>
      <!-- End STEP1 Shopping Cart -->

      <div class="active col-xl-4 col-12 px-3 px-md-5" *ngIf="!currentUser else authenticated">
        <!-- Login or guest -->
        <h4 class="text-center g-color-black font-weight-bold text-uppercase pt-3">Login to continue</h4>
        <hr>

        <!-- Basic info block -->
        <form [formGroup]="signInForm" (ngSubmit)="onSubmit()">
          <div class="col-12">
            <div class="form-group">
              <label class="d-block g-color-gray-dark-v2 g-font-size-13">Account ID:</label>
              <input class="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-rounded-left-0 g-rounded-right-3 g-py-15 g-px-15" placeholder="Account ID" formControlName="email">
            </div>
            <div class="error row" *ngIf="accountIDInvalid">
              Account ID is required
            </div>
          </div>

          <div class="col-12">
            <div class="form-group">
              <label class="d-block g-color-gray-dark-v2 g-font-size-13">Password</label>
              <input class="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-rounded-left-0 g-rounded-right-3 g-py-15 g-px-15" type="password" placeholder="Password" formControlName="password">
            </div>
          </div>

          <div class="mt-3 mt-md-5 pb-5">
            <button class="btn btn-block u-btn-primary g-font-size-15 shadow-none" style="border: solid 1px" type="submit">Login & continue</button>
          </div>
        </form>


        <!-- Basic info block -->

        <hr class="my-5">
        <div class="g-pos-rel u-badge-v1 g-color-white g-bg-black g-rounded-50x g-brd-around g-brd-white g-brd-2" style="top: -43px; left: 44%;">OR</div>

        <div class="mt-3 mb-5 text-center g-color-black">
          <h3 class="font-weight-bold">Guest Checkout</h3>
          <span class="my-3">You can <a href="auth/sign-up">create an account</a> during checkout for faster checkout next time!</span>
          <button class="btn btn-block u-btn-primary g-font-size-15 shadow-none" (click)="continueAsAGuest()">Continue as a guest</button>
        </div>
        <!-- Login or guest -->
      </div>

      <ng-template #authenticated>

        <div id="map"></div>
        <!-- STEP2 Shipping -->
        <div class="active col-xl-4 col-12 px-3 px-md-5">
          <h2 class="text-center g-color-black font-weight-bold text-uppercase pt-3">Shipping</h2>
          <hr>

          <div class="row text-center">
            <div class="btn-group" style="margin-right: auto; margin-left: auto; margin-bottom: 15px">
              <button mdbBtn type="button" class="btn g-font-size-12 text-capitalize font-weight-bold g-py-12 g-px-20 hoverable shadow-none mr-5" style="border-radius: 50px"
                      (click)="selectType('pickup')" [ngClass]="showPickUp ? 'u-btn-primary' : 'u-btn-darkgray'">
                <span class="g-font-size-17 px-sm-4 px-2">Pick up<i class="fa fa-building ml-2"></i></span>
              </button>
              <button mdbBtn type="button" class="btn g-font-size-12 text-capitalize font-weight-bold g-py-12 g-px-20 hoverable shadow-none" style="border-radius: 50px"
                      (click)="selectType('delivery')" [ngClass]="showPickUp ? 'u-btn-darkgray' : 'u-btn-primary'">
                <span class="g-font-size-17 px-sm-4 px-2">Delivery<i class="fa fa-truck ml-2"></i></span>
              </button>
            </div>
          </div>

          <!-- Pickup form -->
          <div *ngIf="showPickUp else delivery">

            <!-- Pick up form block -->
            <div class="mt-2">
              <form class="row mb-3" [formGroup]="pickUpForm">
                <h4 class="g-color-black mt-3 col-12">Pick up from:</h4>
                <label class="col-12">
                  <select class="browser-default custom-select my-2 g-width-100x--lg min-height-53" formControlName="branch" (change)="changeStore($event)">
                    <option *ngFor="let store of stores" [value]='store?.code'>{{store?.name}} {{' ' + store?.state}}</option>
                  </select>
                </label>
                <div class="list-group pl-3 col-12" [hidden]="!selectedStore">
                  <div class="list-inline-item">
                    <p><i class="fa fa-map-marker pr-2"></i>{{selectedStore?.address}}</p>
                    <p><i class="fa fa-envelope pr-2"></i>{{selectedStore?.email}}</p>
                    <p><i class="fa fa-clock-o pr-2"></i>{{selectedStore?.hoursMF}} (Mon-Fri)</p>
                    <p><i class="fa fa-clock-o pr-2"></i>{{selectedStore?.hoursSat}} (Sat)</p>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <label class="d-block g-color-gray-dark-v2 g-font-size-13"><span class="g-color-primary mr-1">*</span>Pick up by</label>
                    <input class="form-control u-form-control g-placeholder-gray-light-v1 rounded-0 g-py-15" type="text" formControlName="pickupBy" placeholder="Name">
                    <div class="error" *ngIf="pf.get('pickupBy').errors && submitted && ( pf.get('pickupBy').dirty || pf.get('pickupBy').touched || pf.get('pickupBy').invalid)">
                      <div *ngIf="pf.get('pickupBy').hasError('required')">
                        Pick up person name is required.
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="d-block g-color-gray-dark-v2 g-font-size-13"><span class="g-color-primary mr-1">*</span>Preferred pick Up date</label>
                    <input type="text" placeholder="Pick up date" class="form-control min-height-53 mt-2" formControlName="dueDate" bsDatepicker
                           [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false }" [daysDisabled]="[0]" [minDate]="minDate">
                    <div class="error" *ngIf="pf.get('dueDate').errors && submitted && ( pf.get('dueDate').dirty || pf.get('dueDate').touched || pf.get('dueDate').invalid)">
                      <div *ngIf="pf.get('dueDate').hasError('required')">
                        Pick up date is required.
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="d-block g-color-gray-dark-v2 g-font-size-13"><span class="g-color-primary mr-1">*</span>Preferred time slot</label>
                      <select class="browser-default custom-select g-width-100x--lg min-height-53" formControlName="timeSlot">
                        <option *ngFor="let time of timeSlot" [value]="time?.id">{{time?.name}}</option>
                      </select>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <label class="d-block g-color-gray-dark-v2 g-font-size-13">Reference/Order number(optional)</label>
                    <input class="form-control u-form-control g-placeholder-gray-light-v1 rounded-0 g-py-15" type="text" formControlName="orderNumber" placeholder="Reference/Order number">
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <label class="d-block g-color-gray-dark-v2 g-font-size-13">Instructions(optional)</label>
                    <input class="form-control u-form-control g-placeholder-gray-light-v1 rounded-0 g-py-15" type="text" formControlName="instructions" placeholder="Instructions">
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <label class="d-block g-color-gray-dark-v2 g-font-size-13">Special requests(surcharge applies)</label>

                    <div class="row justify-content-between">
                      <label class="container">
                        <input type="checkbox" [checked]="" formControlName="strap" class="checkbox">
                        <span class="g-color-black ml-2">Strap</span>
                      </label>
                      <label class="container">
                        <input type="checkbox" [checked]="" formControlName="wrap" class="checkbox">
                        <span class="g-color-black ml-2">Wrap</span>
                      </label>
                      <label class="container">
                        <input type="checkbox" [checked]="" formControlName="onPallet" class="checkbox">
                        <span class="g-color-black ml-2">On Pallets</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <label class="d-block g-color-gray-dark-v2 g-font-size-13">Max weight per pack(kg)</label>
                    <input class="form-control u-form-control g-placeholder-gray-light-v1 rounded-0 g-py-15" type="number" formControlName="craneLimit" placeholder="Max weight per pack">
                  </div>
                </div>

              </form>
            </div>
            <!-- End Pick up form block -->
          </div>
          <!-- End pickup form -->

          <!-- delivery form -->
          <ng-template #delivery>
            <h4 class="g-color-black mt-3">Deliver to:</h4>

            <div class="btn-group mb-3" dropdown>
              <button id="button-basic" dropdownToggle type="button" class="btn btn-outline-primary dropdown-toggle shadow-none text-capitalize g-font-size-15 address-btn"
                      aria-controls="dropdown-basic" style="padding-left: 10px">{{selectedAddressOption}}<span class="caret"></span>
              </button>
              <ul id="dropdown-basic" *dropdownMenu class="dropdown-menu address-dropdown" role="menu" aria-labelledby="button-basic" style="width: 430px">
                <li role="menuitem" class="g-font-size-15 g-color-primary pt-3 pb-2 px-3" (click)="addNewAddress()"><a>Add a new address</a></li>
                <li class="divider dropdown-divider"></li>
                <li role="menuitem" class="py-2 px-3 g-font-size-13 g-bg-primary--hover address-list" *ngFor="let item of address, let i = index" (click)="selectAddress(i)">
                  <a>{{item?.address}} - {{item?.name}}</a>
                </li>
              </ul>
            </div>

            <div class="g-color-black mb-3">
              <span class="font-weight-bold g-color-primary">Must be NSW address only</span>
            </div>

            <div *ngIf="showNewAddress">
              <div class="g-brd-around g-bg-black-opacity-0_1 g-brd-gray-light-v4 rounded g-pa-30 g-mb-30 u-shadow-v1-3">
                <!-- Google places autocomplete -->
                <app-google-places [pageUrl]="'checkOut'" [submitted]="addressSubmitted"></app-google-places>
                <!-- Google places autocomplete -->
                <button class="btn u-btn-primary g-font-size-14 font-weight-bold text-uppercase g-py-12 g-px-25 shadow-none" type="submit" (click)="saveAddress()">Save</button>
                <button class="btn u-btn-primary g-font-size-14 font-weight-bold text-uppercase g-py-12 g-px-25 shadow-none" type="submit" (click)="cancelAddAddress()">Cancel</button>
              </div>
            </div>
            <div class="list-group pl-3" [hidden]="!selectedAddress">
              <div class="list-inline-item">
                <p><i class="fa fa-map-marker pr-2"></i>{{selectedAddress?.address}}</p>
                <p><i class="fa fa-user pr-2"></i>{{selectedAddress?.name}}</p>
                <p><i class="fa fa-mobile pr-2"></i>{{selectedAddress?.mobile}}</p>
              </div>
            </div>
            <!-- Delivery form block -->
            <div class="mt-2">
              <form class="row mb-5" [formGroup]="deliveryForm">
                <div class="col-12">
                  <div class="form-group">
                    <label class="d-block g-color-gray-dark-v2 g-font-size-13"><span class="g-color-primary mr-1">*</span>Unload method</label>
                    <select class="browser-default custom-select my-2 g-width-100x--lg min-height-53" formControlName="unloadMethod">
                      <option *ngFor="let item of unloadMethods" [value]="item?.id">{{item?.name}}</option>
                    </select>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="d-block g-color-gray-dark-v2 g-font-size-13"><span class="g-color-primary mr-1">*</span>Long walk
                      <button type="button" class="btn btn-outline-hume shadow-none" style="padding: unset !important;"
                              tooltip="Unload materials that is further than 20m from the truck. Every extra 20m incurs a long walk surcharge."
                              placement="bottom">
                        <i class="icon-question g-font-size-18 font-weight-bold g-color-primary"></i>
                      </button>
                    </label>
                    <select class="browser-default custom-select g-width-100x--lg min-height-53" (ngModelChange)="estimateDelivery()" formControlName="longWalk">
                      <option value="0">No</option>
                      <option value="1">yes</option>
                    </select>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="d-block g-color-gray-dark-v2 g-font-size-13"><span class="g-color-primary mr-1">*</span>Pass up
                      <ng-template #popTemplate>Pass up/Pass Down - Unload materials above ground or below ground floor. Every level requires 1 Pass up surcharge. <div [innerHtml]="'<br>'"></div>
                        2 Man Pass Up/ Pass to Men - At least 2 on site laborers will help pull materials to the required level.</ng-template>
                      <button type="button" class="btn btn-outline-hume shadow-none" style="padding: unset !important;"
                              [tooltip]="popTemplate"
                              placement="bottom">
                        <i class="icon-question g-font-size-18 font-weight-bold g-color-primary"></i>
                      </button>
                    </label>
                    <select class="browser-default custom-select g-width-100x--lg min-height-53" (ngModelChange)="estimateDelivery()" formControlName="passUp">
                      <option value="0">No</option>
                      <option value="0.5">Pass to your men</option>
                      <option value="1">Pass up or down to 1 floor</option>
                      <option value="2">Pass up or down to 2 floors</option>
                    </select>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="d-block g-color-gray-dark-v2 g-font-size-13"><span class="g-color-primary mr-1">*</span>Preferred delivery date</label>
                    <input type="text" placeholder="Delivery date" class="form-control min-height-53 mt-2 border-radius" formControlName="dueDate" bsDatepicker [daysDisabled]="[0]" [minDate]="minDate"
                           [bsConfig]="{ isAnimated: true, dateInputFormat: 'DD-MM-YYYY', showWeekNumbers: false }">
                    <div class="error" *ngIf="df.get('dueDate').errors && submitted && ( df.get('dueDate').dirty || df.get('dueDate').touched || df.get('dueDate').invalid)">
                      <div *ngIf="df.get('dueDate').hasError('required')">
                        Delivery date is required.
                      </div>
                    </div>
                  </div>
                </div>

                <div class="col-6">
                  <div class="form-group">
                    <label class="d-block g-color-gray-dark-v2 g-font-size-13"><span class="g-color-primary mr-1">*</span>Preferred time slot</label>
                    <select class="browser-default custom-select g-width-100x--lg min-height-53" formControlName="timeSlot">
                      <option *ngFor="let time of timeSlot" [value]="time?.id">{{time?.name}}</option>
                    </select>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <label class="d-block g-color-gray-dark-v2 g-font-size-13">Reference/Order number(optional)</label>
                    <input class="form-control u-form-control g-placeholder-gray-light-v1 rounded-0 g-py-15" type="text" formControlName="orderNumber" placeholder="Reference/Order number">
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <label class="d-block g-color-gray-dark-v2 g-font-size-13">Instructions(optional)</label>
                    <input class="form-control u-form-control g-placeholder-gray-light-v1 rounded-0 g-py-15" type="text" formControlName="instructions" placeholder="Instructions">
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <label class="d-block g-color-gray-dark-v2 g-font-size-13">Special requests(surcharge applies)</label>

                    <div class="row justify-content-between">
                      <label class="container">
                        <input type="checkbox" [checked]="" formControlName="strap" class="checkbox">
                        <span class="g-color-black ml-2">Strap</span>
                      </label>
                      <label class="container">
                        <input type="checkbox" [checked]="" formControlName="wrap" class="checkbox">
                        <span class="g-color-black ml-2">Wrap</span>
                      </label>
                      <label class="container">
                        <input type="checkbox" [checked]="" formControlName="onPallet" class="checkbox">
                        <span class="g-color-black ml-2">On Pallets</span>
                      </label>
                    </div>
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group">
                    <label class="d-block g-color-gray-dark-v2 g-font-size-13">Max weight per pack(kg)</label>
                    <input class="form-control u-form-control g-placeholder-gray-light-v1 rounded-0 g-py-15" type="number" formControlName="craneLimit" placeholder="Max weight per pack">
                  </div>
                </div>

                <div class="col-12">
                  <div class="form-group row">
                    <label class="d-block pl-3 g-color-gray-dark-v2 col-7 col-sm-8 g-pt-10 g-font-size-17">Estimate delivery fee</label>
                    <button class="btn btn-sm shadow-none g-brd-around g-brd-gray-light-v3 g-color-white g-bg-primary details-button
                        g-bg-gray-light-v4--hover g-font-size-14 font-weight-bold text-uppercase g-py-5 g-px-25 float-right"
                            [disabled]="!selectedAddress?.address" (click)="estimateDelivery()">Estimate</button>
                  </div>

                  <div class="g-bg-gray-light-v5 g-pa-10" *ngIf="deliveryFee?.grossFreight > 0">
                    <div class="d-flex justify-content-between mb-2">
                      <span class="g-color-black">Base Rate</span>
                      <span class="g-color-black g-font-weight-500">{{deliveryFee?.baseRate | currency:'AUD' : '$' :  '1.2-2'}}</span>
                    </div>

                    <div class="d-flex justify-content-between mb-2">
                      <span class="g-color-black">Surcharge<small class="g-color-gray-dark-v4 text-capitalize g-font-size-13 ml-1">(Pass up, long walk etc.)</small></span>
                      <span class="g-color-black g-font-weight-500">{{deliveryFee?.surchargeRate | currency:'AUD' : '$' :  '1.2-2'}}</span>
                    </div>

                    <div class="d-flex justify-content-between mb-2">
                      <span class="g-color-black">Total Net</span>
                      <span class="g-color-black g-font-weight-500">{{deliveryFee?.netFreight | currency:'AUD' : '$' :  '1.2-2'}}</span>
                    </div>

                    <div class="d-flex justify-content-between mb-2">
                      <span class="g-color-black">GST<small class="g-color-gray-dark-v4 text-capitalize g-font-size-13 ml-1">(10%)</small></span>
                      <span class="g-color-black g-font-weight-500">{{deliveryFee?.grossFreight - deliveryFee?.netFreight | currency:'AUD' : '$' :  '1.2-2'}}</span>
                    </div>

                    <div class="d-flex justify-content-between mt-4 mb-2 g-font-size-17">
                      <span class="g-color-black">Gross Freight<small class="g-color-gray-dark-v4 text-capitalize g-font-size-13 ml-1">(Inc. GST)</small></span>
                      <span class="g-color-black g-font-weight-500">{{(deliveryFee?.grossFreight ? deliveryFee?.grossFreight : 0) | currency:'AUD' : '$' :  '1.2-2'}}</span>
                    </div>
                  </div>

                  <div class="g-bg-gray-light-v5 g-pa-10" *ngIf="deliveryError">
                    <div class="d-flex justify-content-between mb-4 pt-3">
                      <span class="g-color-black">Gross Freight<small class="g-color-gray-dark-v4 text-capitalize g-font-size-13 ml-1">(Inc. GST)</small></span>
                      <span class="g-color-primary g-font-weight-500">Be advised</span>
                    </div>
                    <div class="error">
                      <span>*{{deliveryError}}</span>
                    </div>
                  </div>

                </div>

              </form>
            </div>
            <!-- End delivery form block -->
          </ng-template>
          <!-- End delivery form -->

        </div>
        <!-- End STEP2 Shipping -->


        <!-- STEP3 Payment & Review -->
        <div class="active col-xl-4 col-12 px-3 px-md-5">
          <h2 class="text-center g-color-black font-weight-bold text-uppercase pt-3">Payment</h2>
          <hr>

          <!-- Summary -->
          <div class="g-bg-gray-light-v5 g-pa-20 g-pb-50 mb-4" *ngIf="deliveryFee || deliveryError">
            <h4 class="h3 text-uppercase g-color-primary mb-3">Summary</h4>

            <div class="d-flex justify-content-between mb-2" *ngIf="globals?.cart?.totalWeight">
              <span class="g-color-black">Total Weight</span>
              <span class="g-color-black g-font-weight-500">{{globals?.cart?.totalWeight | number: '1.2-2'}} kg</span>
            </div>
            <div class="d-flex justify-content-between mb-2" *ngIf="globals?.cart?.totalSqm">
              <span class="g-color-black">Total SQM</span>
              <span class="g-color-black g-font-weight-500">{{globals?.cart?.totalSqm | number: '1.2-2'}} m<sup>2</sup></span>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <span class="g-color-black">Total Net</span>
              <span class="g-color-black g-font-weight-500">{{(globals?.cart?.netAmount ? globals?.cart?.netAmount : 0) | currency:'AUD' : '$' :  '1.2-2'}}</span>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <span class="g-color-black">GST<small class="g-color-gray-dark-v4 text-capitalize g-font-size-13 ml-1">(10%)</small></span>
              <span class="g-color-black g-font-weight-500">{{(globals?.cart?.netAmount ? (globals?.cart?.grossAmount - globals?.cart?.netAmount) : 0) | currency:'AUD' : '$' :  '1.2-2'}}</span>
            </div>
            <div class="d-flex justify-content-between mb-2">
              <span class="g-color-black">Delivery Fee<small class="g-color-gray-dark-v4 text-capitalize g-font-size-13 ml-1">(10%)</small></span>
              <span class="g-font-weight-500" [ngClass]="deliveryFee?.grossFreight > 0 ? 'g-color-black' : 'g-color-primary'">{{deliveryFee?.grossFreight > 0 ? (deliveryFee?.grossFreight | currency:'AUD' : '$' :  '1.2-2') : 'Be advised'}}</span>
            </div>
            <div class="error" *ngIf="deliveryError">
              <span>*{{deliveryError}}</span>
            </div>

            <div class="d-flex justify-content-between mt-3 g-font-size-20">
              <span class="g-color-black">Order Total<small class="g-color-gray-dark-v4 text-capitalize g-font-size-13 ml-1">(Inc.GST)</small><br/></span>
              <span class="g-color-black font-weight-bold" *ngIf="calcTotal() !== 0">{{calcTotal() | currency:'AUD' : '$' :  '1.2-2'}}</span>
              <span class="g-color-black font-weight-bold" *ngIf="calcTotal() === 0">{{0 | currency:'AUD' : '$' :  '1.2-2'}}</span>
            </div>
          </div>
          <!-- End Summary -->


          <div id="accordion-06" class="u-accordion" role="tablist" aria-multiselectable="false">

            <!-- Card -->
            <div class="card rounded-0 g-bg-gray-light-v4 g-brd-none mb-3">
              <div id="accordion-06-heading-01" class="u-accordion__header g-pa-20" role="tab">
                <h5 class="mb-0 text-uppercase g-font-size-default g-font-weight-700 g-color-black">
                  <label class="container">
                    <input type="checkbox" [checked]="orderButton === 'card'" [disabled]="accountBalance?.creditStatus.toLowerCase() !== 'active'" (change)="paymentChange($event, 'card')" class="checkbox">
                    <span class="checkmark mr-2"></span>Credit/debit card
                  </label>
                  <a class="d-flex g-color-main g-text-underline--none--hover" href="#accordion-06-body-01" data-toggle="collapse" data-parent="#accordion-06" [attr.aria-expanded]="orderButton === 'card'" aria-controls="accordion-06-body-01">
                    <!--<span class="u-accordion__control-icon g-ml-10">
                      <i class="fa fa-angle-down"></i>
                      <i class="fa fa-angle-up"></i>
                    </span>-->
                  </a>
                </h5>
              </div>
              <div id="accordion-06-body-01" class="collapse" [ngClass]="orderButton === 'card' ? 'show' : ''" role="tabpanel" aria-labelledby="accordion-06-heading-01" data-parent="#accordion-06">

                <!-- Secure pay-->
                <form onsubmit="return false;" class="g-ml-30--sm g-ml-10 border-0">
                  <div id="pay-container" class="g-width-100x g-pl-10"></div>
                </form>
                <!-- End secure pay-->
              </div>
            </div>
            <!-- End Card -->

            <!-- Hume Credit -->
            <div class="card rounded-0 g-bg-gray-light-v4 g-brd-none mb-3" *ngIf="accountBalance?.creditStatus.toLowerCase() === 'active'">
              <div id="accordion-06-heading-02" class="u-accordion__header g-pa-20" role="tab">
                <h5 class="mb-0 text-uppercase g-font-size-default g-font-weight-700 g-color-black">
                  <label class="container">
                    <input type="checkbox" [checked]="orderButton === 'credit'" (change)="paymentChange($event, 'credit')" class="checkbox">
                    <span class="checkmark mr-2"></span>Pay by Hume credit balance <span class="ml-2 g-color-primary text-capitalize">Available credit({{accountBalance?.availableCredit | currency: 'AUD': 'symbol-narrow'}})</span>
                  </label>
                  <a class="collapsed d-flex g-color-main g-text-underline--none--hover" href="#accordion-06-body-02" data-toggle="collapse" data-parent="#accordion-06" [attr.aria-expanded]="orderButton === 'paypal'" aria-controls="accordion-06-body-02">
                  </a>
                </h5>
              </div>
              <div id="accordion-06-body-02" class="collapse" [ngClass]="orderButton === 'credit' ? 'show' : ''" role="tabpanel" aria-labelledby="accordion-06-heading-02" data-parent="#accordion-06">
                <!--<div class="u-accordion__body g-color-gray-dark-v5 g-pa-0-20-20">
                  This is where we sit down, grab a cup of coffee and dial in the details. Understanding the task at hand and ironing out the wrinkles is key. Now that we've aligned the details, it's time to get things mapped out and organized. This part is really crucial
                  in keeping the project in line to completion.
                </div>-->
              </div>
            </div>
            <!-- End Hume Credit -->
          </div>
          <div class="my-5">
            <div class="row text-center" *ngIf="globals?.cart?.lines?.length > 0">
              <a class="g-color-primary mx-auto g-color-black--hover mb-5" (click)="saveQuote()"><span class="pt-2 pb-3 g-font-size-17"><i class="fa fa-save mr-2"></i>Save as quote</span></a>
            </div>
            <button class="hume-btn btn-border-no-shadow u-btn-outline-black btn-block g-font-size-15" [ngClass]="orderButton === 'paypal' ? 'disabled' : ''"
                    [disabled]="globals?.cart?.lines?.length < 1" *ngIf="isNsw"
                    (click)="orderButton === 'card' ? securePaySubmit() : 'credit' ? humePaySubmit() : paypalSubmit()">{{orderButton === 'card' ? 'Place order' : 'Pay by'}}
              <span class="text-uppercase g-color-primary font-weight-bold" *ngIf="orderButton === 'credit'">Hume Credit</span>
              <img class="g-height-20 ml-3" *ngIf="orderButton === 'paypal'" src="assets/img/logo/logo-paypal.svg" alt="">
            </button>
            <!-- Warning message for other states than NSW -->
            <div class="alert alert-warning" *ngIf="!isNsw">Online order is only available in NSW. For customers of other states, please save this order to quote and we will contact you to process the order.</div>
            <!-- End Warning message -->
            <div class="error mt-2" *ngIf="placeButtonError">
              {{placeButtonError}}
            </div>
          </div>

        </div>
        <!-- End STEP3 Payment & Review -->

      </ng-template>
    </div>
  </div>
</div>
