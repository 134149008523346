<div class="single-component-container">
  <div class="container g-py-10 g-font-size-17">
    <h1 class="h1 text-center my-5">Hume Building Products Terms of Sale and Delivery Policy</h1>
    <h4 class="mb-3 font-weight-bolder">Last Updated: 23 October, 2020</h4>
    <p>The following policy outlines the terms of sale and delivery for Hume Plasterboard Pty Ltd ACN 70 101 562 270 (t/as Hume Building Products, and known as “Hume”).</p>
    <div class="mt-4">
      <h3 class="text-capitalize mb-3">Delivery Policy</h3>
      <p>Any damaged or missing items must be notified within 48 hours upon acceptance of the delivery order.</p>
      <p>Please ensure that the pathway to unload is free from any hazards. It is the responsibility of the site to ensure that any hazards are cleared, scaffolding or skip bins are removed and/or materials on site are not blocking the pathway. Any area which must be cleared by Drivers will cause delay and additional charges will apply.</p>
      <p>Site inspections can be arranged by appointment. Please contact 13 4863 for further information. </p>
    </div>
    <div class="mt-4">
      <h3 class="text-capitalize mb-3">Shopping Centres</h3>
      <p>Advise the following in instructions:</p>
      <ul>
        <li>Restrictions that may apply including height, delivery time or loading dock specifics.</li>
      </ul>
      <p>Note: Our Drivers do not carry plasterboard trolleys on their trucks and trolleys cannot be arranged/provided.</p>
    </div>
    <div class="mt-4">
      <h3 class="text-capitalize mb-3">Out of Area</h3>
      <p>Zones that exceed 100km from our dispatch branch will incur an additional charge based on distance, weight and truck required. Please contact 13 4863 with your order reference for out of area deliveries.</p>
    </div>
    <div class="mt-4">
      <h3 class="text-capitalize mb-3">Poor Weather Conditions</h3>
      <p>Deliveries during poor weather may have delays as materials cannot be unloaded due to risk of damage or safety. Please advise in your order if you require plastic cover or materials to be wrapped. Please contact 13 4863 if a change of delivery date is required, prior to the date of delivery.</p>
    </div>
    <div class="mt-4">
      <h3 class="text-capitalize mb-3">Failed or Cancelled Delivery</h3>
      <p>Any failed delivery or cancellation on the delivery date may incur a surcharge.</p>
    </div>
    <div class="mt-4">
      <h3 class="text-capitalize mb-3">Change of Delivery Date or Place Delivery on Hold</h3>
      <p>Please call 13 4863 to change delivery date/time or to place an order on hold. </p>
    </div>
    <div class="mt-4">
      <h3 class="text-capitalize mb-3">Hand Unload <span class="g-font-size-17">– Our Contractor Drivers will unload to your preferred location within 0-20m of truck to ground floor location.</span></h3>
      <p>Additional charge applies to</p>
      <ul>
        <li>Long walks greater than 20m.</li>
        <li>Pass up or down of materials to another level.</li>
        <li>Heavy materials such as Shaft Wall, CFC and other materials based on total SQM and weight, which is also dependent on site and unloading requirements.</li>
        <li>Combined SQM of top floors are greater than 600m2 for a duplex.</li>
        <li>Combined SQM of top floor of a double storey single house over 800m2.</li>
      </ul>
      <p>Exclusions & Restrictions</p>
      <ul>
        <li>Cladding, tiles, flooring and other fragile or delicate materials cannot be hand unloaded by our Drivers. Alternative methods of unloading are Crane, Fork or Hiab.</li>
        <li>Hebel materials such as Walsc Panels, Nasahi Panels, Pronto Panels and CSR Hebel Panels cannot be hand unloaded by our Drivers. Alternative methods of unloading are Crane, Fork or Hiab.</li>
        <li>Materials greater than 60kg or thicker than 30mm will not be hand unloaded by our Drivers.</li>
        <li>Small trucks are not available. Available trucks are between 6T to 8T.</li>
      </ul>
    </div>
    <div class="mt-4">
      <h3 class="text-capitalize mb-3">Men on Site <span class="g-font-size-17">– No assistance from our Driver to unload. Site must arrange at least two on site labourers to unload materials off the truck. Site will have 1 hour of unloading time.</span></h3>
      <p>Additional charge applies to</p>
      <ul>
        <li>After 1 hour of unloading time, a waiting time surcharge will be applied.</li>
      </ul>
    </div>
    <div class="mt-4">
      <h3 class="text-capitalize mb-3">Fork/Crane Unload <span class="g-font-size-17">– A fork or crane on site will unload the materials off the truck.</span></h3>
      <p>Additional charge applies to</p>
      <ul>
        <li>Wrap, strap or pallet options.</li>
        <li>HIAB (On truck crane – only to ground floor next to truck)</li>
      </ul>
      <p>Exclusions</p>
      <p class="font-weight-bold">HIAB – A truck that has a crane attached to either the front or back of a truck</p>
      <ul>
        <li>This does not include HIAB truck option, please call 13 4863 with your order reference to request a HIAB delivery.</li>
        <li>Freight cannot be estimated online for HIAB unload. </li>
      </ul>
    </div>
    <div class="mt-4">
      <h3 class="text-capitalize mb-3">Terms of Sale</h3>
      <p>Goods will remain the property of Hume Plasterboard Pty Ltd until paid in full.</p>
      <p>A discretion to allocate available stock gives no warranty as to the certainty of products being supplied.</p>
      <p>Please check goods upon pickup and delivery.</p>
      <p>Any damages or missing items must be notified within 48 hours of acceptance of delivery.</p>
    </div>
    <div class="mt-4">
      <h3 class="text-capitalize mb-3">Special Orders/ Non-Stocked Products</h3>
      <p>No cancellation or refunds apply for special orders and non-stock products upon payment of goods.</p>
    </div>
    <div class="mt-4">
      <h3 class="text-capitalize mb-3">Change of Mind Returns/Exchanges</h3>
      <p>Change of mind returns or exchanges will only be accepted on the below conditions:</p>
      <ul>
        <li>With a receipt or tax invoice.</li>
        <li>All returned goods must be unused, in original packaging and in saleable condition.</li>
        <li>All refunds will be provided back in the original tender.</li>
      </ul>
      <p>For returned and/or cancelled orders for standard stocked items, a 20% surcharge applies. Please note that plaster products are not refundable or returnable. </p>
    </div>
    <div class="mt-4 mb-5">
      <h3 class="text-capitalize mb-3">Exceptions</h3>
      <p>Unfortunately, we cannot offer a change of mind refund or exchange on the following items:</p>
      <ul>
        <li>Custom cut.</li>
        <li>Processed or acquired products.</li>
      </ul>
    </div>
  </div>
</div>




