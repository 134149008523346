import {Component, ViewChild, ElementRef, AfterViewInit, OnDestroy} from '@angular/core';
import {ActivatedRoute, Router} from '@angular/router';
import { Observable } from 'rxjs';
import { ChangeDetectorRef } from '@angular/core';
import { Globals} from '../../global';
import { TrackingService} from '../../services/tracking.service';

declare var google: any;
@Component({
  selector: 'app-tracking-result',
  templateUrl: './result.component.html',
  styleUrls: ['./result.component.scss']
})
export class TrackingResultComponent implements  AfterViewInit, OnDestroy {
  sub;
  routeParams;
  truck: Object;
  map: Object;
  marker: Object;
  zoom: number;
  ps: string ;
  expectedDate: string;
  schedule: string;
  status: boolean;
  stepNo: number;
  suburb: string;
  eid: string;
  address: string;
  podUrl: string;
  processSteps: ProcessStep[];
  mapStatus = true ;
  timeInterval;
  mapInterval = 0;
  getLocation = new Observable;
  destination;
  destinationCode;
  duration;
  distance = new Observable;
  showPod = false;
  showTracking: boolean;
  showInfo = false;
  bounds;

  constructor ( private router: Router,
                private ref: ChangeDetectorRef,
                private trackingService: TrackingService,
                private route: ActivatedRoute,
                public globals: Globals) { }
  @ViewChild('map', { static: true }) mapRef: ElementRef;
  ngAfterViewInit() {
    this.sub = this.route.queryParams.subscribe(params => {
      this.routeParams = params;
    });
    this.getLocation = this.trackingService.getLocation(this.routeParams.pickingSlip, this.routeParams.suburb);
    // Tracking order details
    this.trackingService.getTracking(this.routeParams.pickingSlip, this.routeParams.suburb)
      .subscribe(data => {
          // @ts-ignore
          let deliveryDate = data.trackingModel.expectedDate;
          deliveryDate = deliveryDate.split(/\//);
          deliveryDate = deliveryDate[1] + '/' + deliveryDate[0] + '/' + deliveryDate[2];
          deliveryDate = new Date(deliveryDate);
          deliveryDate.setMonth(deliveryDate.getMonth() + 1);
          const currentDate = new Date();
          if (currentDate > deliveryDate) {
            this.showTracking = false;
            this.showInfo = true;
          } else {
            this.showTracking = true;
            this.showInfo = false;
          }
          // @ts-ignore
          const tm = data.trackingModel;
          // @ts-ignore
          if (data.status === true) {
            this.ps = tm.orderNo;
            this.suburb = tm.suburb;
            this.expectedDate = tm.expectedDate;
            this.schedule = tm.schedule;
            this.status = tm.status;
            this.stepNo = tm.step;
            this.eid = tm.eid;
            this.address = tm.address;
            this.processSteps = [
              {title: 'Order Received', status: false, timeStamp: tm.timestamps.confirmed},
              {title: 'Order Prepared', status: false, timeStamp: tm.timestamps.prepared},
              {title: 'Delivery Scheduled', status: false, timeStamp: tm.timestamps.scheduled},
              {title: 'Order Dispatched', status: false, timeStamp: tm.timestamps.dispatched},
              {title: 'Order Delivered', status: false, timeStamp: tm.timestamps.delivered},
            ];
            // Generate Pod Url
            const codeString = this.eid + ':' + this.ps + ':' + this.suburb;
            this.podUrl = 'https://hls.humeplaster.com.au/pod?token=' + btoa(codeString);
            // Show pod button
            if (this.stepNo === 5) {
              this.showPod = true;
            }
            // Update process status
            for (let i = 0; i < this.stepNo; i++) {
              this.processSteps[i].status = true;
            }
            // Geo code process
            this.destination = this.trackingService.getDestination(this.ps, this.suburb);
            this.destination = this.address + ',' + this.destination;
            this.geoCode(this.destination);
          } else {
            window.alert('Your order does not match with suburb, please click ok to go back.');
            this.router.navigate(['']);
          }
        },
        error => {
          this.router.navigate(['']);
        });

    // Tracking truck location details
    this.getLocationFunction();

    // used setTimeout google map is delayed in loading, in stackBlitz
    setTimeout(() => {
      // this.locationBounds();
      this.map = new google.maps.Map(this.mapRef.nativeElement, {
        // zoom: this.zoom,
        // center: this.truck
      });

      /** Set center of maps based on two markers **/
      this.locationBounds();

      this.marker = new google.maps.Marker({
        position: this.truck,
        map: this.map,
        icon: {
          url: 'https://s3-ap-southeast-2.amazonaws.com/hume-website-photo/tracking/truck-solid.svg',
          scaledSize: new google.maps.Size(30, 30)
        }
      });
      this.marker = new google.maps.Marker({
        position: this.destinationCode,
        map: this.map,
      });
    }, 2000);

    this.timeInterval = setInterval(() => {
      this.getLocationFunction();
      // Update map
      this.map = new google.maps.Map(this.mapRef.nativeElement, {
        // zoom: this.zoom
      });

      /** Set center of maps based on two markers **/
      this.locationBounds();

      this.marker = new google.maps.Marker({
        position: this.truck,
        map: this.map,
        icon: {
          url: 'https://s3-ap-southeast-2.amazonaws.com/hume-website-photo/tracking/truck-solid.svg',
          scaledSize: new google.maps.Size(30, 30),
        }
      });
      this.marker = new google.maps.Marker({
        position: this.destinationCode,
        map: this.map,
      });
      this.mapInterval += 1;
      // this.marker = { postion: this.truck, map: this.map };
    }, 30000);

  }

  /** Set center of maps based on two markers **/
  locationBounds() {
    this.bounds = new google.maps.LatLngBounds();
    this.bounds.extend(this.truck);
    this.bounds.extend(this.destinationCode);
    // @ts-ignore
    this.map.fitBounds(this.bounds);
  }

  // Navigate to Home page
  homePage() {
    // this.globals.showSubmit = true;
    this.router.navigate([{outlets: {}}], {queryParams: {}});
  }

  externalPage() {
    window.location.href = this.podUrl;
  }

  // Get location details function
  getLocationFunction(): void {
    this.getLocation
      .subscribe(data => {
          // Setting mapStatus will show the map window
          this.mapStatus = false;
          // @ts-ignore
          this.truck = { lat: data.latitude, lng: data.longitude };
          // @ts-ignore
          this.zoom = 11;
          // Calculate distance
          this.distanceMatrix(this.truck, this.destination);
        },
        error => {
          // console.log(error);
          // Setting mapStatus will hide the map window
          this.mapStatus = true;
          clearInterval(this.timeInterval);
        });
    // Tracking truck location detail
  }

  /**
   * getting estimated distance and duration between the truck and destination
   * @param origin
   * @param destination
   */
  distanceMatrix(origin, destination) {
    let element;
    const service = new google.maps.DistanceMatrixService();
    service.getDistanceMatrix({
      origins: [origin],
      destinations: [destination],
      travelMode: google.maps.TravelMode.DRIVING,
      unitSystem: google.maps.UnitSystem.METRIC,
    }, (response, status) => {
      if ( status === 'OK' ) {
        element = response.rows[0].elements[0];
      }
    });
    setTimeout(() => { this.distance = element.distance.text; this.duration = element.duration.text; }, 1000 ) ;
  }

  geoCode (address) {
    let element;
    const geoCoder = new google.maps.Geocoder();
    geoCoder.geocode({
      'address': address
    }, function (results, status) {
      if (status === 'OK') {
        element = results[0].geometry.location;
      }
    });
    setTimeout(() => { this.destinationCode = element; }, 1000 ) ;
  }

  ngOnDestroy(): void {
    clearInterval(this.timeInterval);
    if (this.sub) {
      this.sub.unsubscribe();
    }
    // localStorage.clear();
  }
}

export interface ProcessStep {
  title: string;
  status: boolean;
  timeStamp: string;
}

/**

 */
