import {Injectable} from '@angular/core';
import {Store} from '../../store';
import {AuthService} from './auth/auth.service';
import {ajax} from 'rxjs/ajax';
import {Globals} from '../global';
import {HumeStoresService} from './hume-stores.service';
import {HttpClient} from '@angular/common/http';
import {BehaviorSubject} from 'rxjs';
import {HandleErrorService} from './handle-error.service';
import {group} from '@angular/animations';


@Injectable()

export class ProductService {
  productApi = this.globals.humeEcomApiRef + 'product/';

  private webCatSubject = new BehaviorSubject<any>([]);
  public webCat = this.webCatSubject.asObservable();

  private eWebCatSubject = new BehaviorSubject<any>([]);
  public eWebCat = this.eWebCatSubject.asObservable();

  private clearanceSubject = new BehaviorSubject<any>([]);
  public clearance = this.clearanceSubject.asObservable();

  constructor(
    private store: Store, private handleErrorService: HandleErrorService,
    private authService: AuthService,
    private humeStoresService: HumeStoresService,
    private http: HttpClient,
    public globals: Globals
  ) {
  }

  getBrands() {
    // return ajax(this.globals.humeApiRef + 'brand');
    return ajax(this.globals.humeEcomApiRef + 'info/brands');
  }

  getBrandsByType(type?) {
    switch (this.globals.divisionTag) {
      case 'home':
        type = 'all';
        break;
      case 'commercial':
        type = 'commercial';
        break;
      case 'architectural':
        type = 'architectural';
        break;
    }
    if (type !== 'all') {
      return ajax(this.globals.humeEcomApiRef + 'info/brands/type/' + type.toUpperCase());
    } else {
      return ajax(this.globals.humeEcomApiRef + 'info/brands');
    }
  }

  getChildrenProductsById(productId) {
    return this.http.get(this.productApi + 'child/' + productId);
  }

  getEProductsWebCategories() {
    return this.http.get(this.productApi + 'webcat');
  }

  getEProductsWebCatDetails(categoryId) {
    // console.log('@@@@@@@@@@@@@@@@@@@@@@@@@@@getEproduct');
    return this.http.get(this.productApi + 'webcat/' + categoryId);
  }

  getEWebCatList() {
    return this.http.get(this.productApi + 'webcat/web/site/0');
  }

  getEWebCatListAll() {
    return this.http.get(this.productApi + 'webcat/web/site/3');
  }

  getEWebCatByDivision(divisionTag) {
    // ** New Api divisionTag will be integer.
    // ** 0 - all, 1 - commercial, 2 - architectural.
    let site;
    switch (divisionTag) {
      case 'arch':
        site = 2;
        break;
      case 'main':
        site = 0;
        break;
      case 'com':
        site = 1;
        break;
    }
    return this.http.get(this.productApi + 'webcat/web/site/' + site);
  }

  getEWebCatById(id) {
    return this.http.get(this.productApi + 'webcat/web/' + id);
  }

  getESpecialProducts(page, rows, filterString?) {
    return this.http.get(this.productApi + 'specials' + (filterString ? ('?q=' + filterString) : '?q=') + '&page=' + (page ? page : 0) + '&size=' + (rows ? rows : 20));
  }

  getEClearanceProducts(page, rows, filterString?) {
    return this.http.get(this.productApi + 'clearance' + (filterString ? ('?q=' + filterString) : '?q=') + '&page=' + (page ? page : 0) + '&size=' + (rows ? rows : 20));
  }

  getEProductDetailsById(productId) {
    return this.http.get(this.productApi + 'details/' + productId);
  }

  getProductsByEcomCategory(ecomCategoryCode, page, rows, filterString?) {
    return this.http.get(this.productApi + 'ecomcat/' + ecomCategoryCode + (filterString ? ('?q=' + filterString) : '?q=') + '&page=' + (page ? page : 0) + '&size=' + (rows ? rows : 20));
  }

  getRelatedProductsById(productId) {
    return this.http.get(this.productApi + 'related/' + productId);
  }

  getFeatureProductsByEcomCategory(ecomCategoryCode) {
    return this.http.get(this.productApi + 'ecomcat/' + ecomCategoryCode + '/featured');
  }

  getFeatureProductsByGroup(groupId) {
    return this.http.get(this.productApi + 'group/' + groupId + '/featured');
  }

  getGroupProductsById(groupId, page, rows, filterString?) {
    return this.http.get(this.productApi + 'group/' + groupId + (filterString ? ('?q=' + filterString) : '?q=') + '&page=' + (page ? page : 0) + '&size=' + (rows ? rows : 20));
  }

  getWizardProductsById(productId) {
    return this.http.get(this.productApi + 'wizard/' + productId);
  }

  getStocksById(productId) {
    return this.http.get(this.productApi + 'stock/' + productId);
  }

  getWebCat() {
    if (this.authService.currentTokenValue) {
      this.getEWebCatList().toPromise().then(res => {
        this.globals.webCat = res;
        this.webCatSubject.next(res);
        return res;
      }).catch(error => {
        // console.log(error);
        this.handleErrorService.subscribeErrorUnAuth(error);
      });
    } else {
      this.authService.refreshToken();
    }

  }

  getEWebCat() {
    if (this.authService.currentTokenValue) {
      this.getEProductsWebCategories().toPromise().then(res => {
        this.globals.eWebCat = res;
        this.eWebCatSubject.next(res);
      }).catch(error => {
        // console.log(error);
      });
    } else {
      this.authService.refreshToken();
    }
  }

  getClearance() {
    this.getEClearanceProducts(0, 20).toPromise().then(res => {
      this.clearanceSubject.next(res);
    });
  }
}
