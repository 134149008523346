import {Component, OnInit, ViewChild} from '@angular/core';
import {FormBuilder, FormGroup, Validators} from '@angular/forms';
import {ReCaptcha2Component} from 'ngx-captcha';
import {ModalDirective} from 'angular-bootstrap-md';
import {Globals} from '../../global';
import {ContactService} from '../../services/contact.service';

@Component({
  selector: 'app-subscription-form',
  templateUrl: './subscription-form.component.html',
  // styleUrls: ['./news.component.scss']
})
export class SubscriptionFormComponent implements OnInit {
  submitted: boolean;
  formSubmitError = false;
  subscribeFormGroup: FormGroup;

  siteKey = this.globals.siteKey;
  @ViewChild('captchaElemSub', { static: true }) captchaElem: ReCaptcha2Component;
  @ViewChild('frame') frame: ModalDirective;
  @ViewChild('successModalSub', { static: true }) successModal: ModalDirective;

  get f() { return this.subscribeFormGroup; }

  constructor(public globals: Globals,
              private contactService: ContactService,
              private formBuilder: FormBuilder) {}

  ngOnInit () {
    this.subscribeFormGroup = this.formBuilder.group({
      recaptchaSub: ['', Validators.required],
      marketingAgreement: ['', Validators.requiredTrue],
      name: ['', Validators.required],
      email: ['', [Validators.required, Validators.pattern('[^ @]*@[^ @]*')]]
    });
  }

  onSubmit() {
    this.submitted = true;
    if (this.subscribeFormGroup.valid) {
      const formValue = this.subscribeFormGroup.value;
      const recaptcha = formValue.recaptchaSub;
      delete formValue.recaptchaSub;
      delete formValue.marketingAgreement;
      const myJSON = JSON.stringify(formValue);
      this.contactService.subscriptionFormPost(myJSON, recaptcha).subscribe(
        data  => {
          this.successModal.show();
          this.subscribeFormGroup.reset();
          this.captchaElem.resetCaptcha();
        },
        error  => {
          if (error.status === 202 || error.status === 200) {
            this.successModal.show();
            this.subscribeFormGroup.reset();
            this.captchaElem.resetCaptcha();
          } else {
            // console.log('Error', error);
            this.formSubmitError = true;
            this.captchaElem.resetCaptcha();
          }
        }
      );
    } else {
      // stop here if form is invalid
      return;
    }
  }
}
