<div [ngClass]="globals?.showHeader ? 'single-component-container' : ''">
  <!-- Promo Block -->
  <section class="g-bg-img-hero">
    <div id="single-news-title" class="container text-center d-flex justify-content-center">
      <div class="g-mb-10" style="margin-top: 5vh !important;">
        <!--<span class="d-block g-color-white g-font-size-20 text-uppercase g-letter-spacing-5 mb-4">Hume In Syndey</span>-->
        <h1 class="g-color-primary g-font-weight-700 g-font-size-20 g-font-size-45--md g-line-height-1_2 mb-4"
            [ngClass]="!globals.isMobile ? 'g-letter-spacing-10' : 'g-letter-spacing-5'">{{singleNews?.title}}</h1>
        <div class="g-width-70x--md mt-2" style="margin: auto">
          <span id="single-news-summary" class="d-block lead text-left g-font-size-17 g-font-weight-400 mb-3 px-3 g-letter-spacing-2">{{singleNews?.summary}}</span>
        </div>
        <div class="mb-3">
          <span class="g-font-weight-400 g-font-size-14">{{singleNews?.createdOn}}</span>
        </div>
        <share-buttons *ngIf="globals?.showHeader" [theme]="'circles-light'"
                       [include]="['facebook','twitter','linkedin','email']"
                       [show]="5"
                       [showText]="globals.isMobile"
                       [autoSetMeta]="false"
        ></share-buttons>
      </div>
    </div>
  </section>
  <!-- End Promo Block -->
  <!-- Blog Single Item Info -->
  <section class="container g-pt-5--md g-pt-2 g-pb-60">
    <img id="single-news-inner-html" [src]= " !globals.isMobile ? imgUrl + singleNews?.bannerUrl : imgUrl + singleNews?.thumbnailUrl" alt="{{singleNews?.title}}" *ngIf="singleNews?.bannerUrl && singleNews?.thumbnailUrl">
    <div class="row justify-content-center g-mt-50">
      <div class="col-lg-12 g-font-size-17">
        <div class="g-mb-20" [innerHTML]="htmlContent">
        </div>
      </div>
    </div>
    <div class="g-mt-30" *ngIf="singleNews?.title === 'Hume Prestons Fire Safety Demo and Information Day'">
      <ks-carousel [id]="108" [images]="imagesRect"
                   [playConfig]="{autoPlay: autoPlay, interval: 3000, pauseOnHover: true}"
                   [previewConfig]="{visible: false}"
                   [carouselConfig]="{maxWidth: '100%', maxHeight: '624px', showArrows: showArrows, objectFit: 'cover', keyboardEnable: true, modalGalleryEnable: false, legacyIE11Mode: false}"
                   [dotsConfig]="{visible: showDots}">
      </ks-carousel>
    </div>

    <!-- Customised page for Hume Architectural Solutions: New Team, New Products news -->
    <div *ngIf="singleNews?.id === 28" class="g-mb-60 g-font-size-17">
      <hr>
      <h3 class="text-left"><strong>Exciting New Products:</strong></h3>

      <p>Amongst the new products being introduced to the Australian market by the team are these innovative solutions:</p>

      <hr>
      <ul>
        <li class="col-lg-12">
          <p>
            <a href="https://www.humebuildingproducts.com.au/categories/sub-categories/compressed_fibre_cement">Compressed Fibre Cement</a> (CFC): Hume&nbsp;provides end-to-end service and solutions&nbsp;for architects, builders and designers, with&nbsp;world-renowned and sustainable brands &amp; technologies of Compressed Fibre Cement.&nbsp;Hume is the sole&nbsp;distributor in Australia of Italy&#39;s&nbsp;<a href="http://www.sil-flatsheets.com/silbonit/">Silbonit</a>&nbsp;Through Coloured Pre-finished Compressed Fibre Cement fa&ccedil;ade panels. We supply Silbonit for&nbsp;facade design,&nbsp;interior cladding&nbsp;and&nbsp;finishings.
          </p>
          <div class="row">
            <figure class="figure col-md-4">
              <img class="figure-img img-fluid" [src]="imgUrl + 'categories-web/cfc_cladding/Cinema+Montivilliers.jpg'" alt="Hume news">
            </figure>
            <figure class="figure col-md-4">
              <img class="figure-img img-fluid" [src]="imgUrl + 'categories-web/cfc_cladding/SILbonit+Tempo+Drummoyne.jpg'" alt="Hume news">
            </figure>
            <figure class="figure col-md-4">
              <img class="figure-img img-fluid g-min-height-230--md" [src]="imgUrl + 'categories-web/cfc_cladding/Silbonit+Facciate.jpg'" alt="Hume news">
            </figure>
          </div>
        </li>
      </ul>
      <hr>
      <ul>
        <li class="col-lg-12">
          <p>
            <a href="https://www.humebuildingproducts.com.au/categories/sub-categories/terracotta_cladding">Terracotta Cladding</a>: We supply <a href="https://www.humebuildingproducts.com.au/categories/sub-categories/terracotta_cladding">terracotta cladding</a>, terracotta battens and terracotta baguettes. We are the sole distributor in Australia for&nbsp; <a href="http://www.faveton.com/en/home">Faveton Terracotta</a> and <a href="http://www.louvelia.com/">Louvelia</a>. Faveton offers higher level comfort and habitability in the ventilated facade market. Louvelia provides porcelain &amp; ceramic architectural fa&ccedil;ade solutions and fixing systems, particularly for ventilated facades.
          </p>
          <div class="row">
            <div class="col-12 col-md-4 mb-3 projects-img" >
              <img [src]="imgUrl + 'categories-web/terracotta_cladding/tc-grid2.jpg'" style="height: 230px" class="img-fluid"
                   alt="Hume Terracotta Cladding">
            </div>
            <div class="col-12 col-md-4 mb-3 projects-img" >
              <img [src]="imgUrl + 'categories-web/terracotta_cladding/tc-grid3.jpg'" style="height: 230px" class="img-fluid"
                   alt="Hume Terracotta Cladding">
            </div>
            <div class="col-12 col-md-4 mb-3 projects-img" >
              <img [src]="imgUrl + 'categories-web/terracotta_cladding/tc-grid4.jpg'" style="height: 230px" class="img-fluid"
                   alt="Hume Terracotta Cladding">
            </div>
          </div>
        </li>
      </ul>
      <hr />
      <ul>
        <li>
          <p><a href="https://www.humebuildingproducts.com.au/categories/sub-categories/ceramic_cladding">Ceramic Cladding</a>: The Hume Architectural team provide solutions in <a href="https://www.humebuildingproducts.com.au/categories/sub-categories/ceramic_cladding">ceramic cladding</a>. We are the sole distributor in Australia for <a href="http://www.louvelia.com/">Louvelia</a> porcelain &amp; ceramic architectural fa&ccedil;ade solutions and fixing systems imported from Spain.&nbsp;Louvelia is known for developing fully customised solutions in engineering, coatings and fixings, particularly for&nbsp;ventilated facades.</p>
          <div class="row">
            <div class="col-12 col-md-4 mb-3 projects-img" >
              <img [src]="imgUrl + 'categories-web/ceramic_cladding/ceramic1.jpg'" style="height: 230px" class="img-fluid"
                   alt="Hume Ceramic Cladding">
            </div>
            <div class="col-12 col-md-4 mb-3 projects-img" >
              <img [src]="imgUrl + 'categories-web/ceramic_cladding/ceramic2.jpg'" style="height: 230px" class="img-fluid"
                   alt="Hume Ceramic Cladding">
            </div>
            <div class="col-12 col-md-4 mb-3 projects-img" >
              <img src="https://36795.cdn.cke-cs.com/HSdkFMpkusmHnhcVmaVi/images/f157007c2c024b97567dbac65ed7c5bf94fea6de9974e67b.png/w_1223" style="height: 230px" class="img-fluid"
                   alt="Hume Ceramic Cladding">
            </div>
          </div>
        </li>
      </ul>
      <hr />
      <ul>
        <li>
          <p>
            <a href="https://www.humebuildingproducts.com.au/categories/sub-categories/BiPV">Building Integrated Photovoltaics</a>: We are the distributor in Australia of Spain&#39;s <a href="https://www.onyxsolar.com/">Onyx Solar</a>, the world&#39;s leading manufacturer of transparent photovoltaic (PV) glass for buildings. PV Glass is used as a building material plus as an energy-generating device, capturing the sunlight and transforming it into electricity.&nbsp;By providing the same thermal performance as conventional glass - along with clean, free electricity from the sun - BiPV allows buildings to drastically improve their energy efficiency, decrease operation and maintenance costs, and <a href="https://www.onyxsolar.com/about-onyx/sustainability">reduce their carbon footprint</a>
          </p>
          <div class="row">
            <div class="col-12 col-md-4 mb-3 projects-img" >
              <img [src]="imgUrl + 'categories-web/building_integrated/g2.jpg'" style="height: 230px" class="img-fluid"
                   alt="Hume Onyx Solar">
            </div>
            <div class="col-12 col-md-4 mb-3 projects-img" >
              <img [src]="imgUrl + 'categories-web/building_integrated/g1.jpg'" style="height: 230px" class="img-fluid"
                   alt="Hume Onyx Solar">
            </div>
            <div class="col-12 col-md-4 mb-3 projects-img" >
              <img [src]="imgUrl + 'categories-web/building_integrated/g3.jpg'" style="height: 230px" class="img-fluid"
                   alt="Hume Onyx Solar">
            </div>
          </div>
        </li>
      </ul>
      <hr/>
      <ul>
        <li>
          <p>
            <a href="/categories/sub-categories/composite_timber_cladding">Composite Timber</a>:&nbsp;Hume provides end-to-end service and solutions in <a href="/categories/sub-categories/composite_timber_cladding">composite timber</a> and engineered timber cladding, battens screening, sofit lining and decking. Hume is the sole distributor in Australia of Italy&#39;s <a href="https://www.woodn.com/">Woodn</a> engineered bamboo (WPC) external and internal building products.&nbsp;Woodn is a composite material, consisting of a wood and PVC-based polymer alloy. The result of this alchemy is a technical wood, which can help overcome the limits of wood, enhancing durability and preventing the need for constant maintenance, while keeping its natural look and materiality.
          </p>
          <div class="row">
            <div class="col-12 col-md-4 mb-3 projects-img" >
              <img [src]="imgUrl + 'categories-web/composite_timber/g1.jpg'" style="height: 230px" class="img-fluid"
                   alt="Hume Ceramic Cladding">
            </div>
            <div class="col-12 col-md-4 mb-3 projects-img" >
              <img [src]="imgUrl + 'categories-web/composite_timber/g2.jpg'" style="height: 230px" class="img-fluid"
                   alt="Hume Ceramic Cladding">
            </div>
            <div class="col-12 col-md-4 mb-3 projects-img" >
              <img [src]="imgUrl + 'categories-web/composite_timber/g3.jpg'" style="height: 230px" class="img-fluid"
                   alt="Hume Ceramic Cladding">
            </div>
          </div>
        </li>
      </ul>
      <hr />
      <ul class="row">
        <li class="col-12 col-md-8">
          <p><a routerLink="/home">Aluminium Panels &amp; Cladding</a>: Hume is now the proud supplier of Aluminium 3mm solid panels and cladding boards in three finishes: solid colours, metallic and woodgrain finishes.</p>
          <p>In ancient mythology, the gods were depicted holding a shield called ‘Aegis’. Its meaning evokes feelings of protection and strength. Hume's new Aegis range of NCC compliant and durable aluminium products embody that sense of enduring protection and confidence.</p>
        </li>
        <li class="col-12 col-md-4 list-unstyled">
          <img class="g-height-200" style="width: auto !important;" src="https://36795.cdn.cke-cs.com/HSdkFMpkusmHnhcVmaVi/images/6483290fc8cf5762a02988a9df64cbf72ebc581e18cb68f8.jpg" alt="Hume Aluminium Cladding">
        </li>
      </ul>
      <hr />
      <h3><strong>More in the Hume Cladding Range:</strong></h3>

      <ul>
        <li><a routerLink="/categories/sub-categories/james_hardie">James Hardie Scyon</a></li>
        <li><a routerLink="/categories/sub-categories/weathertex">Weathertex</a></li>
        <li><a routerLink="/categories/sub-categories/modinex">Modinex</a></li>
        <li><a routerLink="/categories/sub-categories/austratus">Austratus</a></li>
        <li><a routerLink="/categories/sub-categories/Innova_Montage">Innova Montage</a></li>
        <li><a routerLink="/categories/sub-categories/bgc_fibre_cement">BGC Fibre Cement</a></li>
        <li><a routerLink="/categories/sub-categories/inex">Inex Boards</a></li>
      </ul>

      <hr>
      <h3><strong>Enquiries:</strong></h3>

      <div class="row">
        <div class="col-md-6">
          <p><strong>Lee Larbalestier</strong>, Specification Sales Manager</p>

          <p>Email: <a href="mailto:architectural@humebuildingproducts.com.au">architectural@humebuildingproducts.com.au</a></p>

          <p>Phone:  <a href="tel:0296819096">02 9681 9096</a></p>
        </div>
        <!--<div class="col-md-6">
          <figure class="figure align-right">
            <img alt="Hume news" class="figure-img img-fluid" src="https://36795.cdn.cke-cs.com/HSdkFMpkusmHnhcVmaVi/images/9ef8b9b004600a5ad252133395a4051b5b10714f3a4bb24e.jpeg"  width="100" />
            <figcaption></figcaption>
          </figure>
        </div>-->
      </div>


    </div>
    <!--End customised page for Hume Architectural Solutions: New Team, New Products news -->

  </section>
  <!-- End Blog Single Item Info -->

  <!-- Related Posts -->
  <section class="g-bg-gray-light-v5" *ngIf="relatedNews?.length != 0 && globals?.showHeader">
    <div class="g-pt-50--md g-pt-20" [ngClass]="!globals.isMobile ? 'container' : ''">
      <h3 class="h5 g-color-black g-font-weight-600 text-center g-mb-60--md g-mb-30">Other News</h3>

      <div class="row" *ngIf="!globals.isMobile">
        <div class="col-sm-6 col-lg-4 g-mb-30" *ngFor="let item of relatedNews; let i = index">
          <!-- Blog Classic Blocks -->
          <article class="u-shadow-v19 g-bg-white hoverable" *ngIf="i<3"  [routerLink]="['/news/' + item?.title | urlTransfer]">
            <img class="img-fluid w-100" style="height:265px" src="{{ item?.thumbnailUrl }}" alt="Hume news">
            <div class="g-bg-white g-pa-30">
              <span class="d-block g-color-gray-dark-v4 g-font-weight-600 g-font-size-12 text-uppercase mb-2">{{item?.date}}</span>
              <h2 class="h5 g-color-black g-font-weight-600 mb-3">
                <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" [routerLink]="['/news/' + item?.title | urlTransfer]">{{item?.title}}</a>
              </h2>
              <p class="g-color-gray-dark-v4 g-line-height-1_8">{{item?.summary}}</p>
            </div>
          </article>
          <!-- End Blog Classic Blocks -->
        </div>
      </div>

      <div *ngIf="globals.isMobile">
        <ngb-carousel>
          <ng-template ngbSlide *ngFor="let item of relatedNews; let i = index">
            <div class="col-sm-6 col-lg-4 g-mb-30">
              <!-- Blog Classic Blocks -->
              <article class="u-shadow-v19 g-bg-white hoverable" *ngIf="i<3"  [routerLink]="['/news/' + item?.title | urlTransfer]">
                <img class="img-fluid w-100" style="height:265px" src="{{ imgUrl + item?.thumbnailUrl }}" alt="Hume news">
                <div class="g-bg-white g-pa-30">
                  <span class="d-block g-color-gray-dark-v4 g-font-weight-600 g-font-size-12 text-uppercase mb-2">{{item?.date}}</span>
                  <h2 class="h5 g-color-black g-font-weight-600 mb-3">
                    <a class="u-link-v5 g-color-black g-color-primary--hover g-cursor-pointer" [routerLink]="['/news/' + item?.title | urlTransfer]">{{item?.title}}</a>
                  </h2>
                  <p class="g-color-gray-dark-v4 g-line-height-1_8">{{item?.summary}}</p>
                </div>
              </article>
              <!-- End Blog Classic Blocks -->
            </div>
          </ng-template>
        </ngb-carousel>
      </div>
    </div>
  </section>
  <!-- End Related Posts -->
</div>
