import {Component, OnInit} from '@angular/core';
import {Globals} from '../../global';
import {NewsService} from '../../services/news.service';

@Component({
  selector: 'app-home',
  templateUrl: './home.component.html',
  styleUrls: ['./home.component.scss']
})
export class HomeComponent implements OnInit {
  imgUrl = this.globals.picStorageRef;
  archBrochure;
  claddingBrochure;

  constructor(public globals: Globals, private newsService: NewsService) {
  }

  ngOnInit () {
    const url = window.location.href;
    if (url.includes('commercial')) {
      this.globals.divisionTag = 'commercial';
    } else if (url.includes('architectural')) {
      this.globals.divisionTag = 'architectural';
    } else {
      this.globals.divisionTag = 'home';
    }

    this.newsService.getBrochures().toPromise().then(res => {
      const result = res.response;
      this.archBrochure = result.filter(res1 => res1.title?.toLowerCase().includes('architectural'));
      this.claddingBrochure = result.filter(res2 => res2.title?.toLowerCase().includes('cladding'));
    });
  }
}
