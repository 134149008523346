import {AfterViewInit, Component, Input, OnInit} from '@angular/core';
import {Storage} from 'aws-amplify';

@Component({
  selector: 'app-attachments',
  templateUrl: './attachments.component.html',
  styleUrls: ['./attachments.component.scss']
})
export class AttachmentsComponent implements OnInit, AfterViewInit {
  @Input() formValue;
  @Input() pageUrl;
  isOpenLoader = false;
  attachments;
  fileName;
  current;
  max = 100;

  constructor() { }

  ngOnInit() {
    if (this.pageUrl === 'cod') {
      this.current = [0, 0];
      this.attachments = ['', ''];
      this.fileName = ['', ''];
    } else {
      this.current = [0];
      this.attachments = [''];
      this.fileName = [''];
    }
  }

  ngAfterViewInit() {
    document.getElementsByClassName('amplify-form-header')[0].innerHTML = 'Selected Files';
  }

  deleteFile(i) {
    this.fileName[i] = '';
    this.attachments[i] = '';
    this.current[i] = 0;
  }

  // ** Start uploading function **/
  openFileForUpload(i) {
    document.getElementById('selectImage' + i).click();
  }

  // This method puts image in AWS server
  saveImageInAmplify(filename, file, index) {
    const extension = filename.split('.');
    this.isOpenLoader = true;
    this.fileName[index] = filename;
    // Customize path give here
    const customPrefix = {
      public: 'myPublicPrefix/',
      protected: 'myProtectedPrefix/',
      private: 'myPrivatePrefix/'
    };
    const self = this;
    const time = Date.now();
    Storage.put( this.randomMath(20) + time + '.' + extension[1], file, {
      progressCallback(progress) {
        const value = Math.round(progress.loaded / progress.total * 100);
        self.current[index] = value;
      },
      contentType: '*/*',
      // customPrefix: customPrefix  // For customize path
    }).then((result: any) => {
      this.isOpenLoader = false;
      this.attachments[index] = result.key;
    }).catch((err) => {
      this.isOpenLoader = false;
    });
  }
  // ** End uploading function **/

  onImagePicked($event, index) {
    this.saveImageInAmplify($event.name, $event, index);
  }
  onImageLoaded($event, index) {
  }

  randomMath(length) {
    let result = '';
    const characters = 'ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789';
    const charactersLength = characters.length;
    for ( let i = 0; i < length; i++ ) {
      result += characters.charAt(Math.floor(Math.random() * charactersLength));
    }
    return result;
  }

}
