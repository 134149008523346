import { NgModule } from '@angular/core';
import { UrlTransferPipe} from './urlTransfer.pipe';
import {SubCategoriesPipe} from './sub-categories.pipe';
import {GetCartQuantityPipe} from './getCartQuantity.pipe';
import {SortByUnitPipe} from "./sortByUnit.pipe";

// Pipes

@NgModule({
  declarations: [
    SubCategoriesPipe,
    UrlTransferPipe,
    GetCartQuantityPipe,
    SortByUnitPipe
  ],
  imports: [

  ],
  exports: [
    UrlTransferPipe,
    SubCategoriesPipe,
    GetCartQuantityPipe,
    SortByUnitPipe
  ]
})
export class PipesModule {}
