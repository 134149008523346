import { NgModule } from '@angular/core';
import { RouterModule, Routes} from '@angular/router';
import {LoginComponent} from './login/login.component';
import {SignUpComponent} from './signup/signup.component';
import {PasswordRecoveryComponent} from './password/password-recovery.component';
import {CommonModule} from '@angular/common';
import { FormsModule, ReactiveFormsModule } from '@angular/forms';
import { AuthGuard} from './guards/auth.guard';
import { AuthService} from '../services/auth/auth.service';


export const ROUTES: Routes = [
  {
    path: 'auth',
    children: [
      {path: 'login', component: LoginComponent},
      {path: 'sign-up', component: SignUpComponent},
      {path: 'password-recovery', component: PasswordRecoveryComponent},
      {path: '**', redirectTo: 'login'}
    ]
  }
];

/*export const firebaseConfig: FirebaseAppConfig = {
  apiKey: 'AIzaSyAX4JfV6JRv5Vh5RkRZBZfSv-Yu4WoKyCE',
  authDomain: 'hume-website-3f451.firebaseapp.com',
  databaseURL: 'https://hume-website-3f451.firebaseio.com',
  projectId: 'hume-website-3f451',
  storageBucket: 'hume-website-3f451.appspot.com',
  messagingSenderId: '535044076935'
};*/

@NgModule({
  imports: [
    CommonModule,
    FormsModule,
    ReactiveFormsModule,
    RouterModule.forChild(ROUTES),
    // AngularFireModule.initializeApp(firebaseConfig)
  ],
  declarations: [
    LoginComponent,
    SignUpComponent,
    PasswordRecoveryComponent
  ],
  exports: [
    LoginComponent,
    SignUpComponent,
    PasswordRecoveryComponent
  ],
  providers: [ AuthGuard, AuthService ]
})
export class AuthModule { }
