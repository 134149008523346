// WARNING: DO NOT EDIT. This file is automatically generated by AWS Amplify. It will be overwritten.

const awsconfig = {
    "aws_project_region": "ap-southeast-2",
    "aws_cognito_identity_pool_id": "ap-southeast-2:03b53677-6841-460b-8227-21e204576557",
    "aws_cognito_region": "ap-southeast-2",
    "aws_user_pools_id": "ap-southeast-2_5OO0ZICxv",
    "aws_user_pools_web_client_id": "6flv056nsg6fb9a1mriv7b65ut",
    "oauth": {},
    "aws_user_files_s3_bucket": "hume-website-attachment",
    "aws_user_files_s3_bucket_region": "ap-southeast-2"
};


export default awsconfig;
