import {Component, Input} from '@angular/core';

@Component({
  selector: 'app-list-news',
  templateUrl: './list-news.component.html',
  // styleUrls: ['./list-news.component.scss']
})
export class ListNewsComponent {
  mobile = false;
  @Input() item: any;

  constructor() {
    if (window.screen.width < 700) {
      this.mobile = true;
    }
  }
}
