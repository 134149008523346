<div class="single-component-container">
  <div class="g-pb-20" style="font-size: 1.2rem">
    <!-- Promo Block -->
    <section class="u-bg-overlay g-bg-img-hero g-bg-bluegray-opacity-0_3--after"
             [ngStyle]="!globals.isMobile ? {'background-image': 'url(' + imgUrl + store?.bannerUrl + ')'} : {'background-image': 'url(' + imgUrl + store?.thumbnailUrl + ')'}">
      <div id="single-news-title" class="container u-bg-overlay__inner text-center g-pt-70 g-pb-70">
        <div class="g-mb-50 g-mt-50 about-title-overlay">
          <!--<span class="d-block g-color-white g-font-size-20 text-uppercase g-letter-spacing-5 mb-4">Hume In Syndey</span>-->
          <h1 class="g-color-white g-font-weight-700 g-font-size-20 g-font-size-50--md text-uppercase g-line-height-1_2 g-letter-spacing-10 mb-4">Hume Building Products {{store?.name}}</h1>
          <span class="d-block lead g-color-white g-letter-spacing-2" style="font-weight: 500">{{store?.address}}</span>
        </div>
      </div>
    </section>
    <!-- End Promo Block -->

    <!-- Call and quote buttons -->
    <!--<div class="row d-flex justify-content-center my-3" *ngIf="!store?.name?.toLowerCase().includes('sydney') && !store?.name?.toLowerCase().includes('melbourne')">

      <div class="dropdown">
        <button  type="button" class="ml-3 btn btn-hume-color g-bg-primary waves-light waves-effect font-weight-bold g-font-size-16 g-font-size-18--md"
                 [matMenuTriggerFor]="product" [ngClass]="globals.isMobile ? 'btn-sm' : 'btn-lg'" (click)="openList('singleCat')">Product List</button>
        <mat-menu #product="matMenu" class="mt-2">
          <a mat-menu-item class="font-weight-bold g-bg-primary--hover g-color-white" *ngFor="let item of vicWebCategories"
             style="max-width: 450px !important;" [routerLink]="'/' + item?.link">{{item?.name}}</a>
        </mat-menu>
      </div>

      <a href="tel:0384809888">
        <button class="ml-3 btn btn-hume-color g-bg-primary waves-light waves-effect font-weight-bold g-font-size-16 g-font-size-18--md"
                [ngClass]="globals.isMobile ? 'btn-sm' : 'btn-lg'" type="button">Call 13 4863</button>
      </a>
    </div>-->
    <!-- Call and quote buttons -->

    <!-- Show quote form modal -->
    <div mdbModal #frame="mdbModal" class="modal fade" tabindex="-1" role="dialog" aria-labelledby="myModalLabel" aria-hidden="true" style="overflow: scroll">
      <div class="modal-dialog" role="document">
        <app-quote-form  [frame]="frame"></app-quote-form>
      </div>
    </div>
    <!-- Show quote form modal -->

    <section id="content" class="my-5 container" [innerHTML]="store?.content">
    </section>

    <!-- Partner section only shows on sydney and melbourne page -->
    <div class="mt-5 container" *ngIf="store?.name?.toLowerCase().includes('sydney') || store?.name?.toLowerCase().includes('melbourne')">
      <h2 class="text-capitalize mb-3">Supply partners</h2>
      <app-home-partners [pageTag]="'nsw'" *ngIf="store?.name?.toLowerCase().includes('sydney')"></app-home-partners>
      <app-home-partners [pageTag]="'vic'" *ngIf="store?.name?.toLowerCase().includes('melbourne')"></app-home-partners>
    </div>
    <!-- End Partner section only shows on sydney and melbourne page -->

    <!-- section only show when showing branches-->
    <section class="mt-4 container text-center" *ngIf="store?.name?.toLowerCase() !== 'sydney' && store?.name?.toLowerCase() !== 'melbourne'">
      <h4 id="map" class="text-capitalize h2 mb-3">Hume Building Products {{store?.name}}</h4>
      <!-- Grid row -->
      <div class="row">
        <!-- Grid column -->
        <div class="col-md-10 col-12" style="margin-right: auto; margin-left: auto">
          <!-- Google map-->
          <div #map1 class="rounded z-depth-1-half" style="height: 400px"></div>
          <br>
          <!-- Contact Info -->
          <div class="row">
            <div class="col-md-6 col-lg-8 mx-auto">
              <!-- Media -->
              <div class="media g-brd-around g-brd-gray-light-v4 rounded g-pa-40--md g-pa-10">
                <div class="d-flex g-mr-30">
                  <i class="d-inline-block g-color-primary g-font-size-25 g-font-size-40--md g-pos-rel g-top-3 icon-real-estate-027 u-line-icon-pro"></i>
                </div>
                <div class="media-body text-left">
                  <span class="d-block g-font-weight-500 g-font-size-default text-uppercase mb-2">Location</span>
                  <span class="d-block g-color-gray-dark-v4 font-weight-bold">Hume Building Products {{store?.name}}:</span>
                  <span class="d-block g-color-gray-dark-v4">{{store?.address}}</span>
                </div>
              </div>
              <div class="media g-brd-around g-brd-gray-light-v4 rounded g-pa-40--md g-pa-10 mt-4">
                <div class="d-flex g-mr-30">
                  <i class="d-inline-block g-color-primary g-font-size-25 g-font-size-40--md g-pos-rel g-top-3 icon-real-estate-071 u-line-icon-pro"></i>
                </div>
                <div class="media-body text-left">
                  <span class="d-block g-font-size-default text-uppercase mb-2">Nearby Hume stores:</span>
                  <ul class="list-unstyled mb-0">
                    <li class="d-block g-color-gray-dark-v4" *ngIf="store?.nearby?.yen"><a routerLink="/hume-stores/Yennora"><strong>Hume Building Products Yennora</strong></a>: 32 Pine Road, Yennora.</li>
                    <li class="d-block g-color-gray-dark-v4" *ngIf="store?.nearby?.lak"><a routerLink="/hume-stores/Lakemba"><strong>Hume Building Products Lakemba</strong></a>: 6 Frazer Street, Lakemba.</li>
                    <li class="d-block g-color-gray-dark-v4" *ngIf="store?.nearby?.sil"><a routerLink="/hume-stores/Silverwater"><strong>Hume Building Products Silverwater</strong></a>: 9 Blaxland Street, Silverwater.</li>
                    <li class="d-block g-color-gray-dark-v4" *ngIf="store?.nearby?.pres"><a routerLink="/hume-stores/Prestons"><strong>Hume Building Products Prestons</strong></a>: 7-9 Yato Road, Prestons.</li>
                    <li class="d-block g-color-gray-dark-v4" *ngIf="store?.nearby?.pym"><a routerLink="/hume-stores/Pymble"><strong>Hume Building Products Pymble</strong></a>: 14-16 Suakin St, Pymble.</li>
                    <li class="d-block g-color-gray-dark-v4" *ngIf="store?.nearby?.alx"><a routerLink="/hume-stores/Alexandria"><strong>Hume Building Products Alexandria</strong></a>: 63 Bourke Rd, Alexandria.</li>
                    <li class="d-block g-color-gray-dark-v4" *ngIf="store?.nearby?.pre"><a routerLink="/hume-stores/Preston"><strong>Hume Building Products Preston</strong></a>: 1C Bell Street, Preston, VIC.</li>
                    <li class="d-block g-color-gray-dark-v4" *ngIf="store?.nearby?.sun"><a routerLink="/hume-stores/Sunshine_West"><strong>Hume Building Products Sunshine West</strong></a>: 540 Somerville Road, Sunshine West, VIC.</li>
                  </ul>
                </div>
              </div>
              <!-- End Media -->
            </div>

            <div class="col-lg-4 col-md-6">
              <div class="mx-auto">
                <!-- Media -->
                <div class="media g-brd-around g-brd-gray-light-v4 rounded g-pa-40--md g-pa-10 mt-4 mt-md-0">
                  <div class="d-flex g-mr-30">
                    <i class="d-inline-block g-color-primary g-font-size-25 g-font-size-40--md g-pos-rel g-top-3 icon-communication-033 u-line-icon-pro"></i>
                  </div>
                  <div class="media-body text-left">
                    <span class="d-block g-font-weight-500 g-font-size-default text-uppercase mb-2">Call Us</span>
                    <ul class="list-unstyled mb-0">
                      <li class="d-block g-color-gray-dark-v4 g-font-size-30"><a href="tel:134863">13 4863</a></li>
                    </ul>
                  </div>
                </div>
                <div class="media g-brd-around g-brd-gray-light-v4 rounded g-pa-40--md g-pa-10 mt-4">
                  <div class="d-flex g-mr-30">
                    <i class="d-inline-block g-color-primary g-font-size-25 g-font-size-40--md g-pos-rel g-top-3 icon-hotel-restaurant-003 u-line-icon-pro"></i>
                  </div>
                  <div class="media-body text-left g-font-size-14">
                    <span class="d-block g-font-weight-500 g-font-size-default text-uppercase mb-2">Trading hours</span>
                    <ul class="list-unstyled mb-0">
                      <li class="d-block g-color-gray-dark-v4">Mon - Fri: <p>{{store?.hoursMF}}</p></li>
                      <li class="d-block g-color-gray-dark-v4">Saturday: <p>{{store?.hoursSat}}</p></li>
                    </ul>
                  </div>
                </div>
                <!-- End Media -->
              </div>
              <!-- End Contact Info -->

            </div>
          </div>
        </div>
        <!-- Grid column -->
      </div>
      <!-- Grid row -->

      <div class="g-py-40 text-left" *ngIf="store?.isNSW">
        <div class="mt-4">
          <h2 class="text-capitalize mb-3">Products and Services</h2>
          <div class="d-inline-block g-width-60 g-height-1 g-bg-primary mb-2"></div>
          <p>Hume Building Products is a leading Australian supplier of building products, tools and accessories, with <a routerLink="/contact">warehouse stores</a> across Sydney and Melbourne, and a fast delivery network servicing metropolitan and regional areas with:</p>
          <div class="row">
            <div class="col-md-8">
              <p>A vast range of products including:</p>
              <ul class="text-left">
                <li *ngFor="let item of nswWebCategories"><a [routerLink]="'/' + item?.link">{{item?.name}}</a></li>
              </ul>
              <p>Supply and installation services:</p>
              <ul class="text-left">
                <li><a routerLink="/flooring/carpets">Carpet supply and installation</a></li>
                <li><a routerLink="/flooring/timber_floors">Timber flooring supply and installation</a></li>
                <li><a routerLink="/flooring/vinyl_floors">Vinyl flooring supply and installation</a></li>
                <li><a routerLink="/flooring">Flooring supply and installation</a></li>
              </ul>
              <p>Fast delivery services:</p>
              <ul class="text-left">
                <li><a routerLink="/contact">Six stores in Sydney</a> for pick up or delivery</li>
                <li><a routerLink="/contact">Two stores in Melbourne</a> for pick up or delivery</li>
                <li><a routerLink="/tracking">Track delivery online</a></li>
              </ul>
            </div>
            <div class="col-md-4">
              <figure style="width: 305px" class="align-right figure mb-2">
                <img class="figure-img img-fluid" [src]="imgUrl + 'hume-stores/store-warehouse.jpeg'" alt="Hume warehouse stores" >
                <figcaption class="figure-caption pb-2">Warehouse stores across Sydney and Melbourne</figcaption>
              </figure>
              <figure style="width: 305px" class="align-right figure mb-2">
                <img class="figure-img img-fluid" [src]="imgUrl + 'hume-stores/store-floor.jpeg'" alt="Hume Supply & installation" >
                <figcaption class="figure-caption pb-2">Supply & installation</figcaption>
              </figure>
              <figure style="width: 305px" class="align-right figure mb-2">
                <img class="figure-img img-fluid pb-2" [src]="imgUrl + 'hume-stores/store-truck.jpeg'" alt="Hume fast delivery fleet" >
                <figcaption class="figure-caption">Fast delivery</figcaption>
              </figure>
            </div>
          </div>
        </div>
      </div>

      <div class="g-py-40 text-left" *ngIf="store?.isVIC">

        <div class="mt-4">
          <h2 class="text-capitalize mb-3">Products and Services</h2>
          <div class="d-inline-block g-width-60 g-height-1 g-bg-primary mb-2"></div>
          <p>Hume is the best building products supplier in Melbourne, with stores conveniently located at Preston and Sunshine West. Long known for our plasterboard, we remain a specialist supplier of internal linings and external fit outs, offering a wide variety of building products, tools and accessories for fast delivery in Victoria, NSW, the ACT and beyond.</p>
          <div class="row">
            <div class="col-md-8">
              <p>Hume’s main product lines in Victoria include: </p>
              <ul class="text-left">
                <li *ngFor="let item of webCategories" [ngClass]="{'hume-hidden': item?.name.includes('Flooring') || item?.name.includes('Tiles') || item?.name.includes('bathrooms') }"><a [routerLink]="['/categories/category']" [queryParams]="{ title: item?.name, id: item?.id}">{{item?.name}}</a></li>
              </ul>
              <p>Fast delivery services:</p>
              <ul class="text-left">
                <li><a routerLink="/contact">Two stores in Melbourne</a> for pick up or delivery</li>
                <li><a routerLink="/tracking">Fast delivery fleet of trucks</a></li>
                <li><a routerLink="/tracking">Track delivery online</a></li>
              </ul>
            </div>
            <div class="col-md-4">
              <figure style="width: 305px" class="align-right figure mb-2">
                <img class="figure-img img-fluid" [src]="imgUrl + 'Thumbnail_2019-01-23_05-45-34.jpeg'" alt="Hume Preston Warehouse" >
                <figcaption class="figure-caption pb-2">Hume Preston Warehouse</figcaption>
              </figure>
              <figure style="width: 305px" class="align-right figure mb-2">
                <img class="figure-img img-fluid" src="https://36795.cdn.cke-cs.com/HSdkFMpkusmHnhcVmaVi/images/fdb65bd598df90c3989e80297610bda68cb25db19d38b601_Hume%20Preston%20truck.jpeg/w_1080" alt="Hume fast deliver" >
                <figcaption class="figure-caption pb-2">Fast delivery</figcaption>
              </figure>
            </div>
          </div>
        </div>
      </div>

    </section>
    <!-- End section only show when showing branches-->
  </div>
</div>







