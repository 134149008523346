<div class="single-component-container">

  <div class="container g-pt-50 g-pb-20">
    <h1 class="g-mb-30 g-mb-50--md">News from Hume Building Products</h1>
    <div class="row justify-content-between">

      <!-- Links show up only when screen width < 768px-->
      <div id="links-mobile" class="col-lg-9 mb-3">
        <ul class="list-unstyled g-mt-25 g-font-size-13">
          <li style="float:left">
            <a id="links-mobile-label-all" class="d-block u-link-v5 g-color-primary font-weight-bold g-color-primary--hover rounded g-py-8 pl-2" (click)="getNewsByCatMobile('all')">
              All
              <span class="u-label g-rounded-30 g-bg-primary g-mr-5 g-font-weight-bold" style="font-size: 1rem;">{{categoryNewsCount('all')}}</span>
            </a>
          </li>
          <li *ngFor="let category of newsCat$" style="float:left">
            <a id="links-mobile-label-{{category.name}}" class="d-block u-link-v5 g-color-gray-dark-v4 g-color-primary--hover rounded g-py-8 pl-2" (click)="getNewsByCatMobile(category.name)">
              {{category.name}}
              <span class="u-label g-rounded-30 g-bg-primary g-mr-5 ml-2 g-font-weight-bold" style="font-size: 1rem;">{{categoryNewsCount(category.name)}}</span>
            </a>
          </li>
        </ul>
      </div>
      <!--End Links show up only when screen width < 768px-->
      <div class="col-lg-9 order-lg-2 g-py-30--md" *ngIf="filterNews; else loading">
        <div class="col-lg-9 order-lg-2 m-auto" *ngIf="!filterNews.length">
          <div class="alert alert-hume alert-dismissible fade show g-mt-70 text-center" role="alert">
            <h4 class="h5">
              <i class="fa fa-info-circle"></i>
              No News in this category yet!
            </h4>
          </div>
        </div>
        <app-list-news *ngFor="let humeNew of filterNews | paginate: config"
                       [item]="humeNew"
        ></app-list-news>
        <!-- Pagination -->
        <pagination-template #p="paginationApi"
                             [id]="config.id"
                             (pageChange)="onPageChange($event)">
          <nav class="g-mb-100--md g-mb-10" aria-label="Page Navigation">
            <ul class="list-inline mb-0">
              <li class="list-inline-item" [class.disabled]="p.isFirstPage()">
                <a class="u-pagination-v1__item g-width-30 g-height-30 g-brd-gray-light-v3 g-brd-primary--hover g-color-gray-dark-v5 g-color-primary--hover g-font-size-12 rounded-circle g-pa-5 g-ml-15" *ngIf="!p.isFirstPage()" (click)="p.previous()" aria-label="Next">
                          <span aria-hidden="true">
                            <i class="fa fa-angle-left"></i>
                          </span>
                  <span class="sr-only">Previous</span>
                </a>
              </li>
              <li class="list-inline-item hidden-down" *ngFor="let page of p.pages" [class.current]="p.getCurrent() === page.value">
                <a class="u-pagination-v1__item g-width-30 g-height-30 g-color-gray-dark-v5 g-color-primary--hover g-font-size-12 rounded-circle g-pa-5"
                   (click)="p.setCurrent(page.value)" *ngIf="p.getCurrent() !== page.value">{{ page.label }}</a>
                <a class="active u-pagination-v1__item g-width-30 g-height-30 g-brd-gray-light-v3 g-brd-primary--active g-color-white g-bg-primary--active g-font-size-12 rounded-circle g-pa-5"
                   *ngIf="p.getCurrent() === page.value">{{ page.label }}</a>
              </li>
              <li class="list-inline-item" [class.disabled]="p.isLastPage()">
                <a class="u-pagination-v1__item g-width-30 g-height-30 g-brd-gray-light-v3 g-brd-primary--hover g-color-gray-dark-v5 g-color-primary--hover g-font-size-12 rounded-circle g-pa-5 g-ml-15"
                   *ngIf="!p.isLastPage()" (click)="p.next()" aria-label="Next">
                          <span aria-hidden="true">
                            <i class="fa fa-angle-right"></i>
                          </span>
                  <span class="sr-only">Next</span>
                </a>
              </li>
              <!--<li class="list-inline-item float-right">
                {{p.pages | json}}
                <span class="u-pagination-v1__item-info g-color-gray-dark-v4 g-font-size-12 g-pa-5">Page 1 of {{p.pages[p.pages.length-1].label}}</span>
              </li>-->
            </ul>
          </nav>
        </pagination-template>
        <!-- End Pagination -->
      </div>
      <ng-template #loading>
        <div class="col-lg-9 order-lg-2">
          <div class="alert alert-dismissible fade show g-mt-70 text-center" role="alert">
            <div class="spinner-grow" style="width: 4.5rem; height: 4.5rem; background-color: #ae1e37;" role="status">
              <span class="sr-only">Loading...</span>
            </div>
          </div>
        </div>
      </ng-template>
      <!-------- Left side bar -------->
      <div class="col-lg-3 order-lg-1 g-brd-right--lg g-brd-gray-light-v4 g-mb-80--md g-mb-20">
        <div class="g-pr-20--lg g-py-30--md">
          <!-- Links show up only when screen width > 768px-->
          <div id="links-no-mobile" class="g-mb-50">
            <h3 class="h4 g-color-black g-font-weight-600 mb-4">Links</h3>
            <ul class="list-unstyled g-font-size-15 mb-0 pl-2">
              <li>
                <a id="links-label-all" class="d-block u-link-v5 g-color-primary font-weight-bold g-color-primary--hover rounded g-px-20 g-py-8" (click)="getNewsByCat('all')">
                  <i class="mr-2 fa fa-angle-right"></i> All
                  <span class="u-label g-rounded-30 g-bg-primary g-mr-10 ml-2 g-font-weight-bold" style="font-size: 1rem;">{{categoryNewsCount('all')}}</span>
                </a>
              </li>
              <li *ngFor="let category of newsCat$">
                <a id="links-label-{{category.name}}" class="d-block u-link-v5 g-color-gray-dark-v4 g-color-primary--hover rounded g-px-20 g-py-8" (click)="getNewsByCat(category.name)">
                  <i class="mr-2 fa fa-angle-right"></i> {{category.name}}
                  <span class="u-label g-rounded-30 g-bg-primary g-mr-10 ml-2 g-font-weight-bold" style="font-size: 1rem;">{{categoryNewsCount(category.name)}}</span>
                </a>
              </li>
            </ul>
            <hr class="g-brd-gray-light-v4 g-mt-50--md g-mt-10 mb-0">
          </div>
          <!-- End Links show up only when screen width > 768px-->

          <div id="stickyblock-start" class="js-sticky-block g-sticky-block--lg" data-start-point="#stickyblock-start" data-end-point="#stickyblock-end">
            <!-- Publications -->
            <!--<div class="g-mb-50" *ngIf="!globals.isMobile">
              <a class="twitter-timeline" href="https://twitter.com/HumeBuilding?ref_src=twsrc%5Etfw" data-height="1000">Tweets by HumeBuilding</a>
            </div>-->
            <!-- End Publications -->

            <!--<hr class="g-brd-gray-light-v4 g-my-50--md g-my-20">-->

            <!-- Tags -->
            <!--<div class="g-mb-40">
              <h3 class="h5 g-color-black g-font-weight-600 mb-4">Tags</h3>
              <ul class="u-list-inline mb-0">
                <li class="list-inline-item g-mb-10" *ngFor="let category of newsCat$">
                  <a class="u-tags-v1 g-color-gray-dark-v4 g-color-white--hover g-bg-gray-light-v5 g-bg-primary--hover g-font-size-12 g-rounded-50 g-py-4 g-px-15" href="#!">{{category.name}}</a>
                </li>
              </ul>
            </div>-->
            <!-- End Tags -->

            <!-- Newsletter -->
            <div class="g-mb-50--md">
              <h3 class="h5 g-color-black g-font-weight-600 mb-4">Newsletter</h3>
              <h2 class="h5 g-color-gray-light-v3 mb-4">
                <a routerLink="/subscribe">
                  <button class="btn u-btn-primary shadow-none g-rounded-25 rounded g-py-8 g-px-18" type="button" mdbBtn rounded="true" data-toggle="modal"
                          mdbWavesEffect><i class="fa fa-envelope-open g-pos-rel g-top-1 mr-2"></i>Subscribe to Hume</button>
                </a>
              </h2>
            </div>
            <!-- End Newsletter -->
          </div>
        </div>
      </div>
      <!-------- End left side bar -------->
    </div>
  </div>
</div>
