import { NgModule} from '@angular/core';
import { CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {CategoriesComponent} from './categories.component';
import {WebCategoriesSubCategoriesComponent} from './sub-categories/sub-categories.component';
import {ProductsModule} from '../products/products.module';
import {ModalModule} from 'angular-bootstrap-md';
import {ReactiveFormsModule} from '@angular/forms';
import {NgxCaptchaModule} from 'ngx-captcha';
import {PipesModule} from '../pipe/pipes.module';
import {NgbCarouselModule} from '@ng-bootstrap/ng-bootstrap';
import {CladdingComponent} from './sub-categories/cladding.component';
import {MatMenuModule} from '@angular/material/menu';
import {SharedModule} from '../shared/shared.module';

export const ROUTES: Routes = [
  { path: 'categories',
    children : [
      { path: ':link', component: CategoriesComponent},
      { path: 'sub-categories',
        children: [
          { path: ':link', component: WebCategoriesSubCategoriesComponent}
        ]
      }
    ]
  },
];

@NgModule({
  imports: [
    CommonModule,
    ProductsModule,
    ModalModule,
    ReactiveFormsModule,
    NgxCaptchaModule,
    PipesModule,
    NgbCarouselModule,
    RouterModule.forChild(ROUTES),
    MatMenuModule,
    SharedModule
  ],
  declarations: [
    CategoriesComponent,
    CladdingComponent,
    WebCategoriesSubCategoriesComponent,
  ],
  providers: []
})
export class CategoriesModule { }
