import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {Projectervice} from '../../services/project.service';
import {Globals} from '../../global';

@Component({
  selector: 'app-home-projects',
  templateUrl: './home-projects.component.html'
})
export class HomeProjectsComponent implements OnInit, OnDestroy {
  projectsSub: Subscription;
  allProjects;
  imgUrl = this.globals.picStorageRef;

  constructor(private projectService: Projectervice, public globals: Globals) {}

  ngOnInit() {
    this.projectsSub = this.projectService.getProjectList().subscribe( projects => {
      const temp = projects.response.reverse();
      if (this.globals.divisionTag === 'commercial') {
        this.allProjects = temp.filter(value => value.id === 13 || value.id === 15 || value.id === 6);
      } else {
        this.allProjects = temp.filter(value => value.id === 9 || value.id === 8 || value.id === 10);
      }
    });
  }

  ngOnDestroy() {
    if (this.projectsSub) { this.projectsSub.unsubscribe(); }
  }
}
