import {Component, OnDestroy, OnInit} from '@angular/core';
import {NewsService} from '../../services/news.service';
import { Subscription} from 'rxjs';
import {Globals} from '../../global';
declare var $: any;

@Component({
  selector: 'app-home-news',
  // styleUrls: ['./home-news.component.scss'],
  templateUrl: './home-news.component.html',
  styles: ['.news-grid { display: grid; grid-template-columns: 1fr 1fr 1fr }']
})
export class HomeNewsComponent implements OnInit, OnDestroy {
  news;
  newsSub: Subscription;

  constructor (private newsService: NewsService,
               public globals: Globals) {}

  getRoute(item: any) {
    return [
      `../news`, item.newsId
    ];
  }
  ngOnInit () {

    this.newsSub = this.newsService.getNewsList().subscribe(res => {
      if (this.globals.divisionTag === 'architectural') {
        this.news = res.response.filter(data => data?.category?.toLowerCase() === 'architectural');
      } else {
        this.news = res.response.filter(data => data?.feature === true);
      }
    });
  }

  ngOnDestroy() {
    if ( this.newsSub) { this.newsSub.unsubscribe(); }
  }
}

