import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import { CommonModule} from '@angular/common';
import {RouterModule, Routes} from '@angular/router';
import {NgxPaginationModule} from 'ngx-pagination';
import {BadgeModule, CollapseModule} from 'angular-bootstrap-md';
import {NewsComponent} from './containers/news.component';
import {SingleNewsComponent} from './single-new/single-new.component';
import {ListNewsComponent} from './list-news/list-news.component';
import {SharedModule} from '../shared/shared.module';
import {PipesModule} from '../pipe/pipes.module';
import {GalleryModule} from '@ks89/angular-modal-gallery';
import {NgbCarouselModule} from '@ng-bootstrap/ng-bootstrap';
import { ShareButtonsModule } from 'ngx-sharebuttons/buttons';
import { ShareIconsModule } from 'ngx-sharebuttons/icons';

export const ROUTES: Routes = [
  {
    path: 'news',
    children: [
      { path: '', component: NewsComponent },
      { path: ':title',
        data: {
          title: 'Hume Products',
          ogUrl: '/news',
          description: 'Hume provides a wide range of building products tools and accessories.'
        },
        component: SingleNewsComponent}
    ]
  },
  {
    path: 'newsv2',
    children: [
      { path: '', component: NewsComponent },
      { path: ':title',
        data: {
          title: 'Hume Products',
          ogUrl: '/news',
          description: 'Hume provides a wide range of building products tools and accessories.'
        },
        component: SingleNewsComponent}
    ]
  }
];

@NgModule({
    imports: [
        CommonModule,
        NgxPaginationModule,
        SharedModule,
        PipesModule,
        GalleryModule,
        NgbCarouselModule,
        ShareButtonsModule,
        ShareIconsModule,
        CollapseModule.forRoot(),
        RouterModule.forChild(ROUTES),
        BadgeModule,
    ],
  declarations: [
    NewsComponent,
    SingleNewsComponent,
    ListNewsComponent,
  ],
  exports: [
    NewsComponent,
    SingleNewsComponent,
    ListNewsComponent
  ],
  providers: [],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class NewsModule { }
