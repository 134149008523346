import { Component, OnInit } from '@angular/core';
import { FormBuilder, FormGroup, Validators } from '@angular/forms';
import { Router } from '@angular/router';
import { TrackingService} from '../../services/tracking.service';

@Component({
  selector: 'app-tracking-submit',
  templateUrl: './submit.component.html',
  styleUrls: ['./submit.component.scss']
})
export class TrackingSubmitComponent implements OnInit {

  ps: string ;
  suburb: string ;
  trackForm: FormGroup;
  submitted = false;
  noRecord = true;

  constructor(
    private fb: FormBuilder,
    private router: Router,
    private trackingService: TrackingService
  ) {}
  /*private translateService: TranslateService) {
  translateService.setDefaultLang('en');
}*/
  ngOnInit() {
    this.trackForm = this.fb.group({
      ps: ['', [Validators.required, Validators.minLength(9)]],
      suburb: ['', [Validators.required, Validators.pattern('^[a-zA-Z_ ]*$'), Validators.minLength(3)]]
    });
  }

  // convenience getter for easy access to form fields
  get f() { return this.trackForm.controls; }

  onSubmit() {
    this.submitted = true;
    // stop here if form is invalid
    if (this.trackForm.invalid) {
      return;
    } else {
      // process if form is valid
      this.trackingService.getTracking(this.trackForm.value.ps, this.trackForm.value.suburb)
        .subscribe(data => {
            // @ts-ignore
            if (!data.status) {
              this.noRecord = false;
            } else {
              const param = { pickingSlip: this.trackForm.value.ps, suburb: this.trackForm.value.suburb };
              // const param = {'pickingSlip=:this.trackForm.value.ps&suburb=:this.trackForm.value.suburb'};
              // localStorage.setItem('ps', this.trackForm.value.ps);
              // localStorage.setItem('suburb', this.trackForm.value.suburb);
              this.router.navigate([{outlets: {}}], {queryParams: param});
            }
          },
          error => {
            this.noRecord = false;
            // console.log(error);
          } );
    }
  }
  // Clear button
  clearTrack() {
    this.trackForm.get('ps').setValue(null);
    this.trackForm.get('suburb').setValue(null);
    this.noRecord = true;
  }

}
