<div class="single-component-container">
  <div class="container g-pb-20 g-font-size-17">
    <div class="row">
      <section class="mt-4">
        <div [class]="(globals.divisionTag === 'architectural' || globals.divisionTag == 'commercial') ? 'row justify-content-center' : 'row justify-content-center g-mb-60'">
          <!-- Hume building products -->
          <div class="col-lg-12"  *ngIf="globals.divisionTag === 'home'">
             <!-- Heading -->
            <div class="text-center g-py-40">
              <h1 class="g-color-black g-font-weight-700 g-font-size-20 g-font-size-35--md text-uppercase g-line-height-1_2 g-letter-spacing-10 mb-4">{{ 'ABOUT.Title' | translate }}</h1>
              <div class="d-inline-block g-width-150 g-height-6 g-bg-primary my-4"></div>
            </div>
            <p class="font-weight-bold text-center mb-4">Architectural Solutions | Building Supplies | Tools & Accessories | Sydney & Melbourne</p>

            <!--<figure class="figure align-right" style="width: 305px">
               <img class="figure-img img-fluid" src="{{imgUrl + 'about/Hume-Logo-Slogan-20170907-1024x430.png'}}" alt="Hume Logo about">
            </figure>-->
            <p [innerHTML]="'ABOUT.P1' | translate"></p>
            <div class="row">
              <div class="col-12">
                <p [innerHTML]="'ABOUT.P1b' | translate"></p>
                <p [innerHTML]="'ABOUT.P2' | translate"></p>
                <!--<p [innerHTML]="'ABOUT.P3' | translate"></p>-->
              </div>
              <!--<div class="col-md-4">
                <a href="https://s3-ap-southeast-2.amazonaws.com/hume-website-documents/Hume_Overview_Brochure.pdf">
                  <figure style="width: 250px" class="align-right figure mb-2">
                    <img class="figure-img img-fluid" src="https://s3-ap-southeast-2.amazonaws.com/hume-website-photo/categories-web/brochure/hume_overview_brochure.jpg" alt="Hume Overview Brochure" >
                    <figcaption class="figure-caption pb-2 text-center">Hume Overview Brochure</figcaption>
                  </figure>
                </a>
              </div>-->
            </div>
            <div class="row">
              <figure class="figure col-md-4">
                <img class="figure-img img-fluid" [src]="imgUrl + 'about/Hume+Yennora+warehouse.jpg'" alt="Hume yennora warehouse">
                <figcaption class="figure-caption">100,000 sq metres of warehouse</figcaption>
              </figure>

              <figure class="figure col-md-4">
                <img class="figure-img img-fluid" [src]="imgUrl + 'about/Hume+truck+delivery.jpg'" alt="Hume truck delivery">
                <figcaption class="figure-caption">Fast delivery</figcaption>
              </figure>

              <figure class="figure col-md-4">
                <img class="figure-img img-fluid" [src]="imgUrl + 'about/Architectural+facade+solutions.jpg'" alt="Architectural solutions Hume">
                <figcaption class="figure-caption">Architectural solutions</figcaption>
              </figure>
            </div>
            <!-- End Heading -->
          </div>
          <!-- Hume building products -->

          <!-- Hume Architectural-->
          <div class="col-lg-12" *ngIf="globals.divisionTag === 'architectural'">
             <!-- Heading -->
             <div class="text-center g-py-40">
               <h1 class="g-color-black g-font-weight-700 g-font-size-20 g-font-size-35--md text-uppercase g-line-height-1_2 g-letter-spacing-10 mb-4">{{ 'ABOUT.ATitle' | translate }}</h1>
               <div class="d-inline-block g-width-150 g-height-6 g-bg-primary my-4"></div>
             </div>
             <figure class="figure align-right text-center" style="width: 305px">
               <a [href]="archBrochure[0]?.fileUrl">
                 <img class="u-shadow-v1-1" height="250" [src]="archBrochure[0]?.thumbnailUrl" [alt]="archBrochure[0]?.title">
                 <h5 class="my-2 mx-auto" style="width: 250px">{{archBrochure[0]?.title}}</h5>
               </a>
             </figure>
              <p [innerHTML]="'ABOUT.AP1' | translate"></p>
              <p [innerHTML]="'ABOUT.AP2' | translate"></p>
              <p [innerHTML]="'ABOUT.AP3' | translate"></p>
            <div class="row">
                      <figure class="figure col-md-4">
                        <img class="figure-img img-fluid" [src]="imgUrl + 'about/Westmead-660.jpg'" alt="Hume Aluminium">
                        <figcaption class="figure-caption"><a href="http://www.westmeadproject.health.nsw.gov.au">Westmead Redevelopment</a>, featuring Aluminium Cladding Series</figcaption>
                      </figure>
                      <figure class="figure col-md-4">
                        <img class="figure-img img-fluid" [src]="imgUrl + 'categories-web/terracotta_cladding/tc-grid1.jpg'" alt="Hume Architectural Terracotta cladding">
                        <figcaption class="figure-caption">Terracotta cladding</figcaption>
                      </figure>
                      <figure class="figure col-md-4">
                        <img class="figure-img img-fluid" [src]="imgUrl + 'categories-web/cfc_cladding/Cinema+Montivilliers.jpg'" alt="Hume Compressed fibre cement">
                        <figcaption class="figure-caption">Compressed fibre cement</figcaption>
                      </figure>
                    </div>
             <!-- End Heading -->
          </div>
          <!-- Hume Architectural-->

          <!-- Hume Commercial-->
          <div class="col-lg-12" *ngIf="globals.divisionTag == 'commercial'">
            <!-- Heading -->
            <div class="text-center g-py-40">
              <h1 class="g-color-black g-font-weight-700 g-font-size-20 g-font-size-35--md text-uppercase g-line-height-1_2 g-letter-spacing-10 mb-4">{{ 'ABOUT.COMTitle' | translate }}</h1>
              <div class="d-inline-block g-width-150 g-height-6 g-bg-primary my-4"></div>
            </div>
            <p [innerHTML]="'ABOUT.COM1' | translate"></p>
            <p>{{ 'ABOUT.COM2' | translate }}</p>
            <p [innerHTML]="'ABOUT.COM3' | translate"></p>
            <p [innerHTML]="'ABOUT.COM4' | translate"></p>
            <!-- End Heading -->
          </div>
          <!-- Hume Commercial-->
        </div>

        <div id="about-tab">
          <tabset>
            <tab>
              <ng-template tabHeading>
                <h4 class="pt-2 g-font-weight-600">{{'ABOUT.Product' | translate}}</h4>
              </ng-template>
              <p>{{'ABOUT.ProductP1' | translate}}</p>
              <ul>
                <li><a routerLink="/architectural">Architectural Facade Systems</a></li>
                <li><a routerLink="/cladding">Cladding</a></li>
                <li><a routerLink="/AAC">AAC Panels, Wall & Flooring Systems</a></li>
                <li><a routerLink="/render">Render, Texture & Paint Systems</a></li>

                <figure class="figure align-right" style="width: 305px">
                  <img class="figure-img img-fluid" src="{{imgUrl + 'about/our-product1.jpg'}}" alt="Hume product about">
                </figure>

                <li><a routerLink="/timber">Timber products</a></li>
                <li><a routerLink="/flooring">Flooring</a></li>
                <li><a routerLink="/fibre_cement">Fibre Cement</a></li>
                <li><a routerLink="/tiles">Tiles & Porcelain Panels</a></li>
                <li><a routerLink="/ceiling_tiles">Ceiling Tiles</a></li>
                <li><a routerLink="/doors_and_skylights">Doors & Skylights</a></li>
                <li><a routerLink="/kitchens">Kitchens</a></li>
                <li><a routerLink="/bathrooms">Bathrooms</a></li>
                <li><a routerLink="/plasterboard">Plasterboard & Plaster Products</a></li>
                <li><a routerLink="/metals">Metals wall and ceiling systems</a></li>
                <li><a routerLink="/insulation">Insulation</a></li>
                <li><a routerLink="/tools_and_accesories">Tools & Accessories</a></li>
              </ul>
              <p>{{'ABOUT.ProductP2' | translate}}<a href="tel:134863"><strong>13 4863</strong></a>.</p>
            </tab>

            <tab>
              <ng-template class="my-3" tabHeading>
                <h4 class="pt-2 g-font-weight-600">{{'ABOUT.History' | translate}}</h4>
              </ng-template>
              <p>{{'ABOUT.HistoryP1' | translate}}</p>
              <div class="row">
                <div class="col-md-8">
                  <h2 class="mb-2 g-color-gray-dark-v3 g-font-weight-500 py-3">{{'ABOUT.HistoryT1' | translate}}</h2>
                  <p>{{'ABOUT.HistoryP2' | translate}}</p>
                  <p [innerHTML]="'ABOUT.HistoryP3' | translate"></p>
                  <h2 class="mb-2 g-color-gray-dark-v3 g-font-weight-500 py-3">{{'ABOUT.HistoryT2' | translate}}</h2>
                  <p [innerHTML]="'ABOUT.HistoryP4' | translate"></p>
                  <p [innerHTML]="'ABOUT.HistoryP5' | translate"></p>
                  <p [innerHTML]="'ABOUT.HistoryP6' | translate"></p>
                  <h2 class="mb-2 g-color-gray-dark-v3 g-font-weight-500 py-3">{{'ABOUT.HistoryT3' | translate}}</h2>
                  <p>{{'ABOUT.HistoryP7' | translate}}</p>
                  <p [innerHTML]="'ABOUT.HistoryP8' | translate"></p>
                  <p [innerHTML]="'ABOUT.HistoryP9' | translate"></p>
                  <h2 class="mb-2 g-color-gray-dark-v3 g-font-weight-500 py-3">{{'ABOUT.HistoryT4' | translate}}</h2>
                  <p [innerHTML]="'ABOUT.HistoryP10' | translate"></p>
                  <p [innerHTML]="'ABOUT.HistoryP11' | translate"></p>
                  <p [innerHTML]="'ABOUT.HistoryP12' | translate"></p>
                  <p [innerHTML]="'ABOUT.HistoryP13' | translate"></p>
                </div>
                <div class="col-md-4">
                  <figure style="width: 305px" class="align-right figure mb-2">
                    <img class="figure-img img-fluid" [src]="imgUrl + 'about/about3.jpg'" alt="Hume Lansvale Warehouse" >
                    <figcaption class="figure-caption pb-2">Hume Lansvale - where it all began</figcaption>
                  </figure>
                  <figure style="width: 305px" class="align-right figure mb-2">
                    <img class="figure-img img-fluid pb-2" [src]="imgUrl + 'about/about4.jpg'" alt="Canterbury Bankstown Bulldogs" >
                    <figcaption class="figure-caption">Canterbury-Bankstown Bulldogs 2017</figcaption>
                  </figure>
                  <figure style="width: 305px" class="align-right figure mb-2">
                    <img class="figure-img img-fluid pb-2" [src]="imgUrl + 'about/preston_warehouse.jpg'" alt="VIC Preston Warehouse" >
                    <figcaption class="figure-caption">Hume Preston Warehouse in VIC</figcaption>
                  </figure>
                  <figure style="width: 305px" class="align-right figure mb-2">
                    <img class="figure-img img-fluid pb-2" [src]="imgUrl + 'about/Supply+and+installation.jpg'" alt="Hume Supply & installation" >
                    <figcaption class="figure-caption">Supply and installation</figcaption>
                  </figure>
                </div>
              </div>
            </tab>

            <tab>
              <ng-template tabHeading>
                <h4 class="pt-2 g-font-weight-600">{{'ABOUT.Partner' | translate}}</h4>
              </ng-template>
              <p>Hume is very proud to have built strong partnerships with world class and leading Australian brands in the supply of building products, tools and accessories. From our premier partnerships in plasterboard with brands like USG Boral, through Rondo metals and James Hardie, to a vast range of specialist manufacturers across our range of products, you can rely on Hume’s network to bring you the best.</p>
              <div class="py-4 text-center m-auto">
                <h2 class="my-4">From our partners:</h2>
              </div>
              <div class="pb-5 col-sm col-md-10 m-auto" *ngFor="let testimonial of testimonials">
                <!-- Testimonials -->
                <blockquote class="lead hume-blockquote-v1 g-color-black rounded g-pl-60 g-pr-30 g-py-30 g-mb-40">{{testimonial?.comment}}</blockquote>
                <div class="media">
                  <img class="d-flex align-self-center rounded-circle g-brd-around g-brd-3 g-brd-white g-width-70 mr-3"
                       src="{{imgUrl + testimonial?.photoUrl}}" alt="hume testimonial">
                  <div class="media-body align-self-center">
                    <h4 class="h6 g-font-weight-700 g-mb-0">{{testimonial?.name}}</h4>
                    <span class="g-color-gray-dark-v4 g-font-style-normal">{{testimonial?.company}}</span>
                  </div>
                </div>
                <!-- End Testimonials -->
              </div>
              <app-home-partners></app-home-partners>
            </tab>

            <tab>
              <ng-template tabHeading>
                <h4 class="pt-2 g-font-weight-600">{{'ABOUT.Brochures' | translate}}</h4>
              </ng-template>
              <div class="row mt-4">`
                <div class="col-lg-3 col-sm-6 g-mb-30" *ngFor="let item of brochures">
                  <!-- Team Block -->
                  <div class="u-info-v6-1">
                    <!-- Figure -->
                    <figure class="u-block-hover" style="max-height: 307px">
                      <!-- Figure Image -->
                      <img class="g-width-90x"  [src]="item?.thumbnailUrl" [alt]="item?.title">
                      <!-- End Figure Image-->

                      <!-- Figure Caption -->
                      <figcaption class="u-block-hover__additional--fade g-bg-bluegray-opacity-0_5 g-pa-30">
                        <div class="u-block-hover__additional--fade u-block-hover__additional--fade-down g-flex-middle">

                          <!-- Figure Social Icons -->
                          <ul class="list-inline text-center g-flex-middle-item">

                            <li class="list-inline-item align-middle g-mx-3">
                              <!--<a class="u-icon-v2 g-width-35 g-height-35 g-color-white g-color-black--hover g-brd-white g-bg-white--hover g-font-size-default rounded-circle" href="#">
                                <i class="fa fa-facebook"></i>
                              </a>-->
                              <a type="button" class="btn u-btn-primary g-font-size-13" [href]="item?.fileUrl" target="_blank"><i class="fa fa-download mr-3"></i>Download</a>
                            </li>
                          </ul>
                          <!-- End Figure Social Icons -->
                        </div>
                      </figcaption>
                      <!-- End Figure Caption -->
                    </figure>
                    <!-- End Figure -->

                    <!-- Figure Info -->
                    <div class="g-bg-white g-pt-25">
                      <div class="g-mb-15">
                        <em class="d-block mb-3 u-info-v6-1__item g-font-style-normal g-font-size-11 text-uppercase g-color-primary"></em>
                        <h2 class="h5 g-color-black g-font-weight-600">{{item?.title}}</h2>
                      </div>

                    </div>
                    <!-- End Figure Info-->
                  </div>
                  <!-- End Team Block -->
                </div>
              </div>
            </tab>
          </tabset>
        </div>


        <!-- Hume Architectural partners-->
        <div class="cbp-item js-partners" *ngIf="globals.divisionTag === 'architectural' || globals.divisionTag == 'commercial'">
          <app-home-partners></app-home-partners>
        </div>
        <!-- Hume Architectural-->

        <hr *ngIf="globals.divisionTag == 'commercial'">
        <div class="row" *ngIf="globals.divisionTag === 'home' || globals.divisionTag == 'commercial'">
          <figure class="figure col-md-4">
            <img class="figure-img img-fluid" [src]="imgUrl + 'about/HIA_logo.jpg'" alt="Hume Building Products">
          </figure>
          <figure class="figure col-md-4">
            <img class="figure-img img-fluid" [src]="imgUrl + 'about/ATFA_logo.jpg'" alt="Hume Building Products">
          </figure>
        </div>
      </section>

    </div>
  </div>
</div>
