<div class="single-component-container">
  <!-- Login -->
  <section class="container g-pt-50 g-pt-80--md g-pb-20">
    <div class="row justify-content-between">
      <div class="mb-3 mb-sm-5 col-12 text-center">
        <h2 class="h1 g-font-weight-400 mb-3">Welcome to Hume</h2>
      </div>
      <div class="col-md-6 mx-auto col-lg-5 order-lg-2 g-mb-80">
        <div class="g-brd-around g-brd-gray-light-v3 g-bg-white rounded g-px-15 g-px-30--sm g-py-30 g-py-50--sm mb-4">
          <header class="text-center mb-4">
            <h1 class="h4 g-color-black g-font-weight-400">Login to Your Account</h1>
          </header>

          <!-- Form -->
          <form class="g-py-15" [formGroup]="signInForm" (ngSubmit)="onSubmit()">
            <div class="mb-4">
              <div class="input-group g-rounded-left-3">
                    <span class="input-group-prepend g-width-45">
                      <span class="input-group-text justify-content-center w-100 g-bg-transparent g-brd-gray-light-v3 g-color-gray-dark-v5">
                        <i class="icon-finance-067 u-line-icon-pro"></i>
                      </span>
                    </span>
                  <input class="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-rounded-left-0 g-rounded-right-3 g-py-15 g-px-15" placeholder="Account Email" formControlName="email">
              </div>
              <div class="error" *ngIf="f.get('email').errors && submitted && ( f.get('email').dirty || f.get('email').touched || f.get('email').invalid)">
                <div *ngIf="f.get('email').hasError('required')">
                  Account id is required.
                </div>
              </div>
            </div>

            <div class="mb-4">
              <div class="input-group g-rounded-left-3">
                    <span class="input-group-prepend g-width-45">
                      <span class="input-group-text justify-content-center w-100 g-bg-transparent g-brd-gray-light-v3 g-color-gray-dark-v5">
                        <i class="icon-media-094 u-line-icon-pro"></i>
                      </span>
                    </span>
                <input class="form-control g-color-black g-bg-white g-bg-white--focus g-brd-gray-light-v3 g-rounded-left-0 g-rounded-right-3 g-py-15 g-px-15"
                       type="password" [type]="showPassword ? 'text' : 'password'" placeholder="Password" formControlName="password">
                <span class="input-group-append g-width-45 g-brd-gray-light-v3 g-color-gray-dark-v5">
                      <span class="input-group-text justify-content-center w-100 g-bg-transparent g-brd-gray-light-v3 g-color-gray-dark-v5">
                        <i class="fa fa-eye-slash" *ngIf="showPassword" (click)="toggleShowPassword()"></i>
                        <i class="fa fa-eye" *ngIf="!showPassword" (click)="toggleShowPassword()"></i>
                      </span>
                    </span>
              </div>
              <div class="error" *ngIf="f.get('password').errors && submitted && ( f.get('password').dirty || f.get('password').touched || f.get('password').invalid)">
                <div *ngIf="f.get('password').hasError('required')">
                  Password is required.
                </div>
              </div>

            </div>

            <div class="row justify-content-between mb-5">
              <div class="col align-self-center">
                <label class="form-check-inline u-check g-color-gray-dark-v5 g-font-size-13 g-pl-25 mb-0">
                  <!--<input class="g-hidden-xs-up g-pos-abs g-top-0 g-left-0" type="checkbox">
                  <span class="d-block u-check-icon-checkbox-v6 g-absolute-centered--y g-left-0">
                        <i class="fa" data-check-icon="&#xf00c;"></i>
                      </span>
                  Keep signed in-->
                </label>
              </div>
              <div class="col align-self-center text-right">
                <a class="g-font-size-13" routerLink="/auth/password-recovery">Forgot password?</a>
              </div>
            </div>

            <div class="mb-3 mb-sm-5 mt-5">
              <button class="btn btn-block u-btn-primary g-font-size-14 font-weight-bold text-uppercase g-py-15 g-px-25 shadow-none" type="submit">Login</button>
            </div>
          </form>
          <!-- End Form -->
        </div>

        <div class="text-center px-4 mt-4">
          <p class="g-color-gray-dark-v5 mb-0">Don't have an account?</p>
          <button class="btn btn-block u-btn-darkgray g-font-size-14 font-weight-bold text-uppercase g-py-15 g-px-25 shadow-none mt-2" routerLink="/auth/sign-up">Sign up</button>
        </div>
      </div>
    </div>
  </section>
  <!-- End Login -->
</div>

