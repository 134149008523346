import {Injectable} from '@angular/core';
import {Globals} from '../global';
import {ajax} from 'rxjs/ajax';
import {HttpClient, HttpErrorResponse} from '@angular/common/http';
import {throwError} from 'rxjs';

@Injectable()

export class InventoryCategoriesService {

  constructor(
    public globals: Globals,
    private http: HttpClient,
  ) {}

  /*getCategoriesWeb() {
    return ajax(this.globals.humeApiRef + 'category/category.web');
  }*/

  /*getCategoriesWebByDivision(divisionTag) {
    // ** New Api divisionTag will be integer.
    // ** 0 - all, 1 - commercial, 2 - architectural.
    return ajax(this.globals.humeApiRef + 'category/category.web/site/' + divisionTag);
  }

  getCategoryWebById(id) {
    return ajax(this.globals.humeApiRef + 'category/category.web/get/' + id);
  }

  getCategories() {
    return ajax(this.globals.humeApiRef + 'category/category');
  }

  getCategoriesById(id) {
    return ajax(this.globals.humeApiRef + 'category/category/get/' + id);
  }*/

  /*** Testing Open PDF ***/
  downloadPDF(url): any {
    const headers = new Headers({'Access-Control-Allow-Origin': '*'});

    const requestOptions = { method: 'GET',
      headers: headers};

    fetch(url, requestOptions)
      .then(function(response) {
        return response.blob();
      })
      .then(function(myBlob) {
        // console.log(URL.createObjectURL(myBlob));
      });
  }

  // ** re-arrange the categories to 5 in each col.
  arrangeArray (data) {
    let array = [];
    const array2 = [];
    let a = 0;
    const y = data?.length / 6;
    for ( let x = 0; x < y; x++) {
      if (x !== 0) { a += 6; }
      for (let i = a; i < (a + 6); i ++) {
        if (data[i]) { array.push(data[i]); }
        if (i === a + 5) {
          array2.push(array);
          array = []; }
      }
    }
    return array2;
  }

  handleError(error: HttpErrorResponse) {
    if (error.error instanceof ErrorEvent) {
      // A client-side or network error occurred. Handle it accordingly.
      console.error('An error occurred:', error.error.message);
    } else {
      // The backend returned an unsuccessful response code.
      // The response body may contain clues as to what went wrong,
      console.error(
        `Backend returned code ${error.status}, ` +
        `body was: ${error.error}`);
    }
    // return an observable with a user-facing error message
    return throwError(
      'Something bad happened; please try again later.');
  }
  /*** Testing Open PDF ***/
}
