import { CommonModule } from '@angular/common';
import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {CategoriesGridComponent} from './categories-grid/categories-grid.component';
import {RouterModule, Routes} from '@angular/router';
import {SharedCarouselComponent} from './shared-carousel/shared-carousel.component';
import {SubscriptionFormComponent} from './subscription-form/subscription-form.component';
import {FormsModule, ReactiveFormsModule} from '@angular/forms';
import {NgxCaptchaModule} from 'ngx-captcha';
import {MDBBootstrapModule, MDBModalRef} from 'angular-bootstrap-md';
import {PrivacyComponent} from './privacy/privacy.component';
import {PipesModule} from '../pipe/pipes.module';
import {SharedOrderFormComponent} from './shared-order/shared-order-form.component';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import { BsDatepickerModule } from 'ngx-bootstrap/datepicker';
import {SocialShareComponent} from './social-share/social-share.component';
import { ProjectsGridComponent } from './projects-grid/projects-grid.component';
import { ShoppingCartModalComponent } from './ecommerce/shopping-cart-modal/shopping-cart-modal.component';
import { GooglePlacesComponent } from './ecommerce/google-places/google-places.component';
import { AlertsComponent } from './alerts/alerts.component';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import { CartContentComponent } from './ecommerce/cart-content/cart-content.component';
import { CallToActionComponent } from './call-to-action/call-to-action.component';
import { AddToCartButtonComponent } from './ecommerce/add-to-cart-button/add-to-cart-button.component';
import { AttachmentsComponent } from './attachments/attachments.component';
import {AmplifyIonicModule} from 'aws-amplify-angular';
import {CarouselModule} from 'ngx-bootstrap/carousel';
import {TosComponent} from './tos/terms.component';
import { BreadCrumbComponent } from './bread-crumb/bread-crumb.component';
import {MatMenuModule} from '@angular/material/menu';
import {QuoteFormComponent} from './quote-form/quote-form.component';
import {ValidationComponent} from './ecommerce/validation/validation.component';
import { NotFoundComponentComponent } from './not-found-component/not-found-component.component';
import { MakePaymentComponent } from './make-payment/make-payment.component';

export const ROUTES: Routes = [
  {path: 'subscribe', component: SubscriptionFormComponent},
  {path: 'privacy-policy', component: PrivacyComponent},
  {path: 'tos', component: TosComponent},
  {path: 'order', component: SharedOrderFormComponent,
    /*children: [
      {path: 'step1', component: Step1Component},
      {path: 'step2', component: Step2Component},
      {path: '**', redirectTo: 'step1'}
    ]*/
  }
];

@NgModule({
    imports: [
        CommonModule,
        ReactiveFormsModule,
        NgxCaptchaModule,
        PipesModule,
        NgbModule,
        FormsModule,
        MDBBootstrapModule,
        BsDatepickerModule.forRoot(),
        RouterModule.forChild(ROUTES),
        NgxSkeletonLoaderModule,
        AmplifyIonicModule,
        CarouselModule,
        MatMenuModule
    ],
  declarations: [
    CategoriesGridComponent,
    SharedCarouselComponent,
    SubscriptionFormComponent,
    SharedOrderFormComponent,
    SocialShareComponent,
    PrivacyComponent,
    ProjectsGridComponent,
    ShoppingCartModalComponent,
    GooglePlacesComponent,
    AlertsComponent,
    CartContentComponent,
    CallToActionComponent,
    AddToCartButtonComponent,
    AttachmentsComponent,
    TosComponent,
    BreadCrumbComponent,
    QuoteFormComponent,
    ValidationComponent,
    NotFoundComponentComponent,
    MakePaymentComponent
  ],
  exports: [
    CategoriesGridComponent,
    SharedCarouselComponent,
    SubscriptionFormComponent,
    SharedOrderFormComponent,
    PrivacyComponent,
    SocialShareComponent,
    CommonModule,
    ShoppingCartModalComponent,
    GooglePlacesComponent,
    AlertsComponent,
    CartContentComponent,
    CallToActionComponent,
    AddToCartButtonComponent,
    AttachmentsComponent,
    TosComponent,
    BreadCrumbComponent,
    QuoteFormComponent,
    ValidationComponent,
    NotFoundComponentComponent,
    MakePaymentComponent
  ],
  providers: [MDBModalRef],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class SharedModule { }
