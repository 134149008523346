import {Component, Input, OnDestroy, OnInit} from '@angular/core';
import {Subject, Subscription} from 'rxjs';
import {debounceTime, distinctUntilChanged} from 'rxjs/operators';
import {ShoppingCartService} from '../../../services/shopping-cart.service';
import {Globals} from '../../../global';
import {AlertService} from '../../../services/alert.service';
import {HttpClient} from '@angular/common/http';
import {DataService} from '../../../services/data.service';

@Component({
  selector: 'app-cart-content',
  templateUrl: './cart-content.component.html',
  styleUrls: ['./cart-content.component.scss']
})
export class CartContentComponent implements OnInit, OnDestroy {
  @Input() page;
  quantityChangedSub: Subscription;
  quantityChanged: Subject<number> = new Subject<number>();
  updateItem;
  alertInfo = [];
  // cart;

  constructor(public globals: Globals, private alertService: AlertService, private http: HttpClient, private dataService: DataService,
              private shoppingCartService: ShoppingCartService) {
    this.quantityChangedSub = this.quantityChanged.pipe(
      debounceTime(500))
      .subscribe(res => {
        this.shoppingCartService.editELineOnShoppingCart(this.updateItem, 'update', res);
        document.getElementById('quantityInput' + this.updateItem.id + '_' + this.page).classList.remove('disabled');
      });
  }

  ngOnInit(): void {
  }

  addQuantity(item, index) {
    let quantity;
    this.shoppingCartService.getProductPack(item?.productId).toPromise().then(res => {
      // @ts-ignore
      if (res?.packs?.length > 0) {
        // @ts-ignore
        this.alertInfo[index] = {mini: res?.packs?.filter(data => data.type === 'MIN')[0]?.quantity, bun: res?.packs?.filter(data =>
            data.type === 'BUN')[0]?.quantity};
        // @ts-ignore
        if (item?.quantity < res?.packs?.filter(data => data.type === 'MIN')[0]?.quantity) {
          // @ts-ignore
          quantity = res?.packs?.filter(data => data.type === 'MIN')[0]?.quantity - item?.quantity;
        } else {
          // @ts-ignore
          quantity = res?.packs?.filter(data => data.type === 'BUN')[0]?.quantity;
        }
      }
      quantity = quantity ? quantity : 1;
      this.shoppingCartService.editELineOnShoppingCart(item, 'plus', quantity);
    });
  }

  minusQuantity(item, index) {
    let quantity;
    this.shoppingCartService.getProductPack(item?.productId).toPromise().then(res => {
      // @ts-ignore
      if (res?.packs?.length > 0) {
        // @ts-ignore
        this.alertInfo[index] = {mini: res?.packs?.filter(data => data.type === 'MIN')[0]?.quantity, bun: res?.packs?.filter(data =>
            data.type === 'BUN')[0]?.quantity};
        // @ts-ignore
        if (item?.quantity <= res?.packs?.filter(data => data.type === 'MIN')[0]?.quantity) {
          this.delItem(item);
        } else {
          // @ts-ignore
          quantity = res?.packs?.filter(data => data.type === 'BUN')[0]?.quantity;
          this.shoppingCartService.editELineOnShoppingCart(item, 'minus', quantity);
        }
      } else {
        this.shoppingCartService.editELineOnShoppingCart(item, 'minus', 1);
      }
    });
  }

  delItem(item) {
    this.alertService.confirm().then(res => {
      if (res) {
        this.shoppingCartService.removeELineOnShoppingCart(item);
      }
    });
  }

  emptyCart() {
    this.alertService.confirm('all').then(async res1 => {
      if (res1) {
        let result;
        this.alertService.loading('Deleting items in cart');
        for await (const item of this.globals.cart.lines) {
          const object = {
            // @ts-ignore
            cartUUID: this.globals.cart.uuid,
            productId: item.productId,
            quantity: item.quantity,
            lineId: item.id
          };
          const myJSON = JSON.stringify(object);
          result = await this.http.post(this.globals.humeEcomApiRef + 'product/cart/line/remove', myJSON,
            this.shoppingCartService.httpOptions)
            .toPromise().then(res => {
              return res;
            });
        }
        this.globals.cart = result;
        this.dataService.changeCart('remove');
        this.alertService.alertClose();
      }
    });
  }

  updateQuantity(event, item, index) {
    document.getElementById('quantityInput' + item.id + '_' + this.page).classList.add('disabled');
    this.updateItem = item;
    this.shoppingCartService.getProductPack(item?.productId).toPromise().then(res => {
      // @ts-ignore
      const bunQ = res?.packs?.filter(data => data.type === 'BUN')[0]?.quantity;
      // @ts-ignore
      const minQ = res?.packs?.filter(data => data.type === 'MIN')[0]?.quantity;
      // @ts-ignore
      if (res?.packs?.length > 0) {
        this.alertInfo[index] = { mini: minQ, bun: bunQ };
        if (event <= minQ) {
          event = minQ;
        } else {
          const temp = Math.ceil((event - minQ) / bunQ);
          event = minQ + bunQ * temp;
        }
      }
      this.quantityChanged.next(event);
    });
  }

  ngOnDestroy () {
    if (this.quantityChangedSub) {this.quantityChangedSub.unsubscribe(); }
  }

}
