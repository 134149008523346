<div class="single-component-container">

  <section class="container g-pt-60--sm g-pt-60 g-pb-50">
    <div class="row mb-2">
      <div class="col-md-8">
        <h2 class="text-capitalize mb-3">Hume Order Form</h2>
        <div class="d-inline-block g-width-60 g-height-1 g-bg-primary mb-2"></div>
        <h3>Download order form or contact us:</h3>
        <p>Customers can do a personal collection of their building supplies, or request delivery of building products from any of our conveniently located warehouse stores across Sydney and Melbourne.</p>
        <p>To make an order:</p>
        <ul>
          <li>Visit a Hume store <a routerLink="/contact">near you</a>;</li>
          <li><a [href]="docUrl + 'Hume-Order-Form.pdf'">Download</a> an order form and <a routerLink="/contact">contact us</a> online; or</li>
          <li>Call us now on <a href="tel:134863">13 4863</a></li>
        </ul>
      </div>
      <div class="col-md-4">
        <figure ><a [href]="docUrl + 'Hume-Order-Form.pdf'">
          <img [src]=" imgUrl + 'Hume-Order-Form-201808-724x1024.jpg'"
               alt="Hume Plasterboard Order Form" width="341" height="483"></a>
          <figcaption class="wp-caption-text">Download Hume Order Form Here</figcaption>
        </figure>
      </div>
    </div>
  </section>
</div>
