import {Component, OnDestroy, OnInit} from '@angular/core';
import {Subscription} from 'rxjs';
import {ActivatedRoute, Router} from '@angular/router';
import {Globals} from '../../global';
import {DomSanitizer} from '@angular/platform-browser';
import {Projectervice} from '../../services/project.service';

@Component({
  selector: 'app-single-project',
  templateUrl: './single-project.component.html'
})
export class SingleProjectComponent implements OnInit, OnDestroy {
  routeSub: Subscription;
  singleProjectSubscription: Subscription;
  projectRes;
  pageContent;

  constructor(private route: ActivatedRoute,
              public globals: Globals,
              private router: Router,
              private sanitizer: DomSanitizer,
              private projectService: Projectervice) {
  }

  ngOnInit () {
    this.routeSub = this.route.queryParams.subscribe(
      data => {
        if (data.name) {
          this.singleProjectSubscription = this.projectService.getSingleProject(data.id).subscribe(res => {
            this.projectRes = res.response;
            this.pageContent = this.sanitizer.bypassSecurityTrustHtml(this.projectRes.content);
            });
          }
        });
      }
  ngOnDestroy() {
    if (this.routeSub) {this.routeSub.unsubscribe(); }
    if (this.singleProjectSubscription) {this.singleProjectSubscription.unsubscribe(); }
  }
}
