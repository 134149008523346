import { Injectable } from '@angular/core';
import { BehaviorSubject } from 'rxjs';

@Injectable()
export class DataService {

  private openShoppingCart = new BehaviorSubject('');
  changeCartStatus = this.openShoppingCart.asObservable();

  private passingValue = new BehaviorSubject('');
  changeValueStatus = this.passingValue.asObservable();

  constructor(
    // private clipboard: Clipboard
  ) { }

  changeCart(data: string) {
    this.openShoppingCart.next(data);
  }

  changeValue(data: string) {
    this.passingValue.next(data);
  }
}
