
  <div class="g-pt-50--md container">
    <div class="text-center mx-auto g-max-width-600">
      <a routerLink="/news">
        <h1 class="text-uppercase mb-4 g-color-gray-dark-v3">Our News</h1>
      </a>
      <div class="d-inline-block g-width-60 g-height-6 g-bg-primary mb-2"></div>
    </div>

    <section class="my-5 p-1 container">
      <div class="row">
        <div class="col-md-12">
          <div class="card-group news-grid">
            <div class="card card-personal mb-md-0 mb-4 g-mx-10" *ngFor="let item of news ; let i=index;" style="box-shadow: unset; border: unset;">
              <div  *ngIf="i<3">
              <div class="view overlay">
                <img class="card-img-top mx-auto" src="{{item.thumbnailUrl }}" [alt]="item?.title" style="max-height: 300px; width: auto">
                <!--<a  [routerLink]="['/news/single-news']" [queryParams]="{ title: item?.title, id: item?.newsId}">
                  <div class="mask rgba-white-slight waves-effect waves-light"></div>
                </a>-->
                <a [routerLink]="['/news/' + item?.title | urlTransfer]">
                  <div class="mask rgba-white-slight waves-effect waves-light"></div>
                </a>
              </div>
              <div class="card-body">
                <div style="min-height: 68px;">
                  <a class="g-text-underline--none--hover" [routerLink]="['/news/' + item?.title | urlTransfer]">
                    <h3 class="card-title g-color-black g-color-primary--hover">{{item?.title}}</h3>
                  </a>
                </div>
                <p class="card-meta">{{item?.date}}</p>
                <p>{{item?.summary}}</p>
                <!--<a class="card-meta" [routerLink]="['/news/single-news']" [queryParams]="{ title: item?.title, id: item?.newsId}"><span>Read More...</span></a>-->
              </div>
            </div>
            </div>
          </div>
        </div>
      </div>
    </section>
  </div>
  <hr class="g-my-20">
