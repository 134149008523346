import {CUSTOM_ELEMENTS_SCHEMA, NgModule} from '@angular/core';
import {HomeComponent} from './containers/home.component';
import {HomeNewsComponent} from './home-news/home-news.component';
import {HomeTestimonialsComponent} from './home-testimonials/home-testimonials.component';
import {RouterModule, Routes} from '@angular/router';
import {HomeFeaturedProductsComponent} from './home-featured-products/home-featured-products.component';
import {HomePartnersComponent} from './home-partners/home-partners.component';
import {CommonModule} from '@angular/common';
import {NgbModule} from '@ng-bootstrap/ng-bootstrap';
import {HomeProjectsComponent} from './home-projects/home-projects.component';
import {SharedModule} from '../shared/shared.module';
import {PipesModule} from '../pipe/pipes.module';
import {SlickCarouselModule} from 'ngx-slick-carousel';
import {NgxSkeletonLoaderModule} from 'ngx-skeleton-loader';
import {ModalModule} from 'angular-bootstrap-md';

export const ROUTES: Routes = [
  { path: '', component: HomeComponent,
    data: {
      title: 'Hume Building Products',
      description: 'Hume Building Products is a leading Australian supplier of building products, tools and accessories, ' +
        'with warehouse stores located across Sydney and Melbourne, and a fast delivery network ' +
        'servicing metropolitan and regional areas.',
      ogUrl: 'https://www.humebuildingproducts.com.au',
      imgUrl: 'https://s3-ap-southeast-2.amazonaws.com/hume-website-photo/about/hbp-logo-t.png'
    }
  },
  { path: 'architectural', component: HomeComponent,
    data: {
      title: 'Hume Architectural',
      description: 'Hume Architectural supplies architects, builders and developers with an exclusive range of ' +
        'leading brand facade products and solutions from around the world. Our team of dedicated professionals ' +
        'can support your project from initial design through to specification, procurement and installation.',
      ogUrl: 'https://www.humebuildingproducts.com.au/architectural',
      imgUrl: 'https://s3-ap-southeast-2.amazonaws.com/hume-website-photo/about/Hume Architectural Logo.png'
    }
  },
  { path: 'commercial', component: HomeComponent,
    data: {
      title: 'Hume Commercial',
      description: 'Hume Commercial supplies builders, architects and developers with a wide range of building product ' +
        'solutions for residential, commercial, industrial or repair and remodelling projects.',
      ogUrl: 'https://www.humebuildingproducts.com.au/commercial',
      imgUrl: 'https://s3-ap-southeast-2.amazonaws.com/hume-website-photo/about/Hume Commercial Logo.png'
    }
  }
  // { path: '**', component: HomeComponent }
];

@NgModule({
    imports: [
        CommonModule,
        NgbModule,
        SharedModule,
        PipesModule,
        RouterModule.forChild(ROUTES),
        SlickCarouselModule,
        NgxSkeletonLoaderModule,
        ModalModule
    ],
  declarations: [
    HomeComponent,
    HomeNewsComponent,
    HomeTestimonialsComponent,
    HomePartnersComponent,
    HomeProjectsComponent,
    HomeFeaturedProductsComponent
  ],
  exports: [
    HomeComponent,
    HomeNewsComponent,
    HomeTestimonialsComponent,
    HomePartnersComponent,
    HomeFeaturedProductsComponent
  ],
  providers: [],
  schemas: [ CUSTOM_ELEMENTS_SCHEMA ]
})
export class HomeModule { }
