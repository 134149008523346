import {Injectable} from '@angular/core';
import {Router} from '@angular/router';
import {AuthService} from './auth/auth.service';
import {Globals} from '../global';

@Injectable()

export class HandleErrorService {
  currentUser;

  constructor(private router: Router, private authService: AuthService, public globals: Globals) {
    this.currentUser = this.authService.currentUserValue;
  }

  subscribeErrorUnAuth(error, url?) {
    /*console.log(error);
    if (error.status === 401) {
      if (localStorage?.getItem('currentUser')) {
        if (JSON.parse(localStorage?.getItem('currentUser'))?.username?.includes('info@')) {
          this.authService.getCashSaleToken().toPromise().then(res => {
            this.globals.token = res;
          });
        } else {
          localStorage?.removeItem('currentUser');
          this.authService.logoutUser();
        }
      } else {
        console.log('~~~~~~~~~~~~~No current user~~~~~~~~~~');
        this.authService.getCashSaleToken().toPromise().then(res => {
          this.globals.token = res;
        });
      }
    }*/
  }

}
