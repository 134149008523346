<div>

  <div class="py-0 px-0 pt-sm-4 px-sm-3 container product-page-header">

  <div class="row">
    <!-- Categories menu -->
    <div class="col-3 g-pl-15--lg g-pl-0 mb-3" *ngIf="!globals.isMobile else mobile">
      <div class="btn-group dropdown" id="category-menu">
        <button type="button" class="dropdown-toggle btn waves-light u-btn-primary hume-font-family g-font-size-17 font-weight-bold shadow-none"
                 [matMenuTriggerFor]="categoriesMat"><i class="fas fa-bars mr-2 g-font-size-17" aria-hidden="true"></i>Categories</button>
        <mat-menu #categoriesMat="matMenu">
          <!--<app-products-side-bar [eCategories]="eCategories" (messageEvent)='receiveMessage($event)'></app-products-side-bar>-->
          <a mat-menu-item class="font-weight-bold g-bg-primary--hover g-color-white" (click)="getSpecials()" routerLink="/products/hume_specials">Hume Specials</a>
          <a mat-menu-item class="font-weight-bold g-bg-primary--hover g-color-white" (click)="getClearance()" routerLink="/products/hume_eofy" *ngIf="isEOFYAvailable">EOFY Sales</a>
          <div *ngFor="let category of eCategories, let i = index">
            <a class="font-weight-bold g-bg-primary--hover g-color-white" [routerLink]="['/' + category?.link | urlTransfer]"
               mat-menu-item [matMenuTriggerFor]="subMenu">{{category?.name}}</a>

            <!-- Submenu (level 2) -->
            <!--<mat-menu #subMenu="matMenu">
              <a mat-menu-item class="font-weight-bold g-bg-primary--hover g-color-white" *ngFor="let item of subCategories"
                 (click)="openSubCategory(item)" style="max-width: 450px !important;">{{item?.name ? item?.name : item?.description}}</a>
            </mat-menu>-->
            <mat-menu #subMenu="matMenu">
              <div class="row g-max-width-550 mx-0 sub-cat-menu">
                <a mat-menu-item class="sub-cat-menu-item g-bg-primary--hover col-6" [ngClass]="category?.subCategories?.length <= 4 ? 'col-12' : 'col-6'" *ngFor="let item of category?.subCategories" (click)="breadCrumbMessage({type: 'sub'})"
                   [routerLink]="'/products/' + category.name.split(' ').join('_') + '/' + (item?.name ? (item?.name?.split(' ').join('_')) : (item?.description?.split(' ').join('_')))">
                  <span class="font-weight-bold g-color-white">
                    {{item?.name ? item?.name : item?.description}}</span>
                </a>
              </div>

            </mat-menu>
            <!-- End Submenu (level 2) -->
          </div>

        </mat-menu>
      </div>
    </div>

    <!-- Mobile version categories menu -->
    <ng-template #mobile>
      <div class="col-12 g-pl-15--lg g-pl-0 mb-3">
        <mat-accordion class="pl-3 row">
          <div class="col-6">
            <button class="btn my-3 u-btn-primary g-font-size-14 font-weight-bold text-uppercase g-py-12 g-px-25 shadow-none" (click)="openCatMenu()" type="submit">Categories</button>
          </div>
          <div class="col-6 pt-4">
            <h2 class="h6 align-middle d-inline-block g-font-weight-400 text-uppercase g-pos-rel g-top-1 mb-0">Sort:</h2>

            <div class="ml-2 d-inline-block btn-group g-line-height-1_2">
                <span class="btn dropdown-toggle shadow-none h6 align-middle g-brd-none g-color-primary g-font-weight-500 g-color-black--hover g-bg-transparent text-uppercase g-font-size-14 g-pa-0 g-pl-10 g-ma-0">
                  price
                </span>
              <div class="dropdown-content py-2 g-font-size-14 rounded-0">
                <a class="dropdown-item g-color-primary g-font-weight-500" (click)="priceChange('low')">Price low to high</a>
                <a class="dropdown-item g-color-primary g-font-weight-500" (click)="priceChange('high')">price high to low</a>
              </div>
            </div>
          </div>

          <mat-expansion-panel [expanded]="isExpanded" hideToggle style="overflow: auto; max-height: 65vh;">

            <p-accordion id="mobile-category-menu" style="background-color: #2a2e2f">
              <p-accordionTab>
                <ng-template pTemplate="header">
                  <a class="g-bg-primary--hover h6 font-weight-bold g-color-white" routerLink="/products/hume_specials" (click)="isExpanded = false" >Hume Specials</a>
                </ng-template>
              </p-accordionTab>
              <p-accordionTab *ngFor="let category of eCategories">
                <ng-template pTemplate="header">
                  <h6 class="font-weight-bold g-color-white">
                    {{category?.name}}
                  </h6>
                </ng-template>
                <ng-template pTemplate="content">
                  <a mat-menu-item class="font-weight-bold g-bg-primary--hover g-color-white" *ngFor="let item of category?.subCategories" style="background-color: #2a2e2f">
                    <p class="g-color-white g-pl-30" (click)="isExpanded = false"
                       [routerLink]="'/products/' + category.name.split(' ').join('_') + '/' + (item?.name ? (item?.name?.split(' ').join('_')) : (item?.description?.split(' ').join('_')))">
                      {{item?.name ? item?.name : item?.description}}</p>
                  </a>
                </ng-template>
              </p-accordionTab>
            </p-accordion>

            <!--<accordion id="category-menu-accordion" [closeOthers]="true" [isAnimated]="true" style="overflow: auto; max-height: 65vh;">
              <a mat-menu-item class="g-bg-primary--hover font-weight-bold g-color-white" routerLink="/products/hume_specials" (click)="isExpanded = false" >Hume Specials</a>
              <accordion-group panelClass="category-menu-item font-weight-bold g-color-white" [heading]="category?.name"
                               *ngFor="let category of eCategories" (isOpenChange)="openCategoryMenuMobile(category)">

                <a mat-menu-item class="font-weight-bold g-bg-primary--hover g-color-white" *ngFor="let item of subCategories" style="background-color: #2a2e2f">
                  <p class="g-color-white" (click)="isExpanded = false"
                     [routerLink]="'/products/' + category.name.split(' ').join('_') + '/' + (item?.name ? (item?.name?.split(' ').join('_')) : (item?.description?.split(' ').join('_')))">
                    {{item?.name ? item?.name : item?.description}}</p>
                </a>

              </accordion-group>
            </accordion>-->

          </mat-expansion-panel>
        </mat-accordion>
      </div>

    </ng-template>
    <!-- Mobile version categories menu -->

    <!--End Categories menu -->

    <!-- Search function-->
    <div class="col-9 pt-3" *ngIf="!globals.isMobile">
      <div class="row">

        <div class="col-4 col-xl-4 g-font-size-17 text-center">

        </div>

        <div class="col-8 col-xl-8 float-right">
          <ul class="list-inline mb-0">
            <li class="list-inline-item mr-4">
              <!-- Show filter-->
              <h2 class="h6 align-middle pt-2 d-inline-block g-font-weight-400 text-uppercase g-pos-rel g-top-1 mb-0">Show:</h2>

              <div class="ml-2 d-inline-block pt-2 btn-group g-line-height-1_2" >
                <button type="button" class="btn shadow-none dropdown-toggle h6 align-middle g-brd-none g-color-primary g-font-weight-500 g-font-size-14
          g-color-black--hover g-bg-transparent text-uppercase g-pa-0 g-pl-10 g-ma-0" data-toggle="dropdown" aria-haspopup="true" aria-expanded="false">
                  {{productsNumber?.rows}}
                </button>
                <div class="dropdown-content py-2 g-font-size-14 rounded-0">
                  <a class="dropdown-item g-color-primary g-font-weight-500" (click)="onPageChange({rows: 20})">20</a>
                  <a class="dropdown-item g-color-primary g-font-weight-500" (click)="onPageChange({rows: 50})">50</a>
                  <a class="dropdown-item g-color-primary g-font-weight-500" (click)="onPageChange({rows: 100})">100</a>
                </div>
              </div>
              <!-- End Show Filter-->
            </li>
            <li class="list-inline-item mr-4">
              <h2 class="h6 align-middle pt-2 d-inline-block g-font-weight-400 text-uppercase g-pos-rel g-top-1 mb-0">Sort:</h2>

              <div class="ml-2 d-inline-block pt-2 btn-group g-line-height-1_2">
                <span class="btn dropdown-toggle shadow-none h6 align-middle g-brd-none g-color-primary g-font-weight-500 g-color-black--hover g-bg-transparent text-uppercase g-font-size-14 g-pa-0 g-pl-10 g-ma-0">
                  price
                </span>
                <div class="dropdown-content py-2 g-font-size-14 rounded-0">
                  <a class="dropdown-item g-color-primary g-font-weight-500" (click)="priceChange('low')">Price low to high</a>
                  <a class="dropdown-item g-color-primary g-font-weight-500" (click)="priceChange('high')">price high to low</a>
                </div>
              </div>
            </li>
            <li class="list-inline-item g-hidden-xs-down">
              <a class="u-icon-v2 u-icon-size--xs" [ngClass]="isGridView ? 'g-brd-gray-light-v3 g-brd-black--hover g-color-gray-dark-v5 g-color-black--hover' : 'g-brd-primary g-color-primary'" (click)="changeListView('list')">
                <i class="icon-list"></i>
              </a>
            </li>
            <li class="list-inline-item g-hidden-xs-down">
              <a class="u-icon-v2 u-icon-size--xs" [ngClass]="!isGridView ? 'g-brd-gray-light-v3 g-brd-black--hover g-color-gray-dark-v5 g-color-black--hover' : 'g-brd-primary g-color-primary'" (click)="changeListView('grid')">
                <i class="icon-grid"></i>
              </a>
            </li>
          </ul>
        </div>

      </div>
    </div>
    <!-- End search function-->
  </div>

</div>

  <!-- Bread crumb section -->
  <app-bread-crumb [catWebName]="catWebName" [subCategories]="selectedCategory?.subCategories" [subCatName]="subCatName" (messageEvent)="breadCrumbMessage($event)" [page]="'list'"></app-bread-crumb>
  <!-- End bread crumb section -->

  <!-- Featured products section -->
  <app-home-featured-products [page]="'products'" [products]="featuredProducts"></app-home-featured-products>
  <!-- End featured products section -->

  <!-- Mobile version filter function -->
  <div *ngIf="globals.isMobile">
    <p-accordion id="mobile-filter-header">
      <p-accordionTab>
        <ng-template  pTemplate="header">
          <span class="mx-auto g-font-size-18"><i class="fa fa-filter mr-2"></i>Filter</span>
          <button *ngIf="selectedFilter?.length > 0" class="btn ml-2 u-btn-primary g-font-size-14 text-uppercase btn-sm shadow-none font-weight-bold" (click)="clearFilters()">Clear</button>
        </ng-template>
        <p-accordion  id="mobile-filter-content" [multiple]="true">
          <p-accordionTab [selected]=" i === 0" [header]="item?.label" *ngFor="let item of groupFilters; let i = index">
            <ul class="list-unstyled">
              <li class="p-field-checkbox py-1" *ngFor="let type of item?.items | orderBy : 'value'">
                <div *ngIf="!type?.label?.toLowerCase().includes('do not use')">
                  <p-checkbox [name]="type?.label" [value]="type?.value" [inputId]="type?.label" [disabled]="!searchResults" (click)="filter(type)"></p-checkbox>
                  <span class="ml-2">{{type?.label?.toLowerCase() === 'unassigned' ? 'Other brands' : type?.label}}</span>
                </div>
              </li>
            </ul>

          </p-accordionTab>
        </p-accordion>

      </p-accordionTab>
    </p-accordion>
  </div>
  <!-- End mobile version filter function -->

  <!-- Products list -->
  <div class="container px-3">

    <div class="row">
      <!-- Content -->
      <div class="order-md-2 col-12 col-lg-9">
        <div class="g-pl-15--lg">
          <app-products-side-bar *ngIf="globals.isMobile"></app-products-side-bar>

          <div class="g-mb-50"  *ngIf="(searchResults?.data ? searchResults?.data : searchResults) else loading">

            <!-- Products grid view -->
            <div class="row" *ngIf="isGridView else listView">
              <div class="mx-auto g-py-70" *ngIf="searchResults?.data ? searchResults?.data?.length < 1 : searchResults?.length < 1">
                <h3>No product in this category!</h3>
              </div>
              <!--<div class="col-6 col-md-3 g-py-20 px-3 u-shadow-v1-4"-->
              <div class="col-6 col-md-3 g-py-20--lg g-py-10 px-3 px-sm-4"
                   *ngFor="let product of searchResults | filterBy: productsFilter, let i = index">
                <div class="u-block-hover u-block-hover__additional--jump">
                  <div [ngClass]="hover[i] ? 'hover-img' : ''">
                    <a class="g-text-underline--none--hover" [routerLink]="'/product/' + product?.id" target="_blank" (click)="openProductDetails(product?.id, catWebName, catWebType, subCatName)">
                      <!-- Product -->
                      <figure class="g-pos-rel g-mb-5 g-pt-3 text-center align-middle g-height-100 g-height-200--lg">
                        <img class="img-fluid g-max-height-85 g-max-height-180--lg hume-product-img" (mouseover)="hover[i] = true" (mouseleave)="hover[i] = false" (error)="img.src = globals.defaultImgUrl"
                             [src]="hover[i] ? (product?.photoUrl?.length > 1 ? product?.photoUrl[1] : product?.photoUrl[0]) : product?.photoUrl[0]" alt="{{product?.description ? product?.description : product?.name}}" #img>
                        <span class="u-ribbon-v1 g-width-50 g-height-50 g-color-white g-bg-deeporange g-font-size-13 text-center g-rounded-50x g-top-5 g-right-0 g-px-2 g-py-15 font-weight-bold"
                              *ngIf="product?.msrp > product?.grossPrice">{{calDiscount(product)}}</span>
                        <span class="u-ribbon-v1 g-width-50 g-height-50 g-color-white g-bg-deeporange g-font-size-13 text-center g-rounded-50x g-top-0 g-right-0 g-px-2 g-py-15 font-weight-bold" *ngIf="product?.status === 3">Special</span>
                        <span class="u-ribbon-v1 g-width-60 g-height-60 g-color-black g-bg-yellow g-font-size-12 text-center g-rounded-50x g-top-0 g-right-0 g-px-2 g-py-10 font-weight-bold" *ngIf="product?.status === 4">EOFY sales</span>
                        <span class="u-ribbon-v1 g-width-60 g-height-60 g-color-white g-bg-blue g-font-size-13 text-center g-rounded-50x g-top-0 g-right-0 g-px-2 g-py-15 font-weight-bold" *ngIf="product?.status === 5">Custom Made</span>
                      </figure>

                      <div class="media" style="min-height: 110px">
                        <div class="d-flex flex-column">
                          <h4 class="h6 g-color-black mb-1" style="min-height: 76px">
                            <!--<a class="u-link-v5 g-color-black" [routerLink]="['/online-store/single-product']" [queryParams]="{ title: product?.name, id: product?.id}">-->
                            {{product?.description}}
                          </h4>
                          <a class="d-inline-block g-color-gray-dark-v5 g-font-size-13">{{ product?.code}}</a>
                          <a class="d-inline-block g-color-gray-dark-v5 g-font-size-17 font-weight-bold" *ngIf="product?.brand?.toLowerCase() !== 'unassigned' else noBrand">{{ product?.brand}}</a>
                          <ng-template #noBrand>
                            <a class="d-inline-block g-min-height-27"></a>
                          </ng-template>
                          <span class="g-font-size-14 g-color-gray-dark-v5 d-block font-weight-bold" style="text-decoration: line-through" *ngIf="product?.msrp > product?.grossPrice">Was {{product?.msrp | currency}}</span>
                          <span class="d-block g-font-size-20 font-weight-bold" *ngIf="product?.grossPrice !== 0 && !product?.hidePrice else priceZero1" [ngClass]="((product?.msrp > product?.grossPrice) || product?.status === 3) ? 'g-color-primary' : 'g-color-black'">{{product?.grossPrice | currency}}
                            <span class="ml-2 g-color-gray-dark-v5 g-font-size-13">({{product?.uom}})</span></span>
                          <ng-template #priceZero1>
                            <span class="d-block g-font-size-20 font-weight-bold" style="min-height: 32px !important;"></span>
                          </ng-template>
                        </div>
                      </div>
                    </a>
                  </div>
                </div>

                <div class="row list-inline mt-2 media-body px-2" *ngIf="product?.grossPrice !== 0 && product?.multiSize !== 1 else priceZero">
                  <app-add-to-cart-button pageUrl="list" [product]="product" (addToCartEvent)="alertUpdate($event)"></app-add-to-cart-button>
                </div>
                <ng-template #priceZero>
                  <div class="row list-inline mt-2 media-body px-2">
                    <button class="btn u-btn-outline-black g-font-weight-600 btn-border-no-shadow g-px-15" [routerLink]="'/product/' + product?.id" target="_blank" (click)="openProductDetails(product?.id, catWebName, catWebType, subCatName)">
                      <i class="fas fa-list mr-2"></i><span class="g-hidden-md-down">Click to see more</span></button>
                  </div>
                </ng-template>
                <!-- End Product -->
              </div>
            </div>
            <!-- End Products grid view-->

            <!-- Products list view -->
            <ng-template #listView>
              <div class="mx-auto g-py-70" *ngIf="searchResults?.data ? searchResults?.data?.length < 1 : searchResults?.length < 1">
                <h3>No product in this category!</h3>
              </div>
              <div class="row no-gutters g-py-10 g-py-30--sm mb-3 g-brd-bottom g-brd-gray-light-v4" *ngFor="let item of searchResults | filterBy: productsFilter, let i = index">
                <div class="col-5 pr-3">
                  <span class="helper"></span>
                  <a class="u-basket__product-img" [routerLink]="'/product/' + item?.id" target="_blank" (click)="openProductDetails(item?.id, catWebName, catWebType, subCatName)">

                    <img class="img-fluid g-max-height-180 hume-product-img" (mouseover)="hover[i] = true" (mouseleave)="hover[i] = false" (error)="img.src = globals.defaultImgUrl"
                         [src]="hover[i] ? (item?.photoUrl?.length > 1 ? item?.photoUrl[1] : item?.photoUrl[0]) : item?.photoUrl[0]" alt="{{item?.description ? item?.description : item?.name}}" #img>
                    <span class="u-ribbon-v1 g-width-50 g-height-50 g-color-white g-bg-deeporange g-font-size-13 text-center g-rounded-50x g-top-5 g-right-0 g-px-2 g-py-15 font-weight-bold"
                          *ngIf="item?.msrp > item?.grossPrice">{{calDiscount(item)}}</span>
                    <span class="u-ribbon-v1 g-width-50 g-height-50 g-color-white g-bg-deeporange g-font-size-13 text-center g-rounded-50x g-top-0 g-right-7 g-px-2 g-py-15 font-weight-bold" *ngIf="item?.status === 3">Special</span>
                  </a>
                </div>

                <div class="col-7">
                  <a class="u-basket__product-img" [routerLink]="'/product/' + item?.id" target="_blank" (click)="openProductDetails(item?.id, catWebName, catWebType, subCatName)">
                    <div class="d-inline-block align-middle pl-2 mb-2 col-12">
                      <h4 class="h6 g-color-black">{{item?.name ? item?.name : item?.description}}</h4>
                      <span class="d-inline-block g-color-gray-dark-v5 g-font-size-13 mb-2">{{ item?.code}}</span>
                      <span class="d-block g-font-size-20 font-weight-bold mb-1 mb-sm-4" *ngIf="item?.grossPrice !== 0 && !item?.hidePrice else priceZero1Grid" [ngClass]="((item?.rrp > item?.grossPrice) || item?.status === 3) ? 'g-color-primary' : 'g-color-black'">{{item?.grossPrice | currency}}
                        <span class="g-font-size-14 g-color-gray-dark-v5" style="text-decoration: line-through" *ngIf="item?.rrp > item?.grossPrice">Was {{item?.rrp | currency}}</span>
                      </span>
                      <ng-template #priceZero1Grid>
                        <span class="d-block g-font-size-20 font-weight-bold mb-1 mb-sm-4" style="min-height: 32px !important;"></span>
                      </ng-template>
                    </div>
                  </a>
                  <div class="d-flex" *ngIf="item?.grossPrice !== 0 else priceZeroGrid">
                    <app-add-to-cart-button pageUrl="list" [product]="item" (addToCartEvent)="alertUpdate($event)"></app-add-to-cart-button>
                  </div>
                  <ng-template #priceZeroGrid>
                    <div class="d-flex">
                      <button class="btn u-btn-outline-black g-font-weight-600 btn-border-no-shadow g-px-15" (click)="openProductDetails(item?.id, catWebName, catWebType, subCatName)">
                        <i class="fas fa-list mr-2"></i><span class="g-hidden-md-down">Click to see more</span></button>
                    </div>
                  </ng-template>
                </div>
              </div>
            </ng-template>
            <!-- End products list view -->
          </div>

          <!-- Loading skeleton-->
          <ng-template #loading>
            <div class="row g-pt-30 g-mb-50" *ngIf="isGridView else listViewLoading">
              <div class="col-6 col-md-3 g-py-20 px-3 g-height-250 g-height-350--md" *ngFor="let product of generateFake(20)">
                <figure class="g-mb-20 g-pt-3 text-center align-middle">
                  <div class="g-hidden-md-down">
                    <ngx-skeleton-loader
                      [theme]="{ width: '80%', height: '170px'}" ></ngx-skeleton-loader>
                  </div>
                  <div class="g-hidden-md-up">
                    <ngx-skeleton-loader
                      [theme]="{ width: '80%', height: '80px'}" ></ngx-skeleton-loader>
                  </div>

                </figure>
                  <div class="d-flex flex-column text-center align-middle">
                    <h4 class="h6 g-color-black mb-1" style="min-height: 57px">
                      <ngx-skeleton-loader
                        [theme]="{ width: '80%', height: '40px'}"></ngx-skeleton-loader>
                    </h4>
                    <ngx-skeleton-loader
                      [theme]="{ width: '80%', height: '40px'}"></ngx-skeleton-loader>
                  </div>
                <!-- End Product -->
              </div>
            </div>

            <ng-template #listViewLoading>
              <div class="g-brd-bottom g-brd-gray-light-v4 row g-pt-30" *ngFor="let product of generateFake(20)">
                <div class="col-6 col-sm-5 col-lg-4 g-mb-30">
                  <ngx-skeleton-loader
                    [theme]="{ width: '100%', height: '170px'}" ></ngx-skeleton-loader>
                </div>

                <div class="col-6 col-sm-7 col-lg-8 g-mb-15">
                  <!-- Product Info -->
                  <div class="g-mb-0 g-mb-30--sm">
                    <h4 class="h5 g-color-black mb-0 mt-1">
                      <ngx-skeleton-loader
                        [theme]="{ width: '100%', height: '50px'}" ></ngx-skeleton-loader>
                    </h4>
                    <ngx-skeleton-loader
                      [theme]="{ width: '30%', height: '30px'}" ></ngx-skeleton-loader>
                  </div>
                  <!-- End Product Info -->
                  <ngx-skeleton-loader
                    [theme]="{ width: '60%', height: '50px'}" ></ngx-skeleton-loader>
                </div>
              </div>
            </ng-template>

          </ng-template>
          <!-- End loading skeleton-->

          <!-- Pagination -->
          <div class="mb-5">
            <p-paginator class="mb-5" [rows]="productsNumber?.rows" [totalRecords]="productsNumber?.totalResult" [rowsPerPageOptions]="[20,50,100]" pageLinkSize="10" (onPageChange)="onPageChange($event)"></p-paginator>
          </div>

          <!-- End Pagination -->
        </div>
      </div>
      <!-- End Content -->

      <!-- Side bar filter -->
      <div class="col-3 order-md-1 g-brd-right--lg g-brd-gray-light-v4 g-hidden-md-down" *ngIf="eCategories else sideBarLoading">
        <div class="g-pr-15--lg">
          <!-- Desktop filter functions -->
          <div *ngIf="groupFilters else filterLoading">
            <div class="g-my-30" *ngIf="groupFilters?.length > 0">

              <p-accordion [multiple]="true">
                <p-accordionTab [selected]="true" [header]="item?.label" *ngFor="let item of groupFilters | orderBy: 'label'; let i = index">
                  <ul class="list-unstyled">
                    <li class="p-field-checkbox py-1" *ngFor="let type of item?.items | orderBy : 'value'">
                      <div *ngIf="!type?.label?.toLowerCase().includes('do not use')">
                        <p-checkbox [name]="type?.label" [value]="type?.value" [inputId]="type?.label" [(ngModel)]="selectedModelFilter[i]" [disabled]="!searchResults" (click)="filter(type)"></p-checkbox>
                        <span class="ml-2">{{type?.label?.toLowerCase() === 'unassigned' ? 'Other brands' : type?.label}}</span>
                      </div>
                    </li>
                  </ul>

                </p-accordionTab>
              </p-accordion>
              <div class="my-4 text-center">
                <button class="btn u-btn-primary g-font-size-14 text-uppercase g-py-12 g-px-25 mt-4 shadow-none font-weight-bold" (click)="clearFilters()">Reset filter</button>
              </div>

            </div>
          </div>
          <ng-template #filterLoading>
            <div class="g-my-50" *ngIf="sideBarLoadingF">
              <div *ngFor="let product of generateFake(3)">
                <ngx-skeleton-loader
                  [theme]="{ width: '100%', height: '150px'}" ></ngx-skeleton-loader>
              </div>

            </div>
          </ng-template>

          <!-- End desktop filter functions -->
        </div>
      </div>
      <!-- End Side bar filter -->

      <ng-template #sideBarLoading>
        <div class="col-3 order-md-1 g-brd-right--lg g-brd-gray-light-v4 g-hidden-md-down g-my-30">
          <div *ngFor="let product of generateFake(15)">
            <ngx-skeleton-loader
              [theme]="{ width: '100%', height: '50px'}" ></ngx-skeleton-loader>
          </div>

        </div>
      </ng-template>
    </div>
  </div>
  <!-- End Products -->

  <!--<div style="position: absolute; top: 150px; right:-3px">
    <button mdbBtn type="button" color="primary" rounded="true" class="g-color-white" mdbWavesEffect (click)="basicModal.show()">
      <span class="u-badge-v1--sm g-color-white g-bg-transparent g-font-size-11 g-line-height-1_4 g-rounded-50x g-pa-4 font-weight-bold"
            style="top: 11px !important; font-size: 15px !important; right: 23px !important;">{{globals?.cart?.length}}</span>
      <i class="fa fa-shopping-cart g-color-white g-font-size-25"></i>
    </button>
  </div>-->


  <!-- Alert -->
  <app-alert-components id="wishlist-alert1" *ngIf="showAlert !== 'close'" [showAlert]="showAlert" type="mini"></app-alert-components>
  <!-- End Alert -->

  <!-- Call to Action -->
  <app-call-to-action></app-call-to-action>
  <!-- End Call to Action -->

</div>
