<div class="single-component-container">

  <!-- product Description -->
  <div class="container g-pt-45 g-pt-40--md" *ngIf="(product?.status > 0 && isLoadingDone) else offShelfProduct">
    <!-- Bread crumb section -->
    <app-bread-crumb [catWebName]="catWebName" [subCategories]="subCategories" [subCatName]="subCatName" (messageEvent)="breadCrumbMessage($event)"></app-bread-crumb>
    <!-- End bread crumb section -->

    <div class="row mt-3">
      <!-- Product image section -->
      <div class="col-lg-7 g-mb-30 g-mb-0--lg g-min-height-500--md g-min-height-4">
        <div class="row align-items-center">
          <div class="col-9 g-mt-50--lg g-mt-20--sm mx-auto" *ngIf="product?.photos">
            <span class="u-ribbon-v1 g-width-50 g-height-50 g-color-white g-bg-deeporange g-font-size-13 text-center g-rounded-50x g-top-5 g-right-0 g-px-2 g-py-10 font-weight-bold"
                  *ngIf="product?.msrp > product?.grossPrice">{{'-' + discountRate}}</span>
            <span class="u-ribbon-v1 g-width-50 g-height-50 g-color-white g-bg-deeporange g-font-size-13 text-center g-rounded-50x g-top-0 g-right-0 g-px-2 g-py-15 font-weight-bold" *ngIf="product?.status === 3">Special</span>
            <span class="u-ribbon-v1 g-width-60 g-height-60 g-color-black g-bg-yellow g-font-size-12 text-center g-rounded-50x g-top-0 g-right-0 g-px-2 g-py-10 font-weight-bold" *ngIf="product?.status === 4">EOFY sales</span>
            <span class="u-ribbon-v1 g-width-60 g-height-60 g-color-white g-bg-blue g-font-size-13 text-center g-rounded-50x g-top-0 g-right-0 g-px-2 g-py-15 font-weight-bold" *ngIf="product?.status === 5">Custom Made</span>

            <ngb-carousel *ngIf="product?.photos?.length > 0 else noImg" id="single-product-carousel">
                <ng-template ngbSlide *ngFor="let photo of product?.photos, let i = index" data-animation="slide">
                  <div id="single-product">
                    <a (click)="zoomImg(i)">
                      <figure class="g-pos-rel g-mb-20 text-center" *ngIf="!selectedPic">
                        <!--<lib-ngx-image-zoom thumbImage='https://hume-website-photo.s3-ap-southeast-2.amazonaws.com/Photo_2021-01-18_02-56-20.jpg' [fullImage]=photo style="max-height: 400px"></lib-ngx-image-zoom>-->
                        <img class="img-fluid" [src]="photo?.includes('https://') ? photo : imgUrl + photo" (error)="img.src = globals?.defaultImgUrl" alt="{{product?.name}}" #img style="max-height: 400px">

                        <!--<i class="fa fa-search-plus search-plus-icon g-color-gray-dark-v4" (click)="zoomImg(photo)"></i>-->
                      </figure>
                    </a>
                    <figure class="g-pos-rel g-mb-20 text-center" *ngIf="selectedPic">
                      <img class="img-fluid" [src]="selectedPic" (error)="img.src = globals?.defaultImgUrl" alt="{{product?.name}}" #img style="max-height: 400px">
                    </figure>
                  </div>
                </ng-template>
            </ngb-carousel>
            <ng-template #noImg>
              <figure class="g-pos-rel g-mb-20 g-width-400 text-center">
                <img class="img-fluid mt-1 g-max-height-500" [src]="globals.defaultImgUrl" alt="{{product?.name}}">
                <!--<span class="u-ribbon-v1 g-width-50 g-height-50 g-color-white g-bg-deeporange g-font-size-13 text-center g-rounded-50x g-top-0 g-right-0 g-px-2 g-py-15 font-weight-bold" *ngIf="product?.status === 3">Special</span>
                <span class="u-ribbon-v1 g-width-60 g-height-60 g-color-black g-bg-yellow g-font-size-12 text-center g-rounded-50x g-top-0 g-right-0 g-px-2 g-py-10 font-weight-bold" *ngIf="product?.status === 4">EOFY sales</span>
                <span class="u-ribbon-v1 g-width-60 g-height-60 g-color-white g-bg-blue g-font-size-13 text-center g-rounded-50x g-top-0 g-right-0 g-px-2 g-py-15 font-weight-bold" *ngIf="product?.status === 5">Custom Made</span>-->
              </figure>
            </ng-template>
          </div>
        </div>
      </div>
      <!-- End product image section -->

      <!-- Product name and add to cart button -->
      <div class="col-lg-5" *ngIf="product">
        <div class="g-px-40--lg" *ngIf="multiSizeStatus !== 1 else child">
          <!-- product name Info -->
          <div class="g-mb-30" *ngIf="product?.name">
            <h1 class="g-font-size-24 g-font-size-32--md mb-4 g-color-primary">{{product?.name}}</h1>
          </div>
          <ngx-skeleton-loader *ngIf="!product?.name" [theme]="{height: '130px'}"></ngx-skeleton-loader>
          <!-- End product name Info -->

          <!-- Price -->
          <div class="g-mb-30--md g-mb-10" *ngIf="!product?.hidePrice">
            <span class="g-font-size-18 g-color-gray-dark-v5" style="text-decoration: line-through" *ngIf="product?.msrp > product?.grossPrice">
                  Was {{product?.msrp | currency: 'AUD' : '$' :  '1.2-2'}}</span>
            <span class="g-color-black g-font-weight-500 g-font-size-40 mr-2">{{product?.grossPrice | currency:'AUD' : '$' :  '1.2-2'}}
              <span class="g-font-size-15 g-color-gray-dark-v5" *ngIf="product?.grossPrice">(Inc. GST)</span>
            </span>
            <p class="mt-0" *ngIf="product?.msrp > product?.grossPrice">You save: <span class="g-color-red g-font-size-17">{{ '$' + ((product?.msrp - product?.grossPrice) | number: '1.2-2') + ' (' + discountRate + ')'}}</span></p>
            <ngx-skeleton-loader *ngIf="!product?.grossPrice && product?.grossPrice !== 0" [theme]="{height: '50px'}"></ngx-skeleton-loader>
            <div class="row mt-2" *ngIf="product?.measurement?.sqm > 0">
              <div class="col-11 col-md-10">
                <button class="btn btn-md u-btn-primary g-font-weight-600 g-color-white g-letter-spacing-0_5 text-uppercase g-rounded-5 g-mr-10 g-mb-15 shadow-none" (click)="showUnitPrice()">
                  <i class="fa fa-calculator g-ml-3 mr-2 g-font-size-15"></i>
                  square metre rate
                </button>
                <!--<button class="btn btn-block u-btn-primary g-font-size-13 font-weight-bold text-uppercase shadow-none" type="button" (click)="showUnitPrice()">
                  Calculate square metre rate
                </button>-->
              </div>
              <div class="col-12" [hidden]="hidePrice">
                <span class="g-color-black py-3 g-font-weight-500 g-font-size-25 g-color-gray-dark-v5 mr-2">
              {{priceConverter(product?.grossPrice, product?.measurement?.sqm) | currency:'AUD' : '$' :  '1.2-2'}}
                  <span class="g-font-size-15 g-color-gray-dark-v5">(per sqm)</span>
            </span>
              </div>
            </div>

            <!--<s class="g-color-gray-dark-v4 g-font-weight-500 g-font-size-16">$101.00</s>-->
          </div>
          <!-- End Price -->

          <!-- Code -->
          <div class="d-flex justify-content-between align-items-center g-brd-bottom g-brd-gray-light-v3 py-3 g-mb-10" role="tab">
            <div class="row col-6" *ngIf="product?.code">
              <h5 class="g-color-gray-dark-v5 font-weight-bold g-font-size-default mb-1 mr-1">Code:</h5>
              <h5 class="g-color-gray-dark-v5 g-font-weight-400 g-font-size-default">{{product?.code}}</h5>
            </div>
            <div class="row col-6" *ngIf="product?.brand?.name && product?.brand?.name.toLowerCase() !== 'unassigned'">
              <h5 class="g-color-gray-dark-v5 font-weight-bold g-font-size-default mb-1 mr-1">Brand:</h5>
              <h5 class="g-color-gray-dark-v5 g-font-weight-400 g-font-size-default">{{product?.brand?.name}}</h5>
            </div>
          </div>
          <div class="d-flex justify-content-between align-items-center g-brd-bottom g-brd-gray-light-v3 py-3 g-mb-10" role="tab"
          *ngIf="product?.measurement?.sqm || product?.measurement?.weight">
            <div class="row col-6" *ngIf="product?.measurement?.sqm">
              <h5 class="g-color-gray-dark-v5 font-weight-bold g-font-size-default mb-1 mr-1">SQM:</h5>
              <h5 class="g-color-gray-dark-v5 g-font-weight-400 g-font-size-default">{{product?.measurement?.sqm | number: '1.2-2'}} m<sup>2</sup></h5>
            </div>
            <div class="row col-6" *ngIf="product?.measurement?.weight">
              <h5 class="g-color-gray-dark-v5 font-weight-bold g-font-size-default mb-1 mr-1">Weight:</h5>
              <h5 class="g-color-gray-dark-v5 g-font-weight-400 g-font-size-default">{{product?.measurement?.weight | number: '1.2-2'}} kg</h5>
            </div>
          </div>
          <!-- End Code -->

          <!-- Quantity -->
          <div class="d-flex justify-content-between align-items-center g-brd-bottom g-brd-gray-light-v3 py-3 g-mb-10" role="tab">
            <h5 class="g-color-gray-dark-v5 font-weight-bold g-font-size-default mb-0">{{product?.packs?.length >0 ? 'Minimum Order Quantity' : 'Quantity'}}</h5>

            <div class="js-quantity input-group u-quantity-v1 g-width-140 g-brd-primary--focus">
              <input id="changeHourStep1356" name="changeHourStep1356" type="number" value="0" min="0" class="form-control form-control-sm" [(ngModel)]="addQuantity" />
              <label class="ml-3" *ngIf="product?.measurement?.uom !== null">{{addQuantity > 1 ? (product?.measurement?.uom.toLowerCase() === 'each' ? product?.measurement?.uom : product?.measurement?.uom + 'S') : product?.measurement?.uom}}</label>
            </div>
          </div>
          <!-- End Quantity -->

          <div class="row g-mx-minus-5 g-mb-20">
            <div class="g-font-size-17 text-uppercase g-color-primary">Delivery</div>
            <div>Freights fees are estimates only and are subjected to change. When ordering online, our customer service will contact you to confirm delivery costs and delivery date. Quotes will save with the base freight fee, please contact 13 48 63 to receive an updated freight estimate or to make any changes to your order. Delivery only available in Sydney Metro, for all other states and out of area please contact 13 48 63 for further assistance. </div>
          </div>

          <!-- Buttons -->
          <div class="row g-mx-minus-5 g-mb-20">
            <div class="col g-px-5 g-my-10 g-my-5--md" *ngIf="globals.ecommerceDisabled">
              <a href="tel:134863">
                <button class="btn btn-block u-btn-primary g-font-size-17 text-uppercase g-py-15 g-px-25" type="button" >
                  Call 13 4863 to order <i class="align-middle ml-2 icon-finance-100 u-line-icon-pro"></i>
                </button>
              </a>
            </div>
            <div class="g-mx-minus-5 g-mb-0 g-mt-10 g-mb-20--sm g-mt-30--sm" *ngIf="!globals.ecommerceDisabled" style="width: 100%">

              <div class="d-flex g-my-10 g-my-5--md" *ngIf="product?.grossPrice > 0">
                <app-add-to-cart-button pageUrl="single" [product]="product" [addQuantity]="addQuantity" (addToCartEvent)='alertUpdate($event)'></app-add-to-cart-button>
              </div>

              <a class="d-flex mt-2 px-1" *ngIf="wizardList?.length > 0">
                <button class="btn btn-block u-btn-primary g-font-size-13 font-weight-bold text-uppercase shadow-none" type="button" (click)="openProductWizard()">
                  Smart Add
                </button>
              </a>
              <div class="mt-4">
                <p-accordion>
                  <p-accordionTab header="Find in store">
                    <div class="py-2">
                      <div class="table-responsive border-top-0" *ngIf="stocks?.length > 0 else info">
                        <table class="table">
                          <thead>
                          <tr>
                            <th>Branch</th>
                            <th>Status</th>
                          </tr>
                          </thead>

                          <tbody>
                          <tr *ngFor="let stock of stocks">
                            <td><a class="g-color-black" routerLink="{{'/hume-stores/' + (stock?.branch.toLowerCase() !== 'sunshine' ? stock?.branch : 'Sunshine_west')}}">{{stock?.branch}}</a></td>
                            <td>
                              <span class="u-label u-label-warning g-color-white" *ngIf="stock?.status === 1">Low stock</span>
                              <span class="u-label u-label-success g-color-white" *ngIf="stock?.status === 2">In stock</span>
                              <span class="u-label u-label-danger g-color-white" *ngIf="stock?.status === 0">Special order</span>
                            </td>
                          </tr>
                          </tbody>
                        </table>
                        <div class="alert alert-warning" role="alert">
                          * For non stock and special order product, maybe subject to additional surcharge. Please contact <a class="g-color-primary font-weight-bold" href="tel:134863">13 4863</a> for more information.
                        </div>
                      </div>
                      <ng-template #info>
                        <div class="alert alert-warning" role="alert">
                          * Please contact <a class="g-color-primary font-weight-bold" href="tel:134863">13 4863</a> for more information.
                        </div>
                      </ng-template>
                    </div>
                  </p-accordionTab>
                </p-accordion>
              </div>

            </div>
          <!-- End Buttons -->



          </div>
        </div>
        <ng-template #child>
          <div class="g-px-40--lg">
            <!-- Product name Info -->
            <div class="g-mb-30">
              <h1 class="g-font-size-24 g-font-size-32--md mb-4 g-color-primary">{{product?.name}}</h1>
            </div>
            <div *ngIf="childrenProperties?.length > 0 else childLoading">
              <button class="btn btn-md btn-border-no-shadow g-font-size-13 g-mr-10 g-mb-15"
                      *ngFor="let item of childrenProperties | sortBy:'asc', let i = index" [ngClass]="i === childIndex ? 'u-btn-primary' : 'u-btn-outline-primary'" (click)="this.openModal(i);">{{item}}</button>
            </div>
            <ng-template #childLoading>
              <ngx-skeleton-loader [theme]="{ width: '100%', height: '36px'}"> </ngx-skeleton-loader>
            </ng-template>
            <!-- End product name Info -->
          </div>
        </ng-template>
      </div>
      <!-- End product name and add to cart button -->
    </div>
  </div>
  <!-- End product? Description -->

  <!-- Product wizard content-->
  <div *ngIf="isWizardOpen && wizardList?.length > 0 && product?.status > 0" class="container g-px-10 g-px-30--sm mb-3">
    <div class="card g-bg-black-opacity-0_1 g-pa-15 g-pa-30--sm">
      <ul class="list-unstyled" *ngFor="let item1 of wizardList">
        <li class="row py-2" *ngFor="let item of item1?.products; let i = index">
          <div class="col-md-2 col-3 pr-1 text-center g-bg-white">
            <span class="helper"></span>
            <a [routerLink]="['/product']" [queryParams]="{ title: item?.name, id: item?.id}">
              <img class="img-fluid g-height-80" (mouseover)="hoverW[i] = true" (mouseleave)="hoverW[i] = false" (error)="img.src = globals.defaultImgUrl"
                   [src]="hoverW[i] ? (item?.photoUrl?.length > 1 ? item?.photoUrl[1] : item?.photoUrl[0]) : item?.photoUrl[0]" alt="{{item?.description ? item?.description : item?.name}}" #img>
            </a>
          </div>

          <div class="col-6 col-md-7">
            <div class="d-inline-block align-middle pl-2 mb-2 col-12">
              <h4 class="g-font-size-16--md g-font-size-11 g-color-black">{{item?.name ? item?.name : item?.description}}</h4>
              <ul class="list-unstyled g-color-gray-dark-v4 g-font-size-12 g-line-height-1_6 mb-0">
                <li *ngIf="!item?.hidePrice">Price: {{item?.grossPrice | currency: 'AUD' : '$' :  '1.2-2'}}</li>
                <li class="g-hidden-xs-down">Code: {{item?.code}}</li>
              </ul>
            </div>
          </div>

          <div class="col-3 col-md-3">
            <div class="d-flex justify-content-between align-items-center py-3 g-mb-10" role="tab">
              <h5 class="g-color-gray-dark-v5 font-weight-bold g-font-size-default mb-0 g-hidden-sm-down">Quantity</h5>

              <div class="js-quantity input-group u-quantity-v1 g-width-120 g-brd-primary--focus">
                <input type="number" value="0" min="0" class="form-control form-control-sm" [(ngModel)]="wizardQuantity[i]" (ngModelChange)="updateQuantity($event, i, item?.id)"/>
                <label class="ml-3" *ngIf="item?.measurement?.uom !== null">{{item?.measurement?.uom}}</label>
              </div>
            </div>
            <p *ngIf="wizardQuantity[i] < 0 || wizardQuantity[i] === null" class="g-color-primary">*Invalid quantity</p>
          </div>
        </li>
      </ul>

      <div class="mt-3 mx-auto g-width-50x">
        <button class="btn btn-block u-btn-primary g-font-size-13 font-weight-bold text-uppercase shadow-none" type="button" (click)="wizardAddToCart(product)">
          <i class="fas fa-cart-plus mr-2 g-hidden-xs-down"></i>Add to cart
        </button>
      </div>
    </div>

  </div>
  <!-- Product wizard content -->

  <!-- Description & Review -->
  <div class="container g-px-30--sm g-px-5 g-mb-50--sm g-mb-20" *ngIf="product?.status > 0">

    <p-accordion>
      <p-accordionTab [selected]="true">
        <ng-template pTemplate="header">
          <h4 class="mb-0 g-color-primary px-3">
            Description
          </h4>
        </ng-template>
        <ng-template pTemplate="content">
          <div class="card-body py-3 py-sm-5 px-3 px-sm-5 bottom-border" [innerHTML]="sanitizer.bypassSecurityTrustHtml(product?.description)">
          </div>
        </ng-template>
      </p-accordionTab>

      <p-accordionTab *ngIf="product?.tags?.length > 0">
        <ng-template pTemplate="header">
          <h4 class="mb-0 g-color-primary px-3">
            Specifications
          </h4>
        </ng-template>
        <ng-template pTemplate="content">
          <ul class="py-3 py-sm-5">
            <li class="row" *ngFor="let item of product?.tags">
              <div class="col-2 col-sm-4">
                <h5 class="g-color-gray-dark-v5 font-weight-bold g-font-size-default mb-0">{{item?.key}}</h5>
              </div>
              <div class="col-10 col-sm-8">
                <h5 class="g-color-gray-dark-v5 g-font-weight-400 g-font-size-default ml-3">{{item?.value}}</h5>
              </div>
            </li>
          </ul>
        </ng-template>
      </p-accordionTab>

      <p-accordionTab *ngIf="product?.attachments?.length > 0">
        <ng-template pTemplate="header">
          <h4 class="mb-0 g-color-primary px-3">
            Files
          </h4>
        </ng-template>
        <ng-template pTemplate="content">
          <ul class="py-3 py-sm-5">
            <li class="row" *ngFor="let item of product?.attachments">
              <div class="col-5">
                <h5 class="g-color-gray-dark-v5 font-weight-bold g-font-size-default mb-0">{{item?.description}}</h5>
              </div>
              <div class="col-4">
                <h5 class="g-color-gray-dark-v5 font-weight-bold g-font-size-default mb-0">{{item?.meta}}</h5>
              </div>
              <div class="col-2">
                <a class="g-font-size-14 g-font-size-18--md g-color-primary" target="_blank" (click)="openFile(item?.url)">
                  <i class="fa fa-download mr-2 g-color-primary"></i></a>
              </div>
            </li>
          </ul>
        </ng-template>
      </p-accordionTab>
    </p-accordion>

  </div>
  <!-- End Description & Review -->

  <!-- Related Products -->
  <div class="container g-pt-50--md g-pt-20 g-pb-20" *ngIf="relatedProducts?.length > 0 && product?.status > 0">
    <div class="text-center mx-auto g-max-width-600 g-mb-50">
      <h2 class="g-color-black mb-4">You might also like</h2>
    </div>

    <div class="row">
      <a class="col-6 col-lg-3 g-mb-30 g-py-15 g-px-20 g-text-underline--none--hover hoverable" *ngFor="let relatedProduct of relatedProducts, let i = index">
        <!-- product? -->
        <figure class="g-pos-rel g-mb-20 text-center g-height-250" (click)="openDetails(relatedProduct)">
          <img class="img-fluid hume-product-img" (mouseover)="hover[i] = true" (mouseleave)="hover[i] = false" (error)="img.src = globals.defaultImgUrl"
               [src]="hover[i] ? (relatedProduct?.photoUrl?.length > 1 ? relatedProduct?.photoUrl[1] : relatedProduct?.photoUrl[0]) : relatedProduct?.photoUrl[0]"
               alt="{{relatedProduct?.description}}" [ngStyle]="{'max-height': globals.isMobile ? '150px' : '210px'}" #img>
          <img class="img-fluid" src="{{globals.defaultImgUrl}}" alt="{{relatedProduct?.description}}"  *ngIf="!relatedProduct?.photoUrl" [ngStyle]="{'max-height': globals.isMobile ? '150px' : '210px'}">
          <span class="u-ribbon-v1 g-width-50 g-height-50 g-color-white g-bg-deeporange g-font-size-13 text-center g-rounded-50x g-top-5 g-right-0 g-px-2 g-py-15 font-weight-bold"
                *ngIf="relatedProduct?.msrp > relatedProduct?.grossPrice">{{calDiscountRel(relatedProduct)}}</span>
          <span class="u-ribbon-v1 g-width-50 g-height-50 g-color-white g-bg-deeporange g-font-size-13 text-center g-rounded-50x g-top-0 g-right-0 g-px-2 g-py-15 font-weight-bold" *ngIf="relatedProduct?.status === 3">Special</span>
          <span class="u-ribbon-v1 g-width-60 g-height-60 g-color-white g-bg-darkpurple g-font-size-10 text-center g-rounded-50x g-top-0 g-right-0 g-px-2 g-py-20 font-weight-bold" *ngIf="relatedProduct?.status === 4">Clearance</span>
          <span class="u-ribbon-v1 g-width-60 g-height-60 g-color-white g-bg-blue g-font-size-13 text-center g-rounded-50x g-top-0 g-right-0 g-px-2 g-py-15 font-weight-bold" *ngIf="relatedProduct?.status === 5">Custom Made</span>
        </figure>

        <div class="media"  style="min-height: 50px" (click)="openDetails(relatedProduct)">
          <!-- product? Info -->
          <div class="d-flex flex-column">
            <h4 class="h6 g-color-black mb-1" style="min-height: 57px">
              <!--<a class="u-link-v5 g-color-black" [routerLink]="['/online-store/single-product']" [queryParams]="{title: relatedProduct?.name, id: relatedProduct?.id}">-->
              <a class="u-link-v5 g-color-black" (click)="openDetails(relatedProduct)">
                {{relatedProduct?.description}}
              </a>
            </h4>
            <a class="d-inline-block g-color-gray-dark-v5 g-font-size-13" >{{ relatedProduct?.code}}</a>
            <a class="d-inline-block g-color-gray-dark-v5 g-font-size-17 font-weight-bold" *ngIf="relatedProduct?.brand?.toLowerCase() !== 'unassigned' else noBrand">{{ relatedProduct?.brand}}</a>
            <ng-template #noBrand>
              <a class="d-inline-block g-min-height-27"></a>
            </ng-template>
              <span class="d-block g-font-size-20 font-weight-bold g-color-black" *ngIf="!relatedProduct?.hidePrice">{{relatedProduct?.grossPrice | currency: 'AUD' : '$' :  '1.2-2'}}
                <span class="g-font-size-14 g-color-gray-dark-v5" style="text-decoration: line-through" *ngIf="relatedProduct?.msrp > relatedProduct?.grossPrice">
                  Was {{relatedProduct?.msrp | currency: 'AUD' : '$' :  '1.2-2'}}</span>
              </span>
          </div>
          <!-- End product? Info -->

        </div>

        <!-- Products Icons -->
        <div class="row list-inline mt-2 media-body px-2" [ngClass]="{'disabled': globals.ecommerceDisabled}">
          <app-add-to-cart-button pageUrl="list" [product]="relatedProduct" (addToCartEvent)="alertUpdate($event)"></app-add-to-cart-button>
        </div>
        <!-- End Products Icons -->
        <!-- End product? -->
      </a>


    </div>
  </div>
  <!-- End Related Products -->

</div>

<!-- Off shelf products -->
<ng-template #offShelfProduct>
  <div class="single-component-container">
    <app-not-found-component [label]="'product'" *ngIf="isLoadingDone else loading"></app-not-found-component>
    <ng-template #loading>
      <div class="container g-pt-45 g-pt-40--md">
        <div class="row mt-3">
          <!-- Product image section -->
          <div class="col-lg-7 g-mb-30 g-mb-0--lg g-min-height-500--md g-min-height-4">
            <div class="row align-items-center">
              <div class="col-9 g-mt-50--lg g-mt-20--sm mx-auto">
                <ngx-skeleton-loader [theme]="{ width: '100%', height: '400px'}"> </ngx-skeleton-loader>
              </div>
            </div>
          </div>

          <div class="col-lg-5">
            <div class="g-px-40--lg g-mt-50--lg g-mt-20--sm">
              <ngx-skeleton-loader [theme]="{height: '130px'}"></ngx-skeleton-loader>
            </div>

            <div class="g-px-40--lg g-mt-20--sm">
              <ngx-skeleton-loader [theme]="{height: '220px'}"></ngx-skeleton-loader>
            </div>
          </div>
        </div>

        <div class="mt-3 mb-5 g-px-40">
          <ngx-skeleton-loader [theme]="{height: '150px'}"></ngx-skeleton-loader>
        </div>
      </div>

    </ng-template>
  </div>
</ng-template>
<!-- End off shelf products -->

<!-- multi size modal -->
<ng-template #longContent let-modal>
  <div class="modal-header">
    <h4 class="modal-title">{{product?.name}}</h4>
    <button type="button" class="close" aria-label="Close" (click)="modal.dismiss('Cross click')">
      <span aria-hidden="true">&times;</span>
    </button>
  </div>
  <div class="modal-body">
    <div class="row mb-2 pb-2 border-bottom" *ngFor="let item of selectedChildProducts, let i = index">
      <div class="col-8">
        <h5 class="g-color-black">{{item?.description}}</h5>
        <h6>{{'Code: ' + item?.code}}</h6>
        <div class="row px-3">
          <h5 class="g-color-primary pt-2" *ngIf="!item?.hidePrice">{{item?.grossPrice | currency}}</h5>
          <div class="mx-auto row">
            <button type="button" class="quantity-selector_button" [disabled]="quantityG[i] === 0" (click)="minusQuantity(i)">
              <span aria-hidden="true" class="g-font-size-25 g-color-gray-dark-v4 g-color-black--hover minus-symbol">-</span>
            </button>
            <input class="quantityChangeInput js-result form-control text-center g-font-size-13 rounded-0 g-pa-0" min="0" type="text"
                   [(ngModel)]="quantityG[i]">
            <button type="button" class="quantity-selector_button" (click)="plusQuantity(i)">
              <span aria-hidden="true" class="g-font-size-20 g-color-gray-dark-v4 g-color-black--hover">+</span>
            </button>
          </div>
        </div>
      </div>
      <div class="col-4 pt-2">
        <div class="d-flex g-my-10 g-my-5--md float-right mr-3">
          <app-add-to-cart-button pageUrl="single-modal" [modal]="modal" [product]="item" [addQuantity]="quantityG[i]" (addToCartEvent)="alertUpdate($event)"></app-add-to-cart-button>
        </div>
      </div>
    </div>
  </div>
  <div class="modal-footer">
    <button type="button" class="btn btn-light btn-border-no-shadow" (click)="modal.close('Close click')">Close</button>
  </div>
</ng-template>
<!-- End multi size modal -->

<!-- Alert -->
<app-alert-components id="wishlist-alert1" *ngIf="showAlert !== 'close'" [showAlert]="showAlert" type="mini"></app-alert-components>
<!-- End Alert -->

<!-- The img zoom Modal -->
<div id="imgModal" class="modal">
  <i class="fa fa-chevron-circle-left  arrow-icon g-pos-abs left-5x" (click)="preImg()" [ngClass]="modalImgIndex > 0 ? 'g-color-white' : ''" *ngIf="product?.photos?.length > 1"></i>
  <div class="img-modal-content text-center">
    <!--<span class="img-close">&times;</span>-->
    <img id="img01">
  </div>
  <i class="fa fa-chevron-circle-right arrow-icon g-pos-abs right-5x" [ngClass]="modalImgIndex < product?.photos?.length - 1 ? 'g-color-white' : ''" (click)="nextImg()" *ngIf="product?.photos?.length > 1"></i>
</div>
<!-- End the img zoom Modal -->
