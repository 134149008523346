import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-terms',
  templateUrl: './terms.component.html',
})
export class TosComponent implements OnInit {

  ngOnInit() {
  }
}
