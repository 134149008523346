import {Component, OnInit} from '@angular/core';

@Component({
  selector: 'app-social-share',
  templateUrl: './social-share.component.html',
  // styleUrls: ['./news.component.scss']
})
export class SocialShareComponent implements OnInit {
  ngOnInit () {
  }
}
